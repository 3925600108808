import { useContext, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import FlowContext from "../contextFlow";

const PathwayResponse = ({
  name,
  responsePathways,
  setResponsePathways,
  responseVariables,
}) => {
  const { elements } = useContext(FlowContext);
  const [isExpanded, setIsExpanded] = useState(false);

  let placeholder = name === "tool" ? "Tool" : "API Request";

  const addVariable = () => {
    setResponsePathways([
      ...responsePathways,
      ["Default/Webhook Completion", "", "", { id: "", name: "" }],
    ]);
  };

  const removeVariable = (index) => {
    setResponsePathways(responsePathways.filter((_, i) => i !== index));
  };

  const updateVariable = (index, position, value) => {
    setResponsePathways(
      responsePathways.map((item, i) => {
        if (i === index) {
          const updatedItem = [...item];
          if (position === 3) {
            value = JSON.parse(value);
            const selectedNode = elements.nodes.find(
              (node) => node.id === value.id,
            );
            updatedItem[position] = {
              id: value.id,
              name: selectedNode?.data.name || "",
            };
          } else {
            if (position === 0 && value === "Default/Webhook Completion") {
              updatedItem[1] = "";
              updatedItem[2] = "";
            }
            updatedItem[position] = value;
          }
          return updatedItem;
        }
        return item;
      }),
    );
  };

  return (
    <div className="border p-4 rounded-md shadow-sm mt-4">
      <div className="flex items-center justify-between">
        <label className="text-xl">Pathway After {placeholder} Response</label>
        <button onClick={() => setIsExpanded(!isExpanded)} className="text-xl">
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <p className="text-lg text-gray-600 mt-1">
        Select the pathway to follow after the {placeholder} response. Pathway
        can be selected based on the {placeholder} response variable matching a
        specific value.
      </p>

      {isExpanded && (
        <>
          <button
            onClick={addVariable}
            className="bg-indigo-500 text-white py-2 px-4 rounded-md text-lg mt-4"
          >
            Add Pathway
          </button>
          {responsePathways.map((item, index) => (
            <div key={index} className="flex items-center gap-4 py-2 mt-4">
              <select
                value={item[0]}
                onChange={(e) => updateVariable(index, 0, e.target.value)}
                className="border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-1/4 p-2.5 text-lg"
              >
                <option value="Default/Webhook Completion">
                  {placeholder.charAt(0).toUpperCase()}
                  {placeholder.slice(1)} Completion (Default)
                </option>
                {responseVariables.map(
                  (variable, idx) =>
                    variable.name !== "" && (
                      <option key={idx} value={variable.name}>
                        {variable.name}
                      </option>
                    ),
                )}
              </select>
              <select
                value={item[1]}
                onChange={(e) => updateVariable(index, 1, e.target.value)}
                disabled={item[0] === "Default/Webhook Completion"}
                className={`border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-1/4 p-2.5 text-lg ${
                  item[0] === "Default/Webhook Completion"
                    ? "bg-gray-50 text-gray-500"
                    : "text-gray-900"
                }`}
              >
                <option value="">Select Condition</option>
                <option value="==">[==] Equals</option>
                <option value="!=">[!=] Not Equals</option>
                <option value=">">{"[>] More than"}</option>
                <option value="<">{"[<] Less than"}</option>
                <option value=">=">{"[>=] More than or equals to"}</option>
                <option value="<=">{"[<=] Less than or equals to"}</option>
                <option value="contains">{"[contains] Contains"}</option>
                <option value="!contains">
                  {"[!contains] Does not contain"}
                </option>
                <option value="is">{"[is] Is"}</option>
              </select>
              {item[1] === "is" ? (
                <select
                  value={item[2]}
                  onChange={(e) => updateVariable(index, 2, e.target.value)}
                  disabled={item[0] === "Default/Webhook Completion"}
                  className="border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-1/4 p-2.5 text-lg"
                >
                  <option value="">Select Value</option>
                  <option value={"null"}>[null] Null</option>
                  <option value="not null">[not null] Not Null</option>
                  <option value="true">[true] True</option>
                  <option value="false">[false] False</option>
                </select>
              ) : (
                <input
                  value={item[2]}
                  placeholder="Enter a Value/Response Condition"
                  onChange={(e) => updateVariable(index, 2, e.target.value)}
                  disabled={item[0] === "Default/Webhook Completion"}
                  className="border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-1/4 p-2.5 text-lg"
                />
              )}
              <select
                value={
                  JSON.stringify({ id: item[3].id, name: item[3].name }) ||
                  JSON.stringify({ id: "", name: "" })
                }
                onChange={(e) => updateVariable(index, 3, e.target.value)}
                className="border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-1/4 p-2.5 text-lg"
              >
                <option value="">Select Node</option>
                {elements.nodes
                  .slice() // Creates a shallow copy to avoid mutating the original array
                  .sort((a, b) => a.data.name.localeCompare(b.data.name)) // Sorts alphabetically by the 'name' property
                  .map((node) => (
                    <option
                      key={node.id}
                      value={JSON.stringify({
                        id: node.id,
                        name: node.data.name,
                      })}
                    >
                      {node.data.name}
                    </option>
                  ))}
              </select>

              <button
                onClick={() => removeVariable(index)}
                className="p-2 rounded-md"
              >
                <VscClose color="red" size={20} />
              </button>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default PathwayResponse;
