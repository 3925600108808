import {
  AlertDialog,
  Badge,
  Button,
  Dialog,
  DropdownMenu,
  Flex,
  IconButton,
  Popover,
  Spinner,
  TextField,
  Tooltip,
} from "@radix-ui/themes";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import { format } from "date-fns";
import {
  ArrowRight,
  Braces,
  Brackets,
  Check,
  ChevronLeft,
  ChevronLeftIcon,
  ChevronRight,
  ChevronsUp,
  CircleAlert,
  Code,
  Copy,
  Ellipsis,
  ExternalLink,
  Folder,
  GitBranch,
  Link,
  MessageCircle,
  Pencil,
  Phone,
  Plus,
  RotateCcw,
  Settings,
  Trash,
  Variable,
  Zap,
} from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { toDtmy } from "utils/formatting/toDtmy";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import TempExamplePWImage from "assets/images/ExamplePW.png";
import { getUserData } from "utils/funcs/browser/getUserData";
import { Loading } from "components/core/Loading";
import { useNavigate } from "react-router-dom";
import NewVersionModal from "./Components/splash/newVersion";
import { ConfirmChangeNumber } from "./Components/splash/confirmChangeNumber";
import { idID } from "@mui/material/locale";
import RateLimitsModal from "./Components/splash/rateLimitsModal";

const NodePreview = ({ label, type }) => (
  <div className="border border-gray-300 rounded p-2 bg-white shadow-sm">
    <div className="text-xs font-semibold">{type}</div>
    <div className="text-sm">{label}</div>
  </div>
);

const EdgePreview = () => (
  <div className="flex items-center justify-center w-8 h-8">
    <ArrowRight size={16} />
  </div>
);

const ReactFlowPreview = ({ nodes, edges }) => {
  return (
    <div className="border border-gray-300 rounded p-4 bg-gray-100">
      <div className="flex flex-wrap gap-4">
        {nodes.map((node, index) => (
          <div key={node.id} className="flex items-center">
            <NodePreview label={node.data.label} type={node.type} />
            {index < nodes.length - 1 && <EdgePreview />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default function PathwaySplash({ id, pathway }) {
  const navigate = useNavigate();
  const user = getUserData();
  const [graphData, setGraphData] = useState({
    pathway: null,
    versions: null,
    parent_folder: {
      id: null,
      name: null,
    },
    all_graph_folders: null,
    inbound_number: null,
  });
  const [loadingDelete, setLoadingDelete] = useState({
    loading: false,
    version_number: null,
  });
  const [isPrevPublishedExpanded, setIsPrevPublishedExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVersionForClone, setSelectedVersionForClone] = useState(null);

  const [currentVersionPage, setCurrentVersionPage] = useState(1);
  const itemsPerPage = 5;

  const vIndexOfLastItem = currentVersionPage * itemsPerPage;
  const vIndexOfFirstItem = vIndexOfLastItem - itemsPerPage;
  const currentVersions = graphData?.versions?.slice(
    vIndexOfFirstItem,
    vIndexOfLastItem,
  );

  const handlePageChange = (pageNumber) => {
    setCurrentVersionPage(pageNumber);
  };

  const [confirmChangeNumberOpen, setConfirmChangeNumberOpen] = useState({
    open: false,
    number: null,
  });

  const [renameModalOpen, setRenameModalOpen] = useState({
    open: false,
    version_number: null,
    input: "",
  });

  const [renameVersionLoading, setRenameVersionLoading] = useState({
    loading: false,
    version_number: null,
  });

  const getInboundNumberForPathway = async (
    pathway_id,
    staging_version_number,
  ) => {
    console.log("pathway_id", pathway_id);
    const response = await fetch("/api/convo_pathway/inbound", {
      method: "POST",
      headers: {
        authorization: getAuthToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pathway_id: pathway_id,
        pathway_version: staging_version_number,
      }),
    });

    if (response.ok && response.status === 200) {
      const data = await response.json();

      setGraphData((prev) => ({
        ...prev,
        inbound_number: data?.data?.[0]?.phone_number,
      }));
    }
  };

  const getAllAvailableInboundNumbers = async () => {
    const allNumbers = [];
    const [res_a, res_b] = await Promise.all([
      fetch("/api/inbound/get_inbound", {
        method: "GET",
        headers: {
          authorization: getApiKey(),
        },
      }),
      fetch("/api/byot/get", {
        method: "GET",
        headers: {
          authorization: getApiKey(),
        },
      }),
    ]);

    if (res_a.ok && res_a.status === 200) {
      const data_a = await res_a.json();
      if (data_a.inboundNumbers) {
        data_a?.inboundNumbers?.forEach((item) => {
          if (item?.phone_number) {
            allNumbers.push({
              phone_number: item?.phone_number,
              name: item?.label || null,
              prompt: item?.prompt || null,
              pathway_id: item?.pathway_id || null,
            });
          }
        });
      }
    }

    if (res_b.ok && res_b.status === 200) {
      const data_b = await res_b.json();
      if (data_b) {
        data_b?.forEach((item) => {
          if (item?.phone_number) {
            allNumbers.push({
              phone_number: item?.phone_number,
              name: item?.label || null,
              prompt: item?.prompt || null,
              pathway_id: item?.pathway_id || null,
            });
          }
        });
      }
    }

    setGraphData((prev) => ({
      ...prev,
      inbound_numbers: allNumbers,
    }));
  };

  const renameVersion = async (version_number, input) => {
    try {
      setRenameVersionLoading({
        loading: true,
        version_number: version_number,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${id}/version/${version_number}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getApiKey(),
          },
          body: JSON.stringify({
            name: input,
          }),
        },
      );

      if (response.ok && response.status === 200) {
        toast.success("Version renamed successfully");
        setRenameModalOpen({
          open: false,
          version_number: null,
          input: "",
        });

        setGraphData((prev) => ({
          ...prev,
          versions: prev.versions.map((version) =>
            version.version_number === version_number
              ? { ...version, name: input }
              : version,
          ),
        }));
      }
    } catch (error) {
      toast.error("Error renaming version");
    } finally {
      setRenameVersionLoading({ loading: false, version_number: null });
    }
  };

  const [loadingChangeNumber, setLoadingChangeNumber] = useState(false);

  const publishVersion = async (version_number, environment = "production") => {
    try {
      console.log("test 123");

      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${id}/publish`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getApiKey(),
          },
          body: JSON.stringify({
            version_id: version_number,
            environment: environment,
          }),
        },
      );

      const data = await response.json();
      if (response.status === 200 && data) {
        toast.success("Version published successfully");
        await getVersions();

        if (environment === "production") {
          // open prev published versions
          setIsPrevPublishedExpanded(true);
          // update published_at date
          setGraphData((prev) => ({
            ...prev,
            pathway: {
              ...prev.pathway,
              published_at: new Date().getTime(),
              production_version_number: version_number,
            },
          }));
        }
      } else {
        toast.error("Error publishing version here");
      }

      return data;
    } catch (error) {
      toast.error("Error publishing version");
    }
  };

  const handleCreateVersion = (newVersion) => {
    setGraphData((prev) => ({
      ...prev,
      versions: [
        {
          version_number: newVersion.version_number,
          created_at: new Date().getTime(),
        },
        ...prev.versions,
      ],
    }));
    getVersions(); // Refresh the versions list
  };

  const getVersionSource = (version) => {
    if (version.source_version_id) {
      const sourceVersion = [
        ...graphData.versions,
        ...graphData.prevPublishedVersions,
        graphData.stagingVersion,
      ].find((v) => v.version_number === version.source_version_id);
      return sourceVersion ? `v/${sourceVersion.version_number}` : "Unknown";
    }
    return null;
  };

  const deleteVersion = async (version_number) => {
    try {
      setLoadingDelete({ loading: true, version_number: version_number });
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${id}/version/${version_number}`,
        {
          method: "DELETE",
          headers: {
            authorization: user?.api_key,
          },
        },
      );

      if (response.ok && response.status === 200) {
        toast.success("Version deleted successfully");
        setGraphData((prev) => ({
          ...prev,
          versions: prev.versions.filter(
            (version) => version.version_number !== version_number,
          ),
        }));
      }
    } catch (error) {
      toast.error("Error deleting version");
    } finally {
      setLoadingDelete({ loading: false, version_number: null });
    }
  };

  const getVersions = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${id}/versions`,
        {
          method: "GET",
          headers: {
            authorization: getApiKey(),
          },
        },
      );
      const data = await response.json();
      if (!data || data?.status === "error") {
        return;
      }
      let stagingVersion;
      if (response.status === 200 && data) {
        // versions data
        //   {
        //     version_number: 1,
        //     created_at: "2021-09-01T00:00:00.000Z",
        //     name: "Version 1",
        //     is_staging: true,
        //     is_prev_published: false
        // }

        stagingVersion = data.find((version) => version.is_staging);
        const developmentVersions = data.filter(
          (version) => !version.is_staging && !version.is_prev_published,
        );
        const prevPublishedVersions = data.filter(
          (version) => version.is_prev_published,
        );

        if (!stagingVersion) {
          toast.warn(
            "No staging version found for this pathway. Generating staging version now..",
            {
              position: "bottom-right",
            },
          );
          // create new version
          const new_latest_version = await fetch(
            `/api/convo_pathway/create-version`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: getAuthToken(),
              },
              body: JSON.stringify({
                id: id,
              }),
            },
          ).then((res) => res.json());

          stagingVersion = {
            id: new_latest_version?.id,
            version_number: new_latest_version?.version_number,
            created_at: new Date().getTime(),
            name: new_latest_version?.name,
            is_staging: true,
          };
          toast.success("Staging version created successfully");
        }

        setGraphData((prev) => ({
          ...prev,
          stagingVersion: stagingVersion,
          versions: developmentVersions,
          prevPublishedVersions: prevPublishedVersions,
        }));

        getInboundNumberForPathway(id, stagingVersion?.version_number);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching versions:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const rollbackVersion = async (version_number) => {
    try {
      console.log("Rollinng back version");
      const response = await fetch("/api/convo_pathway/publish", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        body: JSON.stringify({
          id: id,
          version_number: version_number,
          environment: "production",
        }),
      });
      const data = await response.json();
      if (response.status === 200 && data) {
        toast.success("Version rolled back successfully");
        await getVersions();

        // update published_at , and production_version_number
        setGraphData((prev) => ({
          ...prev,
          pathway: {
            ...prev.pathway,
            published_at: new Date().getTime(),
            production_version_number: version_number,
          },
        }));
      } else {
        toast.error("Error rolling back version");
      }
    } catch (error) {
      console.error("Error rolling back version:", error);
    }
  };

  const getPathway = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/convo_pathway/${id}`,
      {
        method: "GET",
        headers: {
          authorization: getApiKey(),
        },
      },
    );
    const data = await response.json();
    if (!data || data?.status === "error") {
      return;
    }
    if (response.status === 200 && data) {
      setGraphData((prev) => ({ ...prev, pathway: data }));
    }
  };

  const getFolderData = async () => {
    try {
      const response = await fetch(`/api/convo_pathway/get`, {
        method: "GET",
        headers: {
          authorization: getAuthToken(),
        },
      });

      const data = await response.json();
      if (!data || data?.status === "error") {
        return;
      }
      if (response.status === 200 && data?.data) {
        const folder_id = data?.data
          ?.flatMap((item) => item.children)
          ?.find((item) => item.id === id)?.folder_id;

        if (folder_id) {
          const parentFolderName = data?.data?.find(
            (item) => item?.id === folder_id,
          )?.name;

          setGraphData((prev) => ({
            ...prev,
            parent_folder: { id: folder_id, name: parentFolderName },
          }));
        }

        setGraphData((prev) => ({ ...prev, all_graph_folders: data?.data }));
      }
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  };
  useEffect(() => {
    console.log(pathway, "pathway", id, "id");
    if (!pathway) {
      getPathway();
    } else {
      setGraphData((prev) => ({ ...prev, pathway: pathway }));
    }

    getVersions();
    getFolderData();
    getAllAvailableInboundNumbers();
  }, []);

  useEffect(() => {
    console.log(graphData, "graphData");
  }, [graphData]);

  return (
    <PageWrapper>
      <ActionBar top>
        <PageTitle style={{ fontSize: 14 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 16,
            }}
          >
            <IconButton
              style={{ cursor: "pointer" }}
              color="gray"
              variant="ghost"
              size="2"
              radius="full"
              onClick={() => {
                navigate(`/dashboard?page=convo-pathways`);
              }}
            >
              <ChevronLeftIcon size={14} />
            </IconButton>
            <div className="ml-1">
              <p style={{ fontSize: 13, fontWeight: "600" }}>
                {graphData?.pathway?.name}
              </p>
              <p className="text-gray-700 text-[11px]">
                Conversational Pathways
              </p>
            </div>
          </div>
        </PageTitle>
        {graphData?.parent_folder?.name && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Folder size={16} color="#343a40" />
            <p style={{ fontSize: 14, fontWeight: "500", color: "#343a40" }}>
              / {graphData?.parent_folder?.name}
            </p>
          </div>
        )}
        {/* 
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: 15,
            marginLeft: "auto",
          }}
        >
          <Tooltip content="Variables">
            <IconButton
              radius="full"
              size={"2"}
              variant="ghost"
              color="gray"
              style={{ cursor: "pointer" }}
            >
              <Braces size={16} />
            </IconButton>
          </Tooltip>

          <Tooltip content="Tools">
            <IconButton
              radius="full"
              size={"2"}
              variant="ghost"
              color="gray"
              style={{ cursor: "pointer" }}
            >
              <Zap size={16} />
            </IconButton>
          </Tooltip>
        </div> */}
      </ActionBar>

      <PublishedPathwayComponent style={{ marginBottom: 30 }}>
        <div id="production-pathway" className="">
          <p style={{ fontSize: 24, fontWeight: "550" }}>Production Pathway</p>
          <p
            className="mt-1.5"
            style={{ fontSize: 14, fontWeight: "450", color: "GrayText" }}
          >
            This is the live version of your pathway, that is associated with
            the Pathway ID by default
          </p>
          <PublishedBox className="mt-8">
            <div style={{ padding: 20, width: "100%", height: "100%" }}>
              <img
                src={TempExamplePWImage}
                alt="Pathway Preview"
                style={{
                  height: "100%",
                  width: "100%",
                  maxHeight: 300,
                  objectFit: "cover",
                  objectPosition: "center",
                  border: "1px solid #e0e0e0",
                  borderRadius: 4,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            </div>

            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ maxWidth: "50%" }}>
                  <p style={{ color: "GrayText", fontSize: 12 }}>
                    Pathway Name
                  </p>
                  <p style={{ color: "#000", fontSize: 24, fontWeight: "500" }}>
                    {graphData?.pathway?.name}
                  </p>
                </div>

                <div>
                  <NewVersionModal
                    pathwayId={id}
                    id={id}
                    versions={graphData.versions}
                    stagingVersion={graphData.stagingVersion}
                    prevPublishedVersions={graphData.prevPublishedVersions}
                    initialVersion={0}
                    onCreateVersion={handleCreateVersion}
                    isCloningProd={true}
                    triggerButton={
                      <Button
                        radius="full"
                        size={"3"}
                        variant="ghost"
                        color="gray"
                        style={{ cursor: "pointer" }}
                      >
                        <p>Clone Production</p>
                        <Copy size={16} />
                      </Button>
                    }
                  />

                  <div style={{ marginTop: 10 }}>
                    <RateLimitsModal
                      pathwayId={id}
                      triggerButton={
                        <Button
                          radius="full"
                          size={"3"}
                          variant="ghost"
                          color="gray"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            justifyContent: "start",
                          }}
                        >
                          <p className="items-start">Rate Limits</p>
                          <Settings className="ml-[38px]" size={16} />
                        </Button>
                      }
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  <Link size={10} color="GrayText" />
                  <p style={{ color: "GrayText", fontSize: 12 }}>Pathway ID</p>
                </div>
                <p style={{ fontSize: 14, fontWeight: "500" }}>{id}</p>
              </div>

              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 10,
                  columnGap: "20%",
                  alignItems: "center",
                }}
              >
                <div>
                  <Tooltip content="Date when this pathway was deployed to production.">
                    <p style={{ color: "GrayText", fontSize: 12 }}>Created</p>
                  </Tooltip>
                  <p style={{ fontSize: 14, fontWeight: "500" }}>
                    {graphData?.pathway?.published_at
                      ? toDtmy(new Date(graphData?.pathway?.published_at))
                      : "Not tracked yet"}
                  </p>
                </div>
                <div>
                  <Tooltip content="Version used to deploy to production.">
                    <p style={{ color: "GrayText", fontSize: 12 }}>Version</p>
                  </Tooltip>
                  <p style={{ fontSize: 14, fontWeight: "500" }}>
                    {graphData?.pathway?.production_version_number
                      ? `Version ${graphData?.pathway?.production_version_number}`
                      : "Unknown"}
                  </p>
                </div>
              </div>

              <div style={{ marginTop: 20 }}>
                <Button
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete("view");
                    url.searchParams.set("version", "0");
                    window.location.href = url.toString();
                  }}
                  size={"3"}
                  variant="surface"
                  color="iris"
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                  type="button"
                >
                  View Production Pathway
                  <ExternalLink size={16} />
                </Button>
              </div>
              <div style={{ marginTop: 10 }}>
                <Button
                  onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.delete("view");
                    url.searchParams.set("version", "staging");
                    window.location.href = url.toString();
                  }}
                  size={"3"}
                  variant="surface"
                  color="iris"
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                  type="button"
                >
                  Edit Staging Pathway
                  <ExternalLink size={16} />
                </Button>
              </div>
            </div>
          </PublishedBox>
        </div>

        <div className="mt-8">
          <Button
            size="3"
            variant="outline"
            color="gray"
            style={{ cursor: "pointer", width: "100%" }}
            onClick={() => setIsPrevPublishedExpanded(!isPrevPublishedExpanded)}
          >
            {isPrevPublishedExpanded ? (
              <Brackets size={16} style={{ transform: "rotate(90deg)" }} />
            ) : (
              <Brackets size={16} />
            )}
            Previously Published Versions
          </Button>

          {isPrevPublishedExpanded && (
            <VersionBox className="mt-4">
              {graphData.prevPublishedVersions &&
              graphData.prevPublishedVersions.length > 0 ? (
                graphData.prevPublishedVersions.map((version, id) => (
                  <VersionItem
                    key={id}
                    style={{
                      borderBottom:
                        graphData.prevPublishedVersions.length - 1 === id
                          ? "none"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #e0e0e0",
                        padding: 8,
                        borderRadius: "50%",
                        marginRight: 24,
                      }}
                    >
                      <GitBranch size={16} />
                    </div>
                    <div>
                      <code style={{ fontSize: 14, fontWeight: "550" }}>
                        {version.name || `Version ${version.version_number}`}
                      </code>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flexWrap: "wrap",
                          columnGap: 12,
                          gap: 5,
                          marginTop: 5,
                        }}
                      >
                        <p style={{ fontSize: 12, color: "GrayText" }}>
                          v/{version.version_number}
                        </p>
                        <p style={{ fontSize: 12, color: "GrayText" }}>
                          , published on {toDtmy(new Date(version.created_at))}
                        </p>
                        <Badge color="gray" variant="solid" style={{}}>
                          <p style={{ fontSize: 10 }}>Previously Published</p>
                        </Badge>
                        {version?.source_version_number && (
                          <Badge color="blue" variant="solid" style={{}}>
                            <p style={{ fontSize: 10 }}>
                              Cloned from v/{version?.source_version_number}
                            </p>
                          </Badge>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        alignItems: "center",
                        gap: 12,
                      }}
                    >
                      {/* <Button
                        size="1"
                        variant="soft"
                        color="blue"
                        style={{ cursor: "pointer", marginLeft: "auto" }}
                        onClick={() => rollbackVersion(version.version_number)}
                      >
                        Rollback
                        <RotateCcw size={11} />
                      </Button> */}

                      <DropdownMenu.Root dir="rtl">
                        <DropdownMenu.Trigger style={{ marginLeft: "auto" }}>
                          <IconButton
                            style={{ cursor: "pointer" }}
                            type="button"
                            radius="full"
                            variant="ghost"
                            color="gray"
                            size={"2"}
                          >
                            <Ellipsis size={16} />
                          </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content
                          variant="soft"
                          size={"2"}
                          style={{ marginTop: 2.5, width: "calc(100% + 20px)" }}
                        >
                          <DropdownMenu.Item
                            onClick={() => {
                              const url = new URL(window.location.href);
                              url.searchParams.delete("view");
                              url.searchParams.set(
                                "version",
                                version.version_number,
                              );
                              window.location.href = url.toString();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p>View</p>
                            <ExternalLink
                              size={16}
                              style={{ marginLeft: "auto" }}
                            />
                          </DropdownMenu.Item>

                          <DropdownMenu.Item
                            onSelect={(event) => event.preventDefault()}
                            // onClick={() => {
                            //   setSelectedVersionForClone(
                            //     graphData.stagingVersion.version_number,
                            //   );
                            // }}
                            style={{ cursor: "pointer" }}
                          >
                            <NewVersionModal
                              id={id}
                              versions={graphData.versions}
                              stagingVersion={graphData.stagingVersion}
                              prevPublishedVersions={
                                graphData.prevPublishedVersions
                              }
                              onCreateVersion={handleCreateVersion}
                              initialVersion={version?.version_number}
                              triggerButton={"Clone"} // No visible trigger button
                              isDropdownItem={true}
                            />
                          </DropdownMenu.Item>

                          <DropdownMenu.Separator />

                          <DropdownMenu.Item
                            onClick={() => {
                              rollbackVersion(version.version_number);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p style={{ color: "#8338ec" }}>
                              Rollback to Version
                            </p>
                            <RotateCcw
                              size={14}
                              color="#8338ec"
                              style={{ marginLeft: "auto" }}
                            />
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </div>
                  </VersionItem>
                ))
              ) : (
                <div className="flex items-center justify-center p-4">
                  <p style={{ fontSize: 14, color: "GrayText" }}>
                    No previously published versions found.
                  </p>
                </div>
              )}
            </VersionBox>
          )}
        </div>

        <div id="staging-pathway" className="mt-16">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            <div>
              <p style={{ fontSize: 20, fontWeight: "550" }}>Staging Pathway</p>
              <p
                className="mt-1.5"
                style={{ fontSize: 14, fontWeight: "450", color: "GrayText" }}
              >
                This can be the main version that you do your edits and tests
                on, before promoting to production when ready.
              </p>
            </div>

            <Button
              size={"2"}
              variant="solid"
              color="iris"
              style={{ cursor: "pointer" }}
              type="button"
              onClick={() => {
                publishVersion(
                  graphData.stagingVersion.version_number,
                  "production",
                );
              }}
            >
              <ChevronsUp size={16} />
              Promote to Production
            </Button>
          </div>

          <PublishedBox
            className="mt-8"
            style={{ padding: "0px 16px 16px 16px" }}
          >
            <VersionBox className="mt-8">
              {graphData.stagingVersion !== undefined ? (
                <VersionItem>
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      padding: 8,
                      borderRadius: "50%",
                      marginRight: 24,
                    }}
                  >
                    <GitBranch size={16} />
                  </div>
                  <div>
                    <code style={{ fontSize: 14, fontWeight: "550" }}>
                      {graphData.stagingVersion.name ||
                        `Version ${graphData.stagingVersion.version_number}`}
                    </code>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        columnGap: 12,
                        gap: 5,
                        marginTop: 5,
                      }}
                    >
                      <p style={{ fontSize: 12, color: "GrayText" }}>
                        v/{graphData.stagingVersion.version_number}
                      </p>
                      <p style={{ fontSize: 12, color: "GrayText" }}>
                        , last created on{" "}
                        {toDtmy(new Date(graphData.stagingVersion.created_at))}
                      </p>
                      <Badge variant="solid" color="green" style={{}}>
                        Staging
                      </Badge>
                    </div>
                  </div>

                  <DropdownMenu.Root dir="rtl" style={{}}>
                    <DropdownMenu.Trigger style={{ marginLeft: "auto" }}>
                      <Button
                        style={{ marginTop: 5, cursor: "pointer" }}
                        size={"2"}
                        color="violet"
                        variant="soft"
                      >
                        <Phone size={12} />
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: "500",
                            marginLeft: 2.5,
                            marginRight: 5,
                          }}
                        >
                          {graphData?.inbound_number || "Select Inbound Number"}
                        </p>
                        <DropdownMenu.TriggerIcon />
                      </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content
                      style={{
                        borderRadius: 4,
                        padding: 5,
                        width: 300,
                        maxHeight: 200,
                      }}
                    >
                      {graphData?.inbound_numbers?.map((number, index) => (
                        <DropdownDivItem
                          key={index}
                          onClick={(event) => {
                            event.preventDefault();
                            setConfirmChangeNumberOpen({
                              open: true,
                              number: number.phone_number,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            borderBottom:
                              graphData?.inbound_numbers?.length - 1 === index
                                ? "none"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    fontSize: 10,
                                    fontWeight: "550",
                                    color: "GrayText",
                                  }}
                                >
                                  {number?.name?.toUpperCase() || "NO NAME"}
                                </p>
                                <p
                                  style={{
                                    fontSize: 12,
                                    color: "#000",
                                    fontWeight: 500,
                                  }}
                                >
                                  {number?.phone_number}
                                </p>

                                {number?.prompt && number?.prompt !== "" && (
                                  <Badge color="orange" size="1">
                                    <CircleAlert size={10} />
                                    <p style={{ fontSize: 10 }}>
                                      Has Prompt Set
                                    </p>
                                  </Badge>
                                )}

                                {number?.pathway_id !== null &&
                                  number?.pathway_id !== "" &&
                                  number?.pathway_id !== id && (
                                    <Badge color="yellow" size="1">
                                      <CircleAlert size={10} />
                                      <p style={{ fontSize: 10 }}>
                                        Has Pathway Set
                                      </p>
                                    </Badge>
                                  )}
                              </div>

                              {graphData?.inbound_number ===
                                number?.phone_number && (
                                <Check size={16} color="#000" />
                              )}
                            </div>
                            <ConfirmChangeNumber
                              loading={loadingChangeNumber}
                              open={
                                number.phone_number ===
                                  confirmChangeNumberOpen.number &&
                                confirmChangeNumberOpen.open
                              }
                              onClose={() => {
                                setConfirmChangeNumberOpen({
                                  open: false,
                                  number: null,
                                });
                              }}
                              handleChangeNumber={async () => {
                                try {
                                  setLoadingChangeNumber(true);
                                  const phoneNumber = number.phone_number;
                                  const response = await fetch(
                                    `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/inbound/${phoneNumber}`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization: getApiKey(),
                                      },
                                      body: JSON.stringify({
                                        prompt: "",
                                        pathway_id: id,
                                        pathway_version:
                                          graphData.stagingVersion
                                            ?.version_number,
                                      }),
                                    },
                                  );

                                  if (response.ok) {
                                    setGraphData({
                                      ...graphData,
                                      inbound_number: phoneNumber,
                                    });
                                    toast.success(
                                      "Inbound number changed successfully",
                                    );
                                    setConfirmChangeNumberOpen({
                                      open: false,
                                      number: null,
                                    });
                                  } else {
                                    toast.error(
                                      "Failed to change inbound number",
                                    );
                                  }
                                } catch (error) {
                                } finally {
                                  setLoadingChangeNumber(false);
                                }
                              }}
                            />
                          </>
                        </DropdownDivItem>
                      ))}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>

                  <DropdownMenu.Root dir="rtl">
                    <DropdownMenu.Trigger style={{ marginLeft: "20px" }}>
                      <IconButton
                        style={{ cursor: "pointer" }}
                        type="button"
                        radius="full"
                        variant="ghost"
                        color="gray"
                        size={"2"}
                      >
                        <Ellipsis size={16} />
                      </IconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content
                      variant="soft"
                      size={"2"}
                      style={{ marginTop: 2.5, width: "calc(100% + 20px)" }}
                    >
                      <DropdownMenu.Item
                        onClick={() => {
                          const url = new URL(window.location.href);
                          url.searchParams.delete("view");
                          url.searchParams.set("version", "staging");
                          window.location.href = url.toString();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <p>Edit</p>
                        <ExternalLink
                          size={16}
                          style={{ marginLeft: "auto" }}
                        />
                      </DropdownMenu.Item>

                      <DropdownMenu.Item
                        onSelect={(event) => event.preventDefault()}
                        // onClick={() => {
                        //   setSelectedVersionForClone(
                        //     graphData.stagingVersion.version_number,
                        //   );
                        // }}
                        style={{ cursor: "pointer" }}
                      >
                        <NewVersionModal
                          id={id}
                          versions={graphData.versions}
                          stagingVersion={graphData.stagingVersion}
                          prevPublishedVersions={
                            graphData.prevPublishedVersions
                          }
                          onCreateVersion={handleCreateVersion}
                          initialVersion={
                            graphData?.stagingVersion?.version_number
                          }
                          triggerButton={"Clone"} // No visible trigger button
                          isDropdownItem={true}
                        />
                      </DropdownMenu.Item>

                      <DropdownMenu.Separator />

                      <DropdownMenu.Item
                        onClick={() => {
                          publishVersion(
                            graphData.stagingVersion.version_number,
                            "production",
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <p>Promote to Production</p>
                        <ChevronsUp
                          size={16}
                          color="orange"
                          style={{ marginLeft: "auto" }}
                        />
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </VersionItem>
              ) : (
                <div
                  className="flex items-center justify-center"
                  style={{ height: 100 }}
                >
                  <Loading loading={isLoading} />
                </div>
              )}
            </VersionBox>
          </PublishedBox>
        </div>
      </PublishedPathwayComponent>

      <div
        style={{
          width: "100%",
          height: 1,
          background: "#f0f0f0",
        }}
      />

      <ActiveVersionsComponent style={{ marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          <p style={{ fontSize: 20, fontWeight: "550" }}>
            Development Versions
          </p>

          <NewVersionModal
            id={id}
            versions={graphData.versions}
            stagingVersion={graphData.stagingVersion}
            prevPublishedVersions={graphData.prevPublishedVersions}
            onCreateVersion={handleCreateVersion}
            initialVersion={selectedVersionForClone}
            triggerButton={null} // No visible trigger button
          />
        </div>
        <p
          className="mt-1.5"
          style={{
            fontSize: 14,
            fontWeight: "450",
            color: "GrayText",
            alignItems: "center",
          }}
        >
          Open versions on{" "}
          <code
            style={{
              backgroundColor: "#f0f0f0",
              padding: 4,
              fontWeight: "550",
              fontSize: 10,
              borderRadius: 4,
            }}
          >
            {graphData?.pathway?.name}
          </code>{" "}
          that are currently active.
        </p>

        {graphData.versions && graphData?.versions?.length > 0 ? (
          <VersionBox className="mt-8">
            {currentVersions.map((version, index) => (
              <VersionItem
                key={index}
                style={{
                  borderTopLeftRadius: index === 0 ? "4px" : "0px",
                  borderTopRightRadius: index === 0 ? "4px" : "0px",
                  borderBottomLeftRadius:
                    graphData?.versions?.length - 1 === index ? "4px" : "0px",
                  borderBottomRightRadius:
                    graphData?.versions?.length - 1 === index ? "4px" : "0px",
                  borderBottom:
                    graphData?.versions?.length - 1 === index
                      ? "none"
                      : "1px solid #e0e0e0",
                }}
              >
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    padding: 8,
                    borderRadius: "50%",
                    marginRight: 24,
                  }}
                >
                  <GitBranch size={16} />
                </div>
                <div>
                  {renameModalOpen.version_number === version.version_number &&
                  renameModalOpen.open ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <input
                        value={renameModalOpen.input}
                        style={{ border: "none", outline: "none" }}
                        onChange={(event) => {
                          setRenameModalOpen({
                            ...renameModalOpen,
                            input: event.target.value,
                          });
                        }}
                        placeholder={version.name || "Version Name"}
                      />

                      {renameVersionLoading.version_number ===
                        version.version_number &&
                      renameVersionLoading.loading ? (
                        <Spinner size={"1"} />
                      ) : (
                        <Check
                          style={{ cursor: "pointer" }}
                          size={16}
                          onClick={() => {
                            // event.stopPropagation();
                            // event.preventDefault();
                            console.log("clicked", renameModalOpen);
                            if (renameModalOpen.input === "") {
                              setRenameModalOpen({
                                open: false,
                                version_number: null,
                                input: "",
                              });
                            } else if (renameModalOpen.input === version.name) {
                              setRenameModalOpen({
                                open: false,
                                version_number: null,
                                input: "",
                              });
                            } else {
                              renameVersion(
                                renameModalOpen.version_number,
                                renameModalOpen.input,
                              );
                            }
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <code
                      style={{
                        fontSize: 14,
                        fontWeight: "550",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      {version.name || "Unnamed Version"}
                      <Pencil
                        onClick={() => {
                          setRenameModalOpen({
                            open: true,
                            version_number: version.version_number,
                            input: version.name,
                          });
                        }}
                        size={12}
                        style={{ cursor: "pointer" }}
                      />
                    </code>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      columnGap: 12,
                      gap: 5,
                      marginTop: 5,
                    }}
                  >
                    <p style={{ fontSize: 12, color: "GrayText" }}>
                      v/{version?.version_number}
                    </p>
                    <p style={{ fontSize: 12, color: "GrayText" }}>
                      , created on {toDtmy(new Date(version.created_at))}
                    </p>
                    {version?.source_version_number && (
                      <Badge
                        color="blue"
                        variant="solid"
                        style={{ fontSize: 12 }}
                      >
                        <p style={{ fontSize: 10 }}>
                          Cloned from v/{version?.source_version_number}
                        </p>
                      </Badge>
                    )}
                  </div>
                  {getVersionSource(version) && (
                    <Tooltip
                      content={`Cloned from version ${getVersionSource(version)}`}
                    >
                      <Badge color="blue" style={{}}>
                        Cloned
                      </Badge>
                    </Tooltip>
                  )}
                </div>

                <DropdownMenu.Root dir="rtl">
                  <DropdownMenu.Trigger style={{ marginLeft: "auto" }}>
                    <IconButton
                      style={{ cursor: "pointer" }}
                      type="button"
                      radius="full"
                      variant="ghost"
                      color="gray"
                      size={"2"}
                    >
                      <Ellipsis size={16} />
                    </IconButton>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content
                    variant="soft"
                    size={"2"}
                    style={{ marginTop: 2.5, width: "calc(100% + 20px)" }}
                  >
                    <DropdownMenu.Item
                      onClick={() => {
                        const url = new URL(window.location.href);
                        url.searchParams.delete("view");
                        url.searchParams.set("version", version.version_number);
                        window.location.href = url.toString();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <p>Edit</p>
                      <ExternalLink size={16} style={{ marginLeft: "auto" }} />
                    </DropdownMenu.Item>

                    <DropdownMenu.Item
                      onSelect={(event) => event.preventDefault()}
                      style={{ cursor: "pointer" }}
                    >
                      <NewVersionModal
                        id={id}
                        versions={graphData.versions}
                        stagingVersion={graphData.stagingVersion}
                        prevPublishedVersions={graphData.prevPublishedVersions}
                        onCreateVersion={handleCreateVersion}
                        initialVersion={version.version_number}
                        triggerButton={"Clone"} // No visible trigger button
                      />
                      <Copy size={16} style={{ marginLeft: "auto" }} />
                    </DropdownMenu.Item>

                    {/* <DropdownMenu.Item
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <RenameVersionModal
                        open={renameModalOpen.open}
                        handleClose={() => {
                          setRenameModalOpen({
                            open: false,
                            version_number: null,
                          });
                        }}
                        handleOpen={() => {
                          setRenameModalOpen({
                            open: true,
                            version_number: version.version_number,
                          });
                        }}
                        versionName={version.name}
                      />
                    </DropdownMenu.Item> */}

                    <DropdownMenu.Separator />

                    <DropdownMenu.Item
                      color="orange"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        publishVersion(version.version_number, "staging");
                      }}
                    >
                      <p>Promote to Staging</p>
                      <ChevronsUp size={16} style={{ marginLeft: "auto" }} />
                    </DropdownMenu.Item>

                    <DropdownMenu.Separator />

                    <DropdownMenu.Item
                      color="red"
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteVersion(version.version_number);
                      }}
                      style={{ marginTop: 2.5, cursor: "pointer" }}
                    >
                      <p style={{ color: "red" }}>Delete</p>
                      {loadingDelete.loading &&
                      loadingDelete.version_number ===
                        version.version_number ? (
                        <>
                          <Spinner size={"1"} />
                        </>
                      ) : (
                        <Trash
                          size={16}
                          color="red"
                          style={{ marginLeft: "auto" }}
                        />
                      )}
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </VersionItem>
            ))}

            {graphData?.versions?.length > 5 && (
              <Flex
                direction={"row"}
                gap={"8"}
                height={"40px"}
                style={{
                  alignItems: "center",
                  justifyItems: "center",
                  paddingRight: 20,
                }}
              >
                <IconButton
                  disabled={currentVersionPage === 1}
                  onClick={() => setCurrentVersionPage(currentVersionPage - 1)}
                  variant="ghost"
                  color="iris"
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                >
                  <ChevronLeft size={16} />
                </IconButton>
                <IconButton
                  color="iris"
                  disabled={
                    currentVersionPage * itemsPerPage >=
                    graphData.versions.length
                  }
                  onClick={() => setCurrentVersionPage(currentVersionPage + 1)}
                  variant="ghost"
                  style={{ cursor: "pointer" }}
                >
                  <ChevronRight size={16} />
                </IconButton>
              </Flex>
            )}
          </VersionBox>
        ) : (
          <VersionBox
            style={{ justifyContent: "center", marginTop: 20, padding: 20 }}
          >
            <p
              style={{
                fontSize: 14,
                color: "GrayText",
                textAlign: "center",
              }}
            >
              No active development versions found. <br /> Create a new version
              of your staging or production pathway, to try out new changes
              before promoting them.
            </p>
          </VersionBox>
        )}
      </ActiveVersionsComponent>
    </PageWrapper>
  );
}

const PublishedPathwayComponent = styled.div`
  margin: 10px;
  max-width: 100%;
`;

const ActiveVersionsComponent = styled.div`
  margin: 10px;
  max-width: 100%;
`;

const VersionBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const VersionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
`;

const PublishedBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 30px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  row-gap: 10px;
  max-width: 100%;
  align-items: center;

  @media (min-width: 998px) {
    max-height: 500px;
  }

  @media (max-width: 998px) {
    flex-direction: column;
  }

  > * {
    flex: 1;

    @media (max-width: 998px) {
      width: 100%;
    }
  }
`;

const DropdownDivItem = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #f0f0f0;
  }
`;
