import React, { useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const CollapsibleContent = ({ content, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const stringContent = useMemo(() => {
    if (typeof content === "string") {
      return content;
    } else if (typeof content === "object") {
      return JSON.stringify(content, null, 2);
    } else {
      return String(content);
    }
  }, [content]);

  const shouldTruncate = stringContent.length > maxLength;

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const displayContent = isExpanded
    ? stringContent
    : `${stringContent.slice(0, maxLength)}${shouldTruncate ? "..." : ""}`;

  return (
    <div className="relative">
      <pre className="text-gray-700 font-mono bg-gray-100 px-2 py-1 rounded whitespace-pre-wrap">
        {displayContent}
      </pre>
      {shouldTruncate && (
        <motion.button
          onClick={toggleExpand}
          className="absolute bottom-1 right-1 bg-white border border-gray-300 rounded-full p-1 shadow-sm"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {isExpanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
        </motion.button>
      )}
    </div>
  );
};

export default CollapsibleContent;
