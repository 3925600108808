import { useState, useEffect } from "react";
import { Button, Dialog, Select, Flex, Text } from "@radix-ui/themes";
import { Plus, Copy } from "lucide-react";
import { toast } from "react-toastify";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import { getApiKey } from "utils/funcs/browser/getApiKey";

const NewVersionModal = ({
  id,
  versions,
  stagingVersion,
  prevPublishedVersions,
  onCreateVersion,
  initialVersion = null,
  triggerButton = null,
  isDropdownItem = false,
  isCloningProd = false,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(
    initialVersion || stagingVersion?.version_number,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialVersion) {
      setSelectedVersion(initialVersion);
    }
  }, [initialVersion]);

  const handleCreateVersion = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${id}/version`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: getApiKey(),
          },
          body: JSON.stringify({
            version_id: isCloningProd ? 0 : selectedVersion,
          }),
        },
      );

      console.log("response", response);

      if (response.ok) {
        const newVersion = await response.json();
        onCreateVersion(newVersion);
        handleClose();
        toast.success("New version created successfully");
      } else {
        throw new Error("Failed to create new version");
      }
    } catch (error) {
      console.error("Error creating new version:", error);
      toast.error("Failed to create new version");
    } finally {
      setLoading(false);
    }
  };

  const handleTriggerClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {triggerButton ? (
        <span
          onClick={handleTriggerClick}
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          {triggerButton}
          {isDropdownItem && <Copy size={16} style={{ marginLeft: "auto" }} />}
        </span>
      ) : (
        <Button
          size="2"
          variant="solid"
          color="iris"
          style={{ cursor: "pointer" }}
          onClick={handleTriggerClick}
        >
          <Plus size={16} />
          New Version
        </Button>
      )}

      <Dialog.Root open={open} onOpenChange={handleClose}>
        <Dialog.Content style={{ maxWidth: 550, height: "auto" }}>
          <Dialog.Title>Create New Version</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            {isCloningProd
              ? "You are cloning the production version. This will create a new version with the same content as the production version."
              : "Choose a version to create from:"}
          </Dialog.Description>
          {!isCloningProd && (
            <>
              <Select.Root
                value={selectedVersion}
                onValueChange={setSelectedVersion}
                style={{}}
              >
                <Select.Trigger
                  placeholder="Select a version"
                  value={selectedVersion}
                  style={{ width: "100%" }}
                />
                <Select.Content style={{ marginLeft: 20 }}>
                  <Select.Group>
                    <Select.Label>Published Versions</Select.Label>
                    {prevPublishedVersions?.map((version) => (
                      <Select.Item
                        key={version.version_number}
                        value={version.version_number}
                      >
                        {version.name || `Version ${version.version_number}`}{" "}
                        (v/
                        {version.version_number})
                      </Select.Item>
                    ))}
                  </Select.Group>
                  <Select.Separator />
                  <Select.Group>
                    <Select.Label>Staging</Select.Label>
                    <Select.Item value={stagingVersion?.version_number}>
                      Staging (v/{stagingVersion?.version_number})
                    </Select.Item>
                  </Select.Group>
                  <Select.Separator />
                  <Select.Group>
                    <Select.Label>Development Versions</Select.Label>
                    {versions?.map((version) => (
                      <Select.Item
                        key={version.version_number}
                        value={version.version_number}
                      >
                        {version.name || `Version ${version.version_number}`}{" "}
                        (v/
                        {version.version_number})
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </>
          )}
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button
                variant="soft"
                color="gray"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              disabled={!isCloningProd && !selectedVersion}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleCreateVersion(isCloningProd ? 0 : selectedVersion)
              }
              loading={loading}
            >
              {isCloningProd
                ? "Clone Production"
                : selectedVersion
                  ? "Create Version"
                  : "Select a Version"}
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default NewVersionModal;
