import React, { useState, useCallback, useEffect, useMemo } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  Handle,
  Position,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import { ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import ReplayLogs from "./Components/ReplayComponent";
import { Loading } from "components/core/Loading";
import { Link } from "react-router-dom";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

const getColor = (value, min, max) => {
  const normalizedValue = (value - min) / (max - min);
  const colorStops = [
    { point: 0, color: [255, 0, 0] },
    { point: 0.1, color: [255, 69, 0] },
    { point: 0.2, color: [255, 140, 0] },
    { point: 0.3, color: [255, 165, 0] },
    { point: 0.4, color: [255, 215, 0] },
    { point: 0.5, color: [255, 255, 0] },
    { point: 0.6, color: [204, 255, 0] },
    { point: 0.7, color: [173, 255, 47] },
    { point: 0.8, color: [127, 255, 0] },
    { point: 0.9, color: [0, 255, 127] },
    { point: 1, color: [0, 128, 0] },
  ];
  let lowerStop = colorStops[0];
  let upperStop = colorStops[colorStops.length - 1];

  for (let i = 1; i < colorStops.length; i++) {
    if (normalizedValue <= colorStops[i].point) {
      upperStop = colorStops[i];
      lowerStop = colorStops[i - 1];
      break;
    }
  }

  const rangeBetweenStops = upperStop.point - lowerStop.point;
  const valueFromLowerStop = normalizedValue - lowerStop.point;
  const ratio = valueFromLowerStop / rangeBetweenStops;

  const r = Math.round(
    lowerStop.color[0] + ratio * (upperStop.color[0] - lowerStop.color[0]),
  );
  const g = Math.round(
    lowerStop.color[1] + ratio * (upperStop.color[1] - lowerStop.color[1]),
  );
  const b = Math.round(
    lowerStop.color[2] + ratio * (upperStop.color[2] - upperStop.color[2]),
  );

  return `rgb(${r}, ${g}, ${b})`;
};

const lightenColor = (rgb, percent) => {
  const r = Math.min(
    255,
    Math.round(rgb[0] + ((255 - rgb[0]) * percent) / 100),
  );
  const g = Math.min(
    255,
    Math.round(rgb[1] + ((255 - rgb[1]) * percent) / 100),
  );
  const b = Math.min(
    255,
    Math.round(rgb[2] + ((255 - rgb[2]) * percent) / 100),
  );
  return `rgb(${r}, ${g}, ${b})`;
};

// Custom Node component
const CustomNode = ({ data }) => {
  const color = getColor(data.frequency, data.minFrequency, data.maxFrequency);
  if (!color) return null;
  const [r, g, b] = color.match(/\d+/g).map(Number);
  const borderColor = lightenColor([r, g, b], 40);

  return (
    <div
      className={`p-2 rounded-lg shadow-md border border-gray-200 ${data.isHighlighted ? "ring-2 ring-blue-500" : ""}`}
      style={{ background: color }}
      onClick={() => data.onClick(data.id)}
    >
      <div className="font-semibold text-white truncate">{data.label}</div>
      <div className="text-xs text-white">Freq: {data.frequency}</div>
      <Handle
        type="target"
        position={Position.Top}
        className="w-2 h-2 bg-white"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-2 h-2 bg-white"
      />
    </div>
  );
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  data,
}) => {
  const edgePath = `M${sourceX},${sourceY} C${sourceX},${(sourceY + targetY) / 2} ${targetX},${(sourceY + targetY) / 2} ${targetX},${targetY}`;
  return (
    <>
      <path
        id={id}
        style={{ ...style, strokeWidth: data.thickness }}
        className={`react-flow__edge-path ${data.isHighlighted ? "stroke-blue-500" : "stroke-gray-300"}`}
        d={edgePath}
      />
      {data?.label && (
        <text>
          <textPath
            href={`#${id}`}
            className="text-xs fill-gray-600"
            startOffset="50%"
            textAnchor="middle"
          >
            {data.label}
          </textPath>
        </text>
      )}
    </>
  );
};

const nodeTypes = { custom: CustomNode };
const edgeTypes = { custom: CustomEdge };

const StatDisplay = ({ title, value, change }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
    <h3 className="text-sm font-medium text-gray-500 mb-1">{title}</h3>
    <div className="flex items-baseline">
      <span className="text-2xl font-semibold text-gray-900">{value}</span>
      {change && (
        <span
          className={`ml-2 text-sm font-medium ${change >= 0 ? "text-emerald-600" : "text-rose-600"}`}
        >
          {change >= 0 ? "↑" : "↓"} {Math.abs(change)}%
        </span>
      )}
    </div>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-full">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center h-full text-gray-400"
    >
      <svg
        className="animate-spin h-16 w-16 mb-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </motion.div>
  </div>
);

const Drawer = ({ isOpen, onClose, children }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed right-0 top-0 h-screen w-1/3 bg-white shadow-lg z-50 overflow-y-auto border-l border-gray-200 z-[999999]"
      >
        {/* <button onClick={onClose} className="absolute top-0 right-10 text-gray-500 hover:text-gray-700 z-[99999]">
                    <X size={24} />
                </button> */}
        <div className="p-6">{children}</div>
      </motion.div>
    )}
  </AnimatePresence>
);

const PathwayHeatmap = () => {
  const [pathways, setPathways] = useState([]);
  const [selectedPathway, setSelectedPathway] = useState(null);
  const [heatmapData, setHeatmapData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedCallId, setSelectedCallId] = useState(null);
  const [replayIndex, setReplayIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [replaySpeed, setReplaySpeed] = useState(1000);
  const [callLogs, setCallLogs] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleBack = useCallback(() => {
    setSelectedPathway(null);
    setHeatmapData(null);
    setError(null);
    setWarning(null);
  }, []);

  const handleSpeedChange = useCallback((e) => {
    setReplaySpeed(Number(e.target.value));
  }, []);

  const fetchPathways = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/convo_pathway/get", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      if (!response.ok) throw new Error("Failed to fetch pathways");
      const data = await response.json();

      const extractAllPathways = (items) => {
        let pathways = [];
        for (let item of items) {
          if (item.type === "pathway") {
            pathways.push(item);
          } else if (item.type === "folder" && item.children) {
            pathways = pathways.concat(extractAllPathways(item.children));
          }
        }
        return pathways;
      };
      const extractedPathways = extractAllPathways(data.data);
      setPathways(extractedPathways);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPathways();
  }, [fetchPathways]);

  const fetchCallLogs = useCallback(async (callId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `/api/analytics/pathways/call_replay?callId=${callId}`,
        {
          headers: { Authorization: getAuthToken() },
        },
      );
      if (!response.ok) throw new Error("Failed to fetch call logs");
      const data = await response.json();
      setCallLogs(data.data);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const highlightPath = useCallback(
    (callId) => {
      if (!heatmapData || !heatmapData.callPaths) return;

      const path = heatmapData.callPaths[callId].nodes;

      if (!path || !Array.isArray(path)) return;

      const newNodes = nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          isHighlighted: path?.includes(node.id),
        },
      }));

      const newEdges = edges.map((edge) => ({
        ...edge,
        data: {
          ...edge.data,
          isHighlighted:
            path?.includes(edge.source) && path?.includes(edge.target),
        },
      }));

      setNodes(newNodes);
      setEdges(newEdges);
      setSelectedCallId(callId);
      setReplayIndex(0);
      setIsPlaying(false);
      fetchCallLogs(callId);
      setIsDrawerOpen(true);
    },
    [heatmapData, nodes, edges, setNodes, setEdges, fetchCallLogs],
  );

  const fetchHeatmap = useCallback(async (pathwayId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `/api/analytics/pathways_heatmap?pathwayId=${pathwayId}`,
        {
          headers: { Authorization: getAuthToken() },
        },
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.warn("No call logs found for the selected pathway");
          setWarning(
            "No call logs found for the selected pathway. Send calls from the Conversational Pathways page:",
          );
          setLoading(false);
          return;
        } else {
          throw new Error("Failed to fetch heatmap data");
        }
      }
      const data = await response.json();
      setHeatmapData(data?.data);
      createNodesAndEdges(data.data);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const createNodesAndEdges = useCallback(
    (data) => {
      if (
        !data ||
        !data.nodes ||
        !data.edges ||
        !data.nodeFrequency ||
        !data.heatMap
      )
        return;

      const { nodes, edges, nodeFrequency, heatMap } = data;
      const frequencies = Object.values(nodeFrequency);
      const minFrequency = Math.min(...frequencies);
      const maxFrequency = Math.max(...frequencies);

      const newNodes = nodes.map((node) => ({
        id: node.id,
        type: "custom",
        data: {
          id: node.id,
          label: node.data.name,
          frequency: nodeFrequency[node.id] || 0,
          minFrequency,
          maxFrequency,
          prompt: node.data.prompt,
          onClick: setSelectedNodeId,
        },
        position: node.position,
      }));

      const maxEdgeCount = Math.max(...heatMap.map((h) => h.count));
      const newEdges = edges.map((edge) => {
        const heatMapEntry = heatMap.find(
          (h) => h.source === edge.source && h.target === edge.target,
        );
        const thickness = heatMapEntry
          ? 1 + (heatMapEntry.count / maxEdgeCount) * 3
          : 1;
        return {
          id: edge.id,
          source: edge.source,
          target: edge.target,
          type: "custom",
          data: {
            label: heatMapEntry ? `${heatMapEntry.count}` : "",
            thickness: thickness,
          },
          animated: !!heatMapEntry,
        };
      });

      setNodes(newNodes);
      setEdges(newEdges);
    },
    [setNodes, setEdges],
  );

  const getLayoutedElements = useCallback((nodes, edges, direction = "TB") => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const nodeWidth = 150;
    const nodeHeight = 60;
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    return nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      return node;
    });
  }, []);

  const onLayout = useCallback(
    (direction) => {
      const layoutedNodes = getLayoutedElements(nodes, edges, direction);
      setNodes([...layoutedNodes]);
    },
    [nodes, edges, setNodes, getLayoutedElements],
  );

  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      onLayout("TB");
    }
  }, [nodes, edges, onLayout]);

  const toggleReplay = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);

  const resetReplay = useCallback(() => {
    setReplayIndex(0);
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    let intervalId;
    if (isPlaying && selectedCallId && heatmapData) {
      const path = heatmapData.callPaths[selectedCallId].nodes;
      if (path) {
        intervalId = setInterval(() => {
          setReplayIndex((prevIndex) => {
            if (prevIndex < path.length - 1) {
              return prevIndex + 1;
            } else {
              setIsPlaying(false);
              return prevIndex;
            }
          });
        }, replaySpeed);
      }
    }
    return () => clearInterval(intervalId);
  }, [isPlaying, selectedCallId, heatmapData, replaySpeed]);

  useEffect(() => {
    if (selectedCallId && heatmapData) {
      const path = heatmapData.callPaths[selectedCallId].nodes;
      if (path) {
        const newNodes = nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            isHighlighted:
              path?.includes(node.id) && path.indexOf(node.id) <= replayIndex,
          },
        }));
        const newEdges = edges.map((edge) => ({
          ...edge,
          data: {
            ...edge.data,
            isHighlighted:
              path?.includes(edge.source) &&
              path?.includes(edge.target) &&
              path.indexOf(edge.source) < replayIndex &&
              path.indexOf(edge.target) <= replayIndex,
          },
        }));
        setNodes(newNodes);
        setEdges(newEdges);
      }
    }
  }, [replayIndex, selectedCallId, heatmapData, nodes, edges]);

  const filteredDialogue = useMemo(() => {
    if (!callLogs || !callLogs.dialogue) return [];
    return callLogs.dialogue.filter(
      (message) => message.text && message.text.trim() !== "",
    );
  }, [callLogs]);

  const currentNodeDialogue = useMemo(() => {
    if (!selectedNodeId || !filteredDialogue) return null;
    return filteredDialogue.find(
      (message) => message.nodeId === selectedNodeId,
    );
  }, [selectedNodeId, filteredDialogue]);

  return (
    <div className="h-screen  text-gray-900 ">
      {loading && <Loading loading={loading} />}

      {error ? (
        <div>
          <button
            onClick={handleBack}
            className="my-4  px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
          >
            Back to Pathways
          </button>
          <div
            className="bg-red-50 border-l-4 border-red-500 text-red-700 p-4 mb-6"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        </div>
      ) : (
        warning && (
          <div>
            <button
              onClick={handleBack}
              className="my-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
            >
              Back to Pathways
            </button>
            <div
              className="bg-yellow-50 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
              role="alert"
            >
              <p className="font-bold">Analytics Unavailable</p>
              <p>{warning}</p>
              {/* Navigate to the Conversational Pathways page with the pathway id selected,/dashboard?page=convo-pathways&id=278fc600-6a10-475f-b735-de3a0c7b4d44 */}
            </div>
            <Link
              className="px-4 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              to={`/dashboard?page=convo-pathways&id=${selectedPathway.id}`}
              target="_blank"
            >
              View Pathway
            </Link>
          </div>
        )
      )}

      {!selectedPathway && (
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            Select a Pathway
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {pathways.map((pathway) => (
              <button
                key={pathway.id}
                onClick={() => {
                  setSelectedPathway(pathway);
                  fetchHeatmap(pathway.id);
                }}
                className="p-6 bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow text-left"
              >
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {pathway.name}
                </h3>
                <p className="text-sm text-gray-600">{pathway.description}</p>
              </button>
            ))}
          </div>
        </div>
      )}

      {selectedPathway && heatmapData && (
        <div className="space-y-8">
          <div className="flex justify-between items-center">
            {/* <h2 className="text-3xl font-semibold text-gray-800">Pathway: {selectedPathway.name}</h2> */}
            <button
              onClick={handleBack}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
            >
              Back to Pathways
            </button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
            <StatDisplay title="Total Calls" value={heatmapData.totalCalls} />
            <StatDisplay title="Unique Users" value={heatmapData.uniqueUsers} />
            {heatmapData.avgCallDuration && (
              <StatDisplay
                title="Avg. Call Duration"
                value={`${heatmapData.avgCallDuration.toFixed(2)}m`}
              />
            )}
            {/* <StatDisplay title="Conversion Rate" value={`${(heatmapData.conversionRate * 100).toFixed(2)}%`} /> */}
            <StatDisplay title="Total Nodes" value={heatmapData.totalNodes} />
            <StatDisplay title="Total Edges" value={heatmapData.totalEdges} />
          </div>

          <div className="flex gap-8">
            <div className="w-2/3 bg-white p-2 rounded-lg shadow-sm border border-gray-200">
              <h3 className="text-2xl font-semibold text-gray-800">
                Pathway Heatmap
              </h3>

              <div style={{ height: "400px" }}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  fitView
                >
                  <Background color="#f0f0f0" gap={12} />
                  <Controls />
                  <MiniMap
                    nodeColor={(node) =>
                      getColor(
                        node.data.frequency,
                        node.data.minFrequency,
                        node.data.maxFrequency,
                      )
                    }
                    maskColor="rgba(240, 240, 240, 0.6)"
                  />
                </ReactFlow>
              </div>
            </div>
            <div className="w-1/3 space-y-8">
              {/* <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                                <h3 className="text-xl font-semibold mb-4 text-gray-800">Call Volume Trend</h3>
                                <ResponsiveContainer width="100%" height={200}>
                                    <LineChart data={heatmapData.timeSeries}>
                                        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                                        <XAxis dataKey="date" stroke="#666" />
                                        <YAxis stroke="#666" />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="calls" stroke="#3b82f6" strokeWidth={2} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div> */}
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  Top 5 Most Traversed Paths
                </h3>
                <ul className="space-y-2">
                  {heatmapData.heatMap
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 5)
                    .map((path, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center bg-gray-50 p-3 rounded"
                      >
                        <span className="text-sm text-gray-700">
                          {path.source} → {path.target}
                        </span>
                        <span className="font-semibold text-gray-900">
                          {path.count}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  Call Logs
                </h3>
                <div className="h-64 overflow-y-auto">
                  {heatmapData.callIds.map((callId) => (
                    <button
                      key={callId.c_id}
                      onClick={() => highlightPath(callId.c_id)}
                      className="w-full text-left p-2 hover:bg-gray-100 transition-colors text-gray-700"
                    >
                      {callId.c_id}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            {selectedCallId && (
              <ReplayLogs
                dialogue={filteredDialogue}
                replayIndex={replayIndex}
                isPlaying={isPlaying}
                replaySpeed={replaySpeed}
                onTogglePlay={toggleReplay}
                onClose={setIsDrawerOpen}
                onReset={resetReplay}
                onSpeedChange={handleSpeedChange}
              />
            )}
          </Drawer>

          {currentNodeDialogue && (
            <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
              <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                Selected Node Dialogue
              </h3>
              <div className="p-4 rounded bg-gray-50">
                <p className="font-medium text-gray-800">
                  {currentNodeDialogue.role === "assistant" ? "AI" : "User"}
                </p>
                <p className="text-sm mt-2 text-gray-700">
                  {currentNodeDialogue.text}
                </p>
                <p className="text-xs text-gray-500 mt-2">
                  Node: {currentNodeDialogue.nodeId}
                </p>
                <p className="text-xs text-gray-500">
                  {new Date(currentNodeDialogue.timestamp).toLocaleString()}
                </p>
              </div>
            </div>
          )}

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              Key Insights
            </h3>
            <ul className="space-y-3 text-sm">
              <li className="flex items-start">
                <ChevronRight className="flex-shrink-0 w-5 h-5 text-blue-500 mr-2 mt-0.5" />
                <div>
                  <span className="font-medium">Most traversed path:</span>
                  <span className="ml-1">
                    {heatmapData.mostCommonPath.source}
                  </span>{" "}
                  to
                  <span className="ml-1 font-medium">
                    {heatmapData.mostCommonPath.target}
                  </span>
                  <span className="ml-1">
                    ({heatmapData.mostCommonPath.count} times)
                  </span>
                </div>
              </li>
              <li className="flex items-start">
                <ChevronRight className="flex-shrink-0 w-5 h-5 text-blue-500 mr-2 mt-0.5" />
                <div>
                  <span className="font-medium">Busiest node:</span>
                  <span className="ml-1">
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => b[1] - a[1],
                      )[0][0]
                    }
                  </span>
                  <span className="ml-1">
                    (traversed{" "}
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => b[1] - a[1],
                      )[0][1]
                    }{" "}
                    times)
                  </span>
                </div>
              </li>
              <li className="flex items-start">
                <ChevronRight className="flex-shrink-0 w-5 h-5 text-blue-500 mr-2 mt-0.5" />
                <div>
                  <span className="font-medium">Least used node:</span>
                  <span className="ml-1">
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => a[1] - b[1],
                      )[0][0]
                    }
                  </span>
                  <span className="ml-1">
                    (traversed only{" "}
                    {
                      Object.entries(heatmapData.nodeFrequency).sort(
                        (a, b) => a[1] - b[1],
                      )[0][1]
                    }{" "}
                    times)
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default PathwayHeatmap;
