import React, { useState } from "react";
import {
  CheckCircle,
  AlertTriangle,
  ChevronDown,
  ChevronRight,
} from "lucide-react";

const RenderUnitTestResults = ({ results }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (
    !results ||
    !results.unitTestResults ||
    Object.keys(results.unitTestResults).length === 0
  )
    return null;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center focus:outline-none text-xs font-medium mr-4"
        >
          {isExpanded ? (
            <ChevronDown className="w-3 h-3 mr-1" />
          ) : (
            <ChevronRight className="w-3 h-3 mr-1" />
          )}
          {isExpanded ? "Hide Details" : "Show Details"}
        </button>
        <div className="flex items-center">
          {results.flag ? (
            <AlertTriangle className="w-4 h-4 text-red-500 mr-1" />
          ) : (
            <CheckCircle className="w-4 h-4 text-green-500 mr-1" />
          )}
          <span
            className={`text-xs font-medium ${
              results.flag ? "text-red-500" : "text-green-500"
            }`}
          >
            {results.flag ? "Failed" : "Passed"}
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="mt-2 space-y-1 text-xs">
          {Object.entries(results.unitTestResults).map(([testName, result]) => (
            <div key={testName} className="flex items-start">
              <div className="flex-shrink-0 w-4 mt-1 mr-1">
                {result.passed ? (
                  <CheckCircle className="w-3 h-3 text-green-500" />
                ) : (
                  <AlertTriangle className="w-3 h-3 text-red-500" />
                )}
              </div>
              <div className="flex-grow min-w-0">
                <div className="font-medium text-gray-600 truncate">
                  {testName}
                </div>
                <div className="text-gray-500 break-words">
                  {result.passed ? "Pass" : result.reason}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RenderUnitTestResults;
