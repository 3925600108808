import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "components/core/Button";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

export default function DuplicatePathwayDialog({
  open,
  onClose,
  isDuplicate,
  isTemplateDuplicate,
  currId,
  setRefreshPathways,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  const handleCreateOrDuplicate = async () => {
    try {
      let url = isTemplateDuplicate
        ? "/api/convo_pathway/duplicate_template"
        : "/api/convo_pathway/duplicate";
      let response;

      if (isDuplicate || isTemplateDuplicate) {
        response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthToken(),
          },
          body: JSON.stringify({
            name: name,
            description: description,
            pathwayID: currId,
          }),
        });
      } else {
        response = await fetch("/api/convo_pathway/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthToken(),
          },
          body: JSON.stringify({
            name: name,
            description: description,
          }),
        });
      }

      if (response.status === 200) {
        const data = await response.json();
        toast.success(
          isDuplicate || isTemplateDuplicate
            ? "Pathway duplicated successfully"
            : "Pathway created successfully",
          { position: "bottom-right" },
        );
        onClose();
        setRefreshPathways((prev) => !prev);
        navigate(`/dashboard?page=convo-pathways&id=${data.id}`);
      } else {
        toast.error("Error creating pathway");
      }
    } catch (error) {
      console.error("Error creating or duplicating pathway:", error);
      toast.error("Error creating or duplicating pathway");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 border border-gray-300 bg-white p-8 rounded-lg shadow-lg w-[500px] space-y-4">
        <h1 className="text-2xl text-indigo-500 font-bold mb-4 text-center">
          {isDuplicate
            ? "Duplicate Pathway"
            : isTemplateDuplicate
              ? "Duplicate Template Pathway"
              : "New Pathway"}
        </h1>
        <div className="space-y-2">
          <label>Name:</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5"
            placeholder="Pathway Name"
          />
        </div>
        <div className="space-y-2">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5"
            placeholder="Pathway Description"
          />
        </div>
        <div className="pt-2">
          <button
            className="bg-indigo-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleCreateOrDuplicate}
          >
            {isDuplicate || isTemplateDuplicate
              ? "Duplicate Pathway"
              : "Create pathway"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
