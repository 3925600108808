import Tabs from "components/core/Tabs";
import BYOT from "./BYOT/BYOT";
import TCPA from "./TCPA/TCPA";
import VOIP from "./VOIP/VOIP";
import { PageWrapper } from "components/core/PageWrapper";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import styled from "styled-components";
import AddOnIcon from "assets/icons/addon-icon.svg";
import { getUserData } from "utils/funcs/browser/getUserData";
import { useEffect, useState } from "react";
import Button from "components/core/Button";
import TwilioIcon from "assets/images/TwilioLogo.svg";
import TwilioCircle from "assets/images/TwilioCircle.png";
import Modal from "components/core/Modal";
import CloseIcon from "assets/icons/close-icon-black.svg";
import axios from "axios";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import { toast } from "react-toastify";
import { getUser } from "utils/funcs/auth/getUser";
import TwilioNumbers from "./BYOT/Settings/Numbers/TwilioNumbers";
import GenerateKey from "./BYOT/Settings/Key/GenerateKey";
import CloseRed from "assets/icons/close-red.svg";

const init_items = [
  {
    label: "BYOT",
    value: "twilio_enabled",
    description: `(Bring Your Own Twilio) Integrate your business's Twilio account with Bland. Packaged with a dialer to rotate through your Twilio numbers, preventing them from being marked as 'Spam'. We do not offer refunds for this product.`,
    price_label: "Free",
    price_value: null,
    enabled: false,
  },
  {
    label: "TCPA Protection",
    value: "blacklist_lookup_enabled",
    description:
      "Automatically checks and blocks phone numbers that have been tied to TCPA lawsuits or have asked to not be contacted.",
    price_label: "Contact Us",
    price_value: null,
    enabled: false,
  },
  {
    label: "Local Dialing",
    value: "local_enabled",
    description:
      "Call numbers from an identical area code to increase pickup rates.",
    price_label: "Contact Us",
    price_value: null,
  },
];

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(350px, 1fr)
  ); /* Adjust minmax values as needed */
  gap: 16px;
`;

const GridItem = styled.div`
  /* Any specific styles for grid items */
  padding: 20px;
  border: 2px solid #eeeeee;
  min-width: 350px;
  max-width: 600px;
  border-radius: 2px;
`;

export default function AddOns() {
  const [showByot, setshowByot] = useState(false);
  const [user, setUser] = useState(getUserData());
  const [showModal, setShowModal] = useState(false);
  const [cancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [values, setValues] = useState([]);
  const [modalData, setModalData] = useState({
    modalButton: () => {},
    modalTitle: null,
    title: null,
    description: null,
    price_label: null,
    price_value: null,
    onClickHandler: () => {},
  });

  const getValues = (preVal = null) => {
    console.log(process.env);
    try {
      let userData;

      if (!preVal) {
        userData = user?.user;
      } else {
        userData = preVal?.user?.user;
      }

      console.log({ ...userData, USER: "DATA" });
      const { twilio_enabled, blacklist_lookup_enabled, local_enabled } =
        userData;
      let mappedItems = init_items.map((doc) => {
        switch (doc.value) {
          case "twilio_enabled":
            doc.enabled = true;
            break;
          case "blacklist_lookup_enabled":
            doc.enabled = blacklist_lookup_enabled || false;
            break;
          case "local_enabled":
            doc.enabled = local_enabled || false;
            break;
          default:
            break;
        }

        return doc;
      });

      return mappedItems;
    } catch (error) {
      return [];
    }
  };

  const handleRefreshUserData = async () => {
    const updatedUser = await getUser();
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setUser(updatedUser?.user);

    setTimeout(() => {
      console.log("say cheese");
    }, 2000);

    const r = getValues(updatedUser);
    setValues(r);
  };

  const handleSubscribeByot = async () => {
    try {
      if (!user) throw new Error("No_User");
      setLoading(true);
      const response = await axios({
        url: "/api/byot/subscribe",
        method: "POST",
        headers: {
          authorization: getAuthToken(),
          "content-type": "application/json",
        },
        data: {
          user_id: user?.user?.id,
        },
        validateStatus: false,
      });

      if (response.statusText === "OK" && response?.status === 200) {
        if (response.data?.twilio_enabled) {
          await handleRefreshUserData();
          setLoading(false);
          setShowModal(false);
        }
      } else {
        if (response.status === 400) {
          if (response?.data?.message === "payment_failed") {
            setLoading(false);
            setShowModal(false);
            return toast.error(
              "Payment Declined. Try again with different method.",
            );
          } else if (response?.data?.message === "no_payment_method") {
            setLoading(false);
            setShowModal(false);
            window.location.href = "/dashboard?page=billing-details";
            return toast.error("Payment Method not set up.");
          } else {
            setLoading(false);
            setShowModal(false);
            return toast.error("Issue completing your request.");
          }
        } else {
          setLoading(false);
          setShowModal(false);
          return toast.error("Unexpected Error while processing.");
        }
      }
    } catch (error) {
      setLoading(false);
      return toast.error("Unexpected Error while processing.");
    }
  };

  const handleCancelByot = async () => {
    try {
      if (!user) throw new Error("No_User");
      setLoadingCancel(true);
      const { subscriptions } = getUserData();
      console.log({ subscriptions, ...user?.user });
      if (!subscriptions || subscriptions?.length === 0) {
        setLoadingCancel(false);
        setShowCancelModal(false);
        return toast.error("No active subscription found");
      }

      const sub = subscriptions?.find(
        (doc) => doc.item === "Twilio Discounted" && doc.status === "active",
      );
      if (!sub) {
        setLoadingCancel(false);
        setShowCancelModal(false);
        return toast.error("No active subscription found");
      }

      const response = await axios({
        url: "/api/byot/cancel",
        method: "POST",
        headers: {
          authorization: getAuthToken(),
          "content-type": "application/json",
        },
        data: {
          user_id: user?.user?.id,
          subscription_id: sub.id,
        },
        validateStatus: false,
      });

      if (response.statusText === "OK" && response?.status === 200) {
        if (response.data?.status === "success") {
          await handleRefreshUserData();
          setLoadingCancel(false);
          setShowCancelModal(false);
          return toast.success("BYOT Subscription canceled");
        }
      } else {
        if (response.status === 400) {
          setLoadingCancel(false);
          setShowCancelModal(false);
          return toast.error("Issue canceling subscription");
        }
      }
    } catch (error) {
      setLoadingCancel(false);
      setShowCancelModal(false);
      return toast.error("Issue canceling subscription");
    }
  };

  const verifyTwilioEnabled = () => {
    return true;
  };

  const handleOpenTwilioConfig = () => {
    const isTwilioEnabled = verifyTwilioEnabled();
    if (!isTwilioEnabled)
      return toast.error("BYOT not enabled on your account");
    setshowByot(true);
  };

  useEffect(() => {
    setValues(getValues());
    // handleRefreshUserData();
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle onClick={() => setshowByot(false)}>
          Add Ons {showByot && "/ BYOT"}
        </PageTitle>
        {showByot && (
          <Button
            onClick={() => setshowByot(false)}
            appearance={"outline"}
            style={{ height: 30, borderRadius: 6 }}
          >
            <p>Close BYOT</p>
          </Button>
        )}
      </ActionBar>

      {showByot ? (
        <div id="wrapper" style={{ display: "grid", gap: 16 }}>
          <Tabs
            activeTab={activeTab}
            onTabChange={handleTabChange}
            fixed
            tabs={[
              {
                item: "Numbers",
                panel: (
                  <div style={{ marginTop: 50 }}>
                    <TwilioNumbers onSwitchTab={handleTabChange} />
                  </div>
                ),
              },
              {
                item: "Generate Key",
                panel: (
                  <div style={{ marginTop: 50 }}>
                    <GenerateKey />
                  </div>
                ),
              },
            ]}
          />
        </div>
      ) : (
        <Grid>
          {values.map((item, index) => (
            <GridItem key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    background: "#ecedfc",
                    color: "#514aea",
                    borderRadius: 4,
                    height: 40,
                    width: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={AddOnIcon} style={{ height: 22, width: 22 }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <PageTitle style={{ fontWeight: "550", fontSize: 13 }}>
                    {item.label}
                  </PageTitle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ fontSize: 10, color: "grey", fontWeight: "300" }}
                    >
                      cost
                    </p>
                    &nbsp;
                    <p
                      style={{ fontSize: 11, fontWeight: "550", color: "grey" }}
                    >
                      {item.price_label}
                      {item.price_label !== "Contact Us" &&
                        item.price_label !== "Free" &&
                        "/mo"}
                    </p>
                  </div>
                </div>

                {item.value === "twilio_enabled" && (
                  <img
                    src={TwilioIcon}
                    style={{ marginLeft: "auto", width: 90, height: 20 }}
                  />
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <p
                  style={{
                    fontSize: 11,
                    fontWeight: "300",
                    color: "grey",
                    height: 50,
                    overflow: "clip",
                  }}
                >
                  {item.description}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 15,
                  marginTop: 15,
                }}
              >
                {item.value === "twilio_enabled" ? (
                  <>
                    {item.enabled && (
                      <Button
                        onClick={() => {
                          handleOpenTwilioConfig();
                        }}
                        style={{
                          height: 30,
                          borderRadius: 4,
                          alignItems: "center",
                        }}
                        appearance={"outline"}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: 7,
                          }}
                        >
                          <img
                            src={TwilioCircle}
                            style={{ height: 12, width: 12 }}
                          />
                          <p style={{ fontSize: 12, fontWeight: "500" }}>
                            Use Your Twilio
                          </p>
                        </div>
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      window.location.href = "/enterprise";
                    }}
                    disabled={item?.enabled}
                    appearance={item?.enabled ? "outline" : ""}
                    style={{ borderRadius: 4, height: 30 }}
                  >
                    <p style={{ fontSize: 12, fontWeight: "500" }}>
                      {item?.enabled ? "Enabled" : "Contact Us"}
                    </p>
                  </Button>
                )}
              </div>
            </GridItem>
          ))}
        </Grid>
      )}
      <Modal
        height={"auto"}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <div id="modal-container">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                fontWeight: "500",
                fontSize: 16,
                color: "#000",
              }}
            >
              {modalData.modalTitle}
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                setShowModal(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              border: "1px solid #eeeeee",
              borderRadius: 2,
              marginTop: 15,
              padding: "10px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#ecedfc",
                  color: "#514aea",
                  borderRadius: 4,
                  padding: "2px 12px",
                }}
              >
                <img src={AddOnIcon} style={{ height: 32, width: 32 }} />
              </div>

              <div style={{ marginLeft: 20 }}>
                <p style={{ fontSize: 14, fontWeight: "500" }}>
                  {modalData.title}
                </p>
                <p style={{ fontSize: 11, fontWeight: "450", color: "grey" }}>
                  {modalData.description}
                </p>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <p style={{ fontSize: 14, fontWeight: "500" }}>
                  {modalData.price_label}/m
                </p>
              </div>
            </div>
          </div>

          <Button
            loading={loading}
            onClick={() => handleSubscribeByot()}
            style={{ borderRadius: 6, height: 35, marginTop: 20 }}
          >
            <p style={{ fontSize: 14, fontWeight: "550" }}>Confirm Purchase</p>
          </Button>
        </div>
      </Modal>
      <Modal
        height={"auto"}
        open={cancelModal}
        onClose={() => setShowCancelModal(false)}
      >
        <div id="modal-container">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                margin: 0,
                padding: 0,
                fontWeight: "500",
                fontSize: 16,
                color: "#000",
              }}
            >
              {modalData.modalTitle}
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                setShowModal(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              border: "1px solid #eeeeee",
              borderRadius: 2,
              marginTop: 15,
              padding: "10px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#ffe5ec",
                  color: "#d90429",
                  borderRadius: 4,
                  padding: "2px 12px",
                }}
              >
                <img
                  src={CloseRed}
                  style={{ height: 32, width: 32, color: "#d90429" }}
                />
              </div>

              <div style={{ marginLeft: 20 }}>
                <p style={{ fontSize: 14, fontWeight: "500" }}>
                  {modalData.title}
                </p>
                <p style={{ fontSize: 11, fontWeight: "450", color: "grey" }}>
                  {modalData.description}
                </p>
              </div>
            </div>
          </div>

          <Button
            loading={loadingCancel}
            onClick={() => handleCancelByot()}
            style={{
              borderRadius: 6,
              height: 35,
              marginTop: 20,
              background: "#d90429",
            }}
          >
            <p style={{ fontSize: 14, fontWeight: "550" }}>
              Cancel Subscription
            </p>
          </Button>
        </div>
      </Modal>
    </PageWrapper>
  );
}

/*
      <Tabs
         side
         title="Add Ons"
         tabs={[
            {
               item: "TCPA Protection",
               panel: <TCPA />,
            },
            {
               item: "Bring Your Twilio",
               panel: <BYOT />,
            },
            {
               item: "Local Dialing",
               panel: <VOIP />,
            },
         ]}
      />
*/
