import { VscCallOutgoing } from "react-icons/vsc";
import { useContext, useEffect, useState } from "react";
import { Dialog, Menu } from "@headlessui/react";
import { toast } from "react-toastify";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { FaCaretDown, FaEdit, FaTrash } from "react-icons/fa";
import ReactPhoneInput from "components/core/PhoneInput";
import Voice from "components/Dashboard/Parameters/Voice";
import Button from "components/core/Button";

import Record from "components/Dashboard/Parameters/Record";
import InterruptionThresh from "components/Dashboard/Parameters/InterruptionThresh";
import RequestData from "components/Dashboard/Parameters/RequestData";
import WaitGreeting from "components/Dashboard/Parameters/WaitGreeting";
import Webhook from "components/Dashboard/Parameters/Webhook";
import Metadata from "components/Dashboard/Parameters/Metadata";
import Pronunciation from "components/Dashboard/Parameters/Pronunciation";
import AnsweredBy from "components/Dashboard/Parameters/AnsweredBy";
import MaxDuration from "components/Dashboard/Parameters/MaxDuration";
import Language from "components/Dashboard/Parameters/Language";
import BlockInterruptions from "components/Dashboard/Parameters/BlockInterruptions";
import VoicemailActionSelect from "components/Dashboard/Parameters/VoicemailAction";
import Voicemail_message from "components/Dashboard/Parameters/voicemailMessage";
import CustomDeployment from "components/Dashboard/Parameters/CustomDeployment";
import AnalysisSchemaCo from "components/Dashboard/Parameters/AnalysisSchema";
import { objectToKeyValue } from "utils/formatting/objToKeyValues";
import VoicemailSMS from "components/Dashboard/Parameters/voicemailSMS";
import FlowContext from "../contextFlow";
import { sanitizePhone, addPlus } from "utils/formatting/phone";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import IgnoreButtonPress from "components/Dashboard/Parameters/IgnoreButtonPress";
import { Button as RadixButton } from "@radix-ui/themes";
import WebhookEvents from "components/Dashboard/Parameters/WebhookEvents";
import KeywordInput from "components/Dashboard/Parameters/Keywords";
import NoiseCancellation from "components/Dashboard/Parameters/NoiseCancel";
const SendCall = ({
  user,
  id,
  setShowChat,
  setCallID,
  versions,
  setShowChatInterface,
  button = null,
}) => {
  const token = getAuthToken();
  const { elements, selectedVersion } = useContext(FlowContext);
  const [isLoading, setIsLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  const [savedConfigs, setSavedConfigs] = useState([]);
  const [configName, setConfigName] = useState("");
  const [formKey, setFormKey] = useState(0); // Add this line
  const [editingConfig, setEditingConfig] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);

  const methods = useForm({
    defaultValues: {
      phone_number: "",
      voice: "nat",
      interruption_threshold: 100,
      webhook: null,
      language: "en",
      wait_for_greeting: false,
      block_interruptions: false,
      record: false,
      ignore_button_press: false,
      answered_by_enabled: false,
      max_duration: 12,
      voicemail_message: null,
      voicemail_action: "hangup",
      endpoint: null,
      analysis_schema: null,
      voicemail_sms: null,
      selectedVersion: selectedVersion ?? versions?.[0] ?? null,
      webhook_events: null,
      keywords: null,
      noise_cancellation: false,
    },
  });

  const voicemailAction = methods.watch("voicemail_action");

  const loadSavedConfigs = async () => {
    try {
      const localConfigs = JSON.parse(
        localStorage.getItem("sendCallConfigs") || "[]",
      );

      if (localConfigs && localConfigs.length > 0) {
        const backupResponse = await fetch("/api/call_config/backup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ configs: localConfigs }),
        });
        if (!backupResponse.ok)
          throw new Error("Failed to fetch configurations");

        localStorage.removeItem("sendCallConfigs");
        console.log("Local configs backed up successfully");
      }

      const response = await fetch("/api/call_config/read", {
        headers: {
          Authorization: token,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch configurations");
      const configs = await response.json();

      setSavedConfigs([...configs]);
    } catch (error) {
      console.error("Error loading configurations:", error);
      //   toast.error("Failed to load saved configurations");
    }
  };

  useEffect(() => {
    // Update the form's version field whenever selectedVersion changes
    methods.setValue("selectedVersion", selectedVersion);
    loadSavedConfigs();
  }, [selectedVersion, methods]);

  const saveConfig = async () => {
    if (!configName) {
      toast.error("Please enter a name for your configuration");
      return;
    }
    try {
      const configData = {
        name: configName,
        call_config: methods.getValues(),
      };

      let response;
      if (editingConfig) {
        // Update existing config
        response = await fetch("/api/call_config/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ ...configData, id: editingConfig.id }),
        });
      } else {
        // Create new config
        response = await fetch("/api/call_config/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(configData),
        });
      }

      if (!response.ok) throw new Error("Failed to save configuration");
      const savedConfig = await response.json();

      setSavedConfigs((prev) => {
        const index = prev.findIndex((c) => c.id === savedConfig.id);
        if (index !== -1) {
          return prev.map((c) => (c.id === savedConfig.id ? savedConfig : c));
        } else {
          return [...prev, savedConfig];
        }
      });
      setSelectedConfig(savedConfig);
      setEditingConfig(savedConfig);
      toast.success(
        `Configuration ${editingConfig ? "updated" : "created"} successfully`,
      );
    } catch (error) {
      console.error("Error saving configuration:", error);
      toast.error("Failed to save configuration");
    }
  };

  const loadConfig = (config) => {
    methods.reset(config.call_config);
    setFormKey((prevKey) => prevKey + 1);
    setSelectedConfig(config);
    toast.success("Configuration loaded successfully");
  };

  const deleteConfig = async (configId) => {
    try {
      const response = await fetch(`/api/call_config/delete`, {
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({ id: configId }),
      });

      console.log("response", response);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Delete request failed:", errorText);
        throw new Error("Failed to delete config");
      }
      setSavedConfigs((prev) =>
        prev.filter((config) => config.id !== configId),
      );
      if (selectedConfig?.id === configId) {
        setSelectedConfig(null);
        setEditingConfig(null);
      }
      toast.success("Configuration deleted successfully");
    } catch (error) {
      console.error("Error deleting configuration:", error);
      toast.error("Failed to delete configuration");
    }
  };

  const startEditing = (config) => {
    setConfigName(config.name);
    setEditingConfig(config);
    methods.reset(config.call_config);
    setFormKey((prevKey) => prevKey + 1);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);

    const body = {
      ...methods.getValues(),
      analysis_schema: objectToKeyValue(formData.analysis_schema || {}),
      pathway_id: id,
      version_number: formData?.selectedVersion?.version_number ?? null,
      start_node_id: formData.start_node_id?.id,
      voicemail_sms: formData.voicemail_sms
        ? {
            message: formData.voicemail_sms,
          }
        : null,
    };

    body.phone_number = sanitizePhone(body.phone_number);
    body.phone_number = addPlus(body.phone_number);

    body.request_data = body.request_data?.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    console.log({ body });

    delete body.keywords_input;

    // if formdata endpoint exists and ends with / , remove it
    if (formData?.endpoint && formData.endpoint.endsWith("/")) {
      formData.endpoint = formData.endpoint.slice(0, -1);
    }

    const response = await fetch(
      `${formData?.endpoint || process.env.REACT_APP_BLAND_ENDPOINT}/v1/calls`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: user.api_key,
        },
        body: JSON.stringify(body),
      },
    );
    const data = await response.json();
    if (data.status === "success") {
      toast.success("Call triggered successfully", {
        position: "bottom-right",
      });
      setCallID(data.call_id);
    } else {
      toast.error(
        "Error triggering call.. Please check your call inputs and try again!",
        { position: "bottom-right" },
      );
    }
    setIsLoading(false);
    setIsOpen(false);

    setShowChatInterface(true);
    setShowChat(true);
  };

  return (
    <div>
      {/* <button
        onClick={() => setIsOpen(true)}
        className="flex items-center justify-center space-x-2 bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 text-md font-semibold border border-gray-400 rounded shadow-lg"
      >
        <span>Send Call</span>
        <VscCallOutgoing />
      </button> */}
      <RadixButton
        style={{ cursor: "pointer" }}
        color="iris"
        size={"2"}
        onClick={() => setIsOpen(true)}
      >
        Send Call
        <VscCallOutgoing />
      </RadixButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="fixed inset-0 bg-black/30 z-[100]" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto z-[100]">
          <Dialog.Panel className="w-1/3 rounded bg-white px-6 py-8 space-y-4 overflow-y-auto max-h-[90vh] min-h-[60vh] flex-col justify-center items-center shadow-lg">
            <Dialog.Title className="text-3xl text-indigo-500 font-semibold">
              Send a Call with your pathway now!
            </Dialog.Title>
            <Dialog.Description className="space-y-4 w-full">
              <FormProvider {...methods}>
                <form
                  key={formKey}
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="space-y-8"
                >
                  {/* Load Configuration Section */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Load Configuration
                    </label>
                    <Menu as="div" className="relative">
                      <Menu.Button className="flex items-center w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        <span className="flex-grow text-left">
                          {selectedConfig
                            ? selectedConfig.name
                            : "Select a configuration"}
                        </span>
                        <FaCaretDown className="w-4 h-4" />
                      </Menu.Button>
                      <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {savedConfigs.map((config) => (
                          <Menu.Item key={config.id}>
                            {({ active }) => (
                              <div
                                className={`${
                                  active ? "bg-indigo-50" : "bg-white"
                                } ${
                                  selectedConfig &&
                                  selectedConfig.id === config.id
                                    ? "bg-indigo-100"
                                    : ""
                                } group flex items-center justify-between w-full px-4 py-2 text-sm cursor-pointer`}
                                onClick={() => {
                                  loadConfig(config);
                                  startEditing(config);
                                }}
                              >
                                <span>{config.name}</span>
                                <div onClick={(e) => e.stopPropagation()}>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      // confirm delete with alert, then delete
                                      if (
                                        window.confirm(
                                          `Are you sure you want to delete ${config.name}?`,
                                        )
                                      ) {
                                        console.log("deleting here");

                                        deleteConfig(config.id);
                                      }
                                    }}
                                    className="text-red-600 hover:text-red-800"
                                  >
                                    <FaTrash />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Menu>
                  </div>
                  <ReactPhoneInput fieldName="phone_number" />
                  <div>
                    <label
                      htmlFor="node-select"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Start Node{" "}
                      <span className="italic text-xs text-gray-500">
                        Optional - (Default: Start Node)
                      </span>
                    </label>
                    <Controller
                      name="start_node_id"
                      control={methods.control}
                      render={({ field }) => (
                        <Menu as="div" className="relative mt-1">
                          <Menu.Button className="flex items-center w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                            <span className="flex-grow text-left">
                              {field.value
                                ? field.value.data.name || "Unnamed Node"
                                : "Select Node"}
                            </span>
                            <FaCaretDown className="w-4 h-4" />
                          </Menu.Button>
                          <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {elements.nodes.map((node) => (
                              <Menu.Item key={node.id}>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active ? "bg-indigo-50" : "bg-white"
                                    } ${
                                      field.value?.id === node.id
                                        ? "bg-indigo-100"
                                        : ""
                                    } group flex items-center w-full px-4 py-2 text-sm cursor-pointer`}
                                    onClick={() => field.onChange(node)}
                                  >
                                    {node.data.name || "Unnamed Node"}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                      )}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="version-select"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Version
                    </label>
                    <Controller
                      name="selectedVersion"
                      control={methods.control}
                      render={({ field }) => (
                        <Menu as="div" className="relative mt-1">
                          <Menu.Button className="flex items-center w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                            <span className="flex-grow text-left">
                              {field.value
                                ? field.value.name ||
                                  `Version ${field.value.version_number}`
                                : "Select Version"}
                            </span>
                            <FaCaretDown className="w-4 h-4" />
                          </Menu.Button>
                          <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {versions.map((version, index) => (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active ? "bg-indigo-50" : "bg-white"
                                    } ${
                                      field.value?.version_number ===
                                      version.version_number
                                        ? "bg-indigo-100"
                                        : ""
                                    } group flex justify-between items-center w-full px-4 py-2 text-sm cursor-pointer`}
                                    onClick={() => field.onChange(version)}
                                  >
                                    <span>
                                      {version.name ||
                                        `Version ${version.version_number}`}
                                    </span>
                                    {version.is_latest && (
                                      <span className="px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                                        Latest
                                      </span>
                                    )}
                                    {version.version_number === 0 && (
                                      <span className="px-2 py-1 bg-indigo-100 text-indigo-800 text-xs rounded-full">
                                        Published
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                      )}
                    />
                  </div>
                  <Voice className="overflow-visible" fieldName="voice" />
                  <RequestData fieldName="request_data" />
                  <InterruptionThresh fieldName="interruption_threshold" />
                  <Metadata fieldName="metadata" />
                  <Pronunciation fieldName="pronunciation_guide" />
                  <VoicemailActionSelect fieldName="voicemail_action" />
                  {voicemailAction === "leave_message" && (
                    <Voicemail_message fieldName="voicemail_message" />
                  )}
                  {user?.user?.sms_enabled &&
                    voicemailAction === "leave_sms" && (
                      <VoicemailSMS fieldName="voicemail_sms" />
                    )}
                  <AnsweredBy fieldName="answered_by_enabled" />
                  <WaitGreeting fieldName="wait_for_greeting" />
                  <BlockInterruptions fieldName="block_interruptions" />
                  <IgnoreButtonPress fieldName="ignore_button_press" />
                  <NoiseCancellation fieldName="noise_cancellation" />
                  <KeywordInput fieldName="keywords" />
                  <Language fieldName="language" />
                  <Record fieldName="record" />
                  <MaxDuration fieldName="max_duration" />
                  <Webhook fieldName="webhook" />
                  <WebhookEvents fieldName="webhook_events" />
                  <CustomDeployment fieldName="endpoint" />
                  <AnalysisSchemaCo fieldName="analysis_schema" label={false} />
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      {editingConfig ? "Update" : "Save"} Configuration
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={configName}
                        onChange={(e) => setConfigName(e.target.value)}
                        placeholder="Config Name"
                        className="flex-grow px-2 py-1 border rounded"
                      />
                      <button
                        type="button"
                        onClick={saveConfig}
                        className="px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800"
                      >
                        {editingConfig ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>

                  <Button submit loading={isLoading}>
                    Send Call
                  </Button>
                </form>
              </FormProvider>
            </Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default SendCall;
