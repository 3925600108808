import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import ActionCard from "./ActionCard";
import { actionItems } from "../../../utils/data/menus/homeItems";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import { AlertTriangle } from "lucide-react";
import areaCodeToLocation from "./areaCodeData.json";
import cityCoordinates from "./cityCoordinates.json";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

const chartConfig = {
  calls: {
    label: "Calls",
    color: "#0070f3", // Vercel blue
    gradient: ["rgba(0, 112, 243, 0.1)", "rgba(0, 112, 243, 0.6)"],
  },
};

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const MapMarker = ({ coordinates, color, size, label, isNew }) => (
  <Marker coordinates={coordinates}>
    <g transform={`translate(-${size / 2}, -${size / 2})`}>
      <circle
        r={size / 2}
        fill={color}
        stroke="#FFFFFF"
        strokeWidth="2"
        className={isNew ? "ping" : ""}
      />
      <text
        textAnchor="middle"
        y={size / 2 + 15}
        style={{
          fontFamily: "Inter, sans-serif",
          fill: "#1F2937",
          fontSize: "10px",
          fontWeight: "500",
        }}
      >
        {label}
      </text>
    </g>
  </Marker>
);

const ErrorDisplay = ({ message }) => (
  <div className="flex flex-col items-center justify-center h-full">
    <AlertTriangle className="w-8 h-8 text-yellow-500 mb-2" />
    <p className="text-sm text-gray-600">{message}</p>
  </div>
);

export default function Home() {
  const navigate = useNavigate();
  const [dailyData, setDailyData] = useState([]);
  const [areaCodeData, setAreaCodeData] = useState([]);
  const [error, setError] = useState({ calls: null, map: null });
  const [loading, setLoading] = useState(true);
  const [chartType, setChartType] = useState("line");

  useEffect(() => {
    console.log("here");
    const fetchData = async () => {
      try {
        const [callsRes, mapRes] = await Promise.all([
          fetch("/api/analytics/last_seven_calls", {
            headers: { Authorization: getAuthToken() },
          }),
          fetch("/api/analytics/last_seven_map", {
            headers: { Authorization: getAuthToken() },
          }),
        ]);

        const [callsData, mapData] = await Promise.all([
          callsRes.json(),
          mapRes.json(),
        ]);

        if (callsData.status === "success") {
          const now = new Date();
          const processedData = callsData.data
            .map((item) => ({
              hour: new Date(item.hour),
              count: parseInt(item.count, 10) || 0,
            }))
            .filter((item) => item.hour <= now)
            .sort((a, b) => a.hour - b.hour);

          setDailyData(processedData);
        } else {
          setError((prev) => ({
            ...prev,
            calls: "Failed to fetch calls data",
          }));
        }

        if (mapData.status === "success") {
          setAreaCodeData(mapData.data);
        } else {
          setError((prev) => ({
            ...prev,
            map: "Failed to fetch area code data",
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError({
          calls: "An unexpected error occurred",
          map: "An unexpected error occurred",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);
      return (
        <div className="bg-white border border-gray-200 rounded p-2 shadow-lg">
          <p className="font-semibold text-gray-800">
            {date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
            })}
          </p>
          <p className="text-blue-600">{`${chartConfig.calls.label}: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
    });
  };

  const renderLineChart = () => (
    <AreaChart
      data={dailyData}
      margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorCalls" x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="5%"
            stopColor={chartConfig.calls.gradient[1]}
            stopOpacity={0.8}
          />
          <stop
            offset="95%"
            stopColor={chartConfig.calls.gradient[0]}
            stopOpacity={0.2}
          />
        </linearGradient>
      </defs>
      <XAxis
        dataKey="hour"
        axisLine={{ stroke: "#E5E7EB" }}
        tickLine={false}
        tick={{ fill: "#4B5563", fontSize: 12 }}
        tickFormatter={formatXAxis}
        interval="preserveStart"
      />
      <YAxis
        axisLine={{ stroke: "#E5E7EB" }}
        tickLine={false}
        tick={{ fill: "#4B5563", fontSize: 12 }}
        width={30}
      />
      <Tooltip content={<CustomTooltip />} />
      <Area
        type="monotone"
        dataKey="count"
        stroke={chartConfig.calls.color}
        fillOpacity={1}
        fill="url(#colorCalls)"
      />
    </AreaChart>
  );

  const renderBarChart = () => (
    <BarChart
      data={dailyData}
      margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
    >
      <XAxis
        dataKey="hour"
        axisLine={{ stroke: "#E5E7EB" }}
        tickLine={false}
        tick={{ fill: "#4B5563", fontSize: 12 }}
        tickFormatter={formatXAxis}
        interval="preserveStart"
      />
      <YAxis
        axisLine={{ stroke: "#E5E7EB" }}
        tickLine={false}
        tick={{ fill: "#4B5563", fontSize: 12 }}
        width={30}
      />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="count" fill={chartConfig.calls.color} />
    </BarChart>
  );
  const renderDensityChart = () => {
    const maxCount = Math.max(...dailyData.map((d) => d.count));
    const minCount = Math.min(...dailyData.map((d) => d.count));
    const colorScale = (count) => {
      if (count === 0) {
        return "rgb(248, 248, 248)";
      }
      const ratio = (count - minCount) / (maxCount - minCount);
      const r = Math.floor(45 + 203 * (1 - ratio));
      const g = Math.floor(106 + 142 * (1 - ratio));
      const b = Math.floor(79 + 169 * (1 - ratio));
      return `rgb(${r}, ${g}, ${b})`;
    };

    const hours = Array.from({ length: 24 }, (_, i) => i);

    const getWeekData = () => {
      // Convert UTC to local time and sort the data
      const localData = dailyData
        .map((data) => {
          const localDate = new Date(data.hour);
          return { ...data, localHour: localDate };
        })
        .sort((a, b) => b.localHour - a.localHour);

      // Group the data by local date
      const groupedData = {};
      localData.forEach((data) => {
        const dateKey = data.localHour.toLocaleDateString("en-CA"); // YYYY-MM-DD format
        if (!groupedData[dateKey]) {
          groupedData[dateKey] = Array(24).fill(null);
        }
        groupedData[dateKey][data.localHour.getHours()] = data;
      });

      // Convert the grouped data into an array of day objects
      return Object.entries(groupedData).map(([date, hourData]) => ({
        date: new Date(date),
        hours: hourData,
      }));
    };

    const weekData = getWeekData();
    const d = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const formatDate = (date) => {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return `${days[date.getDay()]} ${date.getMonth() + 1}/${date.getDate()}`;
    };

    const formatTooltipTime = (date) => {
      return date.toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    };

    return (
      <div className="flex flex-col">
        <div className="flex mb-2">
          <div className="w-20"></div>
          {hours.map((hour) => (
            <div
              key={hour}
              className="flex-1 text-center text-xs text-gray-500"
            >
              {hour % 3 === 0 ? `${hour}:00` : ""}
            </div>
          ))}
        </div>
        {weekData.map(({ date, hours: dayHours }) => (
          <div key={date.toISOString()} className="flex items-center mb-1">
            <div className="w-20 text-xs text-right pr-2 text-gray-500">
              {d[date.getDay()]}
            </div>
            {dayHours.map((data, hour) => (
              <div
                key={hour}
                className="flex-1 aspect-square rounded-sm m-[1px] relative group"
                style={{
                  backgroundColor: data
                    ? colorScale(data.count)
                    : "rgb(248, 248, 248)",
                }}
              >
                {data && (
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded hidden group-hover:block whitespace-nowrap z-10">
                    {data.count} calls on {formatTooltipTime(data.localHour)}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        <div className="flex items-center justify-end mt-2">
          <span className="text-xs text-gray-500 mr-2">Less</span>
          <div
            className="w-4 h-4 rounded-sm"
            style={{ backgroundColor: "rgb(248, 248, 248)" }}
          ></div>
          <div
            className="w-4 h-4 rounded-sm"
            style={{ backgroundColor: "rgb(45, 106, 79)" }}
          ></div>
          <span className="text-xs text-gray-500 ml-2">More</span>
        </div>
      </div>
    );
  };
  const renderCallsChart = () => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        {chartType === "line" && renderLineChart()}
        {chartType === "bar" && renderBarChart()}
        {chartType === "density" && renderDensityChart()}
      </ResponsiveContainer>
    );
  };

  const renderAreaCodeMap = () => {
    if (!areaCodeData || areaCodeData.length === 0) {
      return <ErrorDisplay message="No area code data available" />;
    }

    const cityData = areaCodeData.reduce((acc, { areaCode, count }) => {
      const location = areaCodeToLocation[areaCode];
      if (location) {
        const key = `${location.city}, ${location.state}`;
        acc[key] = (acc[key] || 0) + count;
      }
      return acc;
    }, {});

    if (Object.keys(cityData).length === 0) {
      return <ErrorDisplay message="No city data available after processing" />;
    }

    const colorScale = scaleQuantile()
      .domain(Object.values(cityData))
      .range(["#BAE6FD", "#7DD3FC", "#38BDF8", "#0EA5E9", "#0284C7"]);

    return (
      <ComposableMap
        projection="geoAlbersUsa"
        projectionConfig={{ scale: 1100 }}
        className="w-full h-full"
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#d0d3d6"
                stroke="#E2E8F0"
                strokeWidth={0.5}
              />
            ))
          }
        </Geographies>
        {Object.entries(cityData).map(([city, count]) => {
          const coordinates = cityCoordinates[city];
          if (coordinates) {
            return (
              <MapMarker
                key={city}
                coordinates={coordinates}
                color={colorScale(count)}
                size={Math.sqrt(count) * 2}
                label={city.split(",")[0]}
                isNew={false}
              />
            );
          }
          return null;
        })}
      </ComposableMap>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen font-inter text-gray-600">
        Loading...
      </div>
    );
  }

  return (
    <PageWrapper className="bg-gray-50 min-h-screen">
      <ActionBar top>
        <div className="flex items-center justify-between w-full">
          <PageTitle className="text-2xl font-semibold text-gray-800">
            Home
          </PageTitle>
        </div>
      </ActionBar>

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {actionItems.map((action) => (
            <Card
              onClick={() => navigate(action.link)}
              key={action.title}
              className={`bg-white shadow-sm hover:shadow-lg cursor-pointer transition-all duration-200 rounded-sm overflow-hidden
                ${action.title.toLowerCase().includes("pathway") ? "border-l-4 border-blue-500" : ""}`}
            >
              <CardContent className="p-8">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {action.title}
                </h3>
                <p className="text-gray-600">{action.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8 mt-10">
          <Card className="bg-white shadow-sm rounded-sm overflow-hidden">
            <CardHeader className="bg-gray-50 border-b border-gray-200 flex justify-between items-start">
              <CardTitle className="text-lg font-semibold text-gray-800">
                Hourly Calls (Last 7 Days)
              </CardTitle>
              <Select value={chartType} onValueChange={setChartType}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select chart type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="line">Line Chart</SelectItem>
                  <SelectItem value="bar">Bar Chart</SelectItem>
                  <SelectItem value="density">Density Chart</SelectItem>
                </SelectContent>
              </Select>
            </CardHeader>
            <CardContent className="p-4">
              {error.calls ? (
                <ErrorDisplay message={error.calls} />
              ) : (
                renderCallsChart()
              )}
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm rounded-sm overflow-hidden">
            <CardHeader className="bg-gray-50 border-b border-gray-200">
              <CardTitle className="text-lg font-semibold text-gray-800">
                Call Density by Area Code
              </CardTitle>
            </CardHeader>
            <CardContent className="p-4 h-[400px] bg-blue-50/25">
              {error.map ? (
                <ErrorDisplay message={error.map} />
              ) : (
                renderAreaCodeMap()
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      <style jsx global>{`
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

        body {
          font-family: "Inter", sans-serif;
          background-color: #f9fafb;
          color: #1f2937;
        }

        .ping {
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
        }
        @keyframes ping {
          75%,
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
      `}</style>
    </PageWrapper>
  );
}
