import Switch from "components/core/Switch";

import { Tooltip } from "@/components/ui/tooltip";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function NoiseCancellation({ fieldName }) {
  const tooltipContent = (
    <span>
      Enable <strong>noise cancellation</strong> to reduce background noise
      during your call. This feature uses advanced algorithms to filter out
      unwanted sounds, ensuring clearer communication.
    </span>
  );

  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch
          sx={{ m: 5 }}
          fieldName={fieldName}
          label="Noise Cancellation"
        />
        <LightTooltip title={tooltipContent} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
