import { useEffect, useState } from "react";
import { Mixpanel } from "../../../components/Mixpanel";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { PageWrapper } from "components/core/PageWrapper";
import { PageTitle } from "components/core/PageTitle";
import ActionBar from "components/core/ActionBar";
import AuthUsers from "./AuthUsers";
import Affiliate from "./Affiliate";
import { Sidebar } from "components/Dashboard/Infrastructure/components/sidebar";
import styled from "styled-components";
import Billing from "../Billing/Billing";
import ProfileSettings from "./ProfileSettings";

export default function Settings() {
  const user = getUserData();
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    Mixpanel.track("Settings page");
  }, []);
  const pageOptions = [
    {
      label: "General Settings",
      panel: <ProfileSettings />,
      value: 0,
    },
    {
      label: "Affiliate Program",
      panel: <Affiliate user={user} />,
      value: 1,
    },
    {
      label: "Authorized Users",
      panel: <AuthUsers user={user} />,
      value: 2,
    },
    {
      label: "Billing Details",
      panel: <Billing user={user} />,
      value: 3,
    },
  ];
  return (
    <PageWrapper style={{ paddingTop: "140px" }} className="flex">
      <ActionBar top>
        <PageTitle>Account Settings</PageTitle>
      </ActionBar>
      <Sidebar
        onChange={setSelected}
        relative
        selected={selected}
        options={pageOptions}
      />
      <Wrapper>
        {pageOptions.map((key, value) => {
          if (selected === key.value) {
            return key.panel;
          }
          return null;
        })}
      </Wrapper>
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100% - 54px);
  margin-top: 50px;
  top: 0px;
  left: 360px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 395px);
  padding: 20px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
