import React, { useState, useCallback, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "@radix-ui/themes";
import LoadSpinner from "components/core/LoadSpinner";
import SlideOut from "components/Dashboard/CallLogs/SlideOut";
import DecisionLogs from "./decisionLogs";
import { FiCopy, FiCheck } from "react-icons/fi";
import CollapsibleContent from "./pathway_logs/collapsibleContent";

const PathwayLogs = ({
  // this is in call logs pathway logs componenet
  isOpen,
  onClose,
  callIds,
  initialCallId,
  setFocusedCId,
  focusedCall,
  loading = false,
  modalOpen = false,
  notesOpen = false,
  enableQA,
  graphID,
  showChat,
  elements,
  setChatConversationHistory,
  setChatStartNode,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [copied, setCopied] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(true);
  const callLogsRef = useRef(null);

  const handleNavigation = useCallback(
    (direction) => {
      setCurrentIndex((prevIndex) => {
        const newIndex =
          direction === "next"
            ? Math.min(prevIndex + 1, callIds?.length - 1)
            : Math.max(prevIndex - 1, 0);
        const newCallId = callIds[newIndex];
        setFocusedCId(newCallId);
        return newIndex;
      });
    },
    [callIds, setFocusedCId],
  );

  useEffect(() => {
    if (isOpen && initialCallId) {
      const index = callIds?.indexOf(initialCallId);
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [isOpen, initialCallId, callIds]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "j") {
        handleNavigation("next");
      } else if (event.key === "k") {
        handleNavigation("prev");
      }
    };

    if (!modalOpen && !notesOpen) {
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleNavigation, modalOpen, notesOpen]);

  useEffect(() => {
    if (callLogsRef.current && shouldScroll) {
      callLogsRef.current.scrollTop = callLogsRef.current.scrollHeight;
    }
  }, [focusedCall, shouldScroll]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = callLogsRef.current;
    const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
    setShouldScroll(isScrolledToBottom);
  };

  const copyCallID = () => {
    navigator.clipboard.writeText(initialCallId);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const renderContent = (content) => {
    if (typeof content === "string") {
      try {
        const jsonContent = JSON.parse(content);
        return Object.entries(jsonContent).map(([key, value]) => (
          <div key={key} className="flex items-center space-x-2 text-sm mt-2">
            <span className="font-medium text-gray-600 min-w-[100px]">
              {key}:
            </span>
            <CollapsibleContent
              content={
                typeof value === "object"
                  ? JSON.stringify(value, null, 2)
                  : value
              }
            />
          </div>
        ));
      } catch (e) {
        return <CollapsibleContent content={content} />;
      }
    } else if (typeof content === "object") {
      return Object.entries(content).map(([key, value]) => (
        <div key={key} className="flex items-center space-x-2 text-sm mt-2">
          <span className="font-medium text-gray-600 min-w-[100px]">
            {key}:
          </span>
          <CollapsibleContent
            content={
              typeof value === "object"
                ? JSON.stringify(value, null, 2)
                : String(value)
            }
          />
        </div>
      ));
    } else {
      return <CollapsibleContent content={String(content)} />;
    }
  };

  const callLogs = focusedCall?.pathway_logs;

  if (!showChat) return null;

  return (
    <SlideOut
      isOpen={isOpen}
      onClose={onClose}
      title={`Pathway Logs ${currentIndex + 1} of ${callIds?.length}`}
      subtitle={initialCallId ? `Call ID: ${initialCallId}` : null}
      footer={
        <>
          <div className="flex justify-between mb-4">
            <div>
              <button
                onClick={() => handleNavigation("prev")}
                disabled={currentIndex === 0}
                className={`flex items-center px-3 py-2 bg-gray-100 text-sm text-gray-700 rounded ${
                  currentIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                <ChevronLeftIcon className="h-4 w-4 mr-1" />
                Previous
              </button>
            </div>
            <div>
              <button
                onClick={() => handleNavigation("next")}
                disabled={currentIndex === callIds?.length - 1}
                style={{ marginLeft: "auto" }}
                className={`flex items-center text-sm px-3 py-2 bg-gray-100 text-gray-700 rounded ${
                  currentIndex === callIds?.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                Next
                <ChevronRightIcon className="h-4 w-4 ml-1" />
              </button>
            </div>
          </div>
          <Button
            type="button"
            style={{ width: "100%" }}
            color="lightblue"
            variant="solid"
            onClick={() =>
              window.open("https://app.bland.ai/enterprise", "_blank")
            }
            className="w-full bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            <p style={{ fontSize: 13 }}>Interested in Fine-Tuning?</p>
          </Button>
          <p style={{ fontSize: 10 }} className="mt-2 text-xs text-gray-500">
            We're constantly improving. For a fine-tuned model tailored to your
            needs, reach out to our team.
          </p>
        </>
      }
    >
      {loading ? (
        <LoadSpinner loading={loading} />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="bg-white rounded-lg overflow-hidden"
          style={{ fontFamily: "Inter, sans-serif" }}
        >
          {/* <div className="bg-amber-50 border-b border-amber-100 px-4 py-3 flex items-center justify-between">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 text-amber-600 mr-2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
              </svg>
              <span className="text-sm font-semibold text-amber-800">
                Live Call in Progress
              </span>
            </div>
            <div className="flex space-x-4">
              <span className="font-medium text-gray-500">MESSAGES</span>
              <span className="font-mono text-gray-700">
                {callLogs?.length || 0}
              </span>
            </div>
          </div> */}
          <div className="bg-gray-50 px-4 py-3 border-b border-gray-200 flex justify-between items-center text-sm">
            <div className="flex space-x-4 items-center">
              <span className="font-medium text-gray-500">CALL ID</span>
              <span className="font-mono text-gray-700">{initialCallId}</span>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={copyCallID}
                className="ml-2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
              >
                {copied ? (
                  <FiCheck className="w-4 h-4" />
                ) : (
                  <FiCopy className="w-4 h-4" />
                )}
              </motion.button>
            </div>
          </div>
          <div
            className="h-full overflow-y-auto bg-white"
            ref={callLogsRef}
            onScroll={handleScroll}
          >
            <AnimatePresence>
              {callLogs?.length === 0 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-col items-center justify-center h-full text-gray-400"
                >
                  <svg
                    className="animate-spin h-6 w-6 mb-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <p className="text-sm font-medium">Waiting for messages...</p>
                </motion.div>
              ) : (
                callLogs?.map((log, index) => (
                  <motion.div
                    key={log.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.15 }}
                    className={`py-3 px-4 border-b border-gray-100 ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    }`}
                  >
                    {log.text ? (
                      <motion.div
                        className="flex items-start"
                        whileHover={{ x: 0 }}
                        transition={{ type: "spring", stiffness: 300 }}
                      >
                        <span
                          className={`px-2 py-1 text-sm font-semibold rounded-md mr-3 ${
                            log.role === "assistant"
                              ? "bg-blue-100 text-blue-700"
                              : "bg-green-100 text-green-700"
                          }`}
                        >
                          {log.role.toUpperCase()}
                        </span>
                        <p className="text-md text-gray-700 flex-grow">
                          {log.text}
                        </p>
                      </motion.div>
                    ) : log.pathway_info ? (
                      <motion.div
                        whileHover={{ scale: 1.0 }}
                        transition={{ type: "spring", stiffness: 900 }}
                      >
                        <span className="px-2 py-1 text-sm font-semibold bg-purple-100 text-purple-700 rounded-md mb-2 inline-block">
                          PATHWAY INFO
                        </span>
                        {renderContent(log.pathway_info)}
                      </motion.div>
                    ) : (
                      log.decision && (
                        <DecisionLogs
                          decision={JSON.parse(log.decision)}
                          disableFineTune={true}
                          edges={elements?.edges}
                          nodes={elements?.nodes}
                          setChatConversationHistory={
                            setChatConversationHistory
                          }
                          setChatStartNode={setChatStartNode}
                          callID={initialCallId}
                          graphID={graphID}
                          enableQA={enableQA}
                        />
                      )
                    )}
                  </motion.div>
                ))
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      )}
    </SlideOut>
  );
};

export default PathwayLogs;
