import { setAuthToken } from "utils/funcs/browser/setAuthToken";

const {
  default: ViewPathway,
} = require("components/Dashboard/ConvoPathways/ViewPathway");
const { useState, useEffect } = require("react");
const { useParams } = require("react-router-dom");
const { getUser } = require("utils/funcs/auth/getUser");

function EmbeddedPathway() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const apiKey = urlParams.get("api_key");

    if (apiKey) {
      fetchUserData(apiKey)
        .then((userData) => {
          setUser(userData);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        });
    } else {
      // setLoading(false);
    }

    // Optional: Listen for postMessage events if you also want to support that method
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleMessage = (event) => {
    // Verify origin for security
    //   if (event.origin !== "https://trusted-client-domain.com") return;

    if (event.data.type === "setApiKey") {
      fetchUserData(event.data.apiKey)
        .then((userData) => {
          setUser(userData);
          setLoading(false);
        })
        .catch(console.error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!user)
    return <div>Authentication failed. Please check your API key.</div>;

  return (
    <div className="w-screen h-screen overflow-hidden">
      <ViewPathway embedMode={true} embedUser={user} pathwayId={id} />;
    </div>
  );
}

async function fetchUserData(apiKey) {
  const response = await fetch("/api/user/getUserId", {
    headers: {
      Authorization: `${apiKey}`,
    },
  });

  const data = await response.json();
  setAuthToken(data.jwt);

  const user = await getUser();

  localStorage.setItem("user", JSON.stringify(user));

  return user;
}

export default EmbeddedPathway;
