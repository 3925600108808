import Textarea from "components/core/Textarea";
import { Label } from "recharts";

export default function SummaryPrompt({ fieldName, autoResize = false }) {
  return (
    <>
      <Textarea
        autoResize={autoResize}
        label={"Summary Prompt"}
        fieldName={fieldName}
        rows={10}
        placeholder="Enter a prompt for the summary generated post-call"
      />
    </>
  );
}
