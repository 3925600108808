import { Button, Spinner } from "@radix-ui/themes";
import { motion } from "framer-motion";
import { CheckCheck, X } from "lucide-react";
import { useState, useEffect, useCallback, useRef } from "react";
import Editor from "react-simple-wysiwyg";
import { toast } from "react-toastify";
import { getApiKey } from "utils/funcs/browser/getApiKey";

export const NotesSlideOut = ({
  isOpen,
  initialValue,
  onClose,
  callId,
  onSetCallNotes,
}) => {
  const [html, setHtml] = useState(initialValue || "");
  const [loading, setLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // Add this useEffect to update the html state when initialValue changes
  useEffect(() => {
    setHtml(initialValue || "");
  }, [initialValue, callId]);

  const autoSave = useCallback(
    async (content) => {
      try {
        if (content === initialValue || loading) {
          return;
        }
        setLoading(true);
        const response = await fetch(
          `https://us.api.bland.ai/v1/notes/update`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: getApiKey(),
            },
            body: JSON.stringify({ comment: content, call_id: callId }),
          },
        );

        if (response.ok && response.status === 200) {
          const data = await response.json();
          if (data) {
            onSetCallNotes(callId, content);
            toast.success("Note saved", {
              position: "bottom-right",
              autoClose: 200,
              delay: 200,
            });
          }
        }
      } catch (error) {
        console.error("Error autosaving:", error);
        toast.error("Failed to autosave", {
          position: "bottom-right",
        });
      } finally {
        setLoading(false);
      }
    },
    [callId, onSetCallNotes],
  );

  useEffect(() => {
    let timer;
    if (html !== initialValue) {
      timer = setTimeout(() => {
        if (html !== "") {
          autoSave(html);
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [html, initialValue, autoSave]);

  const handleChange = (event) => {
    setHtml(event.target.value);
  };

  const slideOutRef = useRef(null);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    autoSave(html); // Save before closing
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); // Match this with the animation duration
  }, [onClose, autoSave, html]);

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        slideOutRef.current &&
        !slideOutRef.current.contains(event.target) &&
        !event.target.classList.contains("dont-close-note")
      ) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    if (isOpen && !isClosing) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, isClosing, handleOutsideClick]);

  return (
    <>
      <motion.div
        ref={slideOutRef}
        initial={{ x: "100%" }}
        animate={{ x: isOpen && !isClosing ? 0 : "100%" }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed top-0 right-0 w-2/4 h-full bg-white shadow-2xl z-50 overflow-y-auto"
      >
        <div
          id="header"
          className="p-6 flex justify-between items-center"
          style={{
            height: 54,
            borderBottom: "1px solid #000",
          }}
        ></div>
        <div
          style={{
            borderBottom: "1px solid #e9e9e9",
            height: 74.5,
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0 20px",
          }}
        >
          <div>
            <h1 className="text-2xl font-medium">Call Notes</h1>
            <p className="text-sm text-gray-500">Call ID: {callId}</p>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <Button
              loading={loading}
              variant="soft"
              color="iris"
              disabled={loading}
              onClick={() => autoSave(html)}
              style={{ cursor: "pointer" }}
            >
              {loading ? (
                <>
                  <Spinner />
                </>
              ) : (
                <>
                  <CheckCheck size={16} />
                  <p>Saved</p>
                </>
              )}
            </Button>
            <X size={22} onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
        </div>

        {isOpen && (
          <div style={{}} className="w-full ignore-audio-player">
            <Editor
              style={{
                height: "calc(100vh - 160px)",
                maxHeight: "calc(100vh - 160px)",
                overflowY: "scroll",
                padding: "12px",
              }}
              value={html}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={handleChange}
            />
          </div>
        )}
      </motion.div>
    </>
  );
};
