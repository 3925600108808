import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import Button from "components/core/Button";
import { useEffect, useState, useMemo } from "react";
import SequenceSidebar from "./components/SequenceSidebar";
import CallData from "./components/CallData";
import TabSelect from "./components/TabSelect";
import TimeInput from "./components/StartTime";
import axios from "axios";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-dropdown-select";
import InterruptionThresh from "../Parameters/InterruptionThresh";
import Temperature from "../Parameters/Temperature";
import Voice from "../Parameters/Voice";
import MaxDuration from "../Parameters/MaxDuration";
import RequestData from "../Parameters/RequestData";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import {
  getSavedFromLocalStorage,
  removeCampaignFromLocalStorage,
  setSavedToLocalStorage,
} from "./utils/localStorage";
import EncryptedKey from "../Parameters/EncryptedKey";
import PhoneNumberSelector from "components/core//PhoneNumberSelector";
import { getUserData } from "utils/funcs/browser/getUserData";
import Language from "../Parameters/Language";
import CustomDeployment from "../Parameters/CustomDeployment";
import SMSForm from "../Parameters/SMS";
import AnalysisSchemaCo from "../Parameters/AnalysisSchema";
import usePhoneNumbers from "hooks/usePhoneNumbers";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

const my = () => `draft_${Math.random().toString(36).substring(2, 16)}`;

const initialCampaign = {
  _local_storage_id: my(),
  campaign_name: "Untitled Campaign",
  call_data: [],
  request_data: {
    language: "en-US",
    model: "base",
    webhook: "",
    wait_for_greeting: false,
    record: false,
  },
  campaign_config: {
    stop_when_answer: true,
    auto_mark_complete: true,
  },
  sequences: [
    {
      seqId: `seq_${Math.random().toString(36).substring(2, 16)}`,
      step: 0,
      type: "send_call",
      task: "",
      start_time: null,
      pathway: null,
    },
  ],
};

const toLocalDateTimeString = (currStartTime) => {
  if (!currStartTime) {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
    return formattedDate;
  } else if (currStartTime) {
    const date = new Date(currStartTime);
    const formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
    return formattedDate;
  }
};

export default function CreateCampaign() {
  const apiKey = getApiKey();
  const navigator = useNavigate();
  const [savingCampaign, setSavingCampaign] = useState(false);
  const [focusedSeqId, setFocusedSeqId] = useState(
    initialCampaign.sequences[0].seqId,
  );
  const [campaign, setCampaign] = useState(initialCampaign);
  const [userGraphs, setUserGraphs] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const campaignParam = queryParams.get("campaign");

  const user = useMemo(() => getUserData(), []);

  const {
    inboundNumbers,
    twilioNumbers,
    loading: phoneNumbersLoading,
    error: phoneNumbersError,
  } = usePhoneNumbers();

  const methods = useForm({
    defaultValues: {
      interruption_threshold: 50,
      temperature: 0.5,
      language: "en-US",
      voice: "Alexa",
      max_duration: 12,
      request_data: [],
      encrypted_key: "",
      from: "",
      endpoint: null,
      sms: null,
      analysis_schema: null,
    },
  });

  const handleSaveToLocalStorage = () => {
    setSavedToLocalStorage(campaign);
  };

  const handleAddSequence = () => {
    if (campaign.sequences?.filter((doc) => doc?.type !== "wait")?.length > 4)
      return toast.error("You have the maximum sequences already.");
    let sendCallSeqId = `seq_${Math.random().toString(36).substring(2, 16)}`;
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      sequences: [
        ...prevCampaign.sequences,
        {
          seqId: `seq_${Math.random().toString(36).substring(2, 16)}`,
          step: prevCampaign.sequences.length,
          type: "wait",
          wait_duration: "1",
          wait_unit: "days",
        },
        {
          seqId: sendCallSeqId,
          seqName: "",
          step: prevCampaign.sequences.length + 1,
          type: "follow_up",
          task: "",
          start_time: null,
          local_send_time: false,
          local_dialing: false,
          pathway: null,
        },
      ],
    }));

    setFocusedSeqId(sendCallSeqId);
  };

  const handleRemoveSequence = (seqId) => {
    setFocusedSeqId(campaign.sequences[0].seqId);

    setCampaign((prevCampaign) => {
      const seqIndex = prevCampaign.sequences.findIndex(
        (sequence) => sequence.seqId === seqId,
      );
      if (seqIndex > 0) {
        return {
          ...prevCampaign,
          sequences: [
            ...prevCampaign.sequences.slice(0, seqIndex - 1),
            ...prevCampaign.sequences.slice(seqIndex + 1),
          ],
        };
      } else {
        return prevCampaign;
      }
    });
  };

  const handleGetUserPathways = async () => {
    const response = await axios.get("/api/convo_pathway/get", {
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthToken(),
      },
    });

    if (response.status === 200 && response.data.data) {
      setUserGraphs(response.data.data);
    }
  };

  const handleSaveNewCampaign = async () => {
    try {
      if (campaign.call_data.length === 0) {
        return toast.error(
          "You must include Call Data (Phone Numbers) before creating a campaign.",
        );
      }

      setSavingCampaign(true);
      const methodData = methods.getValues();
      const c = campaign;

      c.request_data = {
        ...c.request_data,
        ...methodData,
        request_data:
          methodData.request_data?.length > 0
            ? KeyValuesToObj(methodData.request_data)
            : {},
        language: methods.getValues("language"),
      };

      if (methods.getValues("from")?.length > 1) {
        c.from = methods.getValues("from");
      }

      delete c._local_storage_id;
      delete c.request_data?.encrypted_key;
      delete c.request_data?.from;
      delete c.request_data.endpoint;
      console.log(c);

      let url;
      let endpoint = methodData.endpoint;
      if (endpoint) {
        if (!endpoint.startsWith("https://")) {
          endpoint = "https://" + endpoint;
        }
        url = `${endpoint}/v1/campaigns/create`;
      } else {
        url = "https://us.api.bland.ai/v1/campaigns/create";
      }

      const response = await axios.post(url, c, {
        headers: {
          authorization: apiKey,
          encrypted_key: methods?.getValues("encrypted_key") || undefined,
        },
      });

      if (response.status === 200) {
        setSavingCampaign(false);
        toast.success("Campaign has been saved.");
        return navigator("/dashboard?page=campaigns");
      }

      removeCampaignFromLocalStorage(c._local_storage_id);
      setSavingCampaign(false);
      toast.error(response?.data?.message);
    } catch (err) {
      console.error("Error in saving campaign", err);
      toast.warn("There was an error saving your campaign.");
      setSavingCampaign(false);
    }
  };

  useEffect(() => {
    handleGetUserPathways();
    setCampaign((prevState) => ({ ...prevState, _local_storage_id: my() }));
  }, []);

  useEffect(() => {
    if (campaignParam) {
      const local_campaign = getSavedFromLocalStorage(campaignParam);
      if (local_campaign) {
        setCampaign(local_campaign);
      }
    }
  }, [campaignParam]);

  return (
    <PageWrapper style={{ paddingRight: "0px", paddingBottom: "0px" }}>
      <ActionBar top spaceBetween>
        <PageTitle>Create Campaign</PageTitle>
      </ActionBar>

      <Wrapper>
        <SequenceSidebar
          removeSequence={(seqId) => handleRemoveSequence(seqId)}
          campaignName={campaign.campaign_name}
          focusedSeqId={focusedSeqId}
          setFocusedSeqId={setFocusedSeqId}
          addSequence={handleAddSequence}
          sequences={campaign.sequences}
          setWaitDuration={(id, e) => {
            if (Number(e.target.value)) {
              let number = Number(e.target.value);
              if (number <= 0 || number > 20) {
                return;
              }
            }

            setCampaign((prevCampaign) => ({
              ...prevCampaign,
              sequences: prevCampaign.sequences.map((sequence) => {
                if (sequence.seqId === id) {
                  return { ...sequence, wait_duration: e.target.value };
                }
                return sequence;
              }),
            }));
          }}
        />

        <div style={{ padding: "17px", width: "100%" }}>
          {(() => {
            const focusedSequence = campaign.sequences.find(
              (doc) => doc.seqId === focusedSeqId,
            );

            return (
              <div style={{}}>
                {focusedSequence?.type === "send_call" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <StyledLabel className="font-medium text-[#676775]">
                      Campaign Name
                    </StyledLabel>
                    <StyledInput
                      value={campaign.campaign_name}
                      onChange={(e) => {
                        setCampaign((prevCampaign) => ({
                          ...prevCampaign,
                          campaign_name: e.target.value,
                        }));
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <StyledLabel className="font-medium text-[#676775]">
                      Sequence Name
                    </StyledLabel>
                    <StyledInput
                      value={focusedSequence?.seqName}
                      onChange={(e) => {
                        setCampaign((prevCampaign) => ({
                          ...prevCampaign,
                          sequences: prevCampaign.sequences.map((sequence) => {
                            if (sequence.seqId === focusedSeqId) {
                              return { ...sequence, seqName: e.target.value };
                            }

                            return sequence;
                          }),
                        }));
                      }}
                    />
                  </div>
                )}

                <PathwaysDropdownContainer style={{ marginTop: "10px" }}>
                  <StyledLabel className="font-medium text-[#676775]">
                    Pathways
                  </StyledLabel>
                  <p style={{ margin: 0, padding: 0, paddingTop: "5px" }}>
                    Use a pathway in your calls for a campaign.
                  </p>

                  <Select
                    closeOnSelect
                    style={{ marginTop: "15px" }}
                    multi={false}
                    searchable={false}
                    values={focusedSequence?.pathway || []}
                    options={userGraphs?.map((val, ind) => ({
                      value: val.id,
                      label: val?.name,
                    }))}
                    onChange={(value) => {
                      setCampaign((prevCampaign) => {
                        const updatedSequences = prevCampaign.sequences.map(
                          (sequence) => {
                            if (sequence.seqId === focusedSeqId) {
                              return { ...sequence, pathway: value };
                            }
                            return sequence;
                          },
                        );

                        return { ...prevCampaign, sequences: updatedSequences };
                      });
                    }}
                  />
                </PathwaysDropdownContainer>

                <div style={{ marginTop: "15px" }}>
                  <StyledLabel className="font-medium text-[#676775]">
                    Prompt
                  </StyledLabel>
                  <PromptArea
                    disabled={focusedSequence?.pathway?.length > 0}
                    value={focusedSequence?.task}
                    onChange={(e) => {
                      setCampaign((prevCampaign) => {
                        const updatedSequences = prevCampaign.sequences.map(
                          (sequence) => {
                            if (sequence.seqId === focusedSeqId) {
                              return { ...sequence, task: e.target.value };
                            }
                            return sequence;
                          },
                        );
                        return { ...prevCampaign, sequences: updatedSequences };
                      });
                    }}
                    placeholder={
                      focusedSequence?.pathway?.length > 0
                        ? `Using Pathway: ${focusedSequence.pathway[0].label}`
                        : "Enter prompt..."
                    }
                    style={{ marginTop: "10px" }}
                    onInput={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                    }}
                  />
                </div>

                {focusedSequence?.type === "follow_up" && (
                  <div style={{ marginTop: "15px" }}>
                    <TimeInput
                      controller={true}
                      label={`Start Time`}
                      description={
                        "Setting this will ignore exact wait time for sequence."
                      }
                      type={"time"}
                      value={focusedSequence.start_time}
                      onChange={(e) => {
                        setCampaign((prevCampaign) => {
                          const updatedSequences = prevCampaign.sequences.map(
                            (sequence) => {
                              if (sequence.seqId === focusedSeqId) {
                                return {
                                  ...sequence,
                                  start_time: e.target.value,
                                };
                              }
                              return sequence;
                            },
                          );

                          return {
                            ...prevCampaign,
                            sequences: updatedSequences,
                          };
                        });
                      }}
                    />
                  </div>
                )}

                {focusedSequence?.type === "send_call" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      marginTop: "15px",
                    }}
                  >
                    <div style={{ maxWidth: "100%", overflowX: "scroll" }}>
                      <CallData
                        data={campaign?.call_data}
                        setData={(newData) => {
                          setCampaign((prevCampaign) => {
                            return { ...prevCampaign, call_data: newData };
                          });
                        }}
                      />
                    </div>

                    <TabSelect
                      value={campaign.request_data.model}
                      onChange={(newModel) => {
                        setCampaign((prevCampaign) => {
                          return {
                            ...prevCampaign,
                            request_data: {
                              ...prevCampaign.request_data,
                              model: newModel,
                            },
                          };
                        });
                      }}
                      label="Model"
                      items={[
                        { name: "Base", value: "base" },
                        { name: "Enhanced", value: "enhanced" },
                        { name: "Turbo", value: "turbo" },
                      ]}
                    />

                    <FormProvider {...methods}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                          marginTop: "10px",
                        }}
                      >
                        <RequestData fieldName="request_data" />
                        <AnalysisSchemaCo fieldName="analysis_schema" />
                        <Voice fieldName="voice" apiKey={apiKey} />
                        <MaxDuration fieldName="max_duration" />
                        <SMSForm fieldName="sms" />
                        <>
                          <EncryptedKey fieldName={"encrypted_key"} />
                        </>
                        <PhoneNumberSelector
                          fieldName="from"
                          userNumbers={inboundNumbers}
                          byotNumbers={twilioNumbers}
                          loading={phoneNumbersLoading}
                          error={phoneNumbersError}
                        />
                        <InterruptionThresh fieldName="interruption_threshold" />
                        <Temperature fieldName="temperature" />
                        <Language fieldName={"language"} />
                        <CustomDeployment fieldName={"endpoint"} />
                      </div>
                    </FormProvider>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <SwitchWrapper>
                        <SwitchBody
                          on={campaign.request_data.wait_for_greeting}
                          onClick={() => {
                            setCampaign((prevCampaign) => {
                              return {
                                ...prevCampaign,
                                request_data: {
                                  ...prevCampaign.request_data,
                                  wait_for_greeting:
                                    !prevCampaign.request_data
                                      .wait_for_greeting,
                                },
                              };
                            });
                          }}
                        >
                          <Flicker
                            on={campaign.request_data.wait_for_greeting}
                          />
                        </SwitchBody>
                        <StyledLabel className="font-medium text-[#676775]">
                          Wait for Greeting
                        </StyledLabel>
                      </SwitchWrapper>

                      <SwitchWrapper>
                        <SwitchBody
                          on={campaign.request_data.record}
                          onClick={() => {
                            setCampaign((prevCampaign) => {
                              return {
                                ...prevCampaign,
                                request_data: {
                                  ...prevCampaign.request_data,
                                  record: !prevCampaign.request_data.record,
                                },
                              };
                            });
                          }}
                        >
                          <Flicker on={campaign.request_data.record} />
                        </SwitchBody>
                        <StyledLabel className="font-medium text-[#676775]">
                          Record
                        </StyledLabel>
                      </SwitchWrapper>
                    </div>

                    <div id="webhook-url">
                      <StyledLabel className="font-medium text-[#676775]">
                        Webhook URL
                      </StyledLabel>
                      <StyledInput
                        style={{ marginTop: "10px" }}
                        placeholder="https://your-webhook-url.com"
                        value={campaign.request_data.webhook}
                        onChange={(e) => {
                          setCampaign((prevCampaign) => {
                            return {
                              ...prevCampaign,
                              request_data: {
                                ...prevCampaign.request_data,
                                webhook: e.target.value,
                              },
                            };
                          });
                        }}
                      />
                    </div>

                    <div style={{ marginBottom: 50 }}>
                      <TimeInput
                        controller={true}
                        label={`Start Time ${!focusedSequence?.start_time ? "( This call will send immediately )" : `( This call will send at ${toLocalDateTimeString(focusedSequence?.start_time)} )`}.`}
                        type="datetime-local"
                        value={toLocalDateTimeString(
                          focusedSequence?.start_time,
                        )}
                        onChange={(e) => {
                          setCampaign((prevCampaign) => {
                            const updatedSequences = prevCampaign.sequences.map(
                              (sequence) => {
                                if (sequence.seqId === focusedSeqId) {
                                  return {
                                    ...sequence,
                                    start_time: e.target.value,
                                  };
                                }
                                return sequence;
                              },
                            );

                            return {
                              ...prevCampaign,
                              sequences: updatedSequences,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })()}
        </div>
      </Wrapper>

      <ActionBar bottom>
        <Button
          appearance={"outline"}
          onClick={() => navigator("/dashboard?page=campaigns")}
        >
          Return
        </Button>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Button
            appearance={"outline"}
            onClick={() => {
              handleSaveToLocalStorage();
              toast.success("Campaign has been saved to drafts.");
              navigator("/dashboard?page=campaigns");
            }}
          >
            Save Draft
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            loading={savingCampaign}
            onClick={() => handleSaveNewCampaign()}
          >
            Create & Start Campaign
          </Button>
        </div>
      </ActionBar>
    </PageWrapper>
  );
}

const PathwaysDropdownContainer = styled.div`
  width: 100%;
`;

const Pathways = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Pathway = styled.option`
  padding: 10px;
  border: 1px solid #eeeeee;
  max-width: 100%;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
`;

const SwitchBody = styled.div`
  transition: background-color 0.1s ease;
  background-color: ${({ on }) => (on ? "#433DFF" : "#f0f0ed")};
  position: relative;
  height: 30px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
`;

const Flicker = styled.p`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background-color: white;
  position: absolute;
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: ${({ on }) => (on ? "3px" : "auto")};
`;

// const Select = styled.select`
//     margin-top: 10px;
//     width: 100%;
//     border: 1px solid #eeeeee;
//     padding: 10px;
// `;

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 10px;

  &:focus {
    outline: none;
    border-color: #4361ee;
    border-width: 1px;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: -30px;
  margin-left: -30px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: auto;
  position: relative;
  margin-bottom: 0px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PromptArea = styled.textarea`
  border: 1px solid #eeeeee;
  resize: none;
  width: 100%;
  height: auto;
  min-height: 140px;
  max-height: 340px;
  padding: 10px;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #4361ee;
    border-width: 1px;
  }
`;

const StyledLabel = styled.label`
  font-size: 1.2rem;
`;
