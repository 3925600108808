import { useContext, useEffect, useMemo, useState } from "react";
import { Dialog } from "@headlessui/react";
import FlowContext from "../contextFlow";
import { getUserData } from "utils/funcs/browser/getUserData";
import { Loading } from "components/core/Loading";

const PathwayTransferModal = ({ id, data, setIsOpen }) => {
  const user = useMemo(() => getUserData(), []);
  const [pathways, setPathways] = useState([]);
  const [selectedPathwayNodes, setSelectedPathwayNodes] = useState([]);
  const [name, setName] = useState(data.name || "");
  const [transferPathway, setTransferPathway] = useState(
    data.transferPathway || "",
  );
  const [transferPathwayNode, setTransferPathwayNode] = useState(
    data.transferPathwayNode || "",
  );
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(
    data.version || "production",
  );

  // Loading states
  const [isLoadingPathways, setIsLoadingPathways] = useState(true);
  const [isLoadingVersions, setIsLoadingVersions] = useState(false);
  const [isLoadingNodes, setIsLoadingNodes] = useState(false);

  const getSelectedPathwayData = async () => {
    if (!transferPathway || !selectedVersion) return;
    setIsLoadingNodes(true);

    try {
      const response = await fetch(
        `https://us.api.bland.ai/v1/pathway/${transferPathway}/version/${selectedVersion}`,
        {
          method: "GET",
          headers: {
            authorization: user.api_key,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedPathwayNodes(data.nodes || []);
      } else {
        console.error("Failed to fetch pathway data");
      }
    } catch (error) {
      console.error("Error fetching pathway data:", error);
    } finally {
      setIsLoadingNodes(false);
    }
  };

  const getPathwayVersions = async () => {
    if (!transferPathway) return;
    setIsLoadingVersions(true);

    try {
      const response = await fetch(
        `https://us.api.bland.ai/v1/pathway/${transferPathway}/versions`,
        {
          method: "GET",
          headers: {
            authorization: user.api_key,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        setVersions([
          { version_number: 0, name: "Production", is_production: true },
          ...data,
        ]);
      } else {
        console.error("Failed to fetch pathway versions");
      }
    } catch (error) {
      console.error("Error fetching pathway versions:", error);
    } finally {
      setIsLoadingVersions(false);
    }
  };

  useEffect(() => {
    if (transferPathway) {
      getPathwayVersions();
    }
  }, [transferPathway]);

  useEffect(() => {
    getSelectedPathwayData();
  }, [transferPathway, selectedVersion]);

  useEffect(() => {
    const fetchPathways = async () => {
      setIsLoadingPathways(true);
      try {
        const response = await fetch(
          `https://us.api.bland.ai/v1/convo_pathway`,
          {
            method: "GET",
            headers: {
              authorization: user.api_key,
            },
          },
        );
        if (response.ok) {
          const data = await response.json();
          const pathways = data.map((pathway) => ({
            id: pathway.id,
            name: pathway.name,
          }));
          setPathways(pathways);
        } else {
          console.error("Failed to fetch pathways");
        }
      } catch (error) {
        console.error("Error fetching pathways:", error);
      } finally {
        setIsLoadingPathways(false);
      }
    };

    fetchPathways();
  }, [id]);

  const { triggerUpdate, elements } = useContext(FlowContext);

  return (
    <Dialog.Description className="space-y-4">
      <div className="space-y-2">
        <div>Name:</div>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border border-gray-300 p-2 rounded-md w-full"
        />
        <label className="block text-[12px] font-medium text-gray-700">
          Transfer Pathway
        </label>
        <div className="relative">
          {isLoadingPathways ? (
            <div className="border border-gray-300 p-2 rounded-md w-full h-[38px] flex items-center justify-center">
              <Loading loading={true} />
            </div>
          ) : (
            <select
              value={transferPathway}
              onChange={(e) => setTransferPathway(e.target.value)}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md text-[12px]"
            >
              <option value="">Select Pathway</option>
              {pathways.map((el) => (
                <option key={el.id} value={el.id} className="text-[14px]">
                  {el.name}
                </option>
              ))}
            </select>
          )}
        </div>

        {transferPathway && (
          <>
            <label className="block text-[12px] font-medium text-gray-700">
              Pathway Version
            </label>
            <div className="relative">
              {isLoadingVersions ? (
                <div className="border border-gray-300 p-2 rounded-md w-full h-[38px] flex items-center justify-center">
                  <Loading loading={true} />
                </div>
              ) : (
                <select
                  value={selectedVersion}
                  onChange={(e) => setSelectedVersion(e.target.value)}
                  className="block w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md text-[12px]"
                >
                  {versions.map((version) => (
                    <option
                      key={version.version_number}
                      value={
                        version.is_production
                          ? "production"
                          : version.version_number
                      }
                      className="text-[12px]"
                    >
                      {version.name} {version.is_staging ? "(Staging)" : ""}{" "}
                      {version.is_prev_published
                        ? "(Previously Published)"
                        : ""}
                    </option>
                  ))}
                </select>
              )}
            </div>

            <label className="block text-[12px] font-medium text-gray-700">
              Pathway Transfer Node
            </label>
            <div className="relative">
              {isLoadingNodes ? (
                <div className="border border-gray-300 p-2 rounded-md w-full h-[38px] flex items-center justify-center">
                  <Loading loading={true} />
                </div>
              ) : (
                <select
                  value={transferPathwayNode}
                  onChange={(e) => setTransferPathwayNode(e.target.value)}
                  className="block w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md text-[12px]"
                >
                  <option value="" className="text-[12px]">
                    Select Node
                  </option>
                  {selectedPathwayNodes.map((el) => (
                    <option key={el.id} value={el.id} className="text-[12px]">
                      {el.data.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </>
        )}
      </div>

      <button
        onClick={() => {
          const node = elements.nodes.find((el) => el.id === id);
          const data = {
            transferPathway,
            name,
            version: selectedVersion,
          };
          if (transferPathwayNode) {
            data.transferPathwayNode = transferPathwayNode;
          }
          // update edges to remove all edges with this node as the source
          const edges = elements.edges.filter((el) => el.source !== id);

          setIsOpen(false);
          triggerUpdate(
            {
              nodes: elements.nodes.map((el) => {
                if (el.id === id) {
                  return {
                    ...el,
                    data: data,
                    type: "Transfer Pathway",
                  };
                }
                return el;
              }),
              edges,
            },
            false,
          );
        }}
        className="bg-indigo-500 text-white py-2 px-4 rounded-md mt-4"
        disabled={isLoadingPathways || isLoadingVersions || isLoadingNodes}
      >
        Save
      </button>
    </Dialog.Description>
  );
};

export default PathwayTransferModal;
