import { getUserData } from "../../../utils/funcs/browser/getUserData";
import Profile from "./Profile";
import ApiKey from "./ApiKey";
import WebhookSigningSecret from "./WebhookSigningSecret";
import WebhookActiveUrl from "./WebhookActiveUrl";

const ProfileSettings = () => {
  const user = getUserData();
  return (
    <div className="flex flex-col gap-6">
      <Profile user={user} />
      <ApiKey user={user} />
      <WebhookActiveUrl user={user} />
      <WebhookSigningSecret user={user} />
    </div>
  );
};

export default ProfileSettings;
