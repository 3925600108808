import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { X } from "lucide-react";
import Input from "components/core/Input";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import styled from "styled-components";

const KeywordInput = ({ fieldName }) => {
  const { setValue, watch } = useFormContext();
  const [pendingKeyword, setPendingKeyword] = useState("");
  const keywords = watch(fieldName) || [];

  const addKeyword = () => {
    if (pendingKeyword && !keywords.includes(pendingKeyword)) {
      setValue(fieldName, [...keywords, pendingKeyword]);
      setPendingKeyword("");
    }
  };

  const removeKeyword = (keywordToRemove) => {
    setValue(
      fieldName,
      keywords.filter((keyword) => keyword !== keywordToRemove),
    );
  };

  const description = (
    <span>
      These keywords will be boosted in the transcription engine - recommended
      for proper nouns or words that are frequently mis-transcribed. Press Enter
      or use commas to add multiple keywords.
    </span>
  );

  return (
    <div className="flex flex-col gap-2 w-full">
      <Input
        fieldName={`${fieldName}_input`}
        label={
          <span>
            Keywords
            <LightTooltip title={description} placement="right-start" arrow>
              <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
            </LightTooltip>
          </span>
        }
        value={pendingKeyword}
        onChange={(e) => setPendingKeyword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === ",") {
            e.preventDefault();
            addKeyword();
          }
        }}
        placeholder="Type a keyword and press Enter"
      />

      <KeywordContainer>
        {keywords.map((keyword, index) => (
          <KeywordTag key={index}>
            {keyword}
            <RemoveButton onClick={() => removeKeyword(keyword)}>
              <X size={14} />
            </RemoveButton>
          </KeywordTag>
        ))}
      </KeywordContainer>

      <input type="hidden" name={fieldName} value={keywords.join(",")} />
    </div>
  );
};

const KeywordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const KeywordTag = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 9999px;
  padding: 4px 12px;
  font-size: 14px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
  color: #6b7280;
  &:hover {
    color: #4b5563;
  }
`;

export default KeywordInput;
