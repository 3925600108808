import React, { useState, useEffect } from "react";
import {
  AiOutlineStar,
  AiFillStar,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { toast } from "react-toastify";
import { RxSpeakerLoud } from "react-icons/rx";
import { GoPerson } from "react-icons/go";
import { rateVoice } from "../../../utils/funcs/voices/rateVoice";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { getVoice } from "utils/funcs/voices/getVoice";
import Button from "components/core/Button";

export default function VoiceCard({
  data: voice,
  text,
  isPublicTab,
  hasBeenMadePublic = false,
  onPublishBtn = () => {},
  userCreated = false,
}) {
  const [voiceData, setVoiceData] = useState(voice);
  const [latency, setLatency] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userRating, setUserRating] = useState(null);
  const [hoverRating, setHoverRating] = useState(0);

  const { api_key } = getUserData();

  const truncateName = (name) => {
    const maxLength = 30;
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };

  const extractName = (fullName) => {
    const parts = fullName.split(" - ");
    return parts.length > 1 ? parts[1] : fullName;
  };

  const displayName = truncateName(extractName(voiceData.name));

  useEffect(() => {
    const rated = localStorage.getItem(`rating_${voiceData.id}`);
    if (rated) {
      setUserRating(parseInt(rated));
    }
  }, [voiceData.id]);

  const handleRatingClick = async (rating) => {
    const newRating = rating === userRating ? null : rating;
    setUserRating(newRating);
    localStorage.setItem(`rating_${voiceData.id}`, newRating);

    try {
      await rateVoice(voiceData, api_key, newRating);
      const { voice } = await getVoice(voiceData, api_key, null);
      setVoiceData(voice);
    } catch (error) {
      console.error("Failed to set ratings:", error);
    }
  };

  const addToLibrary = async () => {
    try {
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/library/add/${voiceData.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: api_key,
          },
        },
      );

      if (response.ok) {
        toast.success("Voice added to library");
      } else {
        toast.error("Failed to add voice to library.");
      }
    } catch (error) {
      console.error("Failed to add voice to library:", error);
    }
  };

  const fetchTTSAudio = async (voiceId) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/` + voiceId + "/sample",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: api_key,
          },
          body: JSON.stringify({ text, response_type: "stream" }),
        },
      );

      let latency = null;

      if (response.ok) {
        latency = response.headers.get("x-latency");
        setLatency(latency);
      }
      const mediaSource = new MediaSource();
      const audio = new Audio();
      audio.src = URL.createObjectURL(mediaSource);

      await new Promise((resolve, reject) => {
        mediaSource.addEventListener(
          "sourceopen",
          () => {
            const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
            const reader = response.body.getReader();

            const read = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    if (sourceBuffer.updating) {
                      sourceBuffer.addEventListener(
                        "updateend",
                        () => {
                          mediaSource.endOfStream();
                          resolve();
                        },
                        { once: true },
                      );
                    } else {
                      mediaSource.endOfStream();
                      resolve();
                    }
                    return;
                  }
                  if (!sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(value);
                    read();
                  } else {
                    sourceBuffer.addEventListener("updateend", read, {
                      once: true,
                    });
                  }
                })
                .catch(reject);
            };

            read();
          },
          { once: true },
        );
      });
      audio.play();
      setIsLoading(false);

      return audio;
    } catch (error) {
      console.error("Failed to fetch TTS audio:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
      <div className="p-4">
        <div className="flex justify-between items-start mb-2">
          <h3
            className="font-semibold text-lg text-gray-800"
            title={voiceData.name}
          >
            {displayName}
          </h3>
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-1">
              {voiceData.average_rating
                ? voiceData.average_rating.toFixed(1)
                : "N/A"}
            </span>
            <AiFillStar className="text-yellow-400" />
          </div>
        </div>
        <p className="text-sm text-gray-600 mb-2">
          {voiceData.description || "No description available"}
        </p>
        <div className="flex flex-wrap gap-1 mb-2">
          {voiceData.tags?.map((tag, index) => (
            <span
              key={index}
              className="px-2 py-1 bg-indigo-100 text-indigo-800 text-xs rounded-full"
            >
              {tag}
            </span>
          ))}
        </div>
        <div className="flex justify-between items-center text-sm text-gray-600">
          <span>{voiceData.total_ratings} ratings</span>
          <button
            className="text-indigo-600 hover:text-indigo-800"
            onClick={() => {
              navigator.clipboard.writeText(voiceData.id);
              toast.success("Voice ID copied to clipboard");
            }}
          >
            Copy ID
          </button>
        </div>
        {isPublicTab && (
          <div className="mt-2">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => handleRatingClick(star)}
                onMouseEnter={() => setHoverRating(star)}
                onMouseLeave={() => setHoverRating(0)}
                className="p-1 rounded-full"
              >
                {star <= (hoverRating || userRating) ? (
                  <AiFillStar className="text-yellow-500" />
                ) : (
                  <AiOutlineStar className="text-gray-400" />
                )}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="border-t border-gray-200 p-4">
        <button
          className="w-full py-2 px-4 flex items-center justify-center text-indigo-600 hover:bg-indigo-50 transition-colors duration-300"
          onClick={() => fetchTTSAudio(voiceData.id)}
          disabled={isLoading}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin mr-2" />
          ) : (
            <RxSpeakerLoud className="mr-2" />
          )}
          {isLoading ? "Loading..." : "Play Sample"}
        </button>
        {latency && (
          <div className="text-center text-sm text-gray-600 mt-2">
            Latency: {latency} ms
          </div>
        )}
      </div>
      {isPublicTab && (
        <div className="border-t border-gray-200 p-4">
          <Button onClick={addToLibrary} className="w-full">
            Add to Library
          </Button>
        </div>
      )}
      {userCreated && !hasBeenMadePublic && (
        <div className="border-t border-gray-200 p-4">
          <Button onClick={() => onPublishBtn(voiceData)} className="w-full">
            Publish Voice
          </Button>
        </div>
      )}
    </div>
  );
}
