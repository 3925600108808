import styled from "styled-components";
import Textarea from "components/core/Textarea";
import { useFormContext } from "react-hook-form";
import { telehealthTask } from "utils/data/prompts/telehealthTask";
import { smallBusinessTask } from "utils/data/prompts/smallBusinessTask";
import { stadiumTask } from "utils/data/prompts/stadiumTask";
import { salesTask } from "utils/data/prompts/salesTask";
import { Label } from "recharts";

export default function Prompt({
  fieldName,
  examples,
  autoResize = false,
  label = "Prompt",
}) {
  const { setValue } = useFormContext();

  const handleExampleClick = (task) => {
    setValue("task", task);
  };

  return (
    <>
      {examples && (
        <div className="flex flex-col gap-4">
          <Label>
            Task (
            <a
              href="https://www.bland.ai/blog/prompting-guide-ai-phone-calls"
              className="underline text-blue-500"
            >
              prompting guide
            </a>
            )
          </Label>
          <div className="flex flex-row gap-2">
            <PromptPreset onClick={() => handleExampleClick(telehealthTask)}>
              Telehealth
            </PromptPreset>
            <PromptPreset onClick={() => handleExampleClick(smallBusinessTask)}>
              Small business
            </PromptPreset>
            <PromptPreset onClick={() => handleExampleClick(stadiumTask)}>
              Stadium venues
            </PromptPreset>
            <PromptPreset onClick={() => handleExampleClick(salesTask)}>
              Inbound sales
            </PromptPreset>
          </div>
        </div>
      )}

      <Textarea
        autoResize={autoResize}
        label={label}
        fieldName={fieldName}
        rows={10}
        placeholder="Enter a prompt for the AI..."
      />
    </>
  );
}

const PromptPreset = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 6px 12px;
  border: 1px solid #dddddd;
  transition: background-color 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 5px;
  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
`;
