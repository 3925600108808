import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import ReactFlow, {
  Controls,
  Background,
  addEdge,
  Panel,
  ControlButton,
  useKeyPress,
  ReactFlowProvider,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import ChatInterface from "./Components/Chat";
import { FaCaretDown, FaRedo, FaSortAmountDown, FaUndo } from "react-icons/fa";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdContentCopy, MdDelete } from "react-icons/md";
import DefaultNode from "./Components/nodes/node";
import CustomEdge from "./Components/label_edge";
import Dagre from "dagre";
import { v4 as uuidv4 } from "uuid";
import SendCall from "./Components/send_call";
import PopUp from "./Components/pop_up";
import "reactflow/dist/style.css";
import { Loading } from "components/core/Loading";
import { getActivePathwayCallLogs } from "utils/funcs/convo-pathways/logs";
import GlobalPrompt from "./Components/GlobalPrompt";
import HelpPanel from "./Components/HelpPanel";
import FlowContext from "./contextFlow";
import { PiSpinnerGapLight } from "react-icons/pi";
import EdgeSideBar from "./Components/edgeSidebar";
import useUndoable from "use-undoable";
import TestPathwayButton from "./Components/TestPathway";
import { Menu } from "@headlessui/react";
import { format } from "date-fns";
import ShareModal from "./Components/shareModal";
import { LuShare2 } from "react-icons/lu";
import FeatureFlagPathway from "./Components/featureFlagPathway";
import NodeTypeSlideOut from "./NodeSlideOut";
import PublishConfirmModal from "./Components/publish_modal";
import PathwayLogs from "./PathwayLogs";
import CallLogsModal from "./Components/CallLogsModal";
import PathwayReplay from "./Components/PathwayReplay";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import {
  CheckCheckIcon,
  ChevronLeftIcon,
  CurlyBraces,
  Download,
  Grid,
  Sparkles,
} from "lucide-react";
import { Button, IconButton, Tooltip } from "@radix-ui/themes";
import { PageWrapper } from "components/core/PageWrapper";
import CopyPasteController from "./Components/CopyPasteController";

// Add these state variables

export default function ViewPathway({ embedMode, embedUser, pathwayId }) {
  const [isNodeTypeSlideOutOpen, setIsNodeTypeSlideOutOpen] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(true);
  const [currElementID, setCurrElementID] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [inputLabel, setInputLabel] = useState(null);
  const [originalLabel, setOriginalLabel] = useState(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isEditingEdge, setIsEditingEdge] = useState(false);

  const [selectedNodeData, setSelectedNodeData] = useState(null);

  const [showEdgeSidebar, setShowEdgeSidebar] = useState(false);
  const [selectedEdgeData, setSelectedEdgeData] = useState(null);

  const [updatePending, setUpdatePending] = useState(false);

  const lastProcessedDecisionRef = useRef(null);

  const [isVersionLoading, setIsVersionLoading] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const handleNodeClick = (nodeData) => {
    setCurrElementID(nodeData.id);
    setSelectedNodeData(nodeData);
  };

  const [isSaving, setIsSaving] = useState(false);

  const [showChat, setShowChat] = useState(false);
  const [chatInstanceCount, setChatInstanceCount] = useState(0);
  const [callID, setCallID] = useState(null);

  const [callLogs, setCallLogs] = useState([]);

  const [globalPrompt, setGlobalPrompt] = useState("");
  let user;
  let graphID;
  if (embedMode && embedUser) {
    user = embedUser?.user;
  } else {
    user = getUserData();
  }
  const [searchParams] = useSearchParams();

  if (pathwayId) {
    graphID = pathwayId;
  } else {
    graphID = searchParams.get("id");
  }
  const formatRef = useRef(Boolean(searchParams.get("format")));
  const [featureFlag, setFeatureFlag] = useState("v1");
  const nameRef = useRef(null);

  const navigate = useNavigate();
  const callLogsRef = useRef(callLogs);
  const [showChatInterface, setShowChatInterface] = useState(false);
  const firstRenderRef = useRef(true);

  const closeChatInterface = () => {
    // set active node to false
    const newNodes = elements.nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          active: false,
        },
      };
    });

    const newEdges = elements.edges.map((edge) => {
      return {
        ...edge,
        data: {
          ...edge.data,
          isHighlighted: false,
        },
      };
    });
    triggerUpdate({ nodes: newNodes, edges: newEdges }, true);
    setShowChatInterface(false);
  };

  const [isRootUser, setIsRootUser] = useState(true);

  const [variables, setVariables] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [elements, setElements, { past, undo, canUndo, redo, canRedo }] =
    useUndoable(
      { nodes: [], edges: [] },
      {
        behavior: "destroyFuture",
      },
    );

  const removeDuplicateEdges = (edges) => {
    const edgeMap = new Map();
    edges.forEach((edge) => {
      edgeMap.set(edge.id, edge);
    });
    return Array.from(edgeMap.values());
  };

  const [chatStartNode, setChatStartNode] = useState(null);
  const [chatRequestData, setChatRequestData] = useState(null);
  const [chatEndpoint, setChatEndpoint] = useState(null);
  const [chatConversationHistory, setChatConversationHistory] = useState(null);
  const [chatVersion, setChatVersion] = useState(null);
  const [enableQA, setEnableQA] = useState(true);

  const triggerUpdate = useCallback(
    (updates, ignore = false, ignore_version_number = false) => {
      if (selectedVersion?.version_number <= 0 && !ignore_version_number) {
        setElements(
          (prevElements) => {
            const newElements = {
              nodes: updates.nodes
                ? prevElements.nodes.map((node) => {
                    const updatedNode = updates.nodes.find(
                      (n) => n.id === node.id,
                    );
                    if (updatedNode) {
                      if (
                        JSON.stringify(updatedNode?.data) !==
                        JSON.stringify(node?.data)
                      ) {
                        toast.warn(
                          "Changes not saved. You cannot edit the live pathway. Edit the latest version and publish your changes to update the live pathway!",
                          {
                            position: "bottom-right",
                          },
                        );
                      }
                      // Only update position-related attributes
                      return {
                        ...updatedNode,
                        // Keep the data attribute unchanged
                        data: node.data,
                      };
                    }
                    return node;
                  })
                : prevElements.nodes,
              edges: updates.edges
                ? removeDuplicateEdges(
                    prevElements.edges.map((edge) => {
                      const updatedEdge = updates.edges.find(
                        (e) => e.id === edge.id,
                      );
                      if (updatedEdge) {
                        if (
                          JSON.stringify(updatedEdge?.data) !==
                          JSON.stringify(edge?.data)
                        ) {
                          toast.warn(
                            "Changes not saved. You can't edit the live pathway. Edit the latest version and publish your changes to update the live pathway!",
                            {
                              position: "bottom-right",
                            },
                          );
                        }
                        // Only update position-related attributes if needed (e.g., source or target positions)
                        return {
                          ...updatedEdge,
                          // Update other edge-related positional attributes here if needed
                          data: edge.data, // Keep the data attribute unchanged
                        };
                      }
                      return edge;
                    }),
                  )
                : removeDuplicateEdges(prevElements.edges),
            };
            return newElements;
          },
          "destroyFuture",
          true,
        );
        return;
      }
      setElements(
        (prevElements) => {
          const newElements = {
            nodes: updates.nodes ? updates.nodes : prevElements.nodes,
            edges: updates.edges
              ? removeDuplicateEdges(updates.edges)
              : removeDuplicateEdges(prevElements.edges),
          };
          return newElements;
        },
        "destroyFuture",
        ignore,
      );

      if (!ignore) {
        updateContextVariables(updates.nodes);
        setUpdatePending(true); // Flag that an update is pending
      }
    },
    [setElements, selectedVersion],
  );

  useEffect(() => {
    if (!chatRequestData && !chatStartNode && !chatEndpoint) return;
    console.log({ chatRequestData, chatStartNode, chatEndpoint });
    setShowChatInterface(true);
    setChatInstanceCount((prevCount) => prevCount + 1);
  }, [chatRequestData, chatStartNode, chatEndpoint]);

  const selectionStateRef = useRef({});

  const onNodesChange = useCallback(
    (changes) => {
      // Process all changes, updating selection state
      changes.forEach((change) => {
        if (change.type === "select") {
          selectionStateRef.current[change.id] = change.selected;
        }
      });

      // Apply all changes
      const updatedNodes = applyNodeChanges(changes, elements.nodes);

      // Ensure selection state is preserved
      const finalNodes = updatedNodes.map((node) => ({
        ...node,
        selected: selectionStateRef.current[node.id] ?? node.selected,
      }));

      // Determine if we should ignore this update in the undo history
      const ignore = changes.every((change) =>
        ["select", "position", "dimensions"].includes(change.type),
      );

      triggerUpdate(
        {
          nodes: finalNodes,
        },
        ignore,
      );
    },
    [triggerUpdate, elements.nodes],
  );

  const onEdgesChange = useCallback(
    (changes) => {
      // don't save these changes in the history
      let ignore = ["select"].includes(changes[0].type);
      triggerUpdate(
        {
          edges: applyEdgeChanges(changes, elements.edges),
        },
        ignore,
      );
    },
    [triggerUpdate, elements.edges],
  );

  useEffect(() => {
    callLogsRef.current = callLogs;
  }, [callLogs]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    if (!callID) {
      return;
    }
    setCallLogs([]);
    callLogsRef.current = [];
    const intervalId = setInterval(async () => {
      const fetchCallTranscript = async () => {
        const logsData = await getActivePathwayCallLogs(callID, user.api_key);
        if (logsData === undefined) {
          clearInterval(intervalId);
        }
        // Check if the length has changed or if the current length is zero
        else if (
          logsData.length !== callLogsRef.current.length ||
          callLogsRef.current.length === 0
        ) {
          setCallLogs(logsData);
        }
      };
      fetchCallTranscript();
    }, 2000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [user.api_key, callID, chatInstanceCount]);

  useEffect(() => {
    async function verifyPathway() {
      const response = await fetch("api/convo_pathway/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        body: JSON.stringify({
          id: graphID,
        }),
      });
      const data = await response.json();
      if (data.error) {
        toast.warn("Unauthorized access", {
          position: "bottom-right",
        });
        navigate("/dashboard?page=convo-pathways");
      }

      if (data.status === "Authorized") {
        setIsRootUser(false);
      } else {
        setIsRootUser(true);
      }
    }
    if (!embedMode) {
      verifyPathway();
    }
  }, [graphID, embedMode]);

  const updateContextVariables = (nodes) => {
    if (!nodes) {
      return;
    }
    const newVariables = {
      now_utc: "Bland Variable",
      from: "Bland Variable",
      to: "Bland Variable",
      lastUserMessage: "Bland Variable",
      prevNodePrompt: "Bland Variable",
    };
    nodes.forEach((node) => {
      if (node.data?.extractVars) {
        node.data.extractVars.forEach(([varName, varType, varDescription]) => {
          if (varName) {
            newVariables[varName] = node.data.name;
          }
        });
      }

      if (node?.type === "Custom Tool") {
        let entries;
        if (node?.data?.tool?.tool?.response) {
          entries = Object.entries(node?.data?.tool?.tool?.response);
        }
        if (entries && entries.length > 0) {
          entries.forEach(([key, value]) => {
            newVariables[key] = "Custom Tool Response Variable";
          });
        }
      }
    });

    setVariables(newVariables);
  };

  useEffect(() => {
    const get = async () => {
      try {
        setLoading(true);
        const pathwayData = await fetch(`/api/convo_pathway/get_pathway_info`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthToken(),
          },
          body: JSON.stringify({
            id: graphID,
          }),
        }).then((res) => res.json());

        if (pathwayData.error) {
          toast.error("Error fetching pathway data from server");
          return;
        }
        if (pathwayData) {
          setInputLabel(pathwayData.data?.name);
          setFeatureFlag(pathwayData.data?.feature_flag || "v1");
        }

        const version_data = await getVersions();
        setVersions(version_data);

        if (version_data?.length === 0 || !version_data) {
          toast.warn("No versions found for this pathway", {
            position: "bottom-right",
          });
          return;
        }

        if (searchParams.get("version")) {
          const vv = searchParams.get("version");
          const num_vv = parseInt(vv);

          if (num_vv === 0) {
            setSelectedVersion({
              version_number: 0,
            });
          } else {
            if (vv === "staging") {
              const latestVersion = version_data.find(
                (version) => version?.is_staging,
              );
              setSelectedVersion(latestVersion);
            } else {
              const latestVersion = version_data.find(
                (version) => version.version_number === num_vv,
              );

              if (latestVersion.is_prev_published) {
                latestVersion.version_number =
                  -1 * latestVersion.version_number;
              }
              console.log("latestversion", latestVersion);
              setSelectedVersion(latestVersion);
            }
          }
        } else {
          const latestVersion = version_data.find(
            (version) => version?.is_staging,
          );
          setSelectedVersion(latestVersion);
        }

        // await getVersion(latestVersion.version_number);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching pathway data", error);
        setLoading(false);
        toast.error("Error fetching pathway data");
      }
    };

    get();
  }, [graphID]);

  useEffect(() => {
    if (callLogs.length > 0 && (showChatInterface || showChat)) {
      let latestLogWithChosenNodeId = null;
      // Loop backwards through the callLogs array to find the latest log with a chosen_node_id
      for (let i = callLogs.length - 1; i >= 0; i--) {
        if (callLogs[i].chosen_node_id) {
          latestLogWithChosenNodeId = callLogs[i];
          break; // Break the loop once the first log with a chosen_node_id is found
        }
      }

      if (
        latestLogWithChosenNodeId &&
        latestLogWithChosenNodeId !== lastProcessedDecisionRef.current
      ) {
        const chosenNodeId = latestLogWithChosenNodeId.chosen_node_id;

        const newNodes = elements.nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            active: node.id === chosenNodeId,
          },
        }));

        const newEdges = elements.edges.map((edge) => ({
          ...edge,
          data: {
            ...edge.data,
            isHighlighted: edge.source === chosenNodeId,
          },
        }));

        const nodesChanged = newNodes.some(
          (node, index) =>
            node.data.active !== elements.nodes[index].data.active,
        );

        const edgesChanged = newEdges.some(
          (edge, index) =>
            edge.data.isHighlighted !==
            elements.edges[index].data.isHighlighted,
        );
        if (edgesChanged) {
          triggerUpdate({ edges: newEdges }, true);
        }

        if (nodesChanged) {
          triggerUpdate({ nodes: newNodes }, true);
          lastProcessedDecisionRef.current = latestLogWithChosenNodeId;
        }
      }
    }
  }, [callLogs, elements, triggerUpdate]);

  const nodeTypes = useMemo(
    () => ({
      Default: DefaultNode,
      "Knowledge Base": DefaultNode,
      "Transfer Call": DefaultNode,
      "End Call": DefaultNode,
      Webhook: DefaultNode,
      "Wait for Response": DefaultNode,
      "Vector DB Knowledge Base": DefaultNode,
      "Transfer Pathway": DefaultNode,
      "Custom Tool": DefaultNode,
      SMS: DefaultNode,
      "Press Button": DefaultNode,
      "Amazon Connect": DefaultNode,
    }),
    [],
  );

  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  const handleEdit = () => {
    setOriginalLabel(inputLabel);
    setIsEditing(true);
  };

  const handleSubmit = () => {
    try {
      setIsEditing(false);
      if (inputLabel === originalLabel) {
        return;
      }
      triggerUpdate({}, false);
      fetch("/api/convo_pathway/update_pathway_name", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        body: JSON.stringify({
          pathway_id: graphID,
          name: inputLabel,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            toast.error("Error updating pathway name", {
              position: "bottom-right",
            });
            setInputLabel(originalLabel);
            return;
          }
          toast.success(data.message, {
            position: "bottom-right",
          });
        });
    } catch (error) {
      console.error("Error updating pathway name", error);
      toast.error("Error updating pathway name", {
        position: "bottom-right",
      });
    }
  };

  const onAddNewNode = useCallback(() => {
    setIsNodeTypeSlideOutOpen(true);
  }, []);

  const handleSelectNodeType = useCallback(
    (nodeType) => {
      const containerWidth = document.querySelector(
        ".react-flow__container",
      ).clientWidth;
      const containerHeight = document.querySelector(
        ".react-flow__container",
      ).clientHeight;

      const centerX = containerWidth / 2;
      const centerY = containerHeight / 2;

      const flowCenter = rfInstance.project({ x: centerX, y: centerY });

      let newX = flowCenter.x - 50;
      let newY = flowCenter.y - 50;

      // Check for collision and adjust the position if needed
      const isPositionTaken = (x, y) =>
        elements.nodes.some(
          (node) => node.position.x === x && node.position.y === y,
        );

      while (isPositionTaken(newX, newY)) {
        newX += 10;
        newY += 10;
      }

      const newNode = {
        id: uuidv4(),
        data: {
          name: `New ${nodeType} Node`,
          prompt: "Placeholder instructions for agent to say",
        },
        type: nodeType,
        position: {
          x: newX,
          y: newY,
        },
      };

      triggerUpdate(
        {
          nodes: [...elements.nodes, newNode],
        },
        false,
      );

      setIsNodeTypeSlideOutOpen(false);
    },
    [rfInstance, elements.nodes, triggerUpdate],
  );

  const handleInputChange = (e) => {
    setInputLabel(e.target.value);
  };

  const onConnect = useCallback(
    (params) => {
      const edge = {
        ...params,
        type: "custom",
        data: { label: "User responded", setIsSideBarOpen: setShowEdgeSidebar },
        animated: true,
      };

      const sourceId = edge.source;
      const sourceEdges = elements.edges.filter((e) => e.source === sourceId);
      const hasCondition = sourceEdges.some((e) => e.data?.condition);
      // check other edges with the same source, and if they have a condition
      if (hasCondition) {
        edge.data.condition = [
          {
            operator: "AND",
            field: "",
            conditionOperator: "contains",
            value: "",
          },
        ];
        edge.data.label = "No Variable Set to Compare";
      }

      triggerUpdate(
        {
          edges: addEdge(edge, elements.edges),
        },
        false,
      );
    },
    [triggerUpdate, elements],
  );

  const deleteElement = () => {
    if (currElementID) {
      if (currElementID.includes("edge")) {
        // triggerUpdate("edges", elements.edges.filter((edge) => edge.id !== currElementID));
        triggerUpdate(
          {
            edges: elements.edges.filter((edge) => edge.id !== currElementID),
          },
          false,
        );
      } else {
        let newEdges = elements.edges;
        const nodes = elements.nodes.filter((node) => {
          if (node.id === currElementID) {
            if (node?.data?.isStart) {
              toast.error("You cannot delete the start node", {
                position: "bottom-right",
              });
              return true;
            }

            newEdges = elements.edges.filter(
              (edge) =>
                edge.source !== currElementID && edge.target !== currElementID,
            );
            return false;
          }
          return true;
        });
        triggerUpdate(
          {
            nodes: nodes,
            edges: newEdges,
          },
          false,
        );
      }
      //   exportFlow();
    }
  };

  const getLayoutedElements = (nodes, edges, options) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction, ranksep: 100, nodesep: 200 }); // ranksep is vertical, nodesep is horizontal spacing
    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) => g.setNode(node.id, node));

    Dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const { x, y } = g.node(node.id);
        return { ...node, position: { x, y } };
      }),
      edges,
    };
  };

  const onLayout = useCallback(
    (direction) => {
      const layouted = getLayoutedElements(elements.nodes, elements.edges, {
        direction,
      });
      console.log("layout loop");

      triggerUpdate(
        {
          nodes: layouted.nodes,
          edges: layouted.edges,
        },
        false,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, triggerUpdate],
  );

  const processDownload = async () => {
    let flow = elements;

    const edges = flow.edges.map((edge) => ({
      ...edge,
      data: { ...edge?.data },
    }));

    // Remove non-serializable properties from the cloned edges
    edges.forEach((edge) => {
      if (edge?.data) {
        delete edge.data?.setIsSideBarOpen;
      }
    });
    const exportEdges = edges.filter((edge) => {
      return edge.source || edge.target;
    });

    const exportNodes = flow.nodes.map((node) => {
      node.data.globalPrompt = globalPrompt;
      node.selected = false;
      return node;
    });

    // Create the data object
    const data = {
      nodes: exportNodes,
      edges: exportEdges,
    };
    const jsonString = JSON.stringify(data, null, 2);

    const blob = new Blob([jsonString], {
      type: "application/json",
    });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "pathway.json";

    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const exportFlow = useCallback(async () => {
    let flow;
    if (!rfInstance) return;
    setIsSaving(true);
    flow = rfInstance.toObject();
    // console.log("flow", flow);

    flow = elements;

    if (selectedVersion?.version_number <= 0) {
      toast.warn(
        "You cannot save changes to a published pathway. Edit the latest version and publish your changes to update the published pathway!",
        {
          position: "bottom-right",
        },
      );
      setIsSaving(false);
      return;
    }

    if (flow.nodes.length === 0) {
      setIsSaving(false);
      return;
    }

    const edges = flow.edges.map((edge) => ({
      ...edge,
      data: { ...edge?.data },
    }));

    // Remove non-serializable properties from the cloned edges
    edges.forEach((edge) => {
      if (edge?.data) {
        delete edge.data?.setIsSideBarOpen;
      }
    });
    const exportEdges = edges.filter((edge) => {
      return edge.source || edge.target;
    });

    const exportNodes = flow.nodes.map((node) => {
      node.data.globalPrompt = globalPrompt;
      node.selected = false;
      return node;
    });

    // console.log("export edges", exportEdges);

    if (!getAuthToken()) {
      toast.error("Pathway not saved. Please reload the page or login again", {
        position: "bottom-right",
      });
      setIsSaving(false);
      return;
    }

    try {
      const response = await fetch("/api/convo_pathway/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        body: JSON.stringify({
          nodes: exportNodes,
          edges: exportEdges,
          id: graphID,
          version_number: selectedVersion.version_number,
        }),
      });

      if (response.status !== 200) {
        throw new Error("Error updating pathway");
      }

      const resp_msg = await response.json();

      setIsSaving(false);

      toast(resp_msg.message, { position: "bottom-right" });
    } catch (error) {
      console.error("Error updating pathway", error);
      toast.error("Error updating pathway", { position: "bottom-right" });
      setIsSaving(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rfInstance,
    inputLabel,
    globalPrompt,
    elements,
    featureFlag,
    selectedVersion,
  ]);

  //* version states *\\
  const [versions, setVersions] = useState([]);
  const [versionName, setVersionName] = useState("");

  const [isCreatingVersion, setIsCreatingVersion] = useState(false);
  const [loadingVersion, setLoadingVersion] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [showCallLogsModal, setShowCallLogsModal] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const handlePublish = async (versionName) => {
    setIsPublishing(true);
    const response = await fetch(`/api/convo_pathway/publish`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: getAuthToken(),
      },
      body: JSON.stringify({
        id: graphID,
        version_number: selectedVersion.version_number,
        version_name: versionName,
      }),
    });

    const data = await response.json();

    if (data.error) {
      toast.error("Error publishing pathway", {
        position: "bottom-right",
      });
      setIsPublishing(false);
      return;
    }
    toast.success("Pathway published successfully", {
      position: "bottom-right",
    });

    const version_data = await getVersions();

    setVersions(version_data);

    if (version_data.length === 0 || !version_data) {
      toast.warn("No versions found for this pathway", {
        position: "bottom-right",
      });
      return;
    }

    const latestVersion = version_data.find((version) => version.is_latest);

    setSelectedVersion(latestVersion);

    // await getVersion(latestVersion.version_number);
    // Your publish logic here
    setIsPublishing(false);
    setShowPublishConfirm(false);
  };

  //   useEffect(() => {
  //     setSelectedVersion(versions[0]);
  //   }, [versions]);

  //   sharing
  const [showShareModal, setShowShareModal] = useState(false);

  const getVersions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${graphID}/versions`,
      {
        method: "GET",
        headers: {
          authorization: getApiKey(),
        },
      },
    );
    const data = await response.json();
    if (!data || data?.status === "error") {
      return;
    }

    data.forEach((version) => {
      const date = new Date(version.created_at);
      version.created_at = format(date, "d MMMM yyyy, h:mm a");
    });

    let stagingVersion = data.find((version) => version.is_staging);

    if (!stagingVersion) {
      toast.warn(
        "No dev version found for this pathway. Generating dev version now..",
        {
          position: "bottom-right",
        },
      );
      // create new version
      const new_latest_version = await fetch(
        `/api/convo_pathway/create-version`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getAuthToken(),
          },
          body: JSON.stringify({
            id: graphID,
          }),
        },
      ).then((res) => res.json());

      data.unshift({
        id: new_latest_version.id,
        created_at: new_latest_version.created_at,
        name: new_latest_version.version_name,
        version_number: new_latest_version.version_number,
        is_staging: true,
      });
    }

    // sort by is_published, then is_latest, then created_at
    data.sort((a, b) => {
      if (a?.is_staging && !b?.is_staging) {
        return -1;
      } else if (!a?.is_staging && b?.is_staging) {
        return 1;
      } else if (b.created_at && a.created_at) {
        return new Date(b.created_at) - new Date(a.created_at);
      } else {
        return 0;
      }
    });

    data.unshift({
      version_number: 0,
      created_at: null,
      name: "Production Pathway",
    });
    return data;
  };

  useEffect(() => {
    // run getversions whenever selectedVersion changes
    if (selectedVersion) {
      getVersion(selectedVersion.version_number);
    }
  }, [selectedVersion]);

  const getVersion = async (version_number) => {
    setIsVersionLoading(true);
    version_number = version_number < 0 ? version_number * -1 : version_number;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/${graphID}/version/${version_number}`,
        {
          method: "GET",
          headers: {
            authorization: user?.api_key,
          },
        },
      );
      const data = await response.json();
      if (!data || data?.error) {
        return;
      }

      let edges = (data?.edges || []).map((edge) => {
        const newEdge = { ...edge };
        newEdge.data = {
          ...edge.data,
          setIsSideBarOpen: setShowEdgeSidebar,
        };
        return newEdge;
      });
      let nodes = data?.nodes;

      if (formatRef.current || (version_number === 0 && nodes?.length <= 4)) {
        const organized = getLayoutedElements(nodes, edges, {
          direction: "TB",
        });
        nodes = organized.nodes;
        edges = organized.edges;
        console.log("getverson loop");

        triggerUpdate(
          {
            nodes: nodes,
            edges: edges,
          },
          false,
        );
        formatRef.current = false;
      }

      updateContextVariables(nodes);

      if (data?.nodes?.[0]?.data?.globalPrompt) {
        setGlobalPrompt(data.nodes[0]?.data.globalPrompt || "");
      }

      let ignore = false;

      if (elements.nodes.length === 0) {
        ignore = true;
      }

      await triggerUpdate(
        {
          nodes: nodes,
          edges: edges,
        },
        ignore,
        true,
      );

      toast.success("Version loaded successfully", {
        position: "bottom-right",
      });
    } catch (error) {
      console.error("Error fetching version", error);
      toast.error("Error fetching version", {
        position: "bottom-right",
      });
    } finally {
      setIsVersionLoading(false);
    }
  };

  //   const deleteVersion = async (version_number) => {
  //     if (version_number === selectedVersion.version_number) {
  //       toast.error("You cannot delete the currently selected version", {
  //         position: "bottom-right",
  //       });
  //       return;
  //     }
  //     const response = await fetch(
  //       `http://localhost:3001/v1/pathway/${graphID}/version/${version_number}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           authorization: user?.api_key,
  //         },
  //       },
  //     );

  //     const data = await response.json();
  //     if (!data || data?.error) {
  //       toast.warn("Error deleting version", {
  //         position: "bottom-right",
  //       });
  //       return;
  //     }

  //     setVersions(
  //       versions.filter((version) => version.version_number !== version_number),
  //     );

  //     toast.success("Version deleted successfully", {
  //       position: "bottom-right",
  //     });
  //   };

  //   const createVersion = useCallback(
  //     async (name) => {
  //       setLoadingVersion(true);
  //       const response = await fetch(
  //         `http://localhost:3001/v1/pathway/${graphID}/version`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             authorization: user?.api_key,
  //           },
  //           body: JSON.stringify({
  //             nodes: elements.nodes,
  //             edges: elements.edges,
  //             name: name ? name : null,
  //           }),
  //         },
  //       );

  //       const data = await response.json();
  //       if (!data || data?.error) {
  //         toast.warn("Error creating version", {
  //           position: "bottom-right",
  //         });
  //         return;
  //       }
  //       let { id, created_at, version_number, name: version_name } = data.data;

  //       const date = new Date(created_at);
  //       created_at = format(date, "d MMMM yyyy, h:mm a");

  //       setVersions([
  //         ...versions,
  //         {
  //           id: id,
  //           created_at: created_at,
  //           name: version_name,
  //           version_number: version_number,
  //         },
  //       ]);

  //       setSelectedVersion(data.data);
  //       setLoadingVersion(false);

  //       toast.success("Version created successfully", {
  //         position: "bottom-right",
  //       });
  //     },
  //     [elements, graphID, user?.api_key, versions],
  //   );

  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text");

      try {
        const node = JSON.parse(pastedData);
        if (node.id && node.data && node.type) {
          console.log("Pasted content is a valid node object");
        } else {
          console.log("Pasted content is not a valid node object");
          throw new Error("Invalid node object");
        }
        if (node && typeof node === "object") {
          console.log("Pasted content is a valid JSON object");
          createNodeFromJson(node);
        }
      } catch (error) {
        console.error("Pasted content is not JSON");
      }
    };

    document.addEventListener("paste", handlePaste);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [rfInstance, elements]);

  const createNodeFromJson = (nodeData) => {
    try {
      const containerWidth = document.querySelector(
        ".react-flow__container",
      ).clientWidth;
      const containerHeight = document.querySelector(
        ".react-flow__container",
      ).clientHeight;

      const centerX = containerWidth / 2;
      const centerY = containerHeight / 2;

      const flowCenter = rfInstance.project({ x: centerX, y: centerY });

      let newX = flowCenter.x - 50;
      let newY = flowCenter.y - 50;

      const newNode = {
        ...nodeData,
        id: uuidv4(),
        position: {
          x: newX,
          y: newY,
        },
        x: newX,
        y: newY,
        selected: false,
        isStart: false,
      };

      triggerUpdate(
        {
          nodes: [...elements.nodes, newNode],
        },
        false,
      );

      toast.success("Node pasted successfully", {
        position: "bottom-right",
      });
    } catch (error) {
      console.error("Error pasting node", error);
    }
  };

  const clonePathway = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/convo_pathway/clone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: user?.api_key,
        },
        body: JSON.stringify({
          pathway_id: graphID,
          is_showcase: false,
        }),
      },
    );

    const data = await response.json();
    if (!data || data?.error) {
      toast.warn("Error cloning pathway", {
        position: "bottom-right",
      });

      return;
    }

    const newPathwayID = data.pathway_id;

    toast.success(
      "Pathway cloned successfully. Redirecting to cloned pathway shortly...",
      {
        position: "bottom-right",
      },
    );
    setTimeout(() => {
      navigate(`/dashboard?page=convo-pathways&id=${newPathwayID}`);
    }, 2000);
  };

  const duplicateNode = useCallback(() => {
    if (!currElementID || !selectedNodeData) return; // Check if there's a selected node

    if (selectedNodeData?.data?.isStart) {
      toast.error("You cannot duplicate the start node", {
        position: "bottom-right",
      });
      return;
    }

    const newNode = {
      ...selectedNodeData,
      id: uuidv4(), // Generate a new unique ID for the duplicate node
      position: {
        x: selectedNodeData.position.x + 50,
        y: selectedNodeData.position.y + 50,
      }, // Offset the new node position
      selected: true,
    };
    const newNodes = elements.nodes.map((node) => {
      if (node.id === selectedNodeData.id) {
        return { ...node, selected: false };
      }
      return node;
    });
    setCurrElementID(newNode.id);
    triggerUpdate(
      {
        nodes: [...newNodes, newNode],
      },
      false,
    );
  }, [currElementID, selectedNodeData, elements, triggerUpdate]);

  const deletePressed = useKeyPress(["Delete", "Backspace"]);

  const isInputFocused = () => {
    const activeElement = document.activeElement;
    return (
      activeElement.tagName === "INPUT" ||
      activeElement.tagName === "TEXTAREA" ||
      activeElement.isContentEditable
    );
  };

  useEffect(() => {
    const handleUndoRedo = (e) => {
      if ((e.ctrlKey || e.metaKey) && !isInputFocused()) {
        if (e.key === "z" && !e.shiftKey) {
          e.preventDefault();
          undo();
        } else if (e.key === "z" && e.shiftKey && !isInputFocused()) {
          e.preventDefault();
          redo();
        }
      }
    };

    window.addEventListener("keydown", handleUndoRedo);
    return () => {
      window.removeEventListener("keydown", handleUndoRedo);
    };
  }, [undo, redo]);

  useEffect(() => {
    deleteElement();
  }, [deletePressed]);

  useEffect(() => {
    if (updatePending) {
      exportFlow();
      setUpdatePending(false);
    }
  }, [updatePending, exportFlow]);

  useEffect(() => {
    if (isEditing) {
      nameRef.current.focus();
    }
  }, [isEditing]);

  const [isReplayModalOpen, setIsReplayModalOpen] = useState(false);
  const [replayCallId, setReplayCallId] = useState(null);
  const [isReplayLoading, setIsReplayLoading] = useState(false);

  const handleReplayCall = useCallback(async (id) => {
    setReplayCallId(id);
    setIsReplayModalOpen(true);
  }, []);

  const handleNodesFocus = useCallback(
    (activePath) => {
      const activeNodes = new Set(activePath.filter(Boolean));

      const newNodes = elements.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          active: activeNodes.has(node.id),
        },
      }));

      const newEdges = elements.edges.map((edge) => {
        let isHighlighted = false;
        const sourceIndex = activePath.indexOf(edge.source);
        const targetIndex = activePath.indexOf(edge.target);

        if (sourceIndex !== -1 && targetIndex !== -1) {
          // Check if the edge connects two consecutive active nodes
          isHighlighted = Math.abs(sourceIndex - targetIndex) === 1;
        }

        return {
          ...edge,
          data: {
            ...edge.data,
            isHighlighted,
          },
        };
      });

      console.log("replay loop");
      triggerUpdate({ nodes: newNodes, edges: newEdges }, true);
    },
    [elements, triggerUpdate],
  );

  useEffect(() => {
    // Upon closing the replay modal, if there is a replay call ID, reset the nodes and edges to their original state
    if (!isReplayModalOpen && replayCallId) {
      const newNodes = elements.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          active: false,
        },
      }));
      const newEdges = elements.edges.map((edge) => ({
        ...edge,
        data: {
          ...edge.data,
          isHighlighted: false,
        },
      }));

      // Check if there are actual changes before triggering an update
      const nodesChanged = newNodes.some(
        (newNode, index) =>
          newNode.data.active !== elements.nodes[index].data.active,
      );
      const edgesChanged = newEdges.some(
        (newEdge, index) =>
          newEdge.data.isHighlighted !==
          elements.edges[index].data.isHighlighted,
      );

      if (nodesChanged || edgesChanged) {
        console.log("replay lloop loop");

        triggerUpdate({ nodes: newNodes, edges: newEdges }, true);
      }

      // clear replay state
      setReplayCallId(null);
    }
  }, [isReplayModalOpen, elements, triggerUpdate, replayCallId]);
  const [topOffset, setTopOffset] = useState(24); // Default top offset
  const headerRef = useRef(null);

  const [isWindowTooSmall, setIsWindowTooSmall] = useState(false);
  const [isWayTooSmall, setIsWayTooSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWindowTooSmall(window.innerWidth < 856);
      setIsWayTooSmall(window.innerWidth < 694);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isWayTooSmall) {
    return (
      <PageWrapper>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col items-center justify-center bg-white border border-gray-300 rounded-lg p-4 shadow-lg">
            <p className="text-gray-800 text-center">
              Screen is too small to edit pathway
            </p>
            <p className="text-gray-500 text-center">
              We recommend desktop screen size or larger.
            </p>
          </div>
        </div>
      </PageWrapper>
    );
  }

  return (
    <ReactFlowProvider>
      <FlowContext.Provider
        value={{
          exportFlow,
          isEditingEdge,
          setIsEditingEdge,
          elements,
          setElements,
          triggerUpdate,
          deleteElement,
          duplicateNode,
          variables,
          setVariables,
          setSelectedVersion,
          selectedVersion,
        }}
      >
        {popUpVisible && !localStorage.getItem("popUpShown") && (
          <PopUp setPopUpVisible={setPopUpVisible} />
        )}
        <div className="flex flex-col w-full">
          <header
            style={{ height: 56, paddingLeft: 56 + 15, top: 0, right: 0 }}
            className="flex justify-between items-center bg-white border border-b-[1px] py-2"
          >
            <div className="flex items-center gap-4">
              {!embedMode && (
                <IconButton
                  style={{ cursor: "pointer" }}
                  color="gray"
                  variant="ghost"
                  size="2"
                  radius="full"
                  onClick={() => {
                    navigate(
                      `/dashboard?page=convo-pathways&id=${graphID}&view=splash`,
                    );
                  }}
                >
                  <ChevronLeftIcon size={14} />
                </IconButton>
              )}
              <div className="ml-1">
                <p style={{ fontSize: 13, fontWeight: "600" }}>{inputLabel}</p>
                <p className="text-gray-700 text-[11px]">
                  Conversational Pathways
                </p>
              </div>

              {/* {isEditing ? (
                <span>
                  <input
                    ref={nameRef}
                    className="font-semibold text-gray-700 text-[12px] w-[200px]"
                    type="text"
                    value={inputLabel}
                    onChange={handleInputChange}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                  />
                  <button onClick={handleSubmit}>
                    <AiOutlineEnter />
                  </button>
                </span>
              ) : (
                <span className="flex items-center space-x-1">
                  <span className="font-semibold text-gray-700 text-[12px]">
                    {inputLabel}
                  </span>
                  <button
                    onClick={handleEdit}
                    className="flex items-center justify-center"
                  >
                    <EditIcon />
                  </button>
                </span>
              )} */}

              {/* <span className="text-gray-500 text-[12px] ml-4 mr-2">{">"}</span> */}

              <div className="flex items-center flex-wrap gap-2 ml-4">
                <Menu as="div" className="relative">
                  <Menu.Button className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-4">
                    <span className="mr-2">
                      {isVersionLoading || loading ? (
                        <PiSpinnerGapLight className=" animate-spin w-5 h-5 rounded-lg" />
                      ) : selectedVersion ? (
                        selectedVersion.name ||
                        `Version ${selectedVersion.version_number}`
                      ) : (
                        "Select Version"
                      )}
                    </span>

                    {!isVersionLoading &&
                      !loading &&
                      selectedVersion &&
                      selectedVersion.is_staging && (
                        <span className="mr-2 px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                          Staging
                        </span>
                      )}

                    {!isVersionLoading &&
                      !loading &&
                      selectedVersion &&
                      selectedVersion.version_number === 0 && (
                        <span className="mr-2 px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                          Production (Cannot be edited)
                        </span>
                      )}

                    {!isVersionLoading &&
                      !loading &&
                      selectedVersion &&
                      selectedVersion.version_number < 0 && (
                        <span className="mr-2 px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                          Prev Published (Cannot be edited)
                        </span>
                      )}

                    <FaCaretDown className="w-4 h-4" />
                  </Menu.Button>

                  <Menu.Items
                    style={{ marginTop: 15 }}
                    className="absolute right-0 w-[300px] origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none z-[100] max-h-[400px] overflow-y-auto"
                  >
                    {versions?.length > 0 &&
                      versions?.map((version, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <div
                              className={`${
                                active ? "bg-indigo-50" : "bg-white"
                              } ${
                                selectedVersion.version_number ===
                                version.version_number
                                  ? !"bg-indigo-100"
                                  : ""
                              } group flex justify-between items-center w-full px-4 py-3 text-sm cursor-pointer`}
                              onClick={() => {
                                window.location.href = `/dashboard?page=convo-pathways&id=${graphID}&version=${version.version_number}`;
                              }}
                            >
                              <div className="flex flex-col">
                                <span className="font-medium">
                                  {version.name ||
                                    `Version ${version.version_number}`}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {version.created_at &&
                                    new Date(version.created_at).toLocaleString(
                                      "en-US",
                                      {
                                        month: "short",
                                        day: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      },
                                    )}
                                  {version.version_number === 0 &&
                                    "View Live Pathway"}
                                </span>
                              </div>
                              <div className="flex items-center">
                                {version.is_staging && (
                                  <span className="mr-2 px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                                    Staging
                                  </span>
                                )}
                                {version.version_number === 0 && (
                                  <span className="mr-2 px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                                    Production
                                  </span>
                                )}
                                {/* {version.version_number !== 0 &&
                                  !version.is_latest && (
                                    <button
                                      className="ml-2 text-gray-400 hover:text-red-500"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (
                                          window.confirm(
                                            "Are you sure you want to delete this version?",
                                          )
                                        ) {
                                          deleteVersion(version.version_number);
                                        }
                                      }}
                                    >
                                      <RxCross1 size={16} />
                                    </button>
                                  )} */}
                              </div>
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    {/* <div className="border-t border-gray-200 p-2">
                      <button
                        className="flex items-center justify-center w-full p-2 text-sm bg-indigo-50 hover:bg-indigo-100 text-indigo-700 rounded-md"
                        onClick={() => setIsCreatingVersion(!isCreatingVersion)}
                      >
                        <FaPlusCircle className="mr-2 h-4 w-4" />
                        Create new version
                      </button>
                    </div> */}
                  </Menu.Items>
                </Menu>
              </div>

              {/* <button
                className="bg-indigo-400 hover:bg-indigo-600 text-white font-medium px-4 py-1 rounded-full float-right cursor-pointer shadow-md ml-4 flex items-center gap-2 text-xs"
                onClick={() => {
                  navigator.clipboard.writeText(graphID);
                  toast.success("ID copied to clipboard", {
                    position: "bottom-right",
                  });
                }}
              >
                Copy ID
              </button> */}

              {/* <Transition appear show={isCreatingVersion} as={React.Fragment}>
                <Dialog
                  as="div"
                  className="fixed inset-0 z-10 overflow-y-auto"
                  onClose={() => setIsCreatingVersion(false)}
                >
                  <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                      as={React.Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span
                      className="inline-block h-screen align-middle"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>
                    <Transition.Child
                      as={React.Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Create New Version
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Creating a new version allows you to make changes
                            without affecting the current version. You can
                            switch between versions at any time. Optionally, you
                            can name your version for easier identification.
                          </p>
                        </div>

                        <div className="mt-4">
                          <input
                            type="text"
                            className="w-full p-2 border rounded-md"
                            placeholder="Enter version name (Optional)"
                            value={versionName}
                            onChange={(e) => setVersionName(e.target.value)}
                          />
                        </div>

                        <div className="mt-4 flex justify-end space-x-2">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                            onClick={() => {
                              setIsCreatingVersion(false);
                              setVersionName("");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                            onClick={() => {
                              createVersion(versionName);
                              setIsCreatingVersion(false);
                              setVersionName("");
                            }}
                          >
                            {loadingVersion ? (
                              <PiSpinnerGapLight className="animate-spin w-5 h-5" />
                            ) : (
                              "Create Version"
                            )}
                          </button>
                        </div>
                      </div>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition> */}
            </div>

            <div className="flex flex-wrap items-center gap-4 px-6 ml-auto">
              {!isRootUser && (
                <div className="flex items-center gap-4">
                  <button
                    className="flex items-center text-[12px] justify-center space-x-2 text-gray-800 py-2 px-4 border border-gray-300 bg-indigo-100 rounded-lg"
                    onClick={clonePathway}
                  >
                    Clone Pathway
                  </button>
                </div>
              )}

              <div className="flex flex-wrap justify-end gap-4">
                <button
                  className="flex items-center justify-center space-x-2 text-gray-800 py-2 px-4 hover:bg-gray-100 rounded-lg"
                  onClick={exportFlow}
                >
                  {isSaving ? (
                    <>
                      <PiSpinnerGapLight className=" animate-spin rounded-full w-5 h-5 text-indigo-500" />
                      <span className="font-medium text-sm">Saving...</span>
                    </>
                  ) : (
                    <>
                      <CheckCheckIcon color="#6366f1" size={16} />
                      <span className="font-medium text-sm">Saved</span>
                    </>
                  )}
                </button>

                {/* sharing button that opens a modal */}
                {!isWindowTooSmall && (
                  <>
                    <Tooltip content="Variables">
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="flex items-center justify-center space-x-0 text-gray-800 py-2.5 px-2.5 hover:bg-gray-100 rounded-lg"
                        style={{
                          marginRight: -5,
                          backgroundColor: isOpen ? "#6366f1" : "",
                        }}
                      >
                        <CurlyBraces
                          color={isOpen ? "#fff" : "#6366f1"}
                          size={16}
                        />
                      </button>
                    </Tooltip>
                    <Tooltip content="Share Pathway">
                      <button
                        className="flex items-center justify-center space-x-0 text-gray-800 py-2.5 px-2.5 hover:bg-gray-100 rounded-lg"
                        onClick={() => {
                          setShowShareModal(true);
                        }}
                        style={{
                          marginRight: -5,
                          backgroundColor: showShareModal ? "#6366f1" : "",
                        }}
                      >
                        <LuShare2
                          color={showShareModal ? "#fff" : "#6366f1"}
                          size={16}
                        />
                        {/* <span className="font-medium">Share</span> */}
                      </button>
                    </Tooltip>
                    <Tooltip content="Call Logs">
                      <button
                        className="flex items-center justify-center space-x-0 text-gray-800 py-2.5 px-2.5 hover:bg-gray-100 rounded-lg"
                        onClick={() => {
                          setShowCallLogsModal(true);
                        }}
                        style={{
                          backgroundColor: showCallLogsModal ? "#6366f1" : "",
                        }}
                      >
                        <Grid
                          color={showCallLogsModal ? "#fff" : "#6366f1"}
                          size={16}
                        />
                        {/* <span className="font-medium">Share</span> */}
                      </button>
                    </Tooltip>

                    <Tooltip content="Export Pathway">
                      <button
                        className="flex items-center justify-center space-x-0 text-gray-800 py-2.5 px-2.5 hover:bg-gray-100 rounded-lg"
                        onClick={processDownload}
                      >
                        <Download color="#6366f1" size={16} />
                      </button>
                    </Tooltip>
                  </>
                )}
              </div>

              <TestPathwayButton
                elements={elements}
                setChatInstanceCount={setChatInstanceCount}
                setShowChatInterface={setShowChatInterface}
                setChatStartNode={setChatStartNode}
                chatRequestData={chatRequestData}
                chatStartNode={chatStartNode}
                setChatRequestData={setChatRequestData}
                setChatEndpoint={setChatEndpoint}
                chatEndpoint={chatEndpoint}
                enableQA={enableQA}
                setEnableQA={setEnableQA}
                versions={versions}
                chatVersion={chatVersion}
                setChatVersion={setChatVersion}
              />
              <SendCall
                elements={elements}
                user={user}
                id={graphID}
                setShowChat={setShowChat}
                setShowChatInterface={setShowChatInterface}
                setCallID={setCallID}
                versions={versions}
              />

              {chatInstanceCount > 0 && showChatInterface && (
                <ChatInterface
                  key={chatInstanceCount}
                  setCallID={setCallID}
                  callID={callID}
                  setShowChat={setShowChat}
                  id={graphID}
                  onClose={closeChatInterface}
                  chatStartNode={chatStartNode}
                  chatRequestData={chatRequestData}
                  chatEndpoint={chatEndpoint}
                  chatVersion={chatVersion}
                  chatConversationHistory={chatConversationHistory}
                  setChatConversationHistory={setChatConversationHistory}
                  // get name of first active node
                  currentNodeName={
                    elements.nodes.find((node) => node.data.active)?.data.name
                  }
                />
              )}
              {/* <button className="bg-blue-600 hover:bg-blue-800 text-white font-semibold text-[12px] px-4 py-2 rounded-lg">
                Send Phone Call
              </button> */}

              <PublishConfirmModal
                isOpen={showPublishConfirm}
                onClose={() => setShowPublishConfirm(false)}
                onConfirm={handlePublish}
                isPublishing={isPublishing}
              />

              <CallLogsModal
                isOpen={showCallLogsModal}
                onClose={() => {
                  setShowCallLogsModal(false);
                  setIsReplayModalOpen(false);
                }}
                pathwayId={graphID}
                pathwayName={inputLabel}
                handleReplayCall={handleReplayCall}
                isReplayLoading={isReplayLoading}
              />
              {isReplayModalOpen && (
                <PathwayReplay
                  callId={replayCallId}
                  onClose={() => setIsReplayModalOpen(false)}
                  onNodesFocus={handleNodesFocus}
                />
              )}
            </div>
          </header>

          {showShareModal && (
            <ShareModal
              setShowShareModal={setShowShareModal}
              graphID={graphID}
              graphName={inputLabel}
            />
          )}

          {showChat && (
            <PathwayLogs
              graphID={graphID}
              showChat={showChatInterface}
              callID={callID}
              callLogs={callLogs}
              elements={elements}
              setChatConversationHistory={setChatConversationHistory}
              setChatStartNode={setChatStartNode}
              enableQA={enableQA}
            />
          )}

          {loading ? (
            <Loading loading={loading} />
          ) : (
            <div
              className={`w-full h-screen max-w-full ${embedMode ? "" : "pl-20"}`}
              // style={{"width": "100%", "height": "100%", "paddingLeft": embedMode ? '0' : "50px" }}
            >
              <ReactFlow
                nodes={elements.nodes}
                edges={elements.edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onClick={() => {
                  // if (isOpen) {
                  //   setIsOpen(false);
                  // }
                }}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onInit={(instance) => {
                  instance.setViewport({ x: 300, y: 60, zoom: 0.7 });
                  setRfInstance(instance);
                }}
                fitViewOptions={{ minZoom: -2 }}
                minZoom={0.3}
                onNodeClick={(event, element) => {
                  handleNodeClick(element);
                  //   setCurrElementID(element.id);
                }}
                onEdgeClick={(event, element) => {
                  setCurrElementID(element.id);
                  //   setIsEditingEdge(element.id);
                  setSelectedEdgeData(element);
                }}
                onPaneClick={(event) => {
                  setCurrElementID(null);
                }}
                deleteKeyCode={[]}
                fitView
                proOptions={{ hideAttribution: true }}
              >
                <Controls
                  position="bottom-left"
                  style={{
                    bottom: embedMode ? "60px" : "",
                  }}
                >
                  <ControlButton onClick={() => onLayout("TB")}>
                    <FaSortAmountDown />
                  </ControlButton>
                  <ControlButton
                    onClick={deleteElement}
                    disabled={!currElementID}
                  >
                    <MdDelete />
                  </ControlButton>
                  <ControlButton
                    onClick={duplicateNode}
                    disabled={!currElementID} // Disable the button if no node is selected
                  >
                    <MdContentCopy /> {/* Icon can be changed */}
                  </ControlButton>
                  <ControlButton onClick={undo} disabled={!canUndo}>
                    <FaUndo />
                  </ControlButton>
                  <ControlButton onClick={redo} disabled={!canRedo}>
                    <FaRedo />
                  </ControlButton>
                  <CopyPasteController
                    rfInstance={rfInstance}
                    selectionStateRef={selectionStateRef}
                  />
                </Controls>

                {isWindowTooSmall && (
                  <div
                    style={{
                      position: "absolute",
                      right: 15,
                      top: 15,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <Tooltip side="left" content="Variables">
                        <IconButton
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          variant="solid"
                          color="#fff"
                          style={{
                            background: isOpen ? "#6366f1" : "#fff",
                            border: "1px solid #6366f1",
                            cursor: "pointer",
                            zIndex: 1000,
                          }}
                          size="2"
                        >
                          <CurlyBraces
                            color={isOpen ? "#fff" : "#6366f1"}
                            size={16}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip side="left" content="Share Pathway">
                        <IconButton
                          onClick={() => {
                            setShowShareModal(true);
                          }}
                          variant="solid"
                          color="#fff"
                          style={{
                            background: showShareModal ? "#6366f1" : "#fff",
                            border: "1px solid #6366f1",
                            cursor: "pointer",
                            zIndex: 1000,
                          }}
                          size="2"
                        >
                          <LuShare2
                            color={showShareModal ? "#fff" : "#6366f1"}
                            size={16}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip side="left" content="Call Logs">
                        <IconButton
                          onClick={() => {
                            setShowCallLogsModal(true);
                          }}
                          variant="solid"
                          color="#fff"
                          style={{
                            background: showCallLogsModal ? "#6366f1" : "#fff",
                            border: "1px solid #6366f1",
                            cursor: "pointer",
                            zIndex: 1000,
                          }}
                          size="2"
                        >
                          <Grid
                            color={showCallLogsModal ? "#fff" : "#6366f1"}
                            size={16}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )}

                <div
                  className={`fixed right-12 z-50`}
                  ref={popoverRef}
                  style={{ top: `${topOffset}px` }}
                >
                  {isOpen && (
                    <div className="absolute z-10 mr-[150px] mt-20 w-[350px] bg-white border border-gray-300 rounded-md shadow-2xl right-0 max-h-[300px] overflow-y-auto">
                      {Object.entries(variables).length === 0 ? (
                        <div className="p-4 text-sm text-gray-500">
                          No variables
                        </div>
                      ) : (
                        <div className="py-1">
                          {Object.entries(variables).map(
                            ([variable, nodeName]) => (
                              <div
                                key={variable}
                                className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              >
                                <span className="text-sm font-medium">
                                  {variable}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {nodeName}
                                </span>
                              </div>
                            ),
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <Panel position="bottom-right">
                  <HelpPanel />
                </Panel>
                <Background variant="dots" gap={12} size={1} />
                <Panel position="top left" className="space-y-5 p-0">
                  <div>
                    <Button
                      size={"1"}
                      color="violet"
                      variant="solid"
                      // style={{ border: "1px solid #6e44ff" }}
                      //className="w-full bg-white hover:bg-gray-50 text-gray-800 py-2 px-4 border border-gray-300 rounded shadow transition duration-150 ease-in-out flex items-center justify-center"
                      onClick={onAddNewNode}
                      style={{ cursor: "pointer" }}
                    >
                      <Sparkles size={14} />
                      <p style={{ fontSize: 14 }}>Add new node</p>
                    </Button>
                  </div>
                  <div>
                    <GlobalPrompt
                      globalPrompt={globalPrompt}
                      setGlobalPrompt={setGlobalPrompt}
                    />
                  </div>
                  <div>
                    <FeatureFlagPathway
                      id={graphID}
                      featureFlag={featureFlag}
                      setFeatureFlag={setFeatureFlag}
                    />
                  </div>
                </Panel>
              </ReactFlow>
            </div>
          )}
        </div>

        {showEdgeSidebar && (
          <div>
            <EdgeSideBar
              setIsSideBarOpen={setShowEdgeSidebar}
              edgeData={selectedEdgeData}
              setEdgeData={setSelectedEdgeData}
            />
          </div>
        )}

        <NodeTypeSlideOut
          isOpen={isNodeTypeSlideOutOpen}
          onClose={() => setIsNodeTypeSlideOutOpen(false)}
          onSelectNodeType={handleSelectNodeType}
        />
        {/* {isSideBarOpen && (
          <div>
            <NodeSidebar
              setIsSideBarOpen={setIsSideBarOpen}
              nodeData={selectedNodeData}
            />
          </div>
        )} */}
      </FlowContext.Provider>
    </ReactFlowProvider>
  );
}
