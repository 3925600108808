import React from "react";
import { useFormContext } from "react-hook-form";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Send, Zap, Rocket, CircleCheck } from "lucide-react";

const modelInfo = [
  {
    name: "BASE",
    value: "base",
    icon: <Send className="w-6 h-6" />,
    description: "Best for following scripts and procedures effectively",
  },
  {
    name: "ENHANCED",
    value: "enhanced",
    icon: <Zap className="w-6 h-6" />,
    description:
      "Faster latency, conversational, ideal for objective-based tasks",
  },
  {
    name: "TURBO",
    value: "turbo",
    icon: <Rocket className="w-6 h-6" />,
    description: "Fastest latency, extremely realistic conversations",
  },
];

const longText = (
  <span>
    Select a model to use for your call. <br />
    Options: <br />
    <strong>base</strong>, <strong>turbo</strong> and
    <strong> enhanced</strong>. In nearly all cases, <strong>enhanced</strong>{" "}
    is the best choice
    <br></br>
    <i>
      <b>base</b>
      <br></br> - The original, follows scripts/procedures most effectively.
      <br></br> - Supports all features and capabilities.
      <br></br> - Best for Custom Tools
      <br></br>
      <b>enhanced</b>
      <br></br> - Much faster latency and very conversational, works best with
      objective-based prompts.
      <br></br> -Supports all features and capabilities.
      <br></br>
      <b>turbo</b>
      <br></br> - The absolute fastest latency possible, can be verbose at times
      <br></br> - Limited capabilities currently (excludes Transferring, IVR
      navigation, Custom Tools)
      <br></br> - Extremely realistic conversation capabilities
    </i>
  </span>
);

export default function Model({ fieldName }) {
  const { watch, setValue } = useFormContext();
  const selectedModel = watch(fieldName);

  const handleModelSelect = (value) => {
    setValue(fieldName, value);
  };

  return (
    <div className="w-full">
      <div className="flex items-center mb-3 ">
        <h2 className="text-base font-semibold text-gray-700 mr-1">
          SELECT MODEL
        </h2>
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon className="text-gray-400 cursor-pointer w-5 h-5" />
        </LightTooltip>
      </div>
      <div className="space-y-3">
        {modelInfo.map((model) => (
          <div
            key={model.value}
            className={`
              p-4 border-2 rounded-lg cursor-pointer transition-all duration-200 flex items-center
              ${
                selectedModel === model.value
                  ? "border-[#433DFF] bg-[#433DFF]/5 shadow-md"
                  : "border-gray-200 hover:border-[#433DFF]/50 hover:bg-[#433DFF]/1"
              }
            `}
            onClick={() => handleModelSelect(model.value)}
          >
            <div
              className={`
              text-[#433DFF] mr-4 transition-opacity duration-200
              ${selectedModel === model.value ? "opacity-100" : "opacity-50 group-hover:opacity-75"}
            `}
            >
              {model.icon}
            </div>
            <div className="flex-grow">
              <h3
                className={`
                text-base font-semibold transition-colors duration-200
                ${selectedModel === model.value ? "text-[#433DFF]" : "text-gray-800"}
              `}
              >
                {model.name}
              </h3>
              <p className="text-sm text-gray-600 mt-1">{model.description}</p>
            </div>
            {selectedModel === model.value && (
              <div className="ml-3 animate-fadeIn">
                <CircleCheck className="w-5 h-5 text-[#433DFF]" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
