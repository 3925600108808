// SearsInteractiveDashboard.js
import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, Tooltip,
  XAxis, YAxis, CartesianGrid, ResponsiveContainer
} from 'recharts';
import {
  Play, Pause, TrendingUp, TrendingDown, Minus, Download, Share2, Filter
} from 'lucide-react';

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06595', '#74C0FC', '#B197FC', '#69DB7C', '#FFD43B'];
const TAGS = ['Reschedule', 'Cancel', 'CreateService'];

const SearsInteractiveDashboard = () => {
  const [callData, setCallData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [stats, setStats] = useState({
    totalCalls: 0,
    avgCallLength: '0.00',
    flowPercentages: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
    successRates: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
    flowTagDistributions: {},
    callsWithSuccessTag: 0,
    callLengths: [],
    dailyCallVolume: [],
  });
  const [audioPlaying, setAudioPlaying] = useState(null);

  const fetchCalls = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('auth');
      if (!token) {
        console.error('No authorization token found. Please log in.');
        // Redirect to login page or display a message
        return;
      }

      const response = await fetch('/api/analysis/sears', {
        headers: { 'Authorization': `${token}` },
      });

      if (!response.ok) {
        if (response.status === 401) {
          console.error('Unauthorized access - please log in again.');
          // Handle unauthorized access, possibly redirect to login
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return;
      }

      const data = await response.json();
      if (!data || !data.data) {
        throw new Error('Invalid data format received from API');
      }

      setCallData(data.data);
      calculateStats(data.data);
    } catch (error) {
      console.error('Error fetching call data:', error);
      // Set default values to prevent undefined errors
      setCallData([]);
      setStats({
        totalCalls: 0,
        avgCallLength: '0.00',
        flowPercentages: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
        successRates: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
        flowTagDistributions: {},
        callsWithSuccessTag: 0,
        callLengths: [],
        dailyCallVolume: [],
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (calls) => {
    if (!Array.isArray(calls) || calls.length === 0) {
      // Handle case where there are no calls
      setStats({
        totalCalls: 0,
        avgCallLength: '0.00',
        flowPercentages: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
        successRates: { Reschedule: '0.0', Cancel: '0.0', CreateService: '0.0' },
        flowTagDistributions: {},
        callsWithSuccessTag: 0,
        callLengths: [],
        dailyCallVolume: [],
      });
      return;
    }

    const totalCalls = calls.length;
    const flowCounts = { Reschedule: 0, Cancel: 0, CreateService: 0 };
    const flowTagDistributions = { Reschedule: {}, Cancel: {}, CreateService: {} };
    const successCounts = { Reschedule: 0, Cancel: 0, CreateService: 0 };
    let totalCallLength = 0;
    let callsWithSuccessTag = 0;
    const callLengths = [];
    const dailyCallVolumeMap = {};

    calls.forEach(call => {
      totalCallLength += call.call_length;
      callLengths.push({ id: call.id, length: call.call_length });

      const callDate = new Date(call.created_at).toISOString().split('T')[0];
      dailyCallVolumeMap[callDate] = (dailyCallVolumeMap[callDate] || 0) + 1;

      // Check if call has any pathway tag with 'success' (case-insensitive)
      const hasSuccessTag = call.pathway_tags.some(tag => tag.name.toLowerCase().includes('success'));
      if (hasSuccessTag) {
        callsWithSuccessTag++;
      }

      // Identify main flow tag
      const mainFlowTag = call.pathway_tags.find(tag => TAGS.includes(tag.name));
      if (mainFlowTag) {
        const flowName = mainFlowTag.name;
        flowCounts[flowName]++;

        // Check if call has 'success' tag for this flow
        if (hasSuccessTag) {
          successCounts[flowName]++;
        }

        // Collect downstream tags (other tags)
        call.pathway_tags.forEach(tag => {
          if (tag.name !== flowName) {
            if (!flowTagDistributions[flowName]) {
              flowTagDistributions[flowName] = {};
            }
            flowTagDistributions[flowName][tag.name] = (flowTagDistributions[flowName][tag.name] || 0) + 1;
          }
        });
      }
    });

    // Calculate percentages
    const flowPercentages = {};
    TAGS.forEach(tag => {
      flowPercentages[tag] = totalCalls ? ((flowCounts[tag] / totalCalls) * 100).toFixed(1) : '0.0';
    });

    const successRates = {};
    TAGS.forEach(tag => {
      const successRate = flowCounts[tag] ? (successCounts[tag] / flowCounts[tag]) * 100 : 0;
      successRates[tag] = successRate.toFixed(1);
    });

    const avgCallLength = totalCalls ? (totalCallLength / totalCalls).toFixed(2) : '0.00';

    const dailyCallVolume = Object.keys(dailyCallVolumeMap).map(date => ({
      date,
      count: dailyCallVolumeMap[date],
    })).sort((a, b) => new Date(a.date) - new Date(b.date));

    setStats({
      totalCalls,
      avgCallLength,
      flowPercentages,
      successRates,
      flowTagDistributions,
      callsWithSuccessTag,
      callLengths,
      dailyCallVolume,
    });
  };

  useEffect(() => {
    fetchCalls();
  }, []);

  const SuccessRateCard = ({ tag, rate }) => {
    let TrendIcon = Minus;
    let trendColor = 'text-gray-500';
    if (rate > 50) {
      TrendIcon = TrendingUp;
      trendColor = 'text-green-500';
    } else if (rate < 50) {
      TrendIcon = TrendingDown;
      trendColor = 'text-red-500';
    }

    return (
      <div className="bg-white rounded-lg shadow p-6 flex flex-col border border-gray-200">
        <h3 className="text-lg font-medium text-gray-900 mb-2">{tag}</h3>
        <div className="flex items-baseline justify-between">
          <p className="text-3xl font-semibold text-gray-900">{rate}%</p>
          <div className={`flex items-center ${trendColor}`}>
            <TrendIcon className="h-4 w-4 mr-1" />
            <span className="text-sm font-medium">Success Rate</span>
          </div>
        </div>
      </div>
    );
  };

  const FlowTagDistribution = ({ flowName, distribution }) => {
    // Ensure distribution is an object
    if (!distribution || Object.keys(distribution).length === 0) {
      return (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">{flowName} Tag Distribution</h2>
          <p className="text-gray-500">No downstream tags for {flowName}</p>
        </div>
      );
    }

    const data = Object.entries(distribution).map(([tag, count]) => ({ name: tag, value: count }));

    return (
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">{flowName} Tag Distribution</h2>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const AudioPlayer = ({ url }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
      if (audioPlaying === url) {
        setAudioPlaying(null);
        setIsPlaying(false);
      } else {
        setAudioPlaying(url);
        setIsPlaying(true);
      }
    };

    useEffect(() => {
      if (audioPlaying !== url) {
        setIsPlaying(false);
      }
    }, [audioPlaying, url]);

    return (
      <button onClick={togglePlay} className="p-2 bg-indigo-500 text-white rounded-full">
        {isPlaying ? <Pause size={16} /> : <Play size={16} />}
      </button>
    );
  };

  const CallsTable = ({ calls }) => {
    if (!Array.isArray(calls) || calls.length === 0) {
      return (
        <div className="text-center text-gray-500">
          No calls available.
        </div>
      );
    }

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-100">
            <tr>
              {/* Table Headers */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Length (min)</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Main Flow</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Tags</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Recording</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {calls.map((call) => (
              <tr key={call.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{call.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(call.created_at).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {call.call_length.toFixed(2)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {call.pathway_tags.find(tag => TAGS.includes(tag.name))?.name || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {call.pathway_tags.map(tag => (
                    <span key={tag.name} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800 mr-2">
                      {tag.name}
                    </span>
                  ))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {call.recording_url ? <AudioPlayer url={call.recording_url} /> : 'No Recording'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen bg-gray-50 text-gray-800">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      {/* Header */}
      <header className="bg-white shadow border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold tracking-tight">Sears Interactive Dashboard</h1>
          <div className="flex items-center space-x-4">
            <button className="px-4 py-2 bg-indigo-600 text-white rounded-md flex items-center hover:bg-indigo-700 transition">
              <Download size={16} className="mr-2" /> Export
            </button>
            <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md flex items-center hover:bg-gray-300 transition">
              <Share2 size={16} className="mr-2" /> Share
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Tabs */}
        <div className="mb-8 flex justify-between items-center">
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-4 py-2 rounded-md border ${activeTab === 'overview' ? 'bg-indigo-600 text-white border-indigo-600' : 'bg-white text-gray-700 border-gray-300'} hover:bg-indigo-50 transition`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('calls')}
              className={`px-4 py-2 rounded-md border ${activeTab === 'calls' ? 'bg-indigo-600 text-white border-indigo-600' : 'bg-white text-gray-700 border-gray-300'} hover:bg-indigo-50 transition`}
            >
              Calls
            </button>
            <button
              onClick={() => setActiveTab('analytics')}
              className={`px-4 py-2 rounded-md border ${activeTab === 'analytics' ? 'bg-indigo-600 text-white border-indigo-600' : 'bg-white text-gray-700 border-gray-300'} hover:bg-indigo-50 transition`}
            >
              Analytics
            </button>
          </div>
          <button className="px-4 py-2 bg-white text-gray-700 rounded-md flex items-center border border-gray-300 hover:bg-gray-100 transition">
            <Filter size={16} className="mr-2" /> Filter
          </button>
        </div>

        {activeTab === 'overview' && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {TAGS.map(tag => (
                <SuccessRateCard
                  key={tag}
                  tag={tag}
                  rate={parseFloat(stats.successRates[tag])}
                />
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {TAGS.map(tag => (
                <FlowTagDistribution
                  key={tag}
                  flowName={tag}
                  distribution={stats.flowTagDistributions[tag]}
                />
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold mb-4">Call Flow Percentages</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={TAGS.map(tag => ({ name: tag, value: parseFloat(stats.flowPercentages[tag]) }))}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, value }) => `${name} ${value}%`}
                    >
                      {TAGS.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold mb-4">Daily Call Volume</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={stats.dailyCallVolume}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                    <XAxis dataKey="date" stroke="#4B5563" />
                    <YAxis stroke="#4B5563" />
                    <Tooltip />
                    <Line type="monotone" dataKey="count" stroke="#4C51BF" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </>
        )}

        {activeTab === 'calls' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">All Calls</h2>
            <CallsTable calls={callData} />
          </div>
        )}

        {activeTab === 'analytics' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold mb-4">Call Lengths</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={stats.callLengths}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis dataKey="id" stroke="#4B5563" hide />
                  <YAxis stroke="#4B5563" />
                  <Tooltip />
                  <Bar dataKey="length" fill="#4C51BF" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold mb-4">Detailed Analytics</h2>
              <p className="text-lg">Total Calls: <span className="font-semibold">{stats.totalCalls}</span></p>
              <p className="text-lg">Average Call Length: <span className="font-semibold">{stats.avgCallLength} min</span></p>
              <p className="text-lg">Calls with Success Tag: <span className="font-semibold">{stats.callsWithSuccessTag}</span></p>
            </div>
          </div>
        )}
      </main>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-200 mt-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 flex justify-between items-center">
          <p className="text-sm text-gray-500">© 2023 Sears Dashboard. All rights reserved.</p>
          <div className="flex items-center space-x-4">
            <button className="text-sm text-gray-500 hover:text-gray-700">Privacy Policy</button>
            <button className="text-sm text-gray-500 hover:text-gray-700">Terms of Service</button>
          </div>
        </div>
      </footer>

      {/* Live Indicator */}
      <div className="fixed bottom-4 right-4 flex items-center bg-white rounded-full px-3 py-1 shadow-md border border-gray-300">
        <div className="animate-pulse mr-2">
          <div className="w-3 h-3 bg-green-500 rounded-full"></div>
        </div>
        <span className="text-sm font-medium text-gray-700">Live</span>
      </div>
    </div>
  );
};

export default SearsInteractiveDashboard;
