import { useEffect, useState, useRef, useCallback } from "react";
import { PageWrapper } from "../../core/PageWrapper.js";
import { fetchCalls } from "utils/funcs/call-logs/fetchCalls.js";
import ActionBar from "../../core/ActionBar.js";
import { convertToMinutesAndSeconds } from "utils/formatting/secsToMins.js";
import { toDtmy } from "utils/formatting/toDtmy.js";
import Modal from "components/core/Modal.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import Variables from "./Variables.jsx";
import Metadata from "./Metadata.jsx";
import { PageTitle } from "components/core/PageTitle.js";
import Summary from "./Summary.jsx";
import { getUserData } from "utils/funcs/browser/getUserData.js";
import styled from "styled-components";
import Button from "components/core/Button.js";
import PathwayLogs from "../ConvoPathways/Components/PathwayLogs.js";
import Fuse from "fuse.js";
import ExpandIcon from "../../../assets/icons/expand-icon.svg";
import Call from "./Call.jsx";
import AudioComponent from "./AudioComponent.jsx";
import {
  Button as RadixButton,
  Flex,
  Popover,
  TextField,
  IconButton,
  Spinner,
  Checkbox,
  DropdownMenu,
  Badge,
} from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";
import { AdvancedTable } from "./TableView/AdvTable.jsx";
import { getAuthToken } from "utils/funcs/browser/getAuthToken.js";
import { fetchTranscript } from "utils/funcs/call-logs/fetchTranscript.js";
import TranscriptSlideOut from "./SlideOutCabinet.js";
import { Box } from "@mui/material";

import {
  Select as SSelect,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import { Check, ChevronDown, Filter, PlusIcon, X } from "lucide-react";
import { toast } from "react-toastify";
import { getApiKey } from "utils/funcs/browser/getApiKey.js";
import { NotesSlideOut } from "./NotesSlideOut.js";

import { Calendar } from "lucide-react";

const FILTER_CONFIG = {
  "Call ID": { operators: ["eq"] },
  To: { operators: ["eq"] },
  From: { operators: ["eq"] },
  Direction: { operators: ["eq"] },
  "Transferred To": { operators: ["eq", "neq"] },
  Recording: { operators: ["eq"] },
  Cost: { operators: ["eq", "gt", "lt"] },
  "Call Length": { operators: ["eq", "gt", "lt"] },
  "Error Message": { operators: ["eq"] },
  "Answered By": { operators: ["eq"] },
  "Call Ended By": { operators: ["eq"] },
  "Batch ID": { operators: ["eq"] },
  "Pathway ID": { operators: ["eq"] },
  "Pathway Name": { operators: ["contains"] },
  "Pathway Tags": { operators: ["eq"] }, // Added pathway tags filter
};

const ALL_COLUMNS = [
  "c_id",
  "created_at",
  "call_length",
  "to",
  "from",
  "inbound",
  "max_duration",
  // "metadata",
  //   "endpoint_url",
  "variables",
  "start_time",
  "completed",
  "queue_status",
  "error_message",
  "answered_by",
  "batch_id",
  // "sid",
  "started_at",
  "record",
  "recording_url",
  // "twilio_account_sid",
  "summary",
  "local_dialing",
  "price",
  "call_ended_by",
  "analysis",
  "analysis_schema",
  "campaign_id",
  "transferred_to",
  "pathway_tags",
  "transcripts",
];

const OPERATOR_LABELS = {
  eq: "Equals",
  gt: "Greater Than",
  lt: "Less Than",
  neq: "Not Equal",
  contains: "Contains",
  startsWith: "Starts With",
  endsWith: "Ends With",
};

const timezones = [
  { offset: "-12:00", identifier: "Pacific/Wake" },
  { offset: "-11:00", identifier: "Pacific/Samoa" },
  { offset: "-10:00", identifier: "Pacific/Honolulu" },
  { offset: "-09:00", identifier: "America/Anchorage" },
  { offset: "-08:00", identifier: "America/Los_Angeles" },
  { offset: "-07:00", identifier: "America/Denver" },
  { offset: "-06:00", identifier: "America/Chicago" },
  { offset: "-05:00", identifier: "America/New_York" },
  { offset: "-04:00", identifier: "America/Halifax" },
  { offset: "-03:00", identifier: "America/Sao_Paulo" },
  { offset: "-02:00", identifier: "Atlantic/South_Georgia" },
  { offset: "-01:00", identifier: "Atlantic/Azores" },
  { offset: "+00:00", identifier: "Europe/London" },
  { offset: "+01:00", identifier: "Europe/Paris" },
  { offset: "+02:00", identifier: "Europe/Kiev" },
  { offset: "+03:00", identifier: "Europe/Moscow" },
  { offset: "+04:00", identifier: "Asia/Dubai" },
  { offset: "+05:00", identifier: "Asia/Karachi" },
  { offset: "+06:00", identifier: "Asia/Dhaka" },
  { offset: "+07:00", identifier: "Asia/Bangkok" },
  { offset: "+08:00", identifier: "Asia/Shanghai" },
  { offset: "+09:00", identifier: "Asia/Tokyo" },
  { offset: "+10:00", identifier: "Australia/Sydney" },
  { offset: "+11:00", identifier: "Pacific/Noumea" },
  { offset: "+12:00", identifier: "Pacific/Auckland" },
];

// Helper function to format date as YYYY-MM-DD
function formatDate(date) {
  return date.toISOString().split("T")[0];
}

// Helper function to get date from 30 days ago
function get30DaysAgo() {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return formatDate(date);
}

function DateRangeSelector({
  startDate = get30DaysAgo(),
  endDate = formatDate(new Date()),
  onStartDateChange = () => {},
  onEndDateChange = () => {},
}) {
  const [error, setError] = useState("");

  const handleDateChange = (isStart, date) => {
    if (isStart) {
      onStartDateChange(date);
      if (endDate) {
        const start = new Date(date);
        const end = new Date(endDate);
        if (end < start) {
          onEndDateChange("");
        } else {
          const diffTime = Math.abs(end - start);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 30) {
            onEndDateChange("");
          }
        }
      }
      setError("");
    } else {
      if (!startDate) {
        setError("Please select a start date first");
        return;
      }

      const start = new Date(startDate);
      const end = new Date(date);

      if (end < start) {
        setError("End date must be after start date");
        return;
      }

      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 30) {
        setError("Date range cannot exceed 30 days");
        return;
      }

      setError("");
      onEndDateChange(date);
    }
  };

  // Calculate max end date (30 days from start date)
  const maxEndDate = startDate
    ? formatDate(
        new Date(
          new Date(startDate).setDate(new Date(startDate).getDate() + 30),
        ),
      )
    : "";

  return (
    <div className="w-full" style={{ padding: 0, marginTop: 20 }}>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full"
        style={{ width: "100%" }}
      >
        <div style={{ width: "100%" }}>
          <label className="block text-sm font-medium mb-2">Start Date</label>
          <div className="relative">
            <input
              type="date"
              value={startDate}
              onChange={(e) => handleDateChange(true, e.target.value)}
              className="w-full px-4 py-2 pr-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {/* <Calendar
              className="absolute right-3 top-2.5 text-gray-400"
              size={20}
            /> */}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <label className="block text-sm font-medium mb-2">End Date</label>
          <div className="relative">
            <input
              type="date"
              value={endDate}
              min={startDate}
              max={maxEndDate}
              onChange={(e) => handleDateChange(false, e.target.value)}
              className="w-full px-4 py-2 pr-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {/* <Calendar
              className="absolute right-3 top-2.5 text-gray-400"
              size={20}
            /> */}
          </div>
        </div>
      </div>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
}

function TimezoneDropdown({ value = "", onChange = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);
  const selectedTimezone = timezones.find((tz) => tz.identifier === value);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-2 text-left bg-white border rounded-md shadow flex justify-between items-center"
      >
        <span className="truncate">
          {selectedTimezone
            ? `${selectedTimezone.identifier} (UTC${selectedTimezone.offset})`
            : "Select timezone"}
        </span>
        <ChevronDown
          className={`transform transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          {timezones.map((tz) => (
            <div
              key={tz.identifier}
              onClick={() => {
                onChange(tz.identifier);
                setIsOpen(false);
              }}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              {`${tz.identifier} (UTC${tz.offset})`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function CallLogs() {
  // Core States
  const { user } = getUserData();
  const [calls, setCalls] = useState([]);
  const [allCallsData, setAllCallsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [pageCount, setPageCount] = useState(25);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const currPage = searchParams.get("currPage");
  const navigate = useNavigate();
  const [focusedCId, setFocusedCId] = useState(null);
  const [focusedCall, setFocusedCall] = useState(null);
  const [saved, setSaved] = useState(false);
  const [allCallIds, setAllCallsIds] = useState(null);
  // Modal States
  const [openSummary, setOpenSummary] = useState(false);
  const [openCallData, setOpenCallData] = useState(false);
  const [openPathwayLogs, setOpenPathwayLogs] = useState(false);
  const [openTranscript, setOpenTranscript] = useState(false);
  const [openVariables, setOpenVariables] = useState(false);
  const [openMetadata, setOpenMetadata] = useState(false);
  const [unformattedData, setUnformattedData] = useState([]);

  const [filters, setFilters] = useState(() => {
    const savedPersistentFilterSetting = localStorage.getItem(
      "isPersistentFilterEnabled",
    );
    if (JSON.parse(savedPersistentFilterSetting)) {
      const savedFilters = localStorage.getItem("savedFilters");
      return savedFilters
        ? JSON.parse(savedFilters)
        : [{ column: "", operator: "", value: "" }];
    }
    return [{ column: "", operator: "", value: "" }];
  });

  const [isPersistentFilterEnabled, setIsPersistentFilterEnabled] = useState(
    () => {
      return (
        JSON.parse(localStorage.getItem("isPersistentFilterEnabled")) || false
      );
    },
  );
  const [eventView, setEventView] = useState(false);
  const [events, setEvents] = useState([]);

  const [selected, setSelected] = useState([]);
  const [loadingLookup, setLoadingLookup] = useState(false);
  const [uSp, setSearchParams] = useSearchParams();

  const { api_key } = getUserData();

  const performQuickAnalysis = async (transcripts) => {
    try {
      const response = await fetch(`/api/analysis/call`, {
        method: "POST",
        headers: {
          authorization: getAuthToken(),
        },
        body: JSON.stringify({ transcripts }),
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        return data?.analysis;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const performCallLookup = async (call = null, callId) => {
    try {
      let c = call || null;
      setLoadingLookup(true);
      const response = await fetch(`/api/user/lookup?id=${callId}`, {
        method: "GET",
        headers: {
          authorization: getAuthToken(),
        },
      });

      if (!c) {
        const callData = await fetchCalls(1, 50, [
          { column: "Call ID", operator: "equals", value: callId },
        ]);
        if (callData?.success && callData?.calls?.length === 1) {
          c = callData?.calls?.[0];
          try {
            c.variables = JSON.stringify(c?.variables);
          } catch (error) {}
        }
      }

      if (response.ok && response.status === 200) {
        const data = await response.json();
        const transcriptData = await fetchTranscript(call?.c_id, user.id);
        const mappedTScript =
          transcriptData?.transcript?.map((item) => ({
            role: item?.user,
            content: item?.text,
          })) || [];
        const b64EncodedMappedTranscripts = window.btoa(
          unescape(encodeURIComponent(JSON.stringify(mappedTScript))),
        );
        const quick_analysis = await performQuickAnalysis(
          b64EncodedMappedTranscripts,
        );
        setFocusedCall({
          call: c,
          errors: data?.errors,
          latency: data?.latency,
          transcripts: transcriptData?.transcript || [],
          quick_analysis,
          perfLatency: data?.perfLatency || null,
        });
      }

      setLoadingLookup(false);
    } catch (error) {
      setLoadingLookup(false);
    }
  };

  const [exportModalOpen, setExportModalOpen] = useState(false);

  const handleSelectionModelChange = (sm) => {
    setSelected((prevSelectedCallIds) => {
      const newSelectedCallIds = [...prevSelectedCallIds];

      // Remove any callIds that are no longer selected
      prevSelectedCallIds.forEach((callId) => {
        if (!sm.some((item) => unformattedData[item].c_id === callId)) {
          const index = newSelectedCallIds.indexOf(callId);
          if (index !== -1) {
            newSelectedCallIds.splice(index, 1);
          }
        }
      });

      // Add any new callIds that are selected
      sm.forEach((item) => {
        const callId = unformattedData[item].c_id;
        if (!newSelectedCallIds.includes(callId)) {
          newSelectedCallIds.push(callId);
        }
      });

      return newSelectedCallIds;
    });
  };

  const getEvents = async () => {
    const data = await fetch(`https://us.api.bland.ai/v1/events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: api_key,
      },
    });
    const eventData = await data.json();

    const currentEventsCounts = events?.length;
    const newEventsCounts = eventData?.events.length;

    if (newEventsCounts !== currentEventsCounts || !currentEventsCounts) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (eventData?.events) {
      setEvents(eventData.events);
    }
  };

  const [exportEmail, setExportEmail] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [exportFormat, setExportFormat] = useState("csv");
  const [exportTimezone, setExportTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [exportExlcudedColumns, setExportExlcudedColumns] = useState([]);
  const [eeOpen, setEEOpen] = useState(false);
  const [eestartDate, setEEStartDate] = useState(get30DaysAgo());
  const [eeendDate, setEEEndDate] = useState(formatDate(new Date()));

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setEEOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateInput = () => {
    if (!exportEmail || !exportFormat) {
      toast.error("Please enter an email and select a format.");
      return false;
    }

    if (exportEmail === "") {
      toast.error("Please enter an email.");
      return false;
    }

    if (!exportEmail.includes("@")) {
      toast.error("Please enter a valid email.");
      return false;
    }

    return true;
  };

  const handleExportCallLogs = async () => {
    try {
      if (!validateInput()) {
        return;
      }
      setExportLoading(true);

      let object = {
        email: exportEmail,
        timezone: exportTimezone,
        file_type: exportFormat,
        range: {
          start_date: eestartDate,
          end_date: eeendDate,
        },
      };

      if (exportExlcudedColumns?.length > 0) {
        object.excludedColumns = exportExlcudedColumns;
      }

      const response = await fetch(`https://us.api.bland.ai/exports/calls`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getApiKey(),
        },
        body: JSON.stringify(object),
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (data.errors?.length === 0) {
          return toast.success(
            "You will receive an email shortly with your call logs.",
          );
        }
      } else if (response.status === 429) {
        const errBody = await response.json();
        if (
          errBody?.errors?.find(
            (item) => item.error === "EXPORT_CALLS_FAILURE_RATELIMIT",
          )
        ) {
          return toast.error(
            "Rate limit exceeded. Only allowed to export calls once every 10 minutes.",
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportEmail("");
      setExportExlcudedColumns([]);
      setExportTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      setExportLoading(false);
      setExportModalOpen(false);
    }
  };

  const containerRef = useRef(null);

  const onFilterChange = useCallback((filters) => {
    setFilters(filters);
  }, []);

  const updateFilter = useCallback((index, field, value) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      newFilters[index] = { ...newFilters[index], [field]: value };

      if (field === "column") {
        newFilters[index].operator = "";
        newFilters[index].value = "";
      }

      return newFilters;
    });
  }, []);

  const removeFilter = useCallback((index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  }, []);

  useEffect(() => {
    const savedPersistentFilterSetting = localStorage.getItem(
      "isPersistentFilterEnabled",
    );
    if (savedPersistentFilterSetting) {
      setIsPersistentFilterEnabled(JSON.parse(savedPersistentFilterSetting));
    }

    if (JSON.parse(savedPersistentFilterSetting)) {
      const savedFilters = localStorage.getItem("savedFilters");
      if (savedFilters) {
        setFilters(JSON.parse(savedFilters));
      }
    }
  }, []);

  useEffect(() => {
    if (isPersistentFilterEnabled) {
      localStorage.setItem("savedFilters", JSON.stringify(filters));
    }
  }, [filters, isPersistentFilterEnabled]);

  const togglePersistentFilter = useCallback(() => {
    setIsPersistentFilterEnabled((prev) => {
      const newValue = !prev;
      localStorage.setItem(
        "isPersistentFilterEnabled",
        JSON.stringify(newValue),
      );
      if (!newValue) {
        localStorage.removeItem("savedFilters");
        setFilters([{ column: "", operator: "", value: "" }]);
      } else {
        localStorage.setItem("savedFilters", JSON.stringify(filters));
      }
      return newValue;
    });
  }, [filters]);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (eventView) {
        getEvents();
      }
    }, 5000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [events, eventView]);

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [eventView]);

  useEffect(() => {
    if (!currPage) {
      navigate(`/dashboard?page=call-logs&currPage=1`);
    }
  }, [currPage, navigate]);

  useEffect(() => {
    setSaved(false);
  }, [filters]);

  // when focusedCId changes, update focusedCall
  useEffect(() => {
    if (focusedCId && unformattedData) {
      const call = unformattedData.find((call) => call.c_id === focusedCId);
      setFocusedCall(call);
    }
  }, [focusedCId, unformattedData]);

  const formatTableData = (data) => {
    if (!data || data?.length === 0) {
      setCalls([]);
      return [];
    }
    let tableData = [];
    for (const call of data) {
      // Remove unwanted keys
      const { objective, api_key, request_data, variables, ...rest } = call;
      // Reformat property names and values
      let formattedCallData = {
        Expand: (
          <div
            onClick={() => {
              setOpenCallData(true);
              setFocusedCall(call);
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={ExpandIcon} height={15} width={15} alt="expand" />
          </div>
        ),
        "Created On": toDtmy(new Date(rest.created_at)),
        Direction: rest?.inbound || false ? "Inbound" : "Outbound",
        To: rest.to,
        From: rest.from,
        Recording: (
          <AudioComponent
            recordingUrl={call.recording_url?.replace(".mp3", ".wav")}
          />
        ),
        "Call Length": convertToMinutesAndSeconds(rest.call_length),
        Cost: `$ ${rest.price ? parseFloat(rest.price).toFixed(2) : "0.00"}`,
        Status: rest.completed ? "Completed" : "In Progress",
        Summary: (
          <p
            onClick={() => {
              if (!call.summary) return;
              setOpenSummary(true);
            }}
            style={{ width: "100%" }}
          >
            {call.summary ? "View" : "N/A"}
          </p>
        ),
        "Pathway Logs": (
          <p
            onClick={() => {
              if (!call.pathway_logs) return;
              setOpenPathwayLogs(true);
            }}
            style={{ width: "100%" }}
          >
            {call.pathway_logs ? "View" : "N/A"}
          </p>
        ),

        Transcript: (
          <p
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            onClick={() => {
              setOpenTranscript(
                (currentOpenTranscript) => !currentOpenTranscript,
              );
              navigate(
                `/dashboard?page=call-logs&currPage=${currPage}&callId=${call.c_id}`,
              );
            }}
          >
            View
          </p>
        ),
        Variables: (
          <p
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            onClick={() => {
              setOpenVariables((currentOpenVariables) => !currentOpenVariables);
              navigate(
                `/dashboard?page=call-logs&currPage=${currPage}&callId=${call.c_id}`,
              );
            }}
          >
            View
          </p>
        ),
        "Call ID": rest.c_id,
        Metadata: (
          <p
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            onClick={() => {
              setFocusedCId(call.c_id);
              setOpenMetadata((currentOpenMetadata) => !currentOpenMetadata);
              navigate(
                `/dashboard?page=call-logs&currPage=${currPage}&callId=${call.c_id}`,
              );
            }}
          >
            View
          </p>
        ),
        "Error Message": rest.error_message ? rest.error_message : <p>N/A</p>,
        "Answered By": rest.answered_by ? rest.answered_by : <p>N/A</p>,
        "Call Ended By": rest.call_ended_by ? rest.call_ended_by : <p>N/A</p>,
        "Batch ID": rest.batch_id ? rest.batch_id : <p>N/A</p>,
        "Pathway ID": rest.pathway_id ? rest.pathway_id : <p>N/A</p>,
        "Pathway Name": rest.pathway_name ? rest.pathway_name : <p>N/A</p>,
        "Pathway Tags": rest.pathway_tags ? (
          rest.pathway_tags.join(", ")
        ) : (
          <p>N/A</p>
        ),
      };

      if (user.local_enabled) {
        formattedCallData["Local Dialing"] = rest.local_dialing
          ? "True"
          : "False";
      }

      tableData.push(formattedCallData);
      setCalls(tableData);
      return tableData;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("ref_c_id")) {
      const refCId = urlParams.get("ref_c_id");
      urlParams.delete("ref_c_id");
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}?${urlParams}`,
      );
      performCallLookup(null, refCId);
      setOpenCallData(true);
    }
  }, []);

  const [openNotes, setOpenNotes] = useState({
    open: false,
    callId: null,
  });

  const [loadingCallData, setLoadingCallData] = useState(false);

  const parseFilters = useCallback(() => {
    let oldFilters = [...filters];

    const qpfilters = {};

    oldFilters.forEach((filter) => {
      if (filter.column === "From" && filter.value) {
        qpfilters.from_number = filter.value;
      }

      if (filter.column === "To" && filter.value) {
        qpfilters.to_number = filter.value;
      }

      if (filter.column === "Answered By" && filter.value) {
        qpfilters.answered_by = filter.value;
      }

      if (filter.column === "Direction" && filter.value) {
        qpfilters.direction = filter.value;
      }

      if (filter.column === "Call ID" && filter.value) {
        qpfilters.call_id = filter.value;
      }

      if (filter.column === "Transferred To" && filter.value) {
        qpfilters.transferred_to = filter.value;
      }

      if (filter.column === "Batch ID" && filter.value) {
        qpfilters.batch_id = filter.value;
      }

      if (filter.column === "Pathway ID" && filter.value) {
        qpfilters.pathway_id = filter.value;
      }

      if (filter.column === "Pathway Name" && filter.value) {
        qpfilters.pathway_name = filter.value;
      }

      if (filter.column === "Pathway Tags" && filter.value) {
        qpfilters.pathway_tags = filter.value;
      }

      if (filter.column === "Recording" && filter.value) {
        qpfilters.record = filter.value;
      }

      if (filter.column === "Call Ended By" && filter.value) {
        qpfilters.call_ended_by = filter.value;
      }

      if (filter.column === "Error Message" && filter.value) {
        qpfilters.error_message = filter.value;
      }

      if (filter.column === "Cost" && filter.value && filter.operator) {
        if (filter.operator === "gt") {
          qpfilters.cost_gt = filter.value;
        } else if (filter.operator === "lt") {
          qpfilters.cost_lt = filter.value;
        }
      }

      if (filter.column === "Call Length" && filter.value && filter.operator) {
        if (filter.operator === "gt") {
          qpfilters.duration_gt = filter.value;
        } else if (filter.operator === "lt") {
          qpfilters.duration_lt = filter.value;
        }
      }
    });

    return qpfilters;
  }, [filters, isPersistentFilterEnabled]);

  const loadCallNotes = async (callIds) => {
    try {
      const response = await fetch(`https://us.api.bland.ai/v1/notes/list`, {
        method: "POST",
        headers: {
          Authorization: getApiKey(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          call_ids: callIds,
        }),
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        return data?.data?.notes;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [callNotes, setCallNotes] = useState([]);

  const loadCallLogs = async (
    pageSize = 25,
    setDataOnLoad = true,
    page = currPage,
  ) => {
    try {
      setLoadingCallData(true);
      const response = await fetch(
        `/api/user/logs?${new URLSearchParams({
          pageSize: pageSize,
          page: parseInt(page) || 1,
          ...parseFilters(),
        })}`,
        {
          method: "GET",
          headers: {
            Authorization: getAuthToken(),
          },
        },
      );

      setLoadingCallData(false);

      if (response.status === 200) {
        const data = await response.json();

        console.log("logs data", data);

        if (setDataOnLoad) {
          setRecordCount(data?.totalCount);
          setPageCount(data?.totalPages);
          formatTableData(data?.calls);
          const callIds = data.calls?.map((call) => call.c_id);
          const callNotes = await loadCallNotes(callIds);
          console.log(callNotes, "callNotes");
          setCallNotes(callNotes);
          setAllCallsIds(callIds);
          setAllCallsData(data?.calls);
          setUnformattedData(data?.calls);

          return data.calls;
        }
        return data.calls;
      }
    } catch (error) {
      setLoadingCallData(false);
    }
  };

  useEffect(() => {
    loadCallLogs();
  }, [currPage]);

  useEffect(() => {
    const options = {
      keys: ["from", "to", "call_id"],
    };
    const fuse = new Fuse(allCallsData, options);

    if (searchTerm.trim()) {
      const result = fuse
        .search(searchTerm)
        .slice(0, 50)
        .map(({ item }) => item);
      formatTableData(result);
    } else {
      formatTableData(unformattedData);
    }
  }, [searchTerm]);

  useEffect(() => {
    onFilterChange(filters);
  }, [onFilterChange]);

  const notesRef = useRef(null);

  const handlePageChange = (newPage) => {
    setSearchParams((params) => {
      params.set("currPage", newPage);
      return params;
    });
    loadCallLogs(25, true, newPage);
  };

  return (
    <PageWrapper>
      <ActionBar top>
        <div className="flex justify-between items-center w-full">
          <PageTitle>Call Logs</PageTitle>
          <div className="flex items-center" style={{ columnGap: "10px" }}>
            <Button
              onClick={() => setExportModalOpen(true)}
              appearance={"outline"}
              style={{ height: 35, borderRadius: 6 }}
            >
              Export Call Logs
            </Button>
            <Button
              submit
              onClick={() => {
                navigate("/dashboard?page=analytics&tab=realtime_logs");
              }}
              style={{ height: 35, borderRadius: 6 }}
            >
              Event Stream
            </Button>
            <Button
              onClick={() => {
                navigate("/dashboard?page=analytics&tab=calls");
              }}
              style={{ height: 35, borderRadius: 6 }}
            >
              Analytics
            </Button>
          </div>
        </div>
      </ActionBar>
      {/* Event Stream */}
      {eventView && (
        <EventStream>
          {events
            ?.slice()
            .reverse()
            .map((event, index) => (
              <div
                key={index}
                className="grid grid-cols-12 gap-4 w-full bg-black text-white p-4"
              >
                <p className="col-span-1 truncate">{event?.log_level}</p>
                <p className="col-span-5 truncate">{event?.message}</p>
                <p className="col-span-4">{event?.entity_id}</p>
                <p className="col-span-2">{event?.created_at.slice(11, 24)}</p>
                {/*<p className="col-span-1">{event?.category}</p>*/}
              </div>
            ))}
          <div ref={containerRef} />
        </EventStream>
      )}
      {!eventView && (
        // create a search bar here to filter calls
        <Box
          sx={{
            position: "fixed",
            overflowY: "scroll",
          }}
        >
          {!loading && (
            <div>
              <Flex gap={"3"} align={"center"} style={{ marginBottom: "15px" }}>
                <Popover.Root>
                  <Popover.Trigger>
                    <RadixButton
                      style={{ cursor: "pointer" }}
                      variant="outline"
                      type="button"
                    >
                      <Filter size={12} />
                      {filters.length > 0 &&
                      filters.some((filter) => filter.column !== "")
                        ? `Filtered by ${filters.length} rules`
                        : "Add Filter"}
                    </RadixButton>
                  </Popover.Trigger>
                  <Popover.Content size={"1"} minWidth={"368px"}>
                    {filters.length === 0 ? (
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "400" }}>
                          No Filters applied to this view
                        </p>
                        <p
                          style={{
                            fontSize: 10,
                            fontWeight: "300",
                            color: "GrayText",
                          }}
                        >
                          Add a column below to filter the view.
                        </p>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        {filters.map((filter, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2"
                          >
                            <SSelect
                              value={filter.column}
                              className="focus-visible:ring-transparent"
                              onValueChange={(value) => {
                                if (
                                  filters.find((item) => item.column === value)
                                ) {
                                  toast.error("Cannot use duplicate filters.");
                                  return removeFilter(index);
                                } else {
                                  updateFilter(index, "column", value);
                                }
                              }}
                            >
                              <SelectTrigger
                                className="w-[180px] h-[30px] focus:outline-none focus-visible:ring-transparent"
                                style={{
                                  border: "1px solid #eeeeee",
                                  fontSize: 11,
                                }}
                              >
                                <SelectValue placeholder="Select Field" />
                              </SelectTrigger>
                              <SelectContent>
                                {Object.keys(FILTER_CONFIG).map((field) => (
                                  <SelectItem key={field} value={field}>
                                    {field}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </SSelect>

                            <SSelect
                              disabled={filter.column === ""}
                              value={filter.operator}
                              onValueChange={(value) =>
                                updateFilter(index, "operator", value)
                              }
                            >
                              <SelectTrigger
                                value={filter.operator}
                                className="w-[180px] h-[30px] focus-visible:ring-transparent"
                                style={{
                                  border: "1px solid #eeeeee",
                                  fontSize: 11,
                                }}
                              >
                                <SelectValue placeholder="Select Operator" />
                              </SelectTrigger>
                              <SelectContent>
                                {FILTER_CONFIG[filter?.column]?.operators?.map(
                                  (op) => (
                                    <SelectItem key={op} value={op}>
                                      {OPERATOR_LABELS[op]}
                                    </SelectItem>
                                  ),
                                )}
                              </SelectContent>
                            </SSelect>

                            {filter.column === "Pathway Tags" ? (
                              // Custom input for pathway tags filter
                              <TextField.Root
                                style={{
                                  height: 30,
                                  border: "1px solid #eeeeee",
                                  boxShadow: "none",
                                  fontSize: 11,
                                }}
                                value={filter.value}
                                onChange={(ev) =>
                                  updateFilter(index, "value", ev.target.value)
                                }
                                placeholder="Enter Tag Name"
                                className="focus:outline-none border-none"
                              />
                            ) : (
                              <TextField.Root
                                style={{
                                  height: 30,
                                  border: "1px solid #eeeeee",
                                  boxShadow: "none",
                                  fontSize: 11,
                                }}
                                value={filter.value}
                                onChange={(ev) =>
                                  updateFilter(index, "value", ev.target.value)
                                }
                                placeholder={
                                  filter.column === "Recording"
                                    ? "True/False"
                                    : filter.column === "Direction"
                                      ? "inbound"
                                      : "Enter Value"
                                }
                                className="focus:outline-none border-none"
                              />
                            )}

                            <IconButton
                              onClick={() => removeFilter(index)}
                              type="button"
                              variant="ghost"
                              color="gray"
                              size={"3"}
                              style={{ cursor: "pointer" }}
                            >
                              <X size={13} />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    )}
                    <Flex
                      direction={"row"}
                      justify={"between"}
                      align={"center"}
                      className="mt-[15px]"
                    >
                      <RadixButton
                        style={{ cursor: "pointer" }}
                        size={"1"}
                        color="gray"
                        variant="ghost"
                        onClick={() => {
                          setFilters((prevFilters) => [
                            ...prevFilters,
                            { column: "", operator: "", value: "" },
                          ]);
                        }}
                      >
                        <PlusIcon size={12} />
                        Add Filter
                      </RadixButton>
                      <RadixButton
                        variant="soft"
                        color="gray"
                        size={"1"}
                        type="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          loadCallLogs();
                          setSearchParams((params) => {
                            params.set("currPage", 1);
                            return params;
                          });
                        }}
                      >
                        Apply Filters
                      </RadixButton>
                    </Flex>
                    <Flex
                      gap={"2"}
                      direction={"row"}
                      align={"center"}
                      justify={"start"}
                      className="mt-[15px]"
                    >
                      <Checkbox
                        checked={isPersistentFilterEnabled}
                        onCheckedChange={togglePersistentFilter}
                        id="persistentFilter"
                      />
                      <p
                        onClick={togglePersistentFilter}
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                      >
                        Persist filters
                      </p>
                    </Flex>
                  </Popover.Content>
                </Popover.Root>

                <RadixButton
                  onClick={() => navigate("/dashboard?page=analytics")}
                  size={"2"}
                  variant="soft"
                  style={{ cursor: "pointer" }}
                >
                  View Analytics & Call Insights
                  <Icons.ArrowRightIcon />
                </RadixButton>
              </Flex>
            </div>
          )}

          {calls && calls.length > 0 && (
            <AdvancedTable
              tableCalls={unformattedData}
              recordCount={recordCount}
              loading={loadingCallData}
              selected={selected}
              currPage={parseInt(currPage) || 1}
              pageSizeP={25}
              callNotes={callNotes}
              onExpandNoteClicked={(callId) => {
                setOpenNotes({
                  open: true,
                  callId: callId,
                });
              }}
              onChangeSelected={handleSelectionModelChange}
              onExpandedClicked={(call_data) => {
                setFocusedCall(null);
                performCallLookup(call_data, call_data?.c_id);
                setOpenCallData(true);
              }}
              onOpenModal={(modalName, callId) => {
                switch (modalName) {
                  case "variables":
                    setFocusedCId(callId);
                    setOpenVariables(!openVariables);
                    break;
                  case "summary":
                    setFocusedCId(callId);
                    setOpenSummary(!openSummary);
                    break;
                  case "pathway_logs":
                    setFocusedCId(callId);
                    setOpenPathwayLogs(true);
                    break;
                  case "transcripts":
                    setOpenTranscript(!openTranscript);
                    setFocusedCId(callId);
                    break;
                  default:
                    break;
                }
              }}
              focusedCId={focusedCId}
              onPageChange={handlePageChange}
            />
          )}
        </Box>
      )}

      <Modal
        height={"auto"}
        maxWidth={"80%"}
        open={openCallData}
        onClose={() => setOpenCallData(false)}
      >
        {loadingLookup && <Spinner />}
        <Call
          onClose={() => setOpenCallData(false)}
          loading={loadingLookup}
          call={focusedCall}
          modal
        />
      </Modal>
      <Summary
        isOpen={openSummary}
        onClose={() => setOpenSummary(false)}
        callIds={allCallIds}
        initialCallId={focusedCId}
        setFocusedCId={setFocusedCId}
        focusedCall={focusedCall}
        modalOpen={exportModalOpen ? true : false}
        notesOpen={openNotes.open}
      />
      <PathwayLogs
        isOpen={openPathwayLogs}
        onClose={() => setOpenPathwayLogs(false)}
        callIds={allCallIds}
        initialCallId={focusedCId}
        setFocusedCId={setFocusedCId}
        focusedCall={focusedCall}
        loading={false}
        modalOpen={exportModalOpen ? true : false}
        notesOpen={openNotes.open}
        enableQA={true}
        graphID={null}
        showChat={true}
        elements={null}
        setChatConversationHistory={null}
        setChatStartNode={null}
      />
      <TranscriptSlideOut
        isOpen={openTranscript}
        onClose={() => setOpenTranscript(false)}
        callIds={allCallIds}
        initialCallId={focusedCId}
        setFocusedCId={setFocusedCId}
        modalOpen={exportModalOpen ? true : false}
        notesOpen={openNotes.open}
      />
      <Variables
        isOpen={openVariables}
        onClose={() => setOpenVariables(false)}
        callIds={allCallIds}
        initialCallId={focusedCId}
        setFocusedCId={setFocusedCId}
        focusedCall={focusedCall}
        modalOpen={exportModalOpen ? true : false}
        notesOpen={openNotes.open}
      />

      {/* TODO: Evaluate if we need this Metadata slideout */}
      <Metadata
        isOpen={openMetadata}
        onClose={() => setOpenMetadata(false)}
        callIds={allCallIds}
        initialCallId={focusedCId}
        setFocusedCId={setFocusedCId}
        focusedCall={focusedCall}
        modalOpen={exportModalOpen ? true : false}
        notesOpen={openNotes.open}
      />

      {openNotes.callId && (
        <NotesSlideOut
          ref={notesRef}
          isOpen={openNotes.open}
          onClose={() => setOpenNotes({ open: false, callId: null })}
          callId={openNotes.callId}
          initialValue={
            callNotes?.find((note) => note.c_id === openNotes.callId)
              ?.comment || ""
          }
          onSetCallNotes={(callId, comment) => {
            setCallNotes((prevState) => {
              const existingNoteIndex = prevState.findIndex(
                (note) => note.c_id === callId,
              );
              if (existingNoteIndex !== -1) {
                // If note exists, update it
                return prevState.map((note, index) => {
                  if (index === existingNoteIndex) {
                    return { ...note, comment: comment };
                  }
                  return note;
                });
              } else {
                // If note doesn't exist, create a new one
                return [
                  ...prevState,
                  {
                    c_id: callId,
                    comment: comment,
                    created_at: new Date().toISOString(),
                  },
                ];
              }
            });
          }}
        />
      )}

      <Modal
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        className="ignore-audio-player"
        maxWidth="700px"
        children={
          <div
            className="ignore-audio-player"
            style={{ paddingBottom: eeOpen ? 100 : 0 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h3
                className="ignore-audio-player"
                style={{ fontSize: 22, fontWeight: "500" }}
              >
                Export Call Logs to Email
              </h3>

              <X
                size={22}
                style={{ cursor: "pointer" }}
                onClick={() => setExportModalOpen(false)}
              />
            </div>
            <p style={{ fontSize: 14, marginTop: 2.5, color: "gray" }}>
              Enter your email below to receive a CSV of your call logs. This
              may take a while if you have a lot of data. This will include data
              from {eestartDate} to {eeendDate}.
            </p>

            <label
              style={{
                fontSize: 14,
                marginBottom: 5,
                marginTop: 20,
                display: "block",
              }}
            >
              Email address:
            </label>
            <input
              placeholder="hello@bland.ai"
              value={exportEmail}
              onChange={(e) => setExportEmail(e.target.value)}
              style={{
                width: "100%",
                padding: 4,
                borderRadius: 4,
                border: "1px solid #eeeeee",
                fontSize: 14,
                outline: "none",
              }}
            />

            <label
              style={{
                fontSize: 14,
                marginBottom: 5,
                marginTop: 20,
                display: "block",
              }}
            >
              Timezone:
            </label>

            <TimezoneDropdown
              value={exportTimezone}
              onChange={setExportTimezone}
            />

            {exportTimezone && (
              <p className="mt-2 text-sm text-gray-600">
                Selected: {exportTimezone}
              </p>
            )}

            <div className="">
              <DateRangeSelector
                startDate={eestartDate}
                endDate={eeendDate}
                onStartDateChange={setEEStartDate}
                onEndDateChange={setEEEndDate}
              />
              <div className="mt-4 p-4 bg-gray-50 rounded-md">
                <p className="text-sm text-gray-600">
                  Selected Range: {eestartDate} to {eeendDate}
                </p>
              </div>
            </div>

            {/* <input
              placeholder="America/New_York"
              value={exportTimezone}
              onChange={(e) => setExportTimezone(e.target.value)}
              style={{
                width: "100%",
                padding: 4,
                borderRadius: 4,
                border: "1px solid #eeeeee",
                fontSize: 14,
                outline: "none",
              }}
            />

            {exportTimezone &&
              !Intl.supportedValuesOf("timeZone").includes(exportTimezone) && (
                <p style={{ color: "red" }}>
                  Invalid timezone. A valid timezone is case sensative, for
                  example: America/New_York. See list:
                  https://docs.oracle.com/middleware/1221/wcs/tag-ref/MISC/TimeZones.html
                </p>
              )} */}

            <label
              style={{
                fontSize: 14,
                marginTop: 20,
                display: "block",
              }}
            >
              Excluded Columns:
            </label>
            <p style={{ marginBottom: 5, color: "GrayText" }}>
              This removes columns and any items selected will NOT be included
              in the export file.
            </p>

            <div className="relative w-full" ref={dropdownRef}>
              <button
                onClick={() => setEEOpen(!eeOpen)}
                className="w-full px-4 py-2 text-left bg-white border rounded-md shadow flex justify-between items-center"
              >
                <span className="truncate">
                  {exportExlcudedColumns.length === 0
                    ? "Select items"
                    : `${exportExlcudedColumns?.length > 5 ? exportExlcudedColumns?.length : exportExlcudedColumns?.join(", ")} columns will be hidden`}
                </span>
                <ChevronDown
                  className={`transform transition-transform ${eeOpen ? "rotate-180" : ""}`}
                />
              </button>

              {eeOpen && (
                <div
                  className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg"
                  style={{
                    maxHeight: 200,
                    overflow: "scroll",
                    // marginBottom: eeOpen ? 100 : 0,
                  }}
                >
                  {ALL_COLUMNS.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (exportExlcudedColumns.includes(item)) {
                          setExportExlcudedColumns(
                            exportExlcudedColumns.filter((i) => i !== item),
                          );
                        } else {
                          setExportExlcudedColumns([
                            ...exportExlcudedColumns,
                            item,
                          ]);
                        }
                      }}
                      className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      <div className="w-5 h-5 border rounded-sm mr-2 flex items-center justify-center">
                        {exportExlcudedColumns.includes(item) && (
                          <Check size={16} />
                        )}
                      </div>
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={{ marginTop: 15, marginBottom: 0 }}>
              <label
                style={{ fontSize: 14, marginBottom: 5, display: "block" }}
              >
                Export Format:
              </label>
              <div style={{ display: "flex", gap: 20 }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name="exportFormat"
                    value="csv"
                    checked={exportFormat === "csv"}
                    onChange={() => setExportFormat("csv")}
                    style={{ marginRight: 5 }}
                  />
                  CSV
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name="exportFormat"
                    value="json"
                    checked={exportFormat === "json"}
                    onChange={() => setExportFormat("json")}
                    style={{ marginRight: 5 }}
                  />
                  JSON
                </label>
              </div>
            </div>

            <Button
              loading={exportLoading}
              onClick={() => handleExportCallLogs()}
              style={{ marginTop: 15, cursor: "pointer" }}
            >
              Export Call Logs
            </Button>
          </div>
        }
      />
    </PageWrapper>
  );
}

const EventStream = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100vh;
  border-radius: 1rem;
`;
