import { FaRegFlag } from "react-icons/fa";
import { toast } from "react-toastify";
import FlowContext from "../contextFlow";
import { Button } from "@radix-ui/themes";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

const { useState, useRef, useEffect, useContext } = require("react");

const FeatureFlagPathway = ({ id, featureFlag, setFeatureFlag }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const updateFeatureFlag = async (flag) => {
    try {
      const response = await fetch(`/api/convo_pathway/update-feature-flag`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        body: JSON.stringify({ featureFlag: flag, pathway_id: id }),
      });
      if (response.ok) {
        const data = await response.json();
        toast.success("Feature Flag updated successfully");
        console.log(data);
      } else {
        throw new Error("Something went wrong updating feature flag");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const versionOptions = [
    { value: "v1", label: "Version 1 [Default]" },
    {
      value: "v2",
      label: "Version 2 [Beta]",
      description: `- Updated prompting 
- Press Button/End Call Tool available at each node`,
    },
  ];

  const { triggerUpdate } = useContext(FlowContext);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <Button
        variant="soft"
        size="1"
        color="gray"
        onClick={() => setIsOpen(!isOpen)}
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)" }}
        // className="w-full bg-white hover:bg-gray-50 text-gray-800 py-2 px-4 border border-gray-300 rounded shadow transition duration-150 ease-in-out flex items-center justify-center"
      >
        <FaRegFlag size={14} />
        <p style={{ fontSize: 14 }}>Feature Flags</p>
      </Button>

      {isOpen && (
        <div className="p-2 origin-top-right absolute left-0 mt-2 w-[300px]  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[1000]">
          {versionOptions.map((option) => (
            <button
              key={option.value}
              className={`w-full text-left px-4 py-2 text-[12px] ${
                featureFlag === option.value
                  ? "bg-indigo-100 text-gray-900 hover:bg-indigo-300"
                  : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              } `}
              onClick={() => {
                setFeatureFlag(option.value);
                toast.success(`Feature Flag set to ${option.label}`, {
                  autoClose: 2000,
                });
                setIsOpen(false);
                updateFeatureFlag(option.value);
                //   triggerUpdate({}, false);
              }}
            >
              <div className="flex items-center gap-4">
                {option.label}
                {featureFlag === option.value && (
                  <FaRegFlag
                    className="text-indigo-900 flex items-center"
                    size={12}
                  />
                )}
              </div>
              <pre
                className="text-start text-[11px] text-gray-600 mt-1"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {option.description}
              </pre>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeatureFlagPathway;
