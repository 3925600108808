import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components";
import {
  FaFont,
  FaHashtag,
  FaCheck,
  FaEdit,
  FaCode,
  FaClock,
  FaListAlt,
  FaQuestion,
  FaTrash,
} from "react-icons/fa";
import ReactModal from "react-modal";
import Input from "components/core/Input";
import Textarea from "components/core/Textarea";
import Button from "components/core/Button";
import Label from "components/core/Label";
import { PageWrapper } from "components/core/PageWrapper";
import FieldModal from "./FieldModal";
import { ToastContainer, toast } from "react-toastify";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { Loading } from "components/core/Loading";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle.js";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "@radix-ui/react-icons";
import { IconButton, Select } from "@radix-ui/themes";

const fieldTypes = [
  { value: "string", label: "Text", icon: <FaFont /> },
  { value: "number", label: "Number", icon: <FaHashtag /> },
  { value: "boolean", label: "True or False", icon: <FaCheck /> },
  { value: "json", label: "JSON", icon: <FaCode /> },
  { value: "datetime", label: "Date and Time", icon: <FaClock /> },
  { value: "disposition", label: "Disposition", icon: <FaListAlt /> },
  { value: "other", label: "Other", icon: <FaQuestion /> },
];

const AnalysisPresetConfigurator = () => {
  const mainFormMethods = useForm();
  const modalFormMethods = useForm();
  const newPresetFormMethods = useForm();
  const [fields, setFields] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState({
    name: "",
    type: "",
    description: "",
  });
  const [currentPreset, setCurrentPreset] = useState("");
  const [presets, setPresets] = useState([]);
  const [isNewPresetModalOpen, setIsNewPresetModalOpen] = useState(false);
  const [newPresetName, setNewPresetName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(null);

  useEffect(() => {
    fetchPresets();
  }, []);

  useEffect(() => {
    if (currentPreset) {
      loadPresetById(currentPreset);
    }
  }, [currentPreset]);

  const fetchPresets = async () => {
    try {
      const { api_key: apiKey, user } = getUserData();
      const response = await fetch("/api/analysis_preset/load_presets", {
        headers: {
          Authorization: apiKey,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setPresets(data);
      } else {
        throw new Error("Invalid data format");
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching presets.");
      setLoading(false);
    }
  };

  const loadPresetById = (presetId) => {
    const selectedPreset = presets.find((preset) => preset.id === presetId);
    if (selectedPreset) {
      mainFormMethods.setValue(
        "background_info",
        selectedPreset.raw_fe.background_info,
      );
      setFields(selectedPreset.raw_fe.fields ?? []);
    }
  };

  const openModal = (
    field = { name: "", type: "", description: "" },
    index = null,
  ) => {
    setCurrentField({ ...field, index });
    modalFormMethods.reset(field);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentField({ name: "", type: "", description: "" });
  };

  const saveField = (data) => {
    const newFields = [...fields];
    const description = createDescription(data);
    data["field_desc"] = description;
    if (currentField.index !== null) {
      newFields[currentField.index] = data;
    } else {
      newFields.push(data);
    }
    setFields(newFields);
    closeModal();
  };

  const createDescription = (data) => {
    switch (data.field_type) {
      case "boolean":
        return `True if: ${data.true_if}, False if: ${data.false_if}`;
      case "datetime":
        return `Date and Time format: ${data.time_format}`;
      default:
        return data.field_desc;
    }
  };

  const deleteField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const onModalSubmit = (data) => {
    saveField(data);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const savePreset = async (form_data) => {
    const backgroundInfo = form_data.background_info;
    const allFields = fields;
    const presetId = currentPreset;

    try {
      const { api_key: apiKey } = getUserData();
      const response = await fetch("/api/analysis_preset/save_preset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiKey,
        },
        body: JSON.stringify({
          id: presetId,
          background_info: backgroundInfo,
          fields: allFields,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast.success("Preset saved successfully!");
      fetchPresets();
    } catch (error) {
      toast.error("Error saving preset.");
    }
  };

  const handleNewPreset = async (data) => {
    const name = data.preset_name;
    try {
      const { api_key: apiKey } = getUserData();
      const response = await fetch("/api/analysis_preset/new_preset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiKey,
        },
        body: JSON.stringify({ name: name }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      toast.success("New preset created successfully!");
      setIsNewPresetModalOpen(false);
      setPresets([
        ...presets,
        {
          name: responseData.name,
          id: responseData.id,
          raw_fe: { fields: [], background_info: "" },
        },
      ]);
      newPresetFormMethods.reset();
      setCurrentPreset(responseData.id);
    } catch (error) {
      toast.error("Error creating new preset.");
    }
  };

  const handlePresetChange = (e) => {
    const presetId = e.target.value;
    setCurrentPreset(presetId);
    loadPresetById(presetId);
  };

  const openDeleteModal = (preset) => {
    setPresetToDelete(preset);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setPresetToDelete(null);
  };

  const deletePreset = async () => {
    const presetId = presetToDelete.id;

    try {
      const { api_key: apiKey } = getUserData();
      const response = await fetch("/api/analysis_preset/delete_preset", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiKey,
        },
        body: JSON.stringify({ id: presetId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast.success("Preset deleted successfully!");
      setPresets(presets.filter((preset) => preset.id !== presetId));
      closeDeleteModal();
      setCurrentPreset("");
    } catch (error) {
      toast.error("Error deleting preset.");
    }
  };

  const copyIdToClipboard = () => {
    navigator.clipboard.writeText(currentPreset);
    toast.success("ID copied to clipboard");
  };

  const isDisabled = !currentPreset;

  if (loading) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <FormProvider {...mainFormMethods}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
              columnGap: "5px",
            }}
          >
            <Button
              style={{ borderRadius: "6px" }}
              appearance={"outline"}
              type="button"
              onClick={copyIdToClipboard}
              disabled={isDisabled}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <Icons.CopyIcon />
                Copy ID
              </div>
            </Button>
            <SaveButton
              style={{ borderRadius: "6px" }}
              type="button"
              onClick={mainFormMethods.handleSubmit(savePreset)}
              disabled={isDisabled}
            >
              Save Preset
            </SaveButton>
            <Button
              style={{ borderRadius: "6px" }}
              type="button"
              onClick={() => setIsNewPresetModalOpen(true)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <Icons.PlusIcon />
                New Preset
              </div>
            </Button>
          </div>
          <br></br>
          <Header style={{ width: "100%" }}>
            <SelectPreset style={{ width: "100%" }}>
              <Label>Current Preset</Label>
              <div
                style={{
                  display: "flex",
                  columnGap: "10px",
                  alignItems: "center",
                  width: "96%",
                  marginTop: "10px",
                }}
              >
                <Select.Root
                  value={currentPreset}
                  onValueChange={(value) => setCurrentPreset(value)}
                >
                  <Select.Trigger
                    style={{ width: "100%" }}
                    placeholder="Select a preset..."
                  >
                    {
                      presets?.find((preset) => preset.id === currentPreset)
                        ?.name
                    }
                  </Select.Trigger>
                  <Select.Content
                    onClick={(event) => handlePresetChange(event)}
                    variant="soft"
                    style={{
                      width: "calc(100% - 15px)",
                      marginLeft: 15,
                      marginTop: "40px",
                    }}
                  >
                    {presets.map((preset) => (
                      <Select.Item
                        onClick={(event) => handlePresetChange(event)}
                        key={preset.id}
                        value={preset.id}
                      >
                        {preset.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
                <IconButton
                  size={"2"}
                  color={"gray"}
                  variant="soft"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openDeleteModal(
                      presets.find((preset) => preset.id === currentPreset),
                    )
                  }
                >
                  <Icons.TrashIcon color="red" />
                </IconButton>
              </div>
            </SelectPreset>
          </Header>
          <StyledFormWrapper id="mainForm">
            <TopSection>
              <PromptSection style={{ width: "100%" }}>
                <Textarea
                  fieldName="background_info"
                  label="Background Information"
                  placeholder="Enter your background information here"
                  style={{ minHeight: "300px" }} // Adjust the height as needed
                  disabled={isDisabled}
                />
              </PromptSection>

              <FieldsSection style={{}}>
                <Label>Fields</Label>
                <GridHeader>
                  <HeaderCell style={{ width: "50px" }}>Type</HeaderCell>
                  <HeaderCell style={{ width: "120px", marginRight: "10px" }}>
                    Name
                  </HeaderCell>
                  <HeaderCell style={{ width: "350px" }}>
                    Description
                  </HeaderCell>
                  <HeaderCell style={{ width: "100px", textAlign: "right" }}>
                    Actions
                  </HeaderCell>
                </GridHeader>
                {fields &&
                  fields.length > 0 &&
                  fields.map((field, index) => (
                    <React.Fragment key={index}>
                      <GridRow>
                        <GridCell style={{ width: "50px", textAlign: "left" }}>
                          {
                            fieldTypes.find(
                              (type) => type.value === field.field_type,
                            ).icon
                          }
                        </GridCell>
                        <GridCell
                          style={{
                            width: "120px",
                            textAlign: "left",
                            marginRight: "10px",
                          }}
                        >
                          {truncateText(field.field_name, 13)}
                        </GridCell>
                        <GridCell style={{ width: "350px", textAlign: "left" }}>
                          {truncateText(field.field_desc, 60)}
                        </GridCell>
                        <GridCell
                          style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <EditIcon onClick={() => openModal(field, index)} />
                          <DeleteIcon onClick={() => deleteField(index)} />
                        </GridCell>
                      </GridRow>
                    </React.Fragment>
                  ))}
                <Button
                  type="button"
                  onClick={() => openModal()}
                  style={{ marginTop: "10px" }}
                  disabled={isDisabled}
                >
                  Add Field
                </Button>
              </FieldsSection>
            </TopSection>
          </StyledFormWrapper>
        </div>
      </FormProvider>

      <FieldModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onSave={onModalSubmit}
        field={currentField}
        modalFormMethods={modalFormMethods}
      />

      <FormProvider {...newPresetFormMethods}>
        <ReactModal
          isOpen={isNewPresetModalOpen}
          onRequestClose={() => setIsNewPresetModalOpen(false)}
          style={customStyles}
        >
          <div
            style={{ gap: "10px", display: "flex", flexDirection: "column" }}
          >
            <Label>New Preset Name</Label>
            <Input
              fieldName="preset_name"
              value={newPresetName}
              onChange={(e) => setNewPresetName(e.target.value)}
              placeholder="Enter preset name"
            />
            <Button
              type="button"
              onClick={newPresetFormMethods.handleSubmit(handleNewPreset)}
            >
              Submit
            </Button>
          </div>
        </ReactModal>
      </FormProvider>

      <ReactModal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
      >
        <DeleteModalContent>
          <h3>Confirm Delete</h3>
          {presetToDelete && (
            <>
              <p>Are you sure you want to delete the preset:</p>
              <p>
                <strong>{presetToDelete.name}</strong> (ID: {presetToDelete.id})
              </p>
              <ButtonContainer>
                <Button onClick={deletePreset} color="danger">
                  Delete
                </Button>
                <Button onClick={closeDeleteModal}>Cancel</Button>
              </ButtonContainer>
            </>
          )}
        </DeleteModalContent>
      </ReactModal>
    </PageWrapper>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px auto;
`;

const SelectPreset = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

// const Select = styled.select`
//   padding: 10px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   background-color: #fff;
//   cursor: pointer;
//   width: 100%;
// `;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SaveButton = styled(Button)`
  margin-left: auto;
`;

const StyledFormWrapper = styled.form`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-template-columns: 50% 50%;
`;

const TopSection = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 20px;
  gap: 20px;
`;

const PromptSection = styled.div`
  flex: 1;
  height: 80vh;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const FieldsSection = styled.div`
  flex: 1;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const GridHeader = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #f8f8f8;
  font-weight: normal;
`;

const HeaderCell = styled.div`
  text-align: left;
`;

const GridRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const GridCell = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const EditIcon = styled(FaEdit)`
  cursor: pointer;
  margin-right: 10px;
`;

const DeleteIcon = styled(FaTrash)`
  cursor: pointer;
  margin-left: 10px;
`;

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
`;

const NewPresetModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin: 5px 0;
  }

  ${ButtonContainer} {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    height: "auto",
    maxHeight: "500px",
    maxWidth: "500px",
    padding: "25px",
    overflow: "scroll",
    zIndex: "6000",
  },
  overlay: {
    backgroundColor: "#00000099",
    zIndex: "5000",
  },
};

export default AnalysisPresetConfigurator;
