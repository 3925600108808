import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { toDtmy } from "utils/formatting/toDtmy";
import { convertToMinutesAndSeconds } from "utils/formatting/secsToMins";
import { useSearchParams } from "react-router-dom";
import { Badge, Button, IconButton, Slider, Tooltip } from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";
import {
  Copy,
  ExternalLink,
  MessageCircle,
  Plus,
  Download,
  Loader2,
  PhoneIncoming,
  PhoneOutgoing,
} from "lucide-react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { pathwayTags } from "components/Dashboard/ConvoPathways/Components/CallDetailsSlider";
import AudioPlayer from "./AudioPlayer";

export const AdvancedTable = ({
  tableCalls,
  recordCount,
  pageCount,
  currPage,
  callNotes,
  selected,
  onOpenModal,
  onChangeSelected,
  onExpandedClicked,
  focusedCId,
  onExpandNoteClicked,
  loading = false,
  pageSizeP = 25,
  onPageChange, // Add this prop
}) => {
  const [currentPage, setCurrentPage] = useState(currPage || 0);
  const [pageSize, setPageSize] = useState(pageSizeP || 25);
  const [totalPages, setTotalPages] = useState(pageCount || null);
  const [totalRecords, setTotalRecords] = useState(recordCount || null);
  const [audioStates, setAudioStates] = useState({});
  const audioRefs = useRef({});
  const [activeAudio, setActiveAudio] = useState(null);
  const [loadingPlayAudio, setLoadingPlayAudio] = useState(null);
  const [loadingDownloadAudio, setLoadingDownloadAudio] = useState(null);

  const fetchRecording = async (recordingUrl) => {
    try {
      const res = await fetch("/api/logs/recording", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ recordingUrl }),
      });

      if (res.status === 200) {
        const audioBlob = await res.blob();
        return URL.createObjectURL(audioBlob);
      } else {
        console.error("Failed to fetch the audio file");
        return null;
      }
    } catch (error) {
      console.error("Error fetching recording:", error);
      return null;
    }
  };

  const handlePlay = async (event, params) => {
    event.stopPropagation();
    const cId = params.row.c_id;
    const recUrl = params?.row?.recording_url?.replace(".mp3", ".wav");

    setLoadingPlayAudio(cId);
    try {
      // Fetch the actual audio URL (replace with your actual fetching logic if needed)
      const audioUrl = await fetchRecording(recUrl);

      if (audioUrl) {
        setActiveAudio({ cId, url: audioUrl, autoplay: true });
      } else {
        toast.error("Failed to load the audio");
      }
    } catch (error) {
      console.error("Error fetching audio:", error);
      toast.error("An error occurred while loading the audio");
    } finally {
      setLoadingPlayAudio(null);
    }
  };

  const handleDownload = async (event, params) => {
    event.stopPropagation();
    const cId = params.row.c_id;
    const recUrl = params?.row?.recording_url?.replace(".mp3", ".wav");

    setLoadingDownloadAudio(cId);
    try {
      const audioUrl = await fetchRecording(recUrl);

      if (audioUrl) {
        const link = document.createElement("a");
        link.href = audioUrl;
        link.download = `recording_${params.row.c_id}.wav`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Failed to download the recording");
      }
    } finally {
      setLoadingDownloadAudio(null);
    }
  };

  const getRowClassName = (params) => {
    if (params.row.c_id === activeAudio?.cId) {
      return "playing-row";
    }
    return "";
  };

  const removeHtmlFromNotes = (notes) => {
    return notes.replace(/<[^>]*>?/g, "").replace(/&nbsp;/g, " ");
  };

  const columns = [
    {
      field: "",
      headerName: "",
      className: "select-column",
      sortable: false,
      filter: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onExpandedClicked(params.row);
            }}
            size="1"
            variant="surface"
            color="gray"
            style={{ cursor: "pointer" }}
          >
            <Icons.ZoomInIcon />
          </IconButton>
        );
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      filter: false,
      width: 90,
      disableColumnMenu: true,
      renderCell: (params) => {
        const callNote =
          callNotes?.find((note) => note.c_id === params.row.c_id) || null;
        const hasContent =
          callNote && removeHtmlFromNotes(callNote.comment).trim() !== "";

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {hasContent ? (
              <Tooltip
                style={{ maxWidth: 250 }}
                side="top"
                content={`${removeHtmlFromNotes(callNote.comment).substring(0, 500)}...`}
              >
                <Button
                  variant="soft"
                  size={"1"}
                  color="green"
                  style={{ cursor: "pointer" }}
                  className="dont-close-note"
                  onClick={(event) => {
                    event.stopPropagation();
                    onExpandNoteClicked(params.row.c_id);
                  }}
                >
                  <p style={{ fontSize: 12 }} className="dont-close-note">
                    Notes
                  </p>
                  <ExternalLink size={12} className="dont-close-note" />
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="soft"
                size={"1"}
                color="gray"
                style={{ cursor: "pointer" }}
                className="dont-close-note"
                onClick={(event) => {
                  event.stopPropagation();
                  onExpandNoteClicked(params.row.c_id);
                }}
              >
                <p style={{ fontSize: 12 }} className="dont-close-note">
                  Notes
                </p>
                <ExternalLink size={12} className="dont-close-note" />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "c_id",
      headerName: "Call ID",
      width: 100,
      filter: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const shortId = params.value.substring(0, 5);
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              className: "dont-close-note",
            }}
          >
            <Tooltip content={params.value}>
              <Button
                variant="soft"
                size={"1"}
                color="gray"
                className="dont-close-note"
                style={{ cursor: "pointer", width: "80px" }}
                onClick={(event) => {
                  event.stopPropagation();
                  navigator.clipboard.writeText(params.value);
                  toast.success("Call ID copied to clipboard");
                }}
              >
                <p style={{ fontSize: 12 }} className="dont-close-note">
                  {" "}
                  {shortId}...
                </p>
                <Copy size={12} className="dont-close-note" />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created On",
      sortable: true,
      width: 140,
      disableColumnMenu: true,
    },
    {
      field: "inbound",
      headerName: "Direction",
      sortable: false,
      filter: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Tooltip
              content={
                params.value === "inbound" ? "Inbound Call" : "Outbound Call"
              }
            >
              {params.value === "inbound" ? (
                <PhoneIncoming size={16} color="#4CAF50" />
              ) : (
                <PhoneOutgoing size={16} color="#2196F3" />
              )}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "to",
      headerName: "To",
      sortable: false,
      filter: true,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "from",
      headerName: "From",
      sortable: false,
      filter: true,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "recording_url",
      headerName: "Recording",
      sortable: false,
      filter: false,
      width: 90,
      renderCell: (params) => {
        const cId = params.row.c_id;
        const isPlayLoading = loadingPlayAudio === cId;
        const isDownloadLoading = loadingDownloadAudio === cId;

        return params.row.recording_url ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              onClick={(e) => handlePlay(e, params)}
              size="1"
              variant="surface"
              color="green"
              style={{ cursor: "pointer" }}
              disabled={isPlayLoading || isDownloadLoading}
            >
              {isPlayLoading ? (
                <Loader2 className="animate-spin" size={16} />
              ) : (
                <Icons.PlayIcon />
              )}
            </IconButton>
            <IconButton
              onClick={(e) => handleDownload(e, params)}
              size="1"
              variant="surface"
              color="blue"
              style={{ cursor: "pointer" }}
              disabled={isPlayLoading || isDownloadLoading}
            >
              {isDownloadLoading ? (
                <Loader2 className="animate-spin" size={16} />
              ) : (
                <Download size={16} />
              )}
            </IconButton>
          </div>
        ) : (
          <p>-</p>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "completed",
      headerName: "Status",
      sortable: false,
      filter: false,
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "transcript",
      headerName: "Transcript",
      filter: false,
      sortable: false,
      width: 90,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("transcripts", cId);
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClick}
              size="1"
              variant="surface"
              style={{ cursor: "pointer", width: "100%" }}
            >
              View
            </Button>
          </div>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "pathway_logs",
      headerName: "Pathway Logs",
      sortable: false,
      width: 110,
      filter: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("pathway_logs", cId);
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {params.row.pathway_logs === "N/A" ? (
              <p>-</p>
            ) : (
              <Button
                onClick={handleClick}
                size="1"
                variant="surface"
                style={{ cursor: "pointer", width: "80%" }}
              >
                View
              </Button>
            )}
          </div>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "Pathway Tags",
      headerName: "Pathway Tags",
      sortable: false,
      filter: false,
      width: 210,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "nowrap",
              columnGap: 5,
              gap: 5,
              padding: 5,
              overflowX: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {params?.row?.pathway_tags !== "-" &&
              pathwayTags(params?.row?.pathway_tags)?.map((tag) => (
                <Badge size={1} color="gray" radius="large">
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: "50%",
                      background: tag.color || "#000",
                    }}
                  />
                  <p style={{ fontSize: 12, fontWeight: 500 }}>{tag.name}</p>
                </Badge>
              ))}
          </div>
        );
      },
    },
    {
      field: "call_length",
      headerName: "Call Length",
      sortable: true,
      filter: false,
      width: 95,
      disableColumnMenu: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      sortable: true,
      filter: false,
      width: 80,
      disableColumnMenu: true,
    },
    {
      field: "pathway_id",
      headerName: "Pathway ID",
      width: 280,
      filter: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isClickable = params.row?.pathway_id !== "-";

        const handleClick = (e) => {
          e.preventDefault();
          window.open(
            `${window.location.origin}/dashboard?page=convo-pathways&id=${params.value}`,
            "_blank",
          );
        };

        const handleCopy = async (e) => {
          e.stopPropagation();

          try {
            await navigator.clipboard.writeText(params.value);
            toast.success("Pathway ID copied to clipboard");
          } catch (err) {
            toast.error("Failed to copy Pathway ID");
          }
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              size="1"
              onClick={handleClick}
              variant="ghost"
              disabled={params.value === "-"}
              style={{
                textDecoration: isClickable ? "underline" : "",
                cursor: isClickable ? "pointer" : "auto",
                flexGrow: 1,
                justifyContent: "flex-start",
              }}
            >
              {params.value}
            </Button>
            {isClickable && (
              <Button
                size="1"
                variant="ghost"
                onClick={handleCopy}
                style={{
                  padding: "4px",
                  minWidth: "auto",
                }}
              >
                <Copy size={16} />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "pathway_name",
      headerName: "Pathway Name",
      width: 190,
      filter: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isClickable = params.row?.pathway_id !== "-";
        const handleClick = (e) => {
          e.preventDefault();
          window.open(
            `${window.location.origin}/dashboard?page=convo-pathways&id=${params.row.pathway_id}`,
            "_blank",
          );
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              size="1"
              onClick={handleClick}
              variant="ghost"
              disabled={!isClickable}
              style={{
                textDecoration: isClickable ? "underline" : "",
                cursor: isClickable ? "pointer" : "auto",
              }}
            >
              {params.value}
            </Button>
          </div>
        );
      },
    },

    {
      field: "variables",
      headerName: "Variables",
      sortable: false,
      align: "center",
      headerAlign: "center",
      filter: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("variables", cId);
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClick}
              size="1"
              variant="surface"
              style={{ cursor: "pointer", width: "100%" }}
            >
              View
            </Button>
          </div>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "summary",
      headerName: "Summary",
      align: "center",
      headerAlign: "center",
      sortable: false,
      filter: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("summary", cId);
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {params.row.summary ? (
              <Button
                onClick={handleClick}
                size="1"
                variant="surface"
                style={{ cursor: "pointer", width: "100%" }}
              >
                View
              </Button>
            ) : (
              <p>-</p>
            )}
          </div>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "error_message",
      headerName: "Error Message",
      sortable: false,
      filter: false,
      width: 260,
      disableColumnMenu: true,
    },
    {
      field: "answered_by",
      headerName: "Answered By",
      sortable: true,
      filter: true,
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "call_ended_by",
      headerName: "Call Ended By",
      sortable: true,
      filter: true,
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "transferred_to",
      headerName: "Transferred To",
      sortable: false,
      filter: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "batch_id",
      headerName: "Batch ID",
      sortable: false,
      filter: true,
      width: 260,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    setTotalPages(pageCount || Math.ceil(tableCalls.length / pageSize));
    setTotalRecords(recordCount || tableCalls.length);
  }, [tableCalls, pageCount, recordCount, pageSize]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handlePrevPage = () => {
    const int = parseInt(currentPage) - 1;
    setCurrentPage(int);
    setSearchParams((params) => {
      params.set("currPage", int);
      return params;
    });
  };

  const handleNextPage = () => {
    const int = parseInt(currentPage) + 1;
    setCurrentPage(int);
    setSearchParams((params) => {
      params.set("currPage", int);
      return params;
    });
  };

  const handlePageChange = (newPage) => {
    onPageChange(newPage + 1); // Call the parent component's onPageChange function with the correct page number
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 150px)",
        maxHeight: "1015px",
        width: "calc(100vw - 100px)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <DataGrid
        getRowHeight={() => "auto"}
        columns={columns}
        rows={tableCalls?.map((doc, ind) => ({
          ...doc,
          inbound: doc.inbound ? "inbound" : "outbound",
          call_length: convertToMinutesAndSeconds(doc.call_length),
          created_at: toDtmy(new Date(doc.created_at)),
          id: ind,
          cost: `$${doc?.price ? parseFloat(doc.price)?.toFixed(2) : "0.00"}`,
          completed: doc.completed ? "Completed" : "In Progress",
          pathway_logs: doc?.pathway_logs
            ? JSON.stringify(doc?.pathway_logs || {})
            : "N/A",
          variables: doc?.variables
            ? JSON.stringify(doc?.variables || {})
            : "N/A",
          metadata: doc?.metadata ? JSON.stringify(doc?.metadata || {}) : "N/A",
          batch_id: doc?.batch_id || "-",
          pathway_id: doc?.pathway_id || "-",
          pathway_name: doc?.pathway_name || "-",
          answered_by: doc?.answered_by || "-",
          error_message: doc?.error_message || "-",
          call_ended_by: doc?.call_ended_by || "-",
        }))}
        headerHeight={40}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        getRowClassName={getRowClassName}
        sx={{
          flexGrow: 1,
          "& .MuiDataGrid-row:hover": {
            background: "#FAFAFA !important",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
            background: "transparent !important",
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
            outline: "none !important",
          },
          "& .playing-row": {
            backgroundColor: "#e6ffe6", // Light green background for the playing row
            "&:hover": {
              backgroundColor: "#ccffcc", // Slightly darker green on hover
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
            fontSize: "13px",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none !important",
            },
          "& .MuiDataGrid-cell": {
            fontSize: "12px",
          },
          "& .MuiDataGrid-columnHeaders": {
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#f5f5f5",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "0!important",
          },
          "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: "1px solid #303030",
            borderRightColor: "#f0f0f0",
          },
          "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: "1px solid #303030",
            borderBottomColor: "#f0f0f0",
          },
          "& .MuiDataGrid-footerContainer": {
            "& .MuiTablePagination-actions": {
              "& .MuiIconButton-root": {
                fontSize: "1.1rem",
              },
            },
            "& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel":
              {
                fontSize: "1.1rem",
              },
            // Add this new style for the selected rows text
            "& .MuiDataGrid-selectedRowCount": {
              fontSize: "1.1rem",
            },
          },
          borderRadius: "4px",
        }}
        autoPageSize={false}
        paginationMode={"server"}
        loading={loading}
        pagination={true}
        rowCount={recordCount}
        showCellRightBorder={false}
        showColumnRightBorder={false}
        page={parseInt(currPage) - 1} // Adjust the page prop to be zero-indexed
        onPageChange={handlePageChange}
        checkboxSelection={true}
        onSelectionModelChange={onChangeSelected}
        disableRowSelectionOnClick={true}
        disableSelectionOnClick={true}
      />
      {activeAudio && (
        <AudioPlayerOverlay>
          <AudioPlayer
            recordingUrl={activeAudio.url}
            onClose={() => setActiveAudio(null)}
            autoplay={activeAudio.autoplay}
          />
        </AudioPlayerOverlay>
      )}
    </Box>
  );
};

const AudioPlayerOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: 200px;
  margin-left: 100px;
  margin-bottom: 2px;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
`;

export default AdvancedTable;
