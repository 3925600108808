import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../core/PageWrapper";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import PurchaseNumber from "./PurchaseNumber";
import { FiSearch, FiChevronRight, FiRefreshCw } from "react-icons/fi";
import { AiOutlineEnter } from "react-icons/ai";
import Inbound from "./Inbound/Inbound";
import Tabs from "components/core/Tabs";
import Button from "components/core/Button";
import { FormProvider, useForm } from "react-hook-form";
import JSONMode from "./Inbound/JSONMode";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import axios from "axios";
import { toast } from "react-toastify";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import Switch from "components/core/Switch";
import usePhoneNumbers from "hooks/usePhoneNumbers";
import TwilioCircle from "assets/images/TwilioCircle.png";
import { Loading } from "components/core/Loading";
import { FiEdit2 } from "react-icons/fi";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import { v4 as uuidv4 } from "uuid";

const USFlagSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 912 600"
    className="w-4 h-3 mr-2"
  >
    <rect width="912" height="600" fill="#bf0a30" />
    <rect y="46.15" width="912" height="46.15" fill="#fff" />
    <rect y="138.45" width="912" height="46.15" fill="#fff" />
    <rect y="230.75" width="912" height="46.15" fill="#fff" />
    <rect y="323.05" width="912" height="46.15" fill="#fff" />
    <rect y="415.35" width="912" height="46.15" fill="#fff" />
    <rect y="507.65" width="912" height="46.15" fill="#fff" />
    <rect width="364.8" height="323.1" fill="#002868" />
    <g fill="#fff">
      {[...Array(50)].map((_, i) => {
        const x = 30.4 * (i % 6) + 15.2;
        const y = 28 * Math.floor(i / 6) + 14;
        return <circle key={i} cx={x} cy={y} r="10" />;
      })}
    </g>
  </svg>
);

const PhoneCard = ({ number, location, price, style }) => (
  <div
    className="bg-white rounded-lg shadow-sm p-3 flex items-center justify-between"
    style={style}
  >
    <div className="flex items-center">
      <USFlagSVG />
      <span className="text-gray-800 text-sm font-semibold">{number}</span>
    </div>
    <div className="flex items-center">
      <span className="text-gray-600 text-xs bg-gray-100 px-2 py-0.5 rounded mr-2">
        {location}
      </span>
      <span className="text-gray-400 text-xs">{price}</span>
    </div>
  </div>
);

const PhoneNumberComponent = () => {
  const backgroundNumbers = [
    { number: "(415) 968-1851", opacity: 0.7, top: "10%", left: "10%" },
    { number: "(628) 555-0113", opacity: 0.3, top: "20%", left: "30%" },
    { number: "(510) 555-0198", opacity: 0.4, top: "30%", left: "50%" },
    { number: "(650) 555-0123", opacity: 0.7, top: "40%", left: "70%" },
    { number: "(925) 555-0147", opacity: 0.3, top: "50%", left: "20%" },
    { number: "(707) 555-0176", opacity: 0.5, top: "60%", left: "40%" },
    { number: "(408) 555-0152", opacity: 0.3, top: "70%", left: "60%" },
    { number: "(669) 555-0194", opacity: 0.8, top: "80%", left: "80%" },
    { number: "(831) 555-0183", opacity: 0.5, top: "90%", left: "40%" },
    { number: "(209) 555-0121", opacity: 0.8, top: "30%", left: "80%" },
    { number: "(916) 555-0110", opacity: 0.4, top: "50%", left: "50%" },
    { number: "(415) 555-0178", opacity: 0.7, top: "10%", left: "70%" },
    { number: "(510) 555-0167", opacity: 0.8, top: "75%", left: "15%" },
  ];

  return (
    <div className="relative w-full h-full  p-8 font-sans overflow-hidden">
      {/* Background Layer */}
      <div className="absolute inset-0">
        {backgroundNumbers.map((item, index) => (
          <div
            key={index}
            className="text-gray-300 text-sm absolute"
            style={{
              opacity: item.opacity,
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              whiteSpace: "nowrap",
            }}
          >
            {item.number}
          </div>
        ))}
      </div>

      {/* Foreground Phone Cards */}
      <div className="relative flex flex-col items-center">
        <PhoneCard
          number="(415) 968-1851"
          location="Mill Valley, CA"
          price="$15/month"
          style={{ marginBottom: "-20px", zIndex: 3 }}
        />
        <PhoneCard
          number="(628) 555-0113"
          location="Oakland, CA"
          price="$15/month"
          style={{ marginBottom: "-20px", marginLeft: "12px", zIndex: 2 }}
        />
        <PhoneCard
          number="(510) 555-0198"
          location="Oakland, CA"
          price="$18/month"
          style={{ zIndex: 1, marginLeft: "18px" }}
        />
      </div>
    </div>
  );
};

const MAX_LABEL_WIDTH = 300;

const EditableLabel = ({ number, onUpdate, setLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(number.label);
  const [originalLabel, setOriginalLabel] = useState(number.label);

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const handleSubmit = async () => {
    setIsEditing(false);
    setLoading(true);
    if (label !== originalLabel) {
      try {
        const token = getAuthToken(); // Adjust this based on how you store the token
        const response = await axios.post(
          "/api/inbound/update_label",
          {
            phoneNumber: number.phone_number,
            newLabel: label,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );

        if (response.status === 200) {
          onUpdate(number.phone_number, label);
          toast.success("Label updated successfully");
          setIsEditing(false);
          setLoading(false);
          setOriginalLabel(label);
        } else {
          throw new Error(response.data.error || "Failed to update label");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error updating label:", error);
        toast.error(error.response?.data?.error || "Failed to update label");
        setLabel(originalLabel); // Revert to original label on error
      }
    } else {
      setLoading(false);
      setIsEditing(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return isEditing ? (
    <div
      className="flex items-center"
      style={{ width: `${MAX_LABEL_WIDTH}px` }}
    >
      <input
        type="text"
        value={label}
        onChange={handleLabelChange}
        onKeyDown={handleKeyDown}
        style={{ width: `${MAX_LABEL_WIDTH}px` }}
        className="px-2 py-1 border rounded mr-2"
        autoFocus
      />
      <AiOutlineEnter
        onClick={handleSubmit}
        className="cursor-pointer text-blue-500 hover:text-blue-700"
      />
    </div>
  ) : (
    <div className="flex items-center">
      <div
        onClick={() => setIsEditing(true)}
        className="cursor-pointer hover:bg-gray-100 px-2 py-1 rounded flex items-center"
        style={{ maxWidth: `${MAX_LABEL_WIDTH}px` }}
      >
        {label ? (
          <span
            className="mr-2 truncate"
            style={{ maxWidth: `${MAX_LABEL_WIDTH - 30}px` }}
            title={label}
          >
            {label}
          </span>
        ) : (
          <span className="mr-2 text-gray-400" fontStyle="italic">
            Add a label
          </span>
        )}

        <FiEdit2
          className="text-gray-400 hover:text-blue-500 flex-shrink-0"
          size={16}
        />
      </div>
    </div>
  );
};

export default function PhoneNumber() {
  const JSONmethods = useForm();
  const inboundMethods = useForm({
    defaultValues: {
      prompt: "",
      voice: "nat",
      pathway_id: null,
      interruption_threshold: 100,
      transfer_list: [],
      transfer_phone_number: null,
      first_sentence: null,
      max_duration: 30,
      model: "enhanced",
      record: false,
      ignore_button_press: false,
      temperature: null,
      dynamic_data: null,
      tools: null,
      webhook: null,
      pronunciation_guide: null,
      language: "en-US",
      analysis_schema: [],
      summary_prompt: null,
      webhook_events: null,
      keywords: null,
      noise_cancellation: false,
    },
  });

  const jsonMode = JSONmethods.watch("json_mode_enabled");

  const [paywall, setPaywall] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedNumber = searchParams.get("number")?.trimStart();

  const user = useMemo(() => getUserData(), []);

  const { inboundNumbers, twilioNumbers, loading, setLoading, refreshNumbers } =
    usePhoneNumbers();

  const [initialLoading, setInitialLoading] = useState(true);
  const [showingPurchaseScreen, setShowingPurchaseScreen] = useState(false);

  const [userNumbers, setUserNumbers] = useState([]);
  const [filteredNumbers, setFilteredNumbers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleBuyNewNumber = () => {
    setPaywall(true);
    setShowingPurchaseScreen(true);
  };

  const handleBackToListing = () => {
    setPaywall(false);
    setShowingPurchaseScreen(false);
  };

  const hasInboundNumber =
    inboundNumbers?.length > 0 || twilioNumbers?.length > 0;

  useEffect(() => {
    const inboundNumbersMarked = inboundNumbers.map((num) => ({
      ...num,
      isBYOT: false,
      id: uuidv4(),
    }));
    const twilioNumbersMarked = twilioNumbers.map((num) => ({
      ...num,
      isBYOT: true,
      id: uuidv4(),
    }));
    setUserNumbers([
      ...(inboundNumbersMarked || []),
      ...(twilioNumbersMarked || []),
    ]);
  }, [inboundNumbers, twilioNumbers]);

  const filterNumbers = useCallback((numbersToFilter, term) => {
    return numbersToFilter.filter(
      (number) =>
        number.phone_number?.toLowerCase().includes(term.toLowerCase()) ||
        number.location?.toLowerCase().includes(term.toLowerCase()) ||
        (number.label &&
          number.label.toLowerCase().includes(term.toLowerCase())),
    );
  }, []);

  useEffect(() => {
    const filtered = filterNumbers(userNumbers, searchTerm);
    setFilteredNumbers(filtered);
  }, [userNumbers, searchTerm, filterNumbers]);

  const handleLabelUpdate = useCallback((id, newLabel) => {
    setUserNumbers((prevNumbers) => {
      const updatedNumbers = prevNumbers.map((num) =>
        num.id === id ? { ...num, label: newLabel } : num,
      );
      return updatedNumbers;
    });
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      await refreshNumbers();
      setInitialLoading(false);
    };
    initializeData();
  }, [refreshNumbers]);

  const onSubmit = async (formData) => {
    const headers = {
      Authorization: user.api_key,
      encrypted_key: formData.encrypted_key || undefined,
    };

    const transferList = KeyValuesToObj(formData.transfer_list);
    let analysis_schema = {};
    const as = inboundMethods.getValues("analysis_schema");
    if (as && as.length > 0) {
      const adjAS = KeyValuesToObj(as);
      analysis_schema = adjAS || {};
    }

    console.log({ analysis_schema });

    const data = {
      ...formData,
      language: formData.language,
      transfer_list: transferList,
      analysis_schema: analysis_schema,
      prompt: formData.prompt,
      voice: formData.voice,
      pathway_id: formData.pathway_id,
      interruption_threshold: formData.interruption_threshold,
      first_sentence: formData.first_sentence || null,
      max_duration: formData.max_duration,
      model: formData.model,
      pronunciation_guide: formData.pronunciation_guide || null,
      webhook: formData.webhook,
      dynamic_data: formData.dynamic_data.map((item) => {
        const newItem = {};
        for (const key in item) {
          if (key === "response_data") {
            newItem[key] = item[key];
          } else {
            newItem[key] = Array.isArray(item[key])
              ? KeyValuesToObj(item[key])
              : item[key];
          }
        }
        return newItem;
      }),
    };

    delete data?.keyword_inputs;

    try {
      setSubmitting(true);
      const response = await axios.post(
        `${formData?.endpoint || "https://us.api.bland.ai"}/v1/inbound/${selectedNumber}`,
        data,
        {
          headers,
        },
      );
      if (response.data) {
        setSubmitting(false);
        toast.success("Success updating inbound number details!");
      }
    } catch (error) {
      toast.error(error.response.data.message.toString());
      setSubmitting(false);
    }
  };

  if (initialLoading) {
    return (
      <PageWrapper>
        <div className="flex justify-center items-center h-screen">
          <Loading loading={true} />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle>
          Phone Numbers
          {selectedNumber && (
            <span className="text-sm text-gray-500 ml-4">{selectedNumber}</span>
          )}
        </PageTitle>
        <div className="flex flex-row gap-5" style={{ marginLeft: "auto" }}>
          <FormProvider {...JSONmethods}>
            <form>
              <Switch fieldName="json_mode_enabled" label="JSON Mode" />
            </form>
          </FormProvider>
          {!showingPurchaseScreen && (
            <Button onClick={handleBuyNewNumber}>Buy New Number</Button>
          )}
        </div>
      </ActionBar>
      <div className="overflow-y-scroll min-h-screen w-full font-sans relative">
        {paywall && (
          <div>
            <div className="flex justify-between items-center mb-4">
              <Button onClick={handleBackToListing}>Back</Button>
            </div>
            <PurchaseNumber either setPaywall={setPaywall} />
          </div>
        )}
        <div className="w-full h-full flex">
          {!hasInboundNumber ? (
            <PurchaseNumber either />
          ) : (
            <>
              {!selectedNumber ? (
                <div className="w-5/6 mx-auto px-4 sm:px-6 lg:px-8 py-8">
                  <div className="flex flex-row w-full pb-12">
                    <div className="w-1/2">
                      <h1 className="text-3xl font-bold text-gray-900 mb-2">
                        Phone Numbers
                      </h1>
                      <p className="text-base text-gray-500 mb-6 w-1/2">
                        Purchase and configure a new inbound phone number.
                        ($15/mo. subscription using your stored payment method).
                      </p>
                      <Button
                        onClick={handleBuyNewNumber}
                        style={{ width: "35%" }}
                        className="bg-indigo-20 text-white px-6 py-2 w-1/4 rounded text-sm font-semibold mb-8 hover:bg-indigo-900 transition-colors inline-flex items-center"
                      >
                        PURCHASE NEW NUMBER
                        <FiChevronRight className="my-auto" />
                      </Button>
                    </div>
                    <div className="w-1/2">
                      <PhoneNumberComponent />
                    </div>
                  </div>

                  <div className="bg-white rounded  font-mono">
                    <div className="flex justify-between items-center p-4 border-b border-gray-200 pb-8">
                      <div className="flex justify-left gap-5 items-center">
                        <div className="relative">
                          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                          <input
                            type="text"
                            placeholder="SEARCH NUMBER"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 w-64 bg-gray-100 rounded text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          />
                        </div>
                        <button onClick={refreshNumbers}>
                          <FiRefreshCw className="mr-2" />
                        </button>
                        <Loading loading={loading} />
                      </div>
                      <div className="flex space-x-2">
                        <span className="text-xl">🇺🇸</span>
                        <span className="text-xl">🇨🇦</span>
                      </div>
                    </div>
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                          <th className="px-6 py-3">Number</th>
                          <th className="px-6 py-3">Label</th>
                          <th className="px-6 py-3">State</th>
                          <th className="px-6 py-3">Status</th>
                          <th className="px-6 py-3">Purchased Date</th>
                          <th className="px-6 py-3">Configuration</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {filteredNumbers.map((number) => (
                          <tr key={number.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {number.phone_number}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <EditableLabel
                                number={number}
                                onUpdate={handleLabelUpdate}
                                setLoading={setLoading}
                              />
                              {number.isBYOT && (
                                <img
                                  title="Twilio (BYOT Number)"
                                  alt="Twilio"
                                  src={TwilioCircle}
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                />
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {number.location}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 py-2 inline-flex text-xs leading-5 font-semibold bg-green-100 text-green-800">
                                ACTIVE
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {new Date(number.created_at).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                },
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <button
                                onClick={() =>
                                  navigate(
                                    `?page=phone-numbers&number=${encodeURIComponent(number.phone_number)}`,
                                  )
                                }
                                className={`px-3 py-1 rounded text-xs font-medium ${
                                  number.isBYOT
                                    ? "text-indigo-600 hover:bg-indigo-50"
                                    : "text-blue-600 hover:bg-blue-50"
                                } border border-gray-300 inline-flex items-center`}
                              >
                                {number.isBYOT ? "CONFIGURE" : "USE NUMBER"}
                                <FiChevronRight className="ml-1" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <Tabs
                  fixed
                  tabs={[
                    {
                      item: "Inbound",
                      panel: (
                        <FormProvider {...inboundMethods}>
                          <form
                            onSubmit={inboundMethods.handleSubmit(onSubmit)}
                          >
                            {jsonMode ? (
                              <JSONMode submitting={submitting} />
                            ) : (
                              <Inbound
                                selectedNumber={selectedNumber}
                                apiKey={user?.api_key}
                                hasInbound={hasInboundNumber}
                                submitting={submitting}
                              />
                            )}
                          </form>
                        </FormProvider>
                      ),
                    },
                  ]}
                />
              )}
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}
