import { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DataCard, KeyValue, KeyValues } from "./Styles";
import { motion } from "framer-motion";
import { Button } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import SlideOut from "./SlideOut";
import LoadSpinner from "components/core/LoadSpinner";

// export default function Metadata({ calls, modal }) {
//   const [searchParams] = useSearchParams();
//   const callId = searchParams.get("callId");
//   const [call, setCall] = useState();
//   const [metadataLength, setMetadataLength] = useState([]);

//   useEffect(() => {
//     if (calls) {
//       const matchedCall = calls?.find((call) => call.c_id === callId);
//       setCall(matchedCall);
//       const length = Object.keys(matchedCall?.metadata ?? {})?.length;
//       setMetadataLength(length);
//     }
//   }, [callId, calls]);

//   useEffect(() => {
//     console.log("matched call", call);
//   }, [call]);

//   const renderMetadata = () => {
//     const parsedMetadata = call?.metadata || {};
//     return (
//       <KeyValues>
//         {Object.entries(parsedMetadata).map(([key, value]) => (
//           <KeyValue key={key}>
//             <p>{key}:</p>
//             <p>{typeof value === "object" ? JSON.stringify(value) : value}</p>
//           </KeyValue>
//         ))}
//       </KeyValues>
//     );
//   };
//   return (
//     <>
//       {metadataLength > 0 && (
//         <DataCard modal={modal}>
//           <h2 className="font-bold">Metadata</h2>
//           <div>{renderMetadata()}</div>
//         </DataCard>
//       )}
//     </>
//   );
// }

const Metadata = ({
  isOpen,
  onClose,
  callIds,
  initialCallId,
  setFocusedCId,
  focusedCall,
  loading = false,
  modalOpen = false,
  notesOpen = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNavigation = useCallback(
    (direction) => {
      setCurrentIndex((prevIndex) => {
        const newIndex =
          direction === "next"
            ? Math.min(prevIndex + 1, callIds?.length - 1)
            : Math.max(prevIndex - 1, 0);
        const newCallId = callIds[newIndex];
        setFocusedCId(newCallId);
        return newIndex;
      });
    },
    [callIds, setFocusedCId],
  );

  // Set initial state when the modal is opened
  useEffect(() => {
    if (isOpen && initialCallId) {
      const index = callIds?.indexOf(initialCallId);
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [isOpen, initialCallId, callIds]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "j") {
        handleNavigation("next");
      } else if (event.key === "k") {
        handleNavigation("prev");
      }
    };

    if (!modalOpen && !notesOpen) {
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleNavigation, modalOpen, notesOpen]);

  const renderMetadata = () => {
    const parsedMetadata = focusedCall?.metadata || {};
    return (
      <KeyValues>
        {Object.entries(parsedMetadata).map(([key, value]) => (
          <KeyValue key={key}>
            <p>{key}:</p>
            <p>{typeof value === "object" ? JSON.stringify(value) : value}</p>
          </KeyValue>
        ))}
      </KeyValues>
    );
  };

  const metadataLength = Object.keys(focusedCall?.metadata || {}).length;

  return (
    <SlideOut
      isOpen={isOpen}
      onClose={onClose}
      title={`Metadata ${currentIndex + 1} of ${callIds?.length}`}
      subtitle={initialCallId ? `Call ID: ${initialCallId}` : null}
      footer={
        <>
          <div className="flex justify-between mb-4">
            <div>
              <button
                onClick={() => handleNavigation("prev")}
                disabled={currentIndex === 0}
                className={`flex items-center px-3 py-2 bg-gray-100 text-sm text-gray-700 rounded ${
                  currentIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                <ChevronLeftIcon className="h-4 w-4 mr-1" />
                Previous
              </button>
            </div>
            <div>
              <button
                onClick={() => handleNavigation("next")}
                disabled={currentIndex === callIds?.length - 1}
                style={{ marginLeft: "auto" }}
                className={`flex items-center text-sm px-3 py-2 bg-gray-100 text-gray-700 rounded ${
                  currentIndex === callIds?.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                Next
                <ChevronRightIcon className="h-4 w-4 ml-1" />
              </button>
            </div>
          </div>
          <Button
            type="button"
            style={{ width: "100%" }}
            color="lightblue"
            variant="solid"
            onClick={() =>
              window.open("https://app.bland.ai/enterprise", "_blank")
            }
            className="w-full bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            <p style={{ fontSize: 13 }}>Interested in Fine-Tuning?</p>
          </Button>
          <p style={{ fontSize: 10 }} className="mt-2 text-xs text-gray-500">
            We're constantly improving. For a fine-tuned model tailored to your
            needs, reach out to our team.
          </p>
        </>
      }
    >
      {loading ? (
        <LoadSpinner loading={loading} />
      ) : (
        <motion.p
          className="text-gray-800"
          style={{ fontSize: 18 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {metadataLength > 0 ? renderMetadata() : "No metadata available."}
        </motion.p>
      )}
    </SlideOut>
  );
};

export default Metadata;
