import {
  Badge,
  Box,
  Button,
  Card,
  DropdownMenu,
  Flex,
  IconButton,
  Popover,
  Spinner,
  Tabs,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useCallback, useState, useRef, useEffect, Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import PulsingLight from "assets/gifs/PulsingLight.gif";
import styled from "styled-components";
import * as Icons from "@radix-ui/react-icons";
import { PageTitle } from "components/core/PageTitle";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { getUserData } from "utils/funcs/browser/getUserData";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function secondsSince(timestamp) {
  // Parse the timestamp into a Date object
  const givenDate = new Date(timestamp);

  // Get the current date and time
  const now = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = now - givenDate;

  // Convert milliseconds to seconds
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

  return differenceInSeconds;
}

const getLocalDateTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};

function getTimeElapsedSince(timestamp) {
  const start = new Date(timestamp);
  const now = new Date();

  // Calculate elapsed time in seconds
  const elapsedSeconds = Math.floor((now - start) / 1000);

  // Calculate minutes and seconds
  const minutes = Math.floor(elapsedSeconds / 60);
  const seconds = elapsedSeconds % 60;

  // Format the output MM:SS
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

function format12HourTime(timestamp) {
  // Parse the timestamp into a Date object
  const date = new Date(timestamp);

  // Get the local time components
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes and seconds to always be two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  // Construct the formatted time string
  const formattedTime = `${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

  return formattedTime;
}

export const LiveCallsTable = ({ callId }) => {
  const { user } = getUserData();
  const apiKey = getApiKey();
  const [showPopout, setShowPopout] = useState(false);
  const listening = true;
  const ws1 = useRef(null);
  const ws2 = useRef(null);
  const [events, setEvents] = useState([]);
  const [transcripts, setTranscripts] = useState([]);
  const [call_id, setCall_id] = useState(null);
  const [filters, setFilters] = useState({
    to: {
      enabled: false,
      value: "",
    },
    from: {
      enabled: false,
      value: "",
    },
  });
  const [stoppingCalls, setStoppingCalls] = useState({});

  const logStateChange = useCallback((event) => {
    console.log(`WebSocket event: ${event.type}`);
  }, []);

  useEffect(() => {
    if (listening) {
      console.log("Initializing WebSocket connection for events...");
      ws1.current = new WebSocket(
        `wss://temp-repo-deployment-production.up.railway.app/api/realtime/calls?user_id=${user?.id}&api_key=${apiKey}`,
      );
      //ws1.current = new WebSocket(`ws://localhost:8000/api/realtime/calls?user_id=${user?.id}&api_key=${apiKey}`);

      // WebSocket event listeners for ws1
      ws1.current.onopen = () => {
        console.log("WebSocket 1 is open now.");
      };

      ws1.current.onmessage = (event) => {
        try {
          let json = JSON.parse(event.data);
          if (Array.isArray(json) && json.length > 0) {
            setEvents([...events, ...json]);
          } else if (Array.isArray(json) && json.length === 0) {
            setEvents([]);
          }
        } catch (error) {
          console.error("Error parsing JSON from WebSocket 1:", error);
        }
      };

      ws1.current.onerror = (error) => {
        console.error("WebSocket 1 error observed:", error);
      };

      ws1.current.onclose = (event) => {
        console.log("WebSocket 1 is closed now.", event);
        setEvents([]);
      };

      ws1.current.addEventListener("open", logStateChange);
      ws1.current.addEventListener("close", logStateChange);
      ws1.current.addEventListener("error", logStateChange);
    }

    return () => {
      if (ws1.current) {
        ws1.current.removeEventListener("open", logStateChange);
        ws1.current.removeEventListener("close", logStateChange);
        ws1.current.removeEventListener("error", logStateChange);
        ws1.current.close();
      }
    };
  }, [listening, logStateChange]);

  useEffect(() => {
    if (showPopout && call_id) {
      console.log("Initializing WebSocket connection for transcripts...");
      ws2.current = new WebSocket(
        `wss://temp-repo-deployment-production.up.railway.app/api/realtime/transcripts?call_id=${call_id}&api_key=${apiKey}`,
      );
      //ws2.current = new WebSocket(`ws://localhost:8000/api/realtime/transcripts?call_id=${call_id}&api_key=${apiKey}`);

      // WebSocket event listeners for ws2
      ws2.current.onopen = () => {
        console.log("WebSocket 2 is open now.");
      };

      ws2.current.onmessage = (event) => {
        try {
          let json = JSON.parse(event.data);
          if (Array.isArray(json) && json.length > 0) {
            setTranscripts(json);
          }
        } catch (error) {
          console.error("Error parsing JSON from WebSocket 2:", error);
        }
      };

      ws2.current.onerror = (error) => {
        console.error("WebSocket 2 error observed:", error);
      };

      ws2.current.onclose = (event) => {
        console.log("WebSocket 2 is closed now.", event);
      };

      ws2.current.addEventListener("open", logStateChange);
      ws2.current.addEventListener("close", logStateChange);
      ws2.current.addEventListener("error", logStateChange);

      return () => {
        if (ws2.current) {
          ws2.current.removeEventListener("open", logStateChange);
          ws2.current.removeEventListener("close", logStateChange);
          ws2.current.removeEventListener("error", logStateChange);
          ws2.current.close();
        }
      };
    }
  }, [showPopout, call_id, logStateChange]);

  // Function to apply filters
  const applyFilters = (data, filters) => {
    return data.filter((event) => {
      let matchesTo = true;
      let matchesFrom = true;

      if (filters.to.enabled) {
        matchesTo = event.to.includes(filters.to.value);
      }

      if (filters.from.enabled) {
        matchesFrom = event.from.includes(filters.from.value);
      }

      return matchesTo && matchesFrom;
    });
  };

  // Use the filtered data in your component
  const filteredEvents = applyFilters(events, filters);

  const handleStopCall = async (callId) => {
    setStoppingCalls((prev) => ({ ...prev, [callId]: true }));
    try {
      const response = await axios.post(
        `https://api.bland.ai/v1/calls/${callId}/stop`,
        {},
        {
          headers: { authorization: apiKey },
        },
      );

      if (response.status === 200) {
        toast.success("Call stopped successfully");
      } else {
        toast.error("Failed to stop call");
      }
    } catch (error) {
      console.error("Error stopping call:", error);
      toast.error("Error stopping call: " + error.message);
    } finally {
      setStoppingCalls((prev) => ({ ...prev, [callId]: false }));
    }
  };

  return (
    <Wrapper>
      <ToastContainer position="top-right" autoClose={3000} />
      <MainContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            marginTop: 0,
          }}
        >
          <Card
            style={{
              width: "40%",
              maxWidth: "400px",
              minWidth: "200px",
              padding: "20px",
              marginTop: 7.5,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <PageTitle
                  style={{ fontWeight: "500", color: "grey", fontSize: 10 }}
                >
                  Calls In Progress
                </PageTitle>
                <PageTitle
                  style={{ marginTop: 10, fontWeight: "400", fontSize: 22 }}
                >
                  {events?.length}
                </PageTitle>
              </div>

              {ws1.current?.readyState === WebSocket.OPEN && (
                <div style={{ marginLeft: "auto" }}>
                  <img src={PulsingLight} height={20} width={20} />
                </div>
              )}
            </div>
          </Card>
          <Flex gap="2" align="center" style={{ marginTop: 7.5 }}>
            <Popover.Root>
              <Popover.Trigger>
                <Button disabled={events?.length === 0} size="1" variant="soft">
                  <Icons.PlusCircledIcon height={12} width={12} />
                  Phone Number
                </Button>
              </Popover.Trigger>
              <Popover.Content width={"360px"}>
                <p style={{ fontWeight: "500", marginBottom: 10 }}>
                  Filter by Phone Number
                </p>
                <Flex gap={"3"}>
                  <Box flexGrow={"1"}>
                    <div>
                      <p
                        style={{
                          color: "#000",
                          fontSize: 10,
                          fontWeight: "400",
                          marginBottom: 5,
                        }}
                      >
                        To Number
                      </p>
                      <TextField.Root
                        value={filters.to.value}
                        onChange={(event) =>
                          setFilters({
                            ...filters,
                            to: { ...filters.to, value: event.target.value },
                          })
                        }
                        id="to"
                        placeholder="+13213334600"
                      />
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <p
                        style={{
                          color: "#000",
                          fontSize: 10,
                          fontWeight: "400",
                          marginBottom: 5,
                        }}
                      >
                        From Number
                      </p>
                      <TextField.Root
                        value={filters.from.value}
                        onChange={(event) =>
                          setFilters({
                            ...filters,
                            from: {
                              ...filters.from,
                              value: event.target.value,
                            },
                          })
                        }
                        id="from"
                        placeholder="+13213334600"
                      />
                    </div>

                    <div
                      style={{ marginTop: 15, width: "100%", display: "flex" }}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          const to = document.getElementById("to")?.value;
                          const from = document.getElementById("from")?.value;
                          setFilters({
                            ...filters,
                            to: {
                              ...filters.to,
                              enabled: to?.length > 0 ? true : false,
                            },
                            from: {
                              ...filters.from,
                              enabled: from?.length > 0 ? true : false,
                            },
                          });
                        }}
                        size={"1"}
                        variant={"solid"}
                        style={{ marginLeft: "auto" }}
                      >
                        Save
                      </Button>
                    </div>
                  </Box>
                </Flex>
              </Popover.Content>
            </Popover.Root>

            {filters.to.value && filters.to.enabled && (
              <Badge variant="surface" size={"1"}>
                <Icons.CrossCircledIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      to: { value: "", enabled: false },
                    });
                  }}
                  height={12}
                  width={12}
                />
                To: {filters.to.value}
              </Badge>
            )}

            {filters.from.value && filters.from.enabled && (
              <Badge variant="surface" size={"1"}>
                <Icons.CrossCircledIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      from: { value: "", enabled: false },
                    });
                  }}
                  height={12}
                  width={12}
                />
                From: {filters.from.value}
              </Badge>
            )}
          </Flex>

          <DataGrid
            rows={filteredEvents?.map((e, k) => {
              return {
                ...e,
                duration: getTimeElapsedSince(e?.created_at) || 0,
                call_length: secondsSince(e?.created_at) || 0,
                created_at: getLocalDateTimeFromTimestamp(e.created_at),
                // transript: e,
                transferred_to: e?.transferred_to || "None",
                id: k,
              };
            })}
            autoHeight
            columns={[
              {
                field: "id",
                headerName: "",
                width: 5,
                sortable: false,
                filterable: false,
                renderCell: (params, event) => (
                  <div
                    style={{
                      display: "flex",
                      zIndex: 20000,
                      justifyContent: "left",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        const callId = params?.row?.c_id;
                        setTranscripts([]);
                        setCall_id(callId);
                        setShowPopout(true);
                      }}
                      size={"1"}
                      color="gray"
                      variant="outline"
                    >
                      <Icons.OpenInNewWindowIcon width={14} height={14} />
                    </IconButton>
                  </div>
                ),
              },
              {
                field: "c_id",
                headerName: "Call ID",
                flex: 1,
                sortable: false,
              },
              {
                field: "duration",
                headerName: "Duration",
                flex: 1,
              },
              {
                field: "queue_status",
                headerName: "Status",
                flex: 1,
              },
              {
                field: "inbound",
                headerName: "Inbound",
                flex: 1,
              },
              {
                field: "to",
                headerName: "To",
                flex: 1,
              },
              {
                field: "from",
                headerName: "From",
                flex: 1,
              },
              {
                field: "transferred_to",
                headerName: "Transferred To",
                flex: 1,
              },
              // {
              //     field: "transcript",
              //     headerName: "Transcripts",
              //     renderCell: (params) => (
              //         <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '100%', width: '100%' }}>
              //             <Button onClick={(e) => {
              //                 e.stopPropagation()
              //                 const callData = params.row;
              //             }} variant={"soft"} size={"1"} style={{ cursor: 'pointer' }}>View Transcripts</Button>
              //         </div>
              //     ),
              //     width: 150,
              //     sortable: false,
              // },
              {
                field: "created_at",
                headerName: "Created At",
                flex: 1,
                sortable: false,
              },
            ]}
            pageSize={10}
            // initialState={{
            //     pagination: {
            //         paginationModel: {
            //             pageSize: events?.length > 100 ? 15 : 10
            //         }
            //     }
            // }}
            rowHeight={42}
            checkboxSelection={false}
            isRowSelectable={false}
            disableMultipleRowSelection={true}
            // onRowClick={(params, e) => {
            //     e.stopPropagation();
            //     e.preventDefault();
            //     const callId = params?.row?.c_id;
            //     setTranscripts([]);
            //     setCall_id(callId);
            //     setShowPopout(true);
            // }}
            sx={{
              "& .MuiCheckbox-root svg": {
                width: 12,
                height: 12,
                backgroundColor: "transparent",
                border: `1px solid #d9d9d9`,
                borderRadius: 0.75,
              },
              "& .MuiCheckbox-root svg path": {
                display: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                {
                  outline: "none !important",
                },
              "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                outline: "none !important",
                background: "transparent !important",
              },
              "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
                {
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                },
              "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
                position: "absolute",
                display: "table",
                border: "2px solid #fff",
                borderTop: 0,
                borderLeft: 0,
                transform: "rotate(45deg) translate(-50%,-50%)",
                opacity: 1,
                transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
                content: '""',
                top: "50%",
                left: "39%",
                width: 5.71428571,
                height: 9.14285714,
              },
              "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
                {
                  width: 8,
                  height: 8,
                  backgroundColor: "#1890ff",
                  transform: "none",
                  top: "39%",
                  border: 0,
                },
              letterSpacing: "normal",
              "& .MuiDataGrid-columnsContainer": {
                backgroundColor: "#fafafa",
              },
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                borderRight: `1px solid #f0f0f0`,
              },
              "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                borderBottom: `1px solid #f0f0f0`,
              },
              "& .MuiDataGrid-cell": {
                color: "rgba(0,0,0,.85)",
              },
              "& .MuiPaginationItem-root": {
                borderRadius: 0,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 600,
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                fontSize: "10px",
                fontWeight: "250",
              },
              marginTop: "10px",
              width: "100%",
            }}
            headerHeight={35}
            loading={ws1.current?.readyState !== WebSocket.OPEN}
          />

          <p style={{ marginTop: 10, color: "gray", fontSize: 10 }}>
            Call data is being streamed from the server. Sometimes the data may
            not be available immediately, or the data may be delayed.
          </p>
        </div>
      </MainContent>

      {showPopout && (
        <SidePopout>
          <div style={{ marginTop: "110px" }}>
            <div style={{ position: "absolute", right: 10, marginTop: 7.5 }}>
              <IconButton
                onClick={() => {
                  setShowPopout(false);
                  setCall_id(null);
                }}
                style={{ cursor: "pointer" }}
                variant="soft"
                size={"1"}
              >
                <Icons.Cross1Icon />
              </IconButton>
            </div>
            <Tabs.Root defaultValue="transcripts">
              <Tabs.List>
                <Tabs.Trigger value="transcripts">Transcripts</Tabs.Trigger>
                <Tabs.Trigger value="variables">Variables</Tabs.Trigger>
              </Tabs.List>

              <Box pt="3" style={{ padding: "10px", marginTop: "10px" }}>
                <Tabs.Content
                  value="transcripts"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <Button
                    onClick={() => handleStopCall(call_id)}
                    disabled={stoppingCalls[call_id]}
                    size="2"
                    variant="solid"
                    color="red"
                  >
                    {stoppingCalls[call_id] ? "Stopping..." : "Stop Call"}
                  </Button>

                  {transcripts.length > 0 ? (
                    <>
                      {transcripts?.map((event, ind) => (
                        <div
                          key={ind}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Badge
                            size={"1"}
                            style={{ position: "absolute" }}
                            color={
                              event?.user === "assistant" ? "gray" : "iris"
                            }
                            variant="surface"
                          >
                            <p style={{ fontSize: 10 }}>{event?.user}</p>
                          </Badge>

                          <p
                            style={{
                              marginLeft: 100,
                              maxWidth: "55%",
                              fontSize: 10,
                            }}
                          >
                            {event?.text}
                          </p>
                          <p
                            style={{
                              marginLeft: "auto",
                              textAlign: "right",
                              color: "gray",
                              fontSize: 8,
                            }}
                          >
                            {format12HourTime(event?.created_at)}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: 15,
                      }}
                    >
                      <Spinner />
                      <p>Waiting for Transcripts</p>
                    </div>
                  )}
                </Tabs.Content>

                <Tabs.Content value="variables">
                  <KeyValueGrid
                    data={
                      JSON.parse(
                        events?.find((doc) => doc?.c_id === call_id)
                          ?.variables || "{}",
                      ) || {}
                    }
                  />
                </Tabs.Content>
              </Box>
            </Tabs.Root>
          </div>
        </SidePopout>
      )}
    </Wrapper>
  );
};

const KeyValueGrid = ({ data }) => {
  return (
    <GridContainer>
      {Object.keys(data).map((key, val) => (
        <Fragment key={val}>
          <GridItem style={{ fontWeight: "500", fontSize: 12 }}>{key}</GridItem>
          <GridItem style={{ fontWeight: "350", fontSize: 12 }}>
            {data[key]}
          </GridItem>
        </Fragment>
      ))}
    </GridContainer>
  );
};

// Styled components for the grid and cells
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
`;

const GridItem = styled.div`
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 0px;
  background-color: #fff;
  text-align: left;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
  width: 100%;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 0px;
  box-sizing: border-box;
  transition: margin-right 0.3s ease;

  @media (max-width: 600px) {
    margin-right: 100px; /* Adjust based on SidePopout width */
  }

  @media (max-width: 400px) {
    margin-right: 50px; /* Adjust based on SidePopout width */
  }

  @media (max-width: 300px) {
    margin-right: 0; /* Adjust based on SidePopout width */
  }
`;

const SidePopout = styled.div`
  position: fixed;
  overflow-y: scroll;
  height: calc(100% + 54px);
  width: 40%;
  right: 0;
  max-width: 400px;
  top: -54px;
  border-left: 1px solid #eeeeee;
  background: #fff;
  transition: width 0.3s ease;
  zindex: 1000;

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 900px) {
    width: 20%;
  }

  @media (max-width: 600px) {
    width: 15%;
  }

  @media (max-width: 400px) {
    width: 10%;
  }

  @media (max-width: 300px) {
    display: none; /* Hide SidePopout on very small screens */
  }
`;
