import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  fetchTranscript,
  fetchAndUpdateTranscriptStatus,
} from "utils/funcs/call-logs/fetchTranscript";
import { getUserData } from "utils/funcs/browser/getUserData";
import { Button } from "@radix-ui/themes";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import SlideOut from "./SlideOut";
import LoadSpinner from "components/core/LoadSpinner";

const TranscriptSlideOut = ({
  isOpen,
  onClose,
  callIds,
  initialCallId,
  setFocusedCId,
  modalOpen = false,
  notesOpen = false,
}) => {
  const [currentTranscript, setCurrentTranscript] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentCallId, setCurrentCallId] = useState(null);
  const { user } = getUserData();

  const loadTranscript = useCallback(
    async (callId) => {
      if (!callId) return;
      setLoading(true);
      try {
        const data = await fetchTranscript(callId, user.id);
        setCurrentTranscript({
          callId: callId,
          transcript: data.transcript || [],
        });
      } catch (error) {
        console.error("Error fetching transcript:", error);
        setCurrentTranscript({ callId: callId, transcript: [] });
      } finally {
        setLoading(false);
      }
    },
    [user.id],
  );

  const handleNavigation = useCallback(
    (direction) => {
      setCurrentIndex((prevIndex) => {
        const newIndex =
          direction === "next"
            ? Math.min(prevIndex + 1, callIds?.length - 1)
            : Math.max(prevIndex - 1, 0);
        const newCallId = callIds[newIndex];
        setCurrentCallId(newCallId);
        setFocusedCId(newCallId);
        return newIndex;
      });
    },
    [callIds, setFocusedCId],
  );

  useEffect(() => {
    if (isOpen && initialCallId) {
      const index = callIds?.indexOf(initialCallId);
      if (index !== -1) {
        setCurrentIndex(index);
        setCurrentCallId(initialCallId);
        setFocusedCId(initialCallId);
      }
    }
  }, [isOpen, initialCallId, callIds, setFocusedCId]);

  useEffect(() => {
    if (isOpen && currentCallId) {
      loadTranscript(currentCallId);
    }
  }, [isOpen, currentCallId, loadTranscript]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentTranscript(null);
      setCurrentCallId(null);
      setLoading(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentTranscript && currentTranscript.callId !== currentCallId) {
      setCurrentTranscript(null);
      loadTranscript(currentCallId);
    }
  }, [currentCallId, currentTranscript, loadTranscript]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "j") {
        handleNavigation("next");
      } else if (event.key === "k") {
        handleNavigation("prev");
      }
    };

    if (!modalOpen) {
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleNavigation, modalOpen]);

  const handleSetFeedbackStatus = async (txId, status) => {
    if (!currentTranscript) return;

    setCurrentTranscript((prevTranscript) => ({
      ...prevTranscript,
      transcript: prevTranscript.transcript.map((item) => {
        if (item.id === txId) {
          return { ...item, status: item.status === status ? null : status };
        } else {
          return item;
        }
      }),
    }));

    if (status !== "THUMB_UP" && status !== "THUMB_DOWN") return;

    let s =
      currentTranscript.transcript.find((doc) => doc.id === txId).status ===
      status
        ? null
        : status;

    await fetchAndUpdateTranscriptStatus(currentTranscript.callId, txId, s);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "j") {
        handleNavigation("next");
      } else if (event.key === "k") {
        handleNavigation("prev");
      }
    };

    if (!modalOpen && !notesOpen) {
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [handleNavigation, modalOpen, notesOpen]);

  return (
    <SlideOut
      isOpen={isOpen}
      onClose={onClose}
      title={`Transcript ${currentIndex + 1} of ${callIds?.length}`}
      subtitle={currentCallId ? `Call ID: ${currentCallId}` : null}
      footer={
        <>
          <div className="flex justify-between mb-4">
            <div>
              <button
                onClick={() => handleNavigation("prev")}
                disabled={currentIndex === 0}
                className={`flex items-center px-3 py-2 bg-gray-100 text-sm text-gray-700 rounded ${
                  currentIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                <ChevronLeftIcon className="h-4 w-4 mr-1" />
                Previous
              </button>
            </div>
            <div>
              <button
                onClick={() => handleNavigation("next")}
                disabled={currentIndex === callIds?.length - 1}
                style={{ marginLeft: "auto" }}
                className={`flex items-center text-sm px-3 py-2 bg-gray-100 text-gray-700 rounded ${
                  currentIndex === callIds?.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                Next
                <ChevronRightIcon className="h-4 w-4 ml-1" />
              </button>
            </div>
          </div>
          <Button
            type="button"
            style={{ width: "100%" }}
            color="lightblue"
            variant="solid"
            onClick={() =>
              window.open("https://app.bland.ai/enterprise", "_blank")
            }
            className="w-full bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            <p style={{ fontSize: 13 }}>Interested in Fine-Tuning?</p>
          </Button>
          <p style={{ fontSize: 10 }} className="mt-2 text-xs text-gray-500">
            We're constantly improving. For a fine-tuned model tailored to your
            needs, reach out to our team.
          </p>
        </>
      }
    >
      {loading ? (
        <LoadSpinner loading={loading} />
      ) : currentTranscript && currentTranscript?.transcript?.length > 0 ? (
        <motion.div
          className="space-y-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {currentTranscript.transcript.map((entry, index) => (
            <motion.div
              key={index}
              className={`flex ${
                entry.user.toLowerCase() === "user"
                  ? "justify-start"
                  : "justify-end"
              }`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05 }}
            >
              <div
                className={`max-w-[85%] ${
                  entry.user.toLowerCase() === "user"
                    ? "bg-gray-100"
                    : "bg-blue-50"
                } rounded-lg p-3 shadow-sm`}
              >
                {entry.user.toLowerCase() !== "user" && (
                  <div className="flex items-center mb-1">
                    <span className="bg-blue-100 text-blue-800 text-sm font-medium px-2 py-0.5 rounded">
                      Bland
                    </span>
                  </div>
                )}
                <p className="text-sm text-gray-800" style={{ fontSize: 13 }}>
                  {entry.text}
                </p>
                {entry.user.toLowerCase() !== "user" && (
                  <div className="flex items-center mt-2 space-x-2">
                    <button
                      onClick={() =>
                        handleSetFeedbackStatus(entry.id, "THUMB_UP")
                      }
                      className="focus:outline-none"
                    >
                      <ThumbsUp
                        size={14}
                        color={
                          entry.status === "THUMB_UP" ? "#3b82f6" : "#9ca3af"
                        }
                      />
                    </button>
                    <button
                      onClick={() =>
                        handleSetFeedbackStatus(entry.id, "THUMB_DOWN")
                      }
                      className="focus:outline-none"
                    >
                      <ThumbsDown
                        size={14}
                        color={
                          entry.status === "THUMB_DOWN" ? "#ef4444" : "#9ca3af"
                        }
                      />
                    </button>
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <motion.p
          className="text-center text-gray-500 text-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          No transcript available.
        </motion.p>
      )}
    </SlideOut>
  );
};

export default TranscriptSlideOut;
