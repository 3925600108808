import React, { useEffect, useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getUserData } from "utils/funcs/browser/getUserData";
import { deleteInbound } from "utils/funcs/byot/deleteInbound";
import { getInbound } from "utils/funcs/byot/getInbound";
import { insertInbound } from "utils/funcs/byot/insertInbound";
import { toast } from "react-toastify";
import ConfigurationSection from "./BYOTConfig";
import keyDecryptionAnimation from "assets/lottie/key-lock.json";
import fetchingNumbersAnimation from "assets/lottie/fetching-numbers.json";
import importingNumbersAnimation from "assets/lottie/importing-numbers.json";
import Lottie from "react-lottie-player";

const Trash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="3 6 5 6 21 6"></polyline>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
  </svg>
);

const Copy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
  </svg>
);

const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const LoadingAnimation = ({ message, variant }) => {
  const commonClasses =
    "fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50";

  const animations = {
    keys: keyDecryptionAnimation,
    numbers: fetchingNumbersAnimation,
    import: importingNumbersAnimation,
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={commonClasses}
    >
      <div className="text-center">
        <Lottie
          loop
          animationData={animations[variant]}
          play
          style={{ width: 250, height: 250 }}
        />
        <p className="mt-4 text-white text-lg font-semibold">{message}</p>
      </div>
    </motion.div>
  );
};

const TwilioNumbers = ({ onSwitchTab }) => {
  const { api_key } = getUserData();
  const [encryptedKeys, setEncryptedKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingVariant, setLoadingVariant] = useState("keys");
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const NUMBERS_PER_PAGE = 20;

  const fetchEncryptedKeys = useCallback(async () => {
    setLoading(true);
    setLoadingVariant("keys");
    setError(null);
    try {
      const response = await fetch("/api/byot/get_keys", {
        method: "GET",
        headers: { authorization: api_key },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to fetch encrypted keys: ${errorData.message || response.statusText}`,
        );
      }
      const data = await response.json();
      setEncryptedKeys(data.map((item) => item.id));
    } catch (err) {
      setError(
        `Failed to fetch encrypted keys. Please try again. Error: ${err.message}`,
      );
      console.error("Failed to fetch keys:", err);
    } finally {
      setLoading(false);
    }
  }, [api_key]);

  useEffect(() => {
    fetchEncryptedKeys();
  }, [fetchEncryptedKeys]);

  const fetchNumbers = useCallback(
    async (reset = false) => {
      if (!selectedKey) return;
      setLoading(true);
      setLoadingVariant("numbers");
      setError(null);
      try {
        const { inbound_numbers } = await getInbound(api_key, selectedKey);
        const resp = await fetch("/api/byot/get_twilio", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: api_key,
          },
          body: JSON.stringify({ encrypted_key: selectedKey }),
        });
        if (!resp.ok) {
          const errorData = await resp.json();
          throw new Error(
            `Failed to fetch Twilio numbers: ${errorData.message || resp.statusText}`,
          );
        }
        const { data } = await resp.json();
        const twilioNumbers = data.map((item) => item.phoneNumber);
        const importedNumbers = inbound_numbers.map(
          (item) => item.phone_number,
        );
        const combinedNumbers = twilioNumbers
          .map((num) => ({
            number: num,
            imported: importedNumbers.includes(num),
          }))
          .sort((a, b) =>
            a.imported === b.imported ? 0 : a.imported ? -1 : 1,
          );

        if (reset) {
          setNumbers(combinedNumbers.slice(0, NUMBERS_PER_PAGE));
          setPage(1);
        } else {
          setNumbers((prevNumbers) => [
            ...prevNumbers,
            ...combinedNumbers.slice(
              prevNumbers.length,
              prevNumbers.length + NUMBERS_PER_PAGE,
            ),
          ]);
        }
        setHasMore(combinedNumbers.length > numbers.length + NUMBERS_PER_PAGE);
      } catch (err) {
        setError(
          `Failed to fetch some Twilio numbers. Please try again later.`,
        );
        console.error("Failed to fetch numbers:", err);
      } finally {
        setLoading(false);
      }
    },
    [api_key, selectedKey, numbers.length],
  );

  useEffect(() => {
    if (selectedKey) fetchNumbers(true);
  }, [selectedKey, fetchNumbers]);

  const handleDelete = async (number) => {
    setLoading(true);
    setLoadingVariant("import");
    setError(null);
    try {
      const data = await deleteInbound(api_key, selectedKey, number);
      if (data?.success) {
        setNumbers(
          numbers.map((num) =>
            num.number === number ? { ...num, imported: false } : num,
          ),
        );
        toast.success(`Successfully deleted ${number}`);
      } else {
        throw new Error(data?.message || "Failed to delete number");
      }
    } catch (err) {
      setError(
        `Failed to delete ${number}. Please try again. Error: ${err.message}`,
      );
      console.error("Failed to delete number:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleImportSelected = async () => {
    setLoading(true);
    setLoadingVariant("import");
    setError(null);
    try {
      const numbersToImport = selectedNumbers.filter(
        (num) => !numbers.find((n) => n.number === num).imported,
      );
      const data = await insertInbound(api_key, selectedKey, numbersToImport);
      if (data.status === "success") {
        setNumbers(
          numbers.map((num) => {
            if (selectedNumbers.includes(num.number)) {
              return { ...num, imported: true };
            }
            return num;
          }),
        );
        setSelectedNumbers([]);
        toast.success(
          `Successfully imported ${numbersToImport.length} Twilio numbers.`,
        );
      } else {
        throw new Error(data.message || "Failed to import numbers");
      }
    } catch (err) {
      setError(
        `Failed to import selected numbers. Please try again. Error: ${err.message}`,
      );
      console.error("Failed to import selected numbers:", err);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (key) => {
    navigator.clipboard.writeText(key).then(
      () => {
        toast.success("Encrypted key copied to clipboard");
      },
      (err) => {
        toast.error(`Failed to copy encrypted key: ${err.message}`);
      },
    );
  };

  const filteredNumbers = numbers.filter((num) =>
    num.number.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const loadMoreNumbers = () => {
    setPage((prevPage) => prevPage + 1);
    fetchNumbers();
  };

  const getLoadingMessage = () => {
    switch (loadingVariant) {
      case "keys":
        return "Fetching encrypted keys...";
      case "numbers":
        return "Fetching Twilio numbers...";
      case "import":
        return "Importing numbers...";
      default:
        return "";
    }
  };

  const handleGenerateNewKey = () => {
    onSwitchTab(1); // Switch to the "Generate Key" tab
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-gray-800 border-b pb-4">
        Twilio Number Management
      </h1>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-r">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="mb-12">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">
          Step 1: Select or Generate Encrypted Key
        </h2>
        <p className="text-gray-600 mb-4">
          An encrypted key is required to securely manage your Twilio numbers.
          Select an existing key or generate a new one.
        </p>
        {loading && loadingVariant === "keys" ? (
          <LoadingAnimation
            variant={loadingVariant}
            message={getLoadingMessage()}
          />
        ) : encryptedKeys.length > 0 ? (
          <>
            <p className="text-sm text-gray-500 mb-2">
              Select an encrypted key:
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {encryptedKeys.map((key) => (
                <div
                  key={key}
                  className={`p-4 rounded-lg border ${
                    selectedKey === key
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-200"
                  } hover:border-blue-300 transition-colors cursor-pointer shadow-md`}
                  onClick={() => {
                    setSelectedKey(key);
                    fetchNumbers(true);
                  }}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-mono text-sm text-gray-600">
                      {key.slice(0, 4)}...{key.slice(-4)}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(key);
                      }}
                      className="text-gray-400 hover:text-gray-600"
                      title="Copy encrypted key"
                    >
                      <Copy />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-r">
            <p className="text-yellow-700 mb-2">
              No encrypted keys found. Generate a key to get started.
            </p>
            <button
              onClick={handleGenerateNewKey}
              className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
            >
              Generate New Key
            </button>
          </div>
        )}
      </div>

      {selectedKey && (
        <>
          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">
              Step 2: Manage Twilio Numbers
            </h2>
            <p className="text-gray-600 mb-4">
              View, search, and manage your Twilio numbers. You can import new
              numbers or remove existing ones.
            </p>
            <div className="mb-4 flex items-center">
              <input
                type="text"
                placeholder="Search numbers"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="flex-grow p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="bg-gray-100 p-2 rounded-r-md">
                <Search />
              </div>
            </div>
            <div className="bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Twilio Number
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Import Status
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredNumbers.map((num) => (
                    <tr key={num.number}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={selectedNumbers.includes(num.number)}
                            onChange={() => {
                              setSelectedNumbers((prev) =>
                                prev.includes(num.number)
                                  ? prev.filter((n) => n !== num.number)
                                  : [...prev, num.number],
                              );
                            }}
                            className="mr-3 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            disabled={num.imported}
                          />
                          <span className="font-medium text-gray-900">
                            {num.number}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            num.imported
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {num.imported ? "Imported" : "Not Imported"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {num.imported && (
                          <button
                            onClick={() => handleDelete(num.number)}
                            className="text-red-600 hover:text-red-900"
                            title="Remove number"
                          >
                            <Trash />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {hasMore && (
              <div className="mt-4 text-center">
                <button
                  onClick={loadMoreNumbers}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Load More Numbers
                </button>
              </div>
            )}
            <div className="mt-4 flex justify-between items-center">
              <span className="text-sm text-gray-500">
                {selectedNumbers.length} number
                {selectedNumbers.length !== 1 ? "s" : ""} selected
              </span>
              <button
                onClick={handleImportSelected}
                disabled={selectedNumbers.length === 0 || loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Import Selected Numbers
              </button>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">
              Step 3: Twilio Configuration
            </h2>
            <p className="text-gray-600 mb-4">
              Set up your Twilio account configuration for the selected
              encrypted key.
            </p>
            <ConfigurationSection encryptedKey={selectedKey} />
          </div>
        </>
      )}

      <AnimatePresence>
        {loading && (
          <LoadingAnimation
            variant={loadingVariant}
            message={getLoadingMessage()}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default TwilioNumbers;
