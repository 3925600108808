import axios from "axios";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

export const updateRechargeAmount = async (amount, userId) => {
  const token = getAuthToken();
  const headers = {
    authorization: `${token}`,
  };
  try {
    const response = await axios.post(
      "/api/recharge/update",
      {
        amount: amount,
        user_id: userId,
      },
      { headers },
    );
    if (response.ok) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data?.message || "Unknown error";
  }
};
