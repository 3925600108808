import {
  AlertDialog,
  Button,
  Callout,
  Dialog,
  Flex,
  IconButton,
} from "@radix-ui/themes";
import { CircleAlert, X } from "lucide-react";

export const ConfirmChangeNumber = ({
  open,
  loading,
  onClose,
  handleChangeNumber,
}) => {
  return (
    <AlertDialog.Root open={open} onOpenChange={onClose}>
      <AlertDialog.Content>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ fontWeight: "bold" }}>Confirm Change Number</h3>
            <IconButton
              variant="ghost"
              size="1"
              color="gray"
              radius="full"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
            >
              <X size={15} />
            </IconButton>
          </div>
          <Callout.Root color="blue" style={{ marginTop: 10 }}>
            <Callout.Icon>
              <CircleAlert size={15} />
            </Callout.Icon>
            <Callout.Text>
              Are you sure you want to change the number? This will override
              your current prompt / or pathway.
            </Callout.Text>
          </Callout.Root>

          <Flex direction="row" gap="3" style={{ marginTop: 20 }}>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              type="button"
              variant="soft"
              color="gray"
              style={{ width: "50%", cursor: "pointer" }}
              size={"2"}
            >
              Cancel
            </Button>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                handleChangeNumber();
              }}
              type="button"
              variant="solid"
              loading={loading}
              color="violet"
              style={{ width: "50%", cursor: "pointer" }}
              size={"2"}
            >
              Confirm
            </Button>
          </Flex>
        </>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
