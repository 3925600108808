import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Switch } from "@headlessui/react";

const ModelOptions = ({
  modelOptions,
  setModelOptions,
  noSkipUserResponse = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [skipUserResponse, setSkipUserResponse] = useState(
    modelOptions.skipUserResponse || false,
  );
  const [enableThreshold, setEnableThreshold] = useState(
    modelOptions.interruptionThreshold || false,
  );
  const [blockInterruption, setBlockInterruption] = useState(
    modelOptions.block_interruptions || false,
  );
  const [endCallTool, setEndCallTool] = useState(
    modelOptions?.endCallTool || false,
  );
  const [pressButtonsTool, setPressButtonsTool] = useState(
    modelOptions?.pressButtonsTool || false,
  );

  const [disableSilenceRepeat, setDisableSilenceRepeat] = useState(
    modelOptions?.disableSilenceRepeat || false,
  );

  const [conditionOverridesGlobalPathway, setConditionOverridesGlobalPathway] =
    useState(modelOptions?.conditionOverridesGlobalPathway || false);

  useEffect(() => {
    // handle interruptionThreshold
    if (enableThreshold) {
      setModelOptions({
        ...modelOptions,
        interruptionThreshold: modelOptions.interruptionThreshold,
      });
    } else {
      setModelOptions({
        ...modelOptions,
        interruptionThreshold: null,
      });
    }
  }, [enableThreshold, modelOptions.interruptionThreshold]);

  useEffect(() => {
    setModelOptions({
      ...modelOptions,
      skipUserResponse,
      block_interruptions: blockInterruption,
      disableSilenceRepeat,
      conditionOverridesGlobalPathway,
      //   endCallTool,
      //   pressButtonsTool,
    });
  }, [
    skipUserResponse,
    blockInterruption,
    endCallTool,
    pressButtonsTool,
    disableSilenceRepeat,
    conditionOverridesGlobalPathway,
  ]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === "number" ? parseFloat(value) : value;
    setModelOptions({ ...modelOptions, [name]: newValue });
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const SwitchOption = ({ checked, onChange, label, description }) => (
    <div className="flex items-center space-x-4 py-6 border-b border-gray-200 last:border-b-0">
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? "bg-indigo-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex-shrink-0 mt-1`}
      >
        <span
          className={`${
            checked ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
      <div className="flex-grow">
        <label className="text-lg font-medium">{label}</label>
        <p className="text-sm text-gray-500 mt-1">{description}</p>
      </div>
    </div>
  );

  return (
    <div className="border p-4 rounded-md shadow-sm">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleDropdown}
      >
        <h3 className="text-xl">Advanced Options</h3>
        <button className="text-xl">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <p className="text-lg text-gray-600 mt-1">
        Adjust settings to optimize model performance (Temperature, Latency, and
        Model Intelligence)
      </p>
      {isOpen && (
        <div className="mt-4">
          <div className="mb-4">
            <label className="block mb-1 font-medium">Model Type</label>
            <select
              name="modelType"
              value={modelOptions.modelType}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
            >
              <option value="quick">Higher speed model</option>
              <option value="Bland Beta">
                Bland Bland Model (Balanced in speed and intelligence)
              </option>
              <option value="Bland Beige">
                Bland Beige Model (Higher speed, decent intelligence model)
              </option>
              <option value="smart">Higher intelligence model</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 font-medium">
              Temperature{" "}
              <span className="text-base text-gray-600">
                Between 0.0 to 1.0
              </span>
            </label>
            <input
              type="number"
              name="temperature"
              value={modelOptions.temperature}
              onChange={handleChange}
              max="1"
              min="0"
              step="0.1"
              className="w-full p-2 border rounded-md"
            />
          </div>

          <div className="space-y-2">
            <SwitchOption
              checked={enableThreshold}
              onChange={setEnableThreshold}
              label="Dynamic Interruption Threshold"
              description="Update Interruption Threshold from this node onwards."
            />
            {enableThreshold && (
              <div className="ml-4">
                <label className="block mb-2 font-medium ">
                  Interruption Threshold
                </label>
                <input
                  type="number"
                  name="interruptionThreshold"
                  value={modelOptions.interruptionThreshold}
                  onChange={handleChange}
                  max="500"
                  min="0"
                  step="50"
                  className="w-full p-2 border rounded-md"
                />
              </div>
            )}
          </div>

          {!noSkipUserResponse && (
            <SwitchOption
              checked={skipUserResponse}
              onChange={setSkipUserResponse}
              label="Skip User's Response"
              description="Continue immediately from this node without waiting for user response."
            />
          )}

          <SwitchOption
            checked={blockInterruption}
            onChange={setBlockInterruption}
            label="Block Interruptions"
            description="Ignore user's interruptions at this node."
          />

          <SwitchOption
            checked={disableSilenceRepeat}
            onChange={setDisableSilenceRepeat}
            label="Disable Repeating the node on Silence"
            description="Whenever there is an extended period of silence, the default behaviour is to repeat the current node. This option disables that, and enables picking up the extended silence as a user response to make a decision on. E.g. You can go to a different node if an extended silence is detected."
          />

          <SwitchOption
            checked={conditionOverridesGlobalPathway}
            onChange={setConditionOverridesGlobalPathway}
            label="Condition Overrides Global Pathway"
            description="Whenever a global node is selected as the pathway label, the default behaviour is to ignore whether the condition is met or not, and go to the global node. This option enables the condition to be checked before going to the global node. E.g. If the condition is not met, the model will not go to the global node."
          />

          {/* <SwitchOption
            checked={endCallTool}
            onChange={setEndCallTool}
            label="End Call Tool"
            description="Agent can decide to end the call at this node"
          />

          <SwitchOption
            checked={pressButtonsTool}
            onChange={setPressButtonsTool}
            label="Press Buttons Tool"
            description="Agent can decide to Press Buttons at this node."
          /> */}
        </div>
      )}
    </div>
  );
};

export default ModelOptions;
