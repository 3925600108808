import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { X, ChevronDown, ChevronUp, Search, Check } from "lucide-react";

const MultiSelect = ({ fieldName, label, options, description, ...props }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const selectedValues = watch(fieldName) || [];

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const toggleOption = (value) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    setValue(fieldName, updatedValues);
  };

  const removeOption = (value, event) => {
    event.stopPropagation();
    const updatedValues = selectedValues.filter((v) => v !== value);
    setValue(fieldName, updatedValues);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col gap-2 relative w-full" ref={dropdownRef}>
      {label && (
        <label className="text-sm font-medium text-gray-700">{label}</label>
      )}

      <p className="text-sm text-gray-500">{description}</p>
      <div
        className="flex flex-wrap gap-2 p-4 border rounded-md bg-white w-full min-h-[32px] items-center cursor-pointer relative"
        onClick={toggleDropdown}
      >
        <div className="flex-grow flex flex-wrap gap-2">
          {selectedValues.map((value) => (
            <div
              key={value}
              className="flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm"
              onClick={(e) => e.stopPropagation()}
            >
              {options.find((opt) => opt.value === value)?.label}
              <button onClick={(e) => removeOption(value, e)} className="ml-2">
                <X size={14} />
              </button>
            </div>
          ))}
          {selectedValues.length === 0 && (
            <span className="text-gray-400 text-sm">
              Select webhook events...
            </span>
          )}
        </div>
        <button
          type="button"
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400"
        >
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-[calc(90%+4px)] z-10 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          <div className="sticky top-0 bg-white p-2 border-b">
            <div className="relative flex gap-2">
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={18}
              />
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-10 pr-4 py-2 border rounded-md text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
          {filteredOptions.map((option) => (
            <div
              key={option.value}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => toggleOption(option.value)}
            >
              <div className="w-5 h-5 border border-gray-300 rounded-sm mr-3 flex items-center justify-center">
                {selectedValues.includes(option.value) && (
                  <Check size={16} className="text-blue-500" />
                )}
              </div>
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
      <input type="hidden" {...register(fieldName)} value={selectedValues} />
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <p className="text-red-500 text-sm">{message}</p>
        )}
      />
    </div>
  );
};

export default MultiSelect;
