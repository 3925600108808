import { useState, useEffect, useRef, useContext } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Menu } from "@headlessui/react";
import { FaCaretDown, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import FlowContext from "../contextFlow";
import RequestData from "components/Dashboard/Parameters/RequestData";
import CustomDeployment from "components/Dashboard/Parameters/CustomDeployment";
import Switch from "components/core/Switch";
import { Button } from "@radix-ui/themes";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

function useMaxHeight(offset = 80) {
  const [maxHeight, setMaxHeight] = useState("80vh");

  useEffect(() => {
    function updateMaxHeight() {
      setMaxHeight(`${window.innerHeight - offset}px`);
    }

    window.addEventListener("resize", updateMaxHeight);
    updateMaxHeight();

    return () => window.removeEventListener("resize", updateMaxHeight);
  }, [offset]);

  return maxHeight;
}

function TestPathwayButton({
  elements,
  setChatInstanceCount,
  setShowChatInterface,
  chatStartNode,
  chatRequestData,
  setChatStartNode,
  setChatRequestData,
  chatEndpoint,
  setChatEndpoint,
  enableQA,
  setEnableQA,
  versions,
  setChatVersion,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { triggerUpdate, selectedVersion } = useContext(FlowContext);
  const dropdownRef = useRef(null);
  const maxHeight = useMaxHeight();
  const [savedConfigs, setSavedConfigs] = useState([]);
  const [configName, setConfigName] = useState("");
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [editingConfig, setEditingConfig] = useState(null);

  const token = getAuthToken();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const handleStartTest = () => {
    const formValues = methods.getValues();
    setChatStartNode(formValues.start_node_id?.id);
    setChatRequestData(formValues.request_data);
    setChatEndpoint(formValues.endpoint);
    setChatInstanceCount((prevCount) => prevCount + 1);
    setEnableQA(formValues.enableQA);
    setChatVersion(formValues.version.version_number);
    const newNodes = elements.nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        active: node.id === formValues.start_node_id?.id,
      },
    }));
    triggerUpdate({ nodes: newNodes }, true);
    setIsOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      start_node_id:
        elements.nodes.find((node) => node.id === chatStartNode) ||
        elements.nodes.find((node) => node.data.isStart) ||
        elements.nodes[0],
      request_data: chatRequestData || [],
      endpoint: chatEndpoint || null,
      enableQA: enableQA || false,
      version: selectedVersion ?? versions?.[0] ?? null,
    },
  });

  useEffect(() => {
    methods.setValue("version", selectedVersion);
  }, [selectedVersion, methods]);

  useEffect(() => {
    loadSavedConfigs();
  }, [isOpen]);

  const loadSavedConfigs = async () => {
    try {
      const response = await fetch("/api/call_config/read", {
        headers: {
          Authorization: token,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch configurations");
      const configs = await response.json();
      setSavedConfigs([...configs]);
    } catch (error) {
      console.error("Error loading configurations:", error);
      toast.error("Failed to load saved configurations");
    }
  };

  const saveConfig = async () => {
    if (!configName) {
      toast.error("Please enter a name for your configuration");
      return;
    }
    try {
      const configData = {
        name: configName,
        call_config: methods.getValues(),
      };

      let response;
      if (editingConfig) {
        response = await fetch("/api/call_config/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ ...configData, id: editingConfig.id }),
        });
      } else {
        response = await fetch("/api/call_config/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(configData),
        });
      }

      if (!response.ok) throw new Error("Failed to save configuration");
      const savedConfig = await response.json();

      setSavedConfigs((prev) => {
        const index = prev.findIndex((c) => c.id === savedConfig.id);
        if (index !== -1) {
          return prev.map((c) => (c.id === savedConfig.id ? savedConfig : c));
        } else {
          return [...prev, savedConfig];
        }
      });
      setSelectedConfig(savedConfig);
      setEditingConfig(savedConfig);
      toast.success(
        `Configuration ${editingConfig ? "updated" : "created"} successfully`,
      );
    } catch (error) {
      console.error("Error saving configuration:", error);
      toast.error("Failed to save configuration");
    }
  };

  const loadConfig = (config) => {
    console.log("methods", methods.getValues());

    methods.reset(config.call_config);
    setSelectedConfig(config);
    // toast.success("Configuration loaded successfully");
  };

  const deleteConfig = async (configId) => {
    try {
      const response = await fetch(`/api/call_config/delete`, {
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({ id: configId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete config");
      }
      setSavedConfigs((prev) =>
        prev.filter((config) => config.id !== configId),
      );
      if (selectedConfig?.id === configId) {
        setSelectedConfig(null);
        setEditingConfig(null);
      }
      toast.success("Configuration deleted successfully");
    } catch (error) {
      console.error("Error deleting configuration:", error);
      toast.error("Failed to delete configuration");
    }
  };

  const startEditing = (config) => {
    setConfigName(config.name);
    setEditingConfig(config);
    methods.reset(config.call_config);
  };

  return (
    <FormProvider {...methods}>
      <div className="relative inline-block text-left" ref={dropdownRef}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          size={"2"}
          variant="soft"
          color="gray"
          style={{ cursor: "pointer" }}
        >
          Test Pathway
        </Button>

        {isOpen && (
          <div
            className="p-2 origin-top-right absolute right-0 mt-12 w-[300px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[1000] overflow-y-auto"
            style={{ maxHeight: maxHeight }}
          >
            <div className="p-1">
              {/* Load Configuration Section */}
              <div className="mb-4">
                <label className="block pt-2 px-2 text-[13px] text-[#676775] font-medium">
                  Load Configuration
                </label>
                <Menu as="div" className="relative mt-1">
                  <Menu.Button className="flex items-center w-full px-4 py-2 text-[13px] border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                    <span className="flex-grow text-left">
                      {selectedConfig
                        ? selectedConfig.name
                        : "Select a configuration"}
                    </span>
                    <FaCaretDown className="w-4 h-4" />
                  </Menu.Button>
                  <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {savedConfigs.map((config) => (
                      <Menu.Item key={config.id}>
                        {({ active }) => (
                          <div
                            className={`${
                              active ? "bg-indigo-50" : "bg-white"
                            } ${
                              selectedConfig && selectedConfig.id === config.id
                                ? "bg-indigo-100"
                                : ""
                            } group flex items-center justify-between w-full px-4 py-2 text-sm cursor-pointer`}
                            onClick={() => {
                              loadConfig(config);
                              startEditing(config);
                            }}
                          >
                            <span>{config.name}</span>
                            <div className="flex items-center">
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  startEditing(config);
                                }}
                                className="text-blue-600 hover:text-blue-800 mr-2"
                              >
                                <FaEdit />
                              </button>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    window.confirm(
                                      `Are you sure you want to delete ${config.name}?`,
                                    )
                                  ) {
                                    deleteConfig(config.id);
                                  }
                                }}
                                className="text-red-600 hover:text-red-800"
                              >
                                <FaTrash />
                              </button>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </div>

              <label
                htmlFor="node-select"
                className="block pt-2 px-2 text-[13px] text-[#676775] font-medium"
              >
                Start Node
              </label>
              <p className="px-2 pt-4 text-[12px] text-secondary">
                Choose which Node to start testing from. Default node will be
                the Start Node.
              </p>
              <Controller
                name="start_node_id"
                control={methods.control}
                render={({ field }) => (
                  <Menu as="div" className="relative mt-1 mb-2">
                    <Menu.Button className="flex items-center w-full px-4 py-2 text-[13px] border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                      <span className="flex-grow text-left">
                        {field.value
                          ? field.value.data.name || "Unnamed Node"
                          : "Select Node"}
                      </span>
                      <FaCaretDown className="w-4 h-4" />
                    </Menu.Button>
                    <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {elements.nodes.map((node) => (
                        <Menu.Item key={node.id}>
                          {({ active }) => (
                            <div
                              className={`${
                                active ? "bg-indigo-50" : "bg-white"
                              } ${
                                field.value?.id === node.id
                                  ? "bg-indigo-100"
                                  : ""
                              } group flex items-center w-full px-4 py-2 text-sm cursor-pointer`}
                              onClick={() => field.onChange(node)}
                            >
                              {node.data.name || "Unnamed Node"}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                )}
              />

              <label
                htmlFor="version-select"
                className="block pt-2 px-2 text-[13px] text-[#676775] font-medium"
              >
                Version
              </label>
              <Controller
                name="version"
                control={methods.control}
                render={({ field }) => (
                  <Menu as="div" className="relative mt-1 mb-2">
                    <Menu.Button className="flex items-center w-full px-4 py-2 text-[13px] border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                      <span className="flex-grow text-left">
                        {field.value
                          ? field.value.name ||
                            `Version ${field.value.version_number}`
                          : "Select Version"}
                      </span>
                      <FaCaretDown className="w-4 h-4" />
                    </Menu.Button>
                    <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {versions.map((version, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <div
                              className={`${
                                active ? "bg-indigo-50" : "bg-white"
                              } ${
                                field.value?.version_number ===
                                version.version_number
                                  ? "bg-indigo-100"
                                  : ""
                              } group flex justify-between items-center w-full px-4 py-3 text-sm cursor-pointer`}
                              onClick={() => field.onChange(version)}
                            >
                              <span>
                                {version.name ||
                                  `Version ${version.version_number}`}
                              </span>
                              {version.is_latest && (
                                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                                  Latest
                                </span>
                              )}
                              {version.version_number === 0 && (
                                <span className="px-2 py-1 bg-indigo-100 text-indigo-800 text-xs rounded-full">
                                  Published
                                </span>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                )}
              />

              <div className="p-2">
                <RequestData fieldName="request_data" short />
              </div>
              <div>
                <CustomDeployment fieldName="endpoint" isPathway />
              </div>
              <div className="mt-4">
                <Switch
                  fieldName="enableQA"
                  label="Run Unit Test (Check instruction following)"
                />
              </div>

              {/* Save Configuration Section */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  {editingConfig ? "Update" : "Save"} Configuration
                </label>
                <div className="flex space-x-2 mt-1">
                  <input
                    type="text"
                    value={configName}
                    onChange={(e) => setConfigName(e.target.value)}
                    placeholder="Config Name"
                    className="flex-grow px-2 py-1 text-sm border rounded"
                  />
                  <button
                    type="button"
                    onClick={saveConfig}
                    className="px-3 py-1 bg-indigo-600 text-white text-sm rounded hover:bg-indigo-700"
                  >
                    {editingConfig ? "Update" : "Save"}
                  </button>
                </div>
              </div>

              <button
                onClick={handleStartTest}
                className="mt-4 w-full bg-indigo-600 text-white rounded-md px-4 py-2 text-sm hover:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Test Pathway
              </button>
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  );
}

export default TestPathwayButton;
