import { Dialog, Switch } from "@headlessui/react";
import { useContext, useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp, FaPlay } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ExtractInfo from "../Components/extractInfo";
import { useReactFlow } from "reactflow";
import PathwayResponse from "./WebhookResponsePathway";
import { toast } from "react-toastify";
import GlobalNodeOptions from "./globalSelect";
import ModelOptions from "./modelOptions";
import FlowContext from "../contextFlow";
import { HiOutlinePlus } from "react-icons/hi";
import { AlertCircle } from "lucide-react";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import LightTooltip from "components/Dashboard/Parameters/ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const WebhookNodeModal = ({ id, setNodes, setIsOpen, data }) => {
  const { getNodes, setEdges, getEdges } = useReactFlow();
  const [name, setName] = useState(data.name || "");
  const [text, setText] = useState(data.text || "");
  const [prompt, setPrompt] = useState(data.prompt || "");
  const [useStaticText, setUseStaticText] = useState(!data.prompt);
  const [modelOptions, setModelOptions] = useState(
    data.modelOptions || {
      modelType: "smart",
      temperature: 0.2,
    },
  );

  const { exportFlow, triggerUpdate, elements } = useContext(FlowContext);

  const [timeoutValue, setTimeoutValue] = useState(data.timeoutValue || 10);

  const [isGlobal, setIsGlobal] = useState(data.isGlobal);
  const [globalPathwayLabel, setGlobalPathwayLabel] = useState(
    data.globalLabel || "",
  );

  const [globalPathwayDescription, setGlobalPathwayDescription] = useState(
    data.globalDescription || "",
  );

  const [enableGlobalAutoReturn, setEnableGlobalAutoReturn] = useState(
    data.enableGlobalAutoReturn === false ? false : true,
  );

  const [prevNodePathwayLabel, setPrevNodePathwayLabel] = useState(
    data.prevNodePathwayLabel || "",
  );

  const [prevNodePathwayDescription, setPrevNodePathwayDescription] = useState(
    data.prevNodePathwayDescription || "",
  );

  const [isForwardingEnabled, setIsForwardingEnabled] = useState(false);
  const [forwardingNode, setForwardingNode] = useState("");

  let isWarnToast = false;
  const [responsePathways, setResponsePathways] = useState(
    data.responsePathways || [
      ["Default/Webhook Completion", "", "", { id: "", name: "" }],
    ],
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [url, setURL] = useState(data.url || "");
  const [method, setMethod] = useState(data.method || "GET");
  const [headers, setHeaders] = useState(data.headers || [["", ""]]);
  const [extractVars, setExtractVars] = useState(
    data.extractVars || [["", "", ""]],
  );

  const [apiResponse, setApiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [responseData, setResponseData] = useState(
    data.responseData || [{ name: "", data: "", context: "" }],
  );

  const [useReroute, setUseReroute] = useState(true);

  const testApiRequest = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const headerObj = Object.fromEntries(headers);

      if (useReroute) {
        // Rerouted version
        const runEndpointUrl = "/api/tools/run";

        const response = await fetch(runEndpointUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
          body: JSON.stringify({
            url: url,
            method: method,
            headers: useHeader ? headerObj : undefined,
            body: useBody ? body : undefined,
          }),
        });

        const data = await response.json();

        if (data.statusCode >= 200 && data.statusCode < 300) {
          setApiResponse(data.responseData);
        } else {
          throw new Error(`Request failed with status ${data.statusCode}`);
        }
      } else {
        // Direct version
        const response = await fetch(url, {
          method,
          headers: useHeader ? headerObj : undefined,
          body: useBody ? body : undefined,
        });
        const data = await response.json();
        setApiResponse(data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const extractResponseData = (path) => {
    try {
      return path.split(".").reduce((o, i) => o[i], apiResponse);
    } catch (err) {
      return "N/A";
    }
  };

  const [body, setBody] = useState(data.body || "{}");

  const [useHeader, setUseHeader] = useState(data.headers ? true : false);
  const [useBody, setUseBody] = useState(data.body ? true : false);
  const [useExtractVars, setUseExtractVars] = useState(
    data.extractVars ? true : false,
  );

  const [variableValidationErrors, setVariableValidationErrors] = useState([]);

  const validateVariables = () => {
    // if useExtractVars is false, no need to validate
    if (!useExtractVars) {
      return true;
    }
    const errors = [];
    extractVars.forEach((variable, index) => {
      variable.forEach((field, fieldIndex) => {
        if (field.trim() === "") {
          errors.push({
            index,
            field: fieldIndex,
            message: "Field is required",
          });
        }
      });
    });
    setVariableValidationErrors(errors);
    return errors.length === 0;
  };

  const [useResponseData, setUseResponseData] = useState(
    data.responseData ? true : false,
  );

  const [useSpeechDuringWebhook, setUseSpeechDuringWebhook] = useState(
    data.text || data.prompt,
  );

  const addHeader = () => {
    setHeaders([...headers, ["", ""]]);
  };

  const responseDataRef = useRef(null);

  const removeHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const updateHeader = (index, key, value) => {
    const updatedHeaders = headers.map((header, i) => {
      if (i === index) {
        return [key, value];
      }
      return header;
    });
    setHeaders(updatedHeaders);
  };

  const extractPath = (obj, path = "$") => {
    const result = [];

    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        result.push(...extractPath(item, `${path}[${index}]`));
      });
    } else if (typeof obj === "object" && obj !== null) {
      Object.entries(obj).forEach(([key, value]) => {
        const newPath = `${path}.${key}`;
        result.push({ name: key, path: newPath, value: JSON.stringify(value) });
        if (typeof value === "object" && value !== null) {
          result.push(...extractPath(value, newPath));
        }
      });
    }

    return result;
  };

  const addToResponseData = (item) => {
    setResponseData([
      ...responseData,
      { name: item.name, data: item.path, context: "" },
    ]);
    toast.success(
      "Value extracted and added to response data. You can reference this variable to decide which pathway to go to, or use it in future nodes.",
    );
    setTimeout(() => {
      responseDataRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const renderJsonWithExtract = (obj, path = "$", depth = 0) => {
    const indent = depth * 2; // 20px indentation per level

    if (Array.isArray(obj)) {
      return (
        <div style={{ marginLeft: `${indent}px` }} className="my-1">
          [
          <div style={{ marginLeft: "20px" }}>
            {obj.map((item, index) => (
              <div key={index} className="my-1">
                {renderJsonWithExtract(item, `${path}[${index}]`, depth + 1)}
              </div>
            ))}
          </div>
          ]
        </div>
      );
    } else if (typeof obj === "object" && obj !== null) {
      return (
        <div style={{ marginLeft: `${indent}px` }} className="my-1">
          {"{"}
          <div style={{ marginLeft: "20px" }}>
            {Object.entries(obj).map(([key, value], index, array) => (
              <div key={key} className="my-1 flex items-start">
                <button
                  onClick={() => {
                    setUseResponseData(true);
                    addToResponseData({
                      name: key,
                      path: `${path}.${key}`,
                      value: JSON.stringify(value),
                    });
                  }}
                  className="mr-2 bg-indigo-100 hover:bg-indigo-200 text-indigo-700 p-1 rounded-md text-xs transition-colors duration-200 flex-shrink-0"
                  title="Extract this value"
                >
                  <HiOutlinePlus className="w-4 h-4" />
                </button>
                <span className="font-semibold text-purple-700 mr-2">
                  "{key}":
                </span>
                <div className="flex-grow">
                  {renderJsonWithExtract(value, `${path}.${key}`, depth + 1)}
                </div>
              </div>
            ))}
          </div>
          {"}"}
        </div>
      );
    } else if (typeof obj === "string") {
      return <span className="text-green-600">"{obj}"</span>;
    } else if (typeof obj === "number") {
      return <span className="text-blue-600">{obj}</span>;
    } else if (typeof obj === "boolean") {
      return <span className="text-red-600">{obj.toString()}</span>;
    } else if (obj === null) {
      return <span className="text-gray-500">null</span>;
    } else {
      return <span>{obj}</span>;
    }
  };

  const addResponseData = () => {
    setResponseData([...responseData, { name: "", data: "", context: "" }]);
  };

  const removeResponseData = (index) => {
    setResponseData(responseData.filter((_, i) => i !== index));
  };

  const updateResponseData = (index, field, value) => {
    const updatedData = responseData.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setResponseData(updatedData);
  };

  useEffect(() => {
    setName(data.name || "");
    setText(data.text || "");
    setPrompt(data.prompt || "");
    setUseStaticText(!data.prompt);
    setModelOptions(
      data.modelOptions || { modelType: "smart", temperature: 0.2 },
    );
    setTimeoutValue(data.timeoutValue || 10);
    setIsGlobal(data.isGlobal);
    setGlobalPathwayLabel(data.globalLabel || "");
    setGlobalPathwayDescription(data.globalDescription || "");
    setEnableGlobalAutoReturn(
      data.enableGlobalAutoReturn === false ? false : true,
    );
    setPrevNodePathwayLabel(data.prevNodePathwayLabel || "");
    setPrevNodePathwayDescription(data.prevNodePathwayDescription || "");
    setIsForwardingEnabled(false);
    setForwardingNode("");
    setResponsePathways(
      data.responsePathways || [
        ["Default/Webhook Completion", "", "", { id: "", name: "" }],
      ],
    );
    setURL(data.url || "");
    setMethod(data.method || "GET");
    setHeaders(data.headers || [["", ""]]);
    setExtractVars(data.extractVars || [["", "", ""]]);
    setResponseData(data.responseData || [{ name: "", data: "", context: "" }]);
    setBody(data.body || "{}");
    setUseHeader(data.headers ? true : false);
    setUseBody(data.body ? true : false);
    setUseExtractVars(data.extractVars ? true : false);
    setUseResponseData(data.responseData ? true : false);
    setUseSpeechDuringWebhook(data.text || data.prompt);
  }, [data]);

  // useEffect for when useExtractVars is toggled, if its false then clear the validation errors
  useEffect(() => {
    if (!useExtractVars) {
      setVariableValidationErrors([]);
    }
  }, [useExtractVars]);

  return (
    <Dialog.Description className="space-y-4">
      <div className="space-y-2">
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border border-gray-300 p-2 rounded-md w-full"
        />
      </div>

      <ExtractInfo
        setExtractVars={setExtractVars}
        useExtractVars={useExtractVars}
        extractVars={extractVars}
        setUseExtractVars={setUseExtractVars}
        validationErrors={variableValidationErrors}
        setValidationErrors={setVariableValidationErrors}
        isWebHook={true}
      />

      <div className="border p-4 rounded-md shadow-sm bg-white">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl">Webhook</h2>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-xl"
          >
            {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        </div>
        <p className="text-lg text-gray-600 mt-1">
          Add API details to fetch data from an external source.
        </p>
        {isExpanded && (
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <select
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                className="border border-gray-300 p-2 rounded-md text-lg"
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PATCH">PATCH</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
              </select>
              <input
                type="text"
                value={url}
                onChange={(e) => setURL(e.target.value)}
                placeholder="Enter URL"
                className="border border-gray-300 p-2 rounded-md flex-1 text-lg"
              />
            </div>

            {/* Headers section */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Switch
                    checked={useHeader}
                    onChange={() => setUseHeader(!useHeader)}
                    className={`${
                      useHeader ? "bg-indigo-600" : "bg-gray-200"
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable Headers</span>
                    <span
                      className={`${
                        useHeader ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                  <span className="text-lg font-medium">Headers</span>
                </div>
                {useHeader && (
                  <button
                    onClick={addHeader}
                    className="bg-indigo-500 text-white p-2 rounded-md text-[11px]"
                  >
                    Add Header
                  </button>
                )}
              </div>
              {useHeader &&
                headers.map((header, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      placeholder="Key"
                      value={header[0]}
                      onChange={(e) =>
                        updateHeader(index, e.target.value, header[1])
                      }
                      className="border border-gray-300 p-2 rounded-md w-full text-[11px]"
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={header[1]}
                      onChange={(e) =>
                        updateHeader(index, header[0], e.target.value)
                      }
                      className="border border-gray-300 p-2 rounded-md w-full text-[11px]"
                    />
                    <button
                      onClick={() => removeHeader(index)}
                      className="p-2 rounded-md"
                    >
                      <RxCross2 color={"red"} />
                    </button>
                  </div>
                ))}
            </div>

            {/* Body section */}
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Switch
                  checked={useBody}
                  onChange={() => setUseBody(!useBody)}
                  className={`${
                    useBody ? "bg-indigo-600" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Body</span>
                  <span
                    className={`${
                      useBody ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <span className="text-lg font-medium">Body</span>
              </div>
              {useBody && (
                <textarea
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md w-full text-[11px] h-32"
                  placeholder="Enter request body (JSON)"
                />
              )}
            </div>

            {/* Timeout section */}
            <div className="space-y-2 mt-4">
              <div className="flex items-center gap-2">
                <span className="text-lg font-medium">Timeout (seconds)</span>
                <input
                  type="number"
                  max={999}
                  value={timeoutValue}
                  onChange={(e) => setTimeoutValue(parseInt(e.target.value))}
                  className="border border-gray-300 p-2 rounded-md w-44 text-[11px]"
                  placeholder="Timeout in secs"
                />
              </div>
            </div>

            {/* Reroute toggle */}
            <div className="flex items-center space-x-2 mt-4">
              <span className="text-lg font-medium">
                Reroute through server
              </span>
              <Switch
                checked={useReroute}
                onChange={setUseReroute}
                className={`${
                  useReroute ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Reroute through server</span>
                <span
                  className={`${
                    useReroute ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <LightTooltip
                title={
                  <span>
                    Rerouting through the server helps prevent CORS issues.
                    Disable only if you're experiencing problems with the
                    rerouted requests.
                    <br />
                    <br />
                    Warning: Disabling may cause CORS errors for some API
                    endpoints.
                  </span>
                }
                placement="right-start"
                arrow
              >
                <HelpOutlineIcon
                  color="primary"
                  style={{ paddingLeft: "4px" }}
                />
              </LightTooltip>
            </div>

            {/* Test API Request button */}
            <div className="flex justify-center">
              <button
                onClick={testApiRequest}
                className="bg-green-500 text-white p-2 rounded-md text-lg flex items-center gap-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  "Testing..."
                ) : (
                  <>
                    <FaPlay />
                    Test API Request
                  </>
                )}
              </button>
            </div>

            {error && <div className="text-red-500 text-center">{error}</div>}

            {/* API Response section */}
            {apiResponse && (
              <div className="mt-4 space-y-2">
                <h3 className="text-xl font-semibold">API Response:</h3>
                <div className="bg-gray-100 p-4 rounded-md overflow-x-auto">
                  {renderJsonWithExtract(apiResponse)}
                </div>
              </div>
            )}

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Switch
                  checked={useResponseData}
                  onChange={() => setUseResponseData(!useResponseData)}
                  className={`${
                    useResponseData ? "bg-indigo-600" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Response Data</span>
                  <span
                    className={`${
                      useResponseData ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <span className="text-lg font-medium">Response Data</span>
              </div>
              {useResponseData && (
                <button
                  onClick={addResponseData}
                  className="bg-indigo-500 text-white p-2 rounded-md text-[11px]"
                >
                  Add Response Data
                </button>
              )}
            </div>

            {/* Response Data Extraction section */}
            {useResponseData && (
              <div className="space-y-4" ref={responseDataRef}>
                <h3 className="text-xl font-semibold">
                  Extracted Response Data:
                </h3>
                {responseData.map((item, index) => (
                  <div
                    key={index}
                    className="space-y-2 p-4 bg-gray-50 rounded-md"
                  >
                    <div className="flex justify-between items-center">
                      <input
                        type="text"
                        placeholder="Variable name"
                        value={item.name}
                        onChange={(e) =>
                          updateResponseData(index, "name", e.target.value)
                        }
                        className="border border-gray-300 p-2 rounded-md w-1/2 text-[11px]"
                      />
                      <button onClick={() => removeResponseData(index)}>
                        <RxCross2 color={"red"} />
                      </button>
                    </div>
                    <input
                      type="text"
                      placeholder="JSONPath"
                      value={item.data}
                      onChange={(e) =>
                        updateResponseData(index, "data", e.target.value)
                      }
                      className="border border-gray-300 p-2 rounded-md w-full text-[11px]"
                    />
                    <input
                      type="text"
                      placeholder="Additional context and description"
                      value={item.context}
                      onChange={(e) =>
                        updateResponseData(index, "context", e.target.value)
                      }
                      className="border border-gray-300 p-2 rounded-md w-full text-[11px]"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <PathwayResponse
        responseVariables={responseData}
        responsePathways={responsePathways}
        setResponsePathways={setResponsePathways}
      />

      <div className="border p-4 rounded-md shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <label className="text-xl">Send Speech During Webhook</label>
            <p className="text-lg text-gray-600 mt-1">
              Enable speech output while the webhook is running in the
              background.
            </p>
          </div>
          <Switch
            checked={useSpeechDuringWebhook}
            onChange={() => setUseSpeechDuringWebhook(!useSpeechDuringWebhook)}
            className={`${
              useSpeechDuringWebhook ? "bg-indigo-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Enable Speech During Webhook</span>
            <span
              className={`${
                useSpeechDuringWebhook ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        {useSpeechDuringWebhook && (
          <>
            <div className="flex items-center space-x-4 mt-4">
              <Switch
                checked={useStaticText}
                onChange={setUseStaticText}
                className={`${
                  useStaticText ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Static Text</span>
                <span
                  className={`${
                    useStaticText ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label>
                Static Text{" "}
                <span className="italic text-lg">
                  (Use a specific dialogue. Uncheck for AI-generated text)
                </span>
              </label>
            </div>

            {useStaticText && (
              <>
                <div className="mt-4">Text:</div>
                <textarea
                  placeholder="Exact text to be spoken by the agent"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md w-full text-lg mt-2 min-h-[100px]"
                />
              </>
            )}
            {!useStaticText && (
              <>
                <div className="mt-4">Prompt:</div>
                <textarea
                  placeholder="Provide a goal/prompt for the agent (e.g., Ask for the customer's name)"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md w-full text-lg mt-2 min-h-[100px]"
                />
              </>
            )}
          </>
        )}
      </div>

      <ModelOptions
        modelOptions={modelOptions}
        setModelOptions={setModelOptions}
        noSkipUserResponse={true}
      />

      <GlobalNodeOptions
        isGlobal={isGlobal}
        setIsGlobal={setIsGlobal}
        globalPathwayLabel={globalPathwayLabel}
        setGlobalPathwayLabel={setGlobalPathwayLabel}
        globalPathwayDescription={globalPathwayDescription}
        setGlobalPathwayDescription={setGlobalPathwayDescription}
        isForwardingEnabled={isForwardingEnabled}
        setIsForwardingEnabled={setIsForwardingEnabled}
        forwardingNode={forwardingNode}
        setForwardingNode={setForwardingNode}
        enableGlobalAutoReturn={enableGlobalAutoReturn}
        setEnableGlobalAutoReturn={setEnableGlobalAutoReturn}
        prevNodePathwayLabel={prevNodePathwayLabel}
        setPrevNodePathwayLabel={setPrevNodePathwayLabel}
        prevNodePathwayDescription={prevNodePathwayDescription}
        setPrevNodePathwayDescription={setPrevNodePathwayDescription}
      />
      <div className="mt-4 flex flex-row items-center">
        <button
          onClick={async () => {
            if (validateVariables()) {
              let updatedEdges = elements.edges;
              const updatedNodes = elements.nodes.map((element_node) => {
                // element_node.draggable = true;
                if (element_node.id === id) {
                  let newEdges = [];
                  const node = structuredClone(element_node);
                  for (const responsePathway of responsePathways) {
                    console.log("Response pathway", responsePathway);
                    if (responsePathway[0] === "Default/Webhook Completion") {
                      if (
                        responsePathway[3].id === "" ||
                        responsePathway[3].name === ""
                      ) {
                        toast.warn(
                          "Please select the default node to path to after webhook completion.",
                        );
                        isWarnToast = true;
                        return node;
                      }
                      const edge = {
                        id: `reactflow__edge-${id}-${responsePathway[3].id}`,
                        animated: true,
                        sourceHandle: null,
                        targetHandle: null,
                        type: "custom",
                        source: id,
                        target: responsePathway[3].id,
                        data: {
                          label: `${responsePathway[0]}`,
                        },
                      };
                      newEdges.push(edge);
                      continue;
                    }
                    if (
                      !responsePathway[1] ||
                      !responsePathway[2] ||
                      !responsePathway[3].id ||
                      !responsePathway[3].name
                    ) {
                      toast.warn(
                        "Fields in 'Pathway after Webhook Response' not filled out. Please fill in all fields.",
                      );
                      isWarnToast = true;
                      break;
                    }

                    const edge = {
                      id: `reactflow__edge-${id}-${responsePathway[3].id}`,
                      animated: true,
                      sourceHandle: null,
                      targetHandle: null,
                      type: "custom",
                      source: id,
                      target: responsePathway[3].id,
                      data: {
                        label: `${responsePathway[0]} ${responsePathway[1]} ${responsePathway[2]}`,
                      },
                    };
                    newEdges.push(edge);
                  }

                  const currentEdges = elements.edges;

                  const filteredEdges = currentEdges.filter(
                    (edge) => edge.source !== id,
                  );
                  updatedEdges = [...filteredEdges, ...newEdges];
                  node.data.name = name;
                  node.type = "Webhook";
                  if (
                    url.startsWith("http://") ||
                    (node.data.url && node.data.url.startsWith("http://"))
                  ) {
                    isWarnToast = true;
                    toast.warn(
                      "Please use HTTPS instead of HTTP for security reasons.",
                    );
                    return node;
                  }
                  node.data.url = url;
                  node.data.method = method;
                  const nonEmptyHeaders = headers.filter(
                    (header) => header[0] !== "" || header[1] !== "",
                  );
                  if (nonEmptyHeaders.length > 0 && useHeader) {
                    node.data.headers = nonEmptyHeaders;
                  } else {
                    delete node.data.headers;
                  }

                  if (useExtractVars) {
                    const resVars = extractVars.filter((varArr) => {
                      if (varArr[0] === "bland-prompt") {
                        return true;
                      }
                      // For all variables excluding "bland-prompt", ensure all fields are non-empty
                      return (
                        varArr[0] !== "" && varArr[1] !== "" && varArr[2] !== ""
                      );
                    });

                    const nonPromptVars = resVars.filter(
                      (varArr) => varArr[0] !== "bland-prompt",
                    );

                    if (nonPromptVars.length === 0) {
                      // If there are no valid variables, remove extractVars completely
                      delete node.data.extractVars;
                      setExtractVars([]);
                    } else {
                      // Save the variables including the "bland-prompt" if it exists and is non-empty
                      node.data.extractVars = resVars;
                      setExtractVars(resVars);
                    }
                  } else {
                    delete node.data.extractVars;
                  }

                  if (body !== "{}" && useBody) {
                    node.data.body = body;
                  } else {
                    delete node.data.body;
                  }

                  if (isForwardingEnabled && forwardingNode) {
                    node.data.forwardingNode = forwardingNode;
                  } else {
                    delete node.data.forwardingNode;
                  }

                  if (useResponseData && responseData.length > 0) {
                    node.data.responseData = responseData;
                  } else {
                    delete node.data.responseData;
                  }
                  node.data.timeoutValue = timeoutValue;
                  if (useSpeechDuringWebhook) {
                    if (useStaticText) {
                      node.data.text = text;
                      setPrompt("");
                      delete node.data.prompt;
                    } else {
                      node.data.prompt = prompt;
                      setText("");
                      delete node.data.text;
                    }
                  } else {
                    delete node.data.text;
                    delete node.data.prompt;
                  }
                  if (
                    responsePathways.length > 0 &&
                    responsePathways[0][0] !== ""
                  ) {
                    node.data.responsePathways = responsePathways;
                  } else {
                    delete node.data.responsePathways;
                  }

                  if (modelOptions) {
                    if (modelOptions.temperature) {
                      modelOptions.temperature = parseFloat(
                        modelOptions.temperature.toFixed(1),
                      );
                    }
                    if (modelOptions.interruptionThreshold) {
                      modelOptions.interruptionThreshold = parseInt(
                        modelOptions.interruptionThreshold,
                      );
                    }

                    if (
                      modelOptions.conditionOverridesGlobalPathway &&
                      modelOptions.conditionOverridesGlobalPathway === true
                    ) {
                      modelOptions.conditionOverridesGlobalPathway = true;
                    } else {
                      delete modelOptions.conditionOverridesGlobalPathway;
                    }

                    node.data.modelOptions = modelOptions;
                  } else {
                    delete node.data.modelOptions;
                  }
                  if (isGlobal) {
                    node.data.isGlobal = true;
                    node.data.globalLabel = globalPathwayLabel;
                    if (globalPathwayDescription) {
                      node.data.globalDescription = globalPathwayDescription;
                    } else {
                      delete node.data.globalDescription;
                    }
                    if (!enableGlobalAutoReturn) {
                      // if auto return is disabled/treating like normal node
                      node.data.enableGlobalAutoReturn = false;
                      if (prevNodePathwayLabel) {
                        node.data.prevNodePathwayLabel = prevNodePathwayLabel;
                      }
                      if (prevNodePathwayDescription) {
                        node.data.prevNodePathwayDescription =
                          prevNodePathwayDescription;
                      }
                    } else {
                      updatedEdges = updatedEdges.filter(
                        (edge) => edge.target !== id && edge.source !== id,
                      );
                      delete node.data.enableGlobalAutoReturn;
                      delete node.data.prevNodePathwayLabel;
                      delete node.data.prevNodePathwayDescription;
                    }
                  } else {
                    delete node.data.isGlobal;
                    delete node.data.globalLabel;
                    delete node.data.globalDescription;
                    delete node.data.enableGlobalAutoReturn;
                    delete node.data.prevNodePathwayLabel;
                    delete node.data.prevNodePathwayDescription;
                  }
                  // setEdges(newEdges);
                  return node;
                }
                return element_node;
              });

              if (!isWarnToast) {
                triggerUpdate(
                  {
                    nodes: updatedNodes,
                    edges: updatedEdges,
                  },
                  false,
                );

                setIsOpen(false);
                // exportFlow();
              }
              isWarnToast = false;
            }
          }}
          className="bg-indigo-500 text-white py-2 px-4 rounded-md"
        >
          Save
        </button>
        {variableValidationErrors.length > 0 && (
          <div className="relative group ml-2">
            <AlertCircle className="h-6 w-6 text-red-500 " />
            <span className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded hidden group-hover:block whitespace-nowrap">
              Some variables are missing fields
            </span>
          </div>
        )}
      </div>
    </Dialog.Description>
  );
};

export default WebhookNodeModal;
