import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ClientSecretProvider } from "./contexts/ClientSecretContext";
import SignUp from "./pages/SignUp";
import NotificationProvider from "./hooks/useNotify";
import ConfirmationPage from "./pages/ConfirmationPage";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import OAuthApproval from "./pages/OAuth";
import { GlobalStyles } from "utils/theme/global";
import { ToastContainer } from "react-toastify";
import Enterprise from "pages/Enterprise";
import PromptUniversity from "pages/Prompting/PromptUniversity";
import GeneratePathway from "pages/GeneratePathway";
import PathwayShowcase from "pages/pathwayShowcase";
import PathwayHeatmap from "components/Dashboard/ConvoPathways/HeatMap";
import { ThemeWrapper } from "utils/theme/theme";
import Tools from "pages/Tools/Tools";
import ToolSlug from "pages/Tools/ToolSlug";
import EmbeddedPathway from "pages/embeddedPathway";
import SearsDashboard from "SearsDashboard";

function App() {
  return (
    <ThemeWrapper>
      <BrowserRouter>
        <NotificationProvider>
          <ClientSecretProvider>
            <GlobalStyles />
            <ToastContainer />
            <Routes>
              <Route path="/" element={<SignUp />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/home" element={<Login />}></Route>
              <Route path="/signup" element={<SignUp />}></Route>
              <Route path="/signup-xto" element={<SignUp />}></Route>
              {/*NOT Q SIGNUP PATH*/}
              <Route path="/signup-qto" element={<SignUp />}></Route>
              {/*Q SIGNUP PATH*/}
              <Route path="/enterprise" element={<Enterprise />}></Route>
              <Route
                path="/setup-complete"
                element={<ConfirmationPage />}
              ></Route>
              <Route path="/auth/oauth" element={<OAuthApproval />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/generate-pathway" element={<GeneratePathway />} />
              <Route path="/showcase/pathway" element={<PathwayShowcase />} />
              <Route path="/embed-pathway" element={<EmbeddedPathway />} />
              <Route path="/showcase/tools" element={<Tools />} />
              <Route path="/showcase/tools/:id" element={<ToolSlug />} />
              <Route path="/analytics/dashboard/tempPathwayAnalytics" element={<SearsDashboard />} />
            </Routes>
          </ClientSecretProvider>
        </NotificationProvider>
      </BrowserRouter>
    </ThemeWrapper>
  );
}
export default App;
