import React, { useState, useMemo } from "react";
import styled from "styled-components";
import VoiceCard from "./VoiceCard";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { getUserData } from "utils/funcs/browser/getUserData";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { SearchIcon } from "lucide-react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";

export default function UserVoices({ voices, setShowCloneModal }) {
  const { user } = getUserData();
  const [sampleText, setSampleText] = useState(
    "Hey there, this is Blandie. How are you doing today?",
  );
  const [tempSampleText, setTempSampleText] = useState(sampleText);
  const [publishModal, setPublishModal] = useState(false);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [active_pv, setActive_pv] = useState(null);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  const curatedVoicesList = useMemo(
    () =>
      voices.filter(
        (voice) =>
          voice?.tags?.includes("Bland Curated") && voice?.user_id == null,
      ),
    [voices],
  );

  const privateVoicesList = useMemo(
    () =>
      voices.filter(
        (voice) => voice.user_id != null && !voice.name.includes("Public -"),
      ),
    [voices],
  );

  const allTags = useMemo(() => {
    const tags = new Set();
    voices.forEach((voice) => voice.tags?.forEach((tag) => tags.add(tag)));
    return Array.from(tags);
  }, [voices]);

  const filteredPrivateVoices = useMemo(() => {
    return privateVoicesList.filter((voice) => {
      const nameMatch = voice.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const tagMatch =
        selectedTags.length === 0 ||
        selectedTags.some((tag) => voice.tags?.includes(tag));
      return nameMatch && tagMatch;
    });
  }, [privateVoicesList, searchTerm, selectedTags]);

  const handleSave = () => {
    setSampleText(tempSampleText);
    setShowCustomizeModal(false);
    toast.success("TTS Text updated! Play any sample to hear it!");
  };

  const handleCancel = () => {
    setTempSampleText(sampleText);
    setShowCustomizeModal(false);
  };

  const handlePublishVoice = async () => {
    try {
      if (!active_pv) return;
      setLoadingPublish(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/${active_pv.id}/publish`,
        {
          method: "POST",
          headers: {
            authorization: getApiKey(),
          },
        },
      );

      if (response.status !== 200 && !response.ok) {
        setActive_pv(null);
        setLoadingPublish(false);
        setPublishModal(false);
        return toast.error("Error publishing voice.");
      }

      setLoadingPublish(false);
      setPublishModal(false);
      toast.success("Your voice has been published successfully.");
      window.location.reload();
    } catch (error) {
      setPublishModal(false);
      setLoadingPublish(false);
      return toast.error("Error publishing voice");
    }
  };

  const toggleTag = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Your Voices</h1>
        <div className="flex space-x-4">
          <button
            className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out"
            onClick={() => setShowCloneModal(true)}
          >
            Upload Voice Clone
          </button>
          <button
            className="bg-indigo-100 text-indigo-800 px-6 py-2 rounded-md hover:bg-indigo-200 transition duration-300 ease-in-out"
            onClick={() => {
              setTempSampleText(sampleText);
              setShowCustomizeModal(true);
            }}
          >
            Customize TTS Text
          </button>
        </div>
      </div>

      <div className="mb-8">
        <div className="flex items-center bg-white border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-indigo-500">
          <SearchIcon className="h-5 w-5 text-gray-400 ml-3" />
          <input
            type="text"
            placeholder="Search voices..."
            className="w-full py-2 px-4 rounded-r-lg focus:outline-none"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" /> Filter by Tags
        </h2>
        <div className="flex flex-wrap gap-2">
          {allTags.map((tag) => (
            <button
              key={tag}
              onClick={() => toggleTag(tag)}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedTags.includes(tag)
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
              } transition duration-300 ease-in-out`}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>

      <h2 className="text-2xl font-semibold mb-4">Curated Bland Voices</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-8">
        {curatedVoicesList.map((voice) => (
          <VoiceCard
            key={voice.id}
            data={voice}
            text={sampleText}
            isPublicTab={false}
          />
        ))}
      </div>

      <h2 className="text-2xl font-semibold mb-4">Private & Cloned Voices</h2>
      {filteredPrivateVoices.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredPrivateVoices.map((voice) => {
            const hasBeenMadePublic = voices?.find((doc) => {
              const possible_name = doc?.name?.split("Public -")?.[1]?.trim();
              return possible_name === voice.name;
            });

            return (
              <VoiceCard
                key={voice.id}
                userCreated={user.id === voice.user_id}
                data={voice}
                text={sampleText}
                isPublicTab={false}
                hasBeenMadePublic={hasBeenMadePublic}
                onPublishBtn={(voiceData) => {
                  setPublishModal(true);
                  setActive_pv(voiceData);
                }}
              />
            );
          })}
        </div>
      ) : (
        <p className="text-center text-gray-600">No private voices found.</p>
      )}

      <Dialog
        open={showCustomizeModal}
        onClose={() => setShowCustomizeModal(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative bg-white rounded-lg max-w-md w-full mx-4 p-6 shadow-xl">
            <Dialog.Title className="text-lg font-medium text-gray-900 mb-4">
              Customize TTS Sample Text
            </Dialog.Title>
            <textarea
              value={tempSampleText}
              onChange={(e) => setTempSampleText(e.target.value)}
              className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none mb-4"
              placeholder="Enter your custom text here..."
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Modal
        open={publishModal}
        maxWidth="40%"
        onClose={() => setPublishModal(false)}
      >
        <p className="text-lg font-medium mb-2">You are publishing a Voice.</p>
        <p className="text-sm text-gray-600 mb-6">
          By publishing a voice, you are making this voice public to use by any
          user on Bland.
        </p>

        <div className="flex space-x-4">
          <Button
            onClick={() => {
              setPublishModal(false);
              setActive_pv(null);
            }}
            appearance="outline"
            className="w-1/2"
          >
            Cancel
          </Button>

          <Button
            loading={loadingPublish}
            onClick={handlePublishVoice}
            type="button"
            className="w-1/2"
          >
            Publish
          </Button>
        </div>
      </Modal>
    </div>
  );
}
