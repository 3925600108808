import { Badge, Button, Flex, Spinner } from "@radix-ui/themes";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import {
  Clock,
  Code,
  Copy,
  Edit2,
  ExternalLink,
  Globe,
  Key,
  PlusCircle,
  PlusIcon,
  Settings,
  Store,
  Trash2,
  Zap,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import { Fragment } from "react";

export default function AllTools() {
  const [tools, setTools] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState({
    id: null,
    loading: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toolToDelete, setToolToDelete] = useState(null);

  const deleteToolHandler = async (id) => {
    try {
      if (tools?.find((tool) => tool.id === id)?.public) {
        return toast.error("Cannot delete public tool", {
          position: "bottom-right",
        });
      }
      setLoadingDelete({ id, loading: true });
      const toolIdWithoutPrefix = id.replace(/^TL-/, "");
      const response = await fetch(
        `https://us.api.bland.ai/v1/tools/${toolIdWithoutPrefix}`,
        {
          method: "DELETE",
          headers: {
            Authorization: getApiKey(),
          },
        },
      );

      if (response.ok && response.status === 200) {
        setTools(tools.filter((tool) => tool.id !== id));
        toast.success("Tool deleted successfully", {
          position: "bottom-right",
        });
      }
      setLoadingDelete({ id, loading: false });
    } catch (error) {
      setLoadingDelete({ id, loading: false });
      toast.error("Failed to delete tool", {
        position: "bottom-right",
      });
    } finally {
      setLoadingDelete({ id: null, loading: false });
      setShowDeleteModal(false);
      setToolToDelete(null);
    }
  };

  const getAllTools = async () => {
    try {
      const response = await fetch("https://us.api.bland.ai/v1/tools", {
        method: "GET",
        headers: {
          Authorization: getApiKey(),
        },
      });

      if (response.ok && response.status === 200) {
        const jData = await response.json();
        let mappedTools = jData?.tools?.map((tool) => ({
          id: tool.tool_id,
          ...tool.tool,
          public: tool.public,
        }));
        console.log(mappedTools);
        setTools(mappedTools);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getDescriptionLengthAndTruncate = (description) => {
    if (!description) return "";
    if (description.length > 100) {
      return description.substring(0, 100) + "...";
    }
    return description;
  };

  useEffect(() => {
    getAllTools();
  }, []);

  const EmptyState = () => (
    <div
      className="text-center py-12"
      style={{ border: "1px solid #eeeeee", borderRadius: 10 }}
    >
      <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-indigo-100 rounded-full">
        <Settings className="w-8 h-8 text-indigo-600" />
      </div>
      <h3 className="mb-2 text-xl font-bold text-gray-900">
        No tools created yet
      </h3>
      <p className="mb-6 text-gray-500">
        Get started by creating your first tool. It's easy!
      </p>
      <button
        type="button"
        onClick={() => navigate("/dashboard?page=tools")}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusCircle className="w-5 h-5 mr-2" />
        Create Your First Tool
      </button>
    </div>
  );

  const DeleteConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm,
    toolName,
  }) => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[100000] overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Confirm Deletion
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete the tool "{toolName}"? This
                    action cannot be undone.
                  </p>
                </div>

                <div className="mt-4 flex justify-end space-x-3">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onClose}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  >
                    Cancel
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onConfirm}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                  >
                    Delete
                  </motion.button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle>Tools</PageTitle>

        <Flex direction="row" gap="3">
          <Button
            variant="surface"
            color="iris"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/showcase/tools");
            }}
          >
            Tools Showcase
            <Store size={14} />
          </Button>

          <Button
            variant="solid"
            color="violet"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/dashboard?page=secrets");
            }}
          >
            Secrets
            <Key size={14} />
          </Button>

          <Button
            onClick={() => navigate("/dashboard?page=tools")}
            color="iris"
            variant="solid"
            style={{ cursor: "pointer" }}
          >
            New Tool
            <Zap size={14} />
          </Button>
        </Flex>
      </ActionBar>

      <Wrapper>
        {!loading && tools?.length === 0 && <EmptyState />}

        <Grid>
          {tools?.length > 0 &&
            tools.map((tool, key) => (
              <GridItem
                key={key}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dashboard?page=tools&tool_id=${tool.id}`);
                }}
              >
                {/* <p>{JSON.stringify(tool)}</p> */}
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-4">
                    <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center">
                      <span className="text-2xl font-bold text-indigo-600">
                        {tool?.name?.charAt(0) || "T"}
                      </span>
                    </div>

                    {tool?.public && (
                      <Badge
                        style={{ cursor: "pointer" }}
                        className="text-xs text-gray-500"
                        onClick={(event) => {
                          event.stopPropagation();
                          window.open(
                            `/showcase/tools/${tool?.id?.split("TL-")[1]}`,
                            "_blank",
                          );
                        }}
                      >
                        Public
                        <ExternalLink size={13} />
                      </Badge>
                    )}
                  </div>
                  <div className="flex space-x-4">
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        navigator.clipboard.writeText(tool.id);
                        toast.success("Copied Tool ID to clipboard", {
                          position: "bottom-right",
                        });
                      }}
                      id="copy-id-btn"
                      className="text-gray-400 hover:text-orange-600 transition-colors duration-300"
                    >
                      <Copy size={14} />
                    </button>

                    <button className="text-gray-400 hover:text-indigo-600 transition-colors duration-300">
                      <Edit2 size={14} />
                    </button>

                    <button
                      style={{ zIndex: 900000 }}
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        setToolToDelete(tool);
                        setShowDeleteModal(true);
                      }}
                      className="text-gray-400 hover:text-red-600 transition-colors duration-300"
                    >
                      {loadingDelete.id === tool.id && loadingDelete.loading ? (
                        <Spinner />
                      ) : (
                        <Trash2 size={14} color="#f28482" />
                      )}
                    </button>
                    {/* <button className="text-gray-400 hover:text-red-600 transition-colors duration-300">
                                        <Trash2 size={14} />
                                    </button> */}
                  </div>
                </div>

                <p style={{ fontSize: 16, fontWeight: "500", marginTop: 10 }}>
                  {tool.name}
                </p>
                <p className="text-gray-600 mb-4">
                  {getDescriptionLengthAndTruncate(tool?.description)}
                </p>

                <div className="space-y-2 mt-8 mb-0">
                  <div className="flex items-center text-sm text-gray-500">
                    <Globe size={14} className="mr-4" />
                    <span className="font-medium">URL:</span>
                    <span className="ml-1 truncate">
                      {tool.url || "No URL"}
                    </span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <Code size={14} className="mr-4" />
                    <span className="font-medium">Method:</span>
                    <span className="ml-1">{tool.method || "No Method"}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock size={14} className="mr-4" />
                    <span className="font-medium">Timeout:</span>
                    <span className="ml-1">
                      {tool.timeout ? `${tool.timeout} ms` : "No Timeout"}
                    </span>
                  </div>
                </div>
              </GridItem>
            ))}
        </Grid>
      </Wrapper>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          deleteToolHandler(toolToDelete.id);
          setShowDeleteModal(false);
        }}
        toolName={toolToDelete?.name}
      />
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const GridItem = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 20px;
`;

const DEMO_TOOL_COLLECTION = [
  {
    id: "TL-some-id-random",
    name: "Test Tool",
    description: "Test tool description.",
    url: "https://www.youtube.com",
    method: "GET",
    timeout: 3000,
    tags: ["Hubspot API", "CRM"],
  },
];
