import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import FlowContext from "../contextFlow";
import { FiGlobe, FiAlertCircle, FiRefreshCw } from "react-icons/fi";
import { Tab } from "@headlessui/react";
import axios from "axios";
import { Button } from "@radix-ui/themes";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

const GlobalPrompt = ({ globalPrompt, setGlobalPrompt }) => {
  const { exportFlow } = useContext(FlowContext);
  const [isOpen, setIsOpen] = useState(false);
  const [tempPrompt, setTempPrompt] = useState(globalPrompt || "");
  //   const [prompt, setPrompt] = useState(globalPrompt || "");
  const [logs, setLogs] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const [compressionResult, setCompressionResult] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const getPromptStatus = (length) => {
    if (length <= 500) return { color: "bg-green-500", message: "Good" };
    if (length <= 1000) return { color: "bg-yellow-500", message: "Warning" };
    return { color: "bg-red-500", message: "Critical" };
  };

  const handleCancel = () => {
    setTempPrompt(globalPrompt); // Reset to the original value
    setIsOpen(false);
  };

  useEffect(() => {
    setTempPrompt(globalPrompt);
  }, [globalPrompt]);

  const promptStatus = getPromptStatus(prompt.length);

  useEffect(() => {
    if (!isSaving) return;
    exportFlow();
    setIsSaving(false);
  }, [isSaving]);

  const handleSave = async () => {
    setGlobalPrompt(tempPrompt);
    setIsSaving(true);
    setIsOpen(false);
    setLogs([
      ...logs,
      { timestamp: new Date(), action: "Global prompt updated" },
    ]);
  };

  const compressPrompt = async () => {
    setIsCompressing(true);
    try {
      const response = await axios.post(
        "/api/convo_pathway/compress_prompt",
        { tempPrompt },
        {
          headers: { Authorization: getAuthToken() },
        },
      );
      setCompressionResult(response.data);
      setTempPrompt(response.data.compressed_prompt);
      setLogs([
        ...logs,
        { timestamp: new Date(), action: "Global prompt compressed" },
      ]);
    } catch (error) {
      console.error("Error compressing prompt:", error);
      setLogs([
        ...logs,
        { timestamp: new Date(), action: "Error compressing prompt" },
      ]);
    }
    setIsCompressing(false);
  };

  return (
    <>
      <Button
        variant="surface"
        color="blue"
        size={"1"}
        // className="w-full bg-indigo-600 hover:bg-indigo-700 text-white  py-2 px-4 rounded shadow transition duration-150 ease-in-out flex items-center justify-center"
        onClick={() => setIsOpen(true)}
        style={{
          cursor: "pointer",
        }}
      >
        <FiGlobe size={14} />
        <p style={{ fontSize: 14 }}>Global Prompt</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Global Prompt and Logs
                </Dialog.Title>
                <div className="mt-4">
                  <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 bg-indigo-900/20 rounded-xl">
                      <Tab
                        className={({ selected }) =>
                          `w-full py-2.5 text-sm leading-5 font-medium text-indigo-700 rounded-lg
                          focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60
                          ${
                            selected
                              ? "bg-white shadow"
                              : "text-indigo-100 hover:bg-white/[0.12] hover:text-white"
                          }`
                        }
                      >
                        Global Prompt
                      </Tab>
                      {/* <Tab
                        className={({ selected }) =>
                          `w-full py-2.5 text-sm leading-5 font-medium text-indigo-700 rounded-lg
                          focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60
                          ${selected
                            ? "bg-white shadow"
                            : "text-indigo-100 hover:bg-white/[0.12] hover:text-white"
                          }`
                        }
                      >
                        Logs
                      </Tab> */}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                      <Tab.Panel>
                        <div className="relative">
                          <textarea
                            value={tempPrompt}
                            onChange={(e) => setTempPrompt(e.target.value)}
                            className="w-full h-72 p-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500 resize-y"
                            placeholder="Enter your global prompt here..."
                          />
                          <div className="absolute bottom-0 left-0 right-0 h-2 bg-gray-200 rounded-b-md">
                            <div
                              className={`h-full ${promptStatus.color} rounded-b-md transition-all duration-300 ease-in-out`}
                              style={{
                                width: `${Math.min((tempPrompt.length / 1500) * 100, 100)}%`,
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-2 flex items-center justify-between">
                          <div className="flex items-center">
                            <span
                              className={`inline-block w-3 h-3 rounded-full ${promptStatus.color} mr-2`}
                            ></span>
                            <span className="text-sm font-medium">
                              {promptStatus.message}
                            </span>
                            <div className="relative ml-2 group">
                              <FiAlertCircle className="text-gray-400" />
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                                Having too much content can affect the pathway's
                                ability to follow instructions.
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={compressPrompt}
                            disabled={isCompressing}
                            className={`px-3 py-1 text-sm font-medium text-indigo-600 border border-indigo-600 rounded-md hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isCompressing ? "opacity-50 cursor-not-allowed" : ""}`}
                          >
                            {isCompressing ? (
                              <>
                                <FiRefreshCw className="inline-block mr-1 animate-spin" />
                                Compressing...
                              </>
                            ) : (
                              "Compress Prompt"
                            )}
                          </button>
                        </div>
                        {compressionResult && (
                          <div className="mt-4 p-4 bg-gray-100 rounded-md">
                            <h4 className="text-sm font-medium text-gray-900">
                              Compression Result:
                            </h4>
                            <p className="mt-1 text-sm text-gray-600">
                              Compression Ratio:{" "}
                              {compressionResult.compression_ratio.toFixed(2)}
                            </p>
                            <p className="mt-1 text-sm text-gray-600">
                              Key Elements Preserved:
                            </p>
                            <ul className="list-disc list-inside text-sm text-gray-600">
                              {compressionResult.key_elements_preserved.map(
                                (element, index) => (
                                  <li key={index}>{element}</li>
                                ),
                              )}
                            </ul>
                          </div>
                        )}
                      </Tab.Panel>
                      <Tab.Panel>
                        <div className="h-64 overflow-y-auto">
                          {logs.map((log, index) => (
                            <div
                              key={index}
                              className="mb-2 p-2 bg-gray-100 rounded-md"
                            >
                              <span className="text-sm text-gray-500">
                                {log.timestamp.toLocaleString()}
                              </span>
                              <p className="text-sm">{log.action}</p>
                            </div>
                          ))}
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default GlobalPrompt;
