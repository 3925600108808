import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Download, AlertCircle, ChevronDown, ChevronUp } from "lucide-react";
import { getUserData } from "utils/funcs/browser/getUserData";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

const PaymentHistory = () => {
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState("invoices");
  const user = getUserData();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [invoiceResponse, paymentResponse] = await Promise.all([
          fetch("/api/billing/list_invoices", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: getAuthToken(),
            },
            body: JSON.stringify({ user_id: user.user.id }),
          }),
          fetch("/api/billing/list_payments", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: getAuthToken(),
            },
            body: JSON.stringify({ user_id: user.user.id }),
          }),
        ]);

        if (!invoiceResponse.ok || !paymentResponse.ok) {
          throw new Error(
            "Unable to retrieve billing information at this time.",
          );
        }

        const invoiceData = await invoiceResponse.json();
        const paymentData = await paymentResponse.json();

        setInvoices(invoiceData.data);
        setPayments(paymentData.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.user.id]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Wrapper>
      <Header onClick={toggleExpanded}>
        <h5 className="text-lg font-semibold text-gray-900">Payment History</h5>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </Header>
      <Content $isExpanded={isExpanded}>
        {loading ? (
          <LoadingMessage>Loading payment history...</LoadingMessage>
        ) : error ? (
          <ErrorMessage>
            <AlertCircle size={20} />
            <span>{error}</span>
            <p>
              Please try again later or contact support if the problem persists.
            </p>
          </ErrorMessage>
        ) : invoices.length === 0 && payments.length === 0 ? (
          <NoDataMessage>No payment history available.</NoDataMessage>
        ) : (
          <>
            <div className="flex space-x-4 border-b border-gray-200 mb-4">
              <button
                onClick={() => setActiveTab("invoices")}
                className={`pb-2 px-1 ${
                  activeTab === "invoices"
                    ? "border-b-2 border-indigo-500 text-indigo-600"
                    : "text-gray-500"
                }`}
              >
                Invoices
              </button>
              <button
                onClick={() => setActiveTab("payments")}
                className={`pb-2 px-1 ${
                  activeTab === "payments"
                    ? "border-b-2 border-indigo-500 text-indigo-600"
                    : "text-gray-500"
                }`}
              >
                Payments
              </button>
            </div>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>
                      {activeTab === "invoices" ? "Invoice" : "Payment Method"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {activeTab === "invoices"
                    ? invoices.map((invoice) => (
                        <tr key={invoice.id}>
                          <td>
                            {format(
                              new Date(invoice.created * 1000),
                              "MMM dd, yyyy",
                            )}
                          </td>
                          <td>${(invoice.amount_paid / 100).toFixed(2)}</td>
                          <td>
                            <StatusBadge $status={invoice.status}>
                              {invoice.status.charAt(0).toUpperCase() +
                                invoice.status.slice(1)}
                            </StatusBadge>
                          </td>
                          <td>
                            <a
                              href={invoice.invoice_pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <DownloadButton>
                                <Download size={16} />
                                PDF
                              </DownloadButton>
                            </a>
                          </td>
                        </tr>
                      ))
                    : payments.map((payment) => (
                        <tr key={payment.id}>
                          <td>
                            {format(
                              new Date(payment.created * 1000),
                              "MMM dd, yyyy",
                            )}
                          </td>
                          <td>${(payment.amount / 100).toFixed(2)}</td>
                          <td>
                            <StatusBadge $status={payment.status}>
                              {payment.status.charAt(0).toUpperCase() +
                                payment.status.slice(1)}
                            </StatusBadge>
                          </td>
                          <td>{payment.payment_method_types.join(", ")}</td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </TableWrapper>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default PaymentHistory;

const TableWrapper = styled.div`
  max-height: 600px;
  overflow-y: auto;
  border-top: 1px solid ${({ theme }) => theme.colors?.border || "#e2e8f0"};
`;

const Wrapper = styled.div`
  // Styles remain unchanged
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Content = styled.div`
  max-height: ${({ $isExpanded }) => ($isExpanded ? "650px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.border || "#e2e8f0"};
  }

  th {
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.text || "#1f2937"};
  }

  td {
    color: ${({ theme }) => theme.colors?.secondary || "#4b5563"};
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.colors?.background || "#ffffff"};
  border: 1px solid ${({ theme }) => theme.colors?.border || "#e2e8f0"};
  border-radius: ${({ theme }) => theme.radii?.sm || "0.125rem"};
  color: ${({ theme }) => theme.colors?.text || "#1f2937"};
  font-size: ${({ theme }) => theme.fontSizes?.sm || "0.875rem"};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors?.muted || "#f3f4f6"};
  }
`;

const LoadingMessage = styled.p`
  color: ${({ theme }) => theme.colors?.secondary || "#4b5563"};
  font-style: italic;
  padding: 1rem 0;
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors?.error?.bg || "#fee2e2"};
  color: ${({ theme }) => theme.colors?.error?.text || "#b91c1c"};

  svg {
    color: ${({ theme }) => theme.colors?.error?.text || "#b91c1c"};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes?.sm || "0.875rem"};
    text-align: center;
  }
`;

const NoDataMessage = styled.p`
  color: ${({ theme }) => theme.colors?.secondary || "#4b5563"};
  padding: 1rem 0;
`;

const StatusBadge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  background-color: ${({ $status, theme }) =>
    $status === "paid" || $status === "succeeded"
      ? theme.colors?.success?.bg || "#dcfce7"
      : theme.colors?.warning?.bg || "#fef3c7"};
  color: ${({ $status, theme }) =>
    $status === "paid" || $status === "succeeded"
      ? theme.colors?.success?.text || "#166534"
      : theme.colors?.warning?.text || "#92400e"};
`;
