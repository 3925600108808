import React, { memo, useContext, useEffect, useState } from "react";
import { Handle, Position, useReactFlow, NodeToolbar } from "reactflow";
import { EditIcon } from "assets/icons/navigationIcons";
import NodeIcon from "./nodeIcon";
import NodeModal from "../node_modal";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { GoDuplicate } from "react-icons/go";
import FlowContext from "../../contextFlow";
import { LuClipboardCopy } from "react-icons/lu";
import { toast } from "react-toastify";
import { getUserData } from "utils/funcs/browser/getUserData";

const DefaultNode = (props) => {
  const context = props?.context ?? null;

  const user = getUserData();

  const api_key = user?.api_key;

  const { id, data, type, selected } = props;
  const { setNodes, setEdges } = useReactFlow();
  const { deleteElement, duplicateNode } = useContext(FlowContext);
  let [isOpen, setIsOpen] = useState(false);

  const [transferPathwayName, setTransferPathwayName] = useState("");
  const [transferPathwayNodeName, setTransferPathwayNodeName] = useState("");

  const copyNode = () => {
    navigator.clipboard.writeText(JSON.stringify(props));
    toast("Node copied to clipboard. Command + V to paste node", {
      type: "success",
      autoClose: 2000,
      position: "bottom-right",
    });
  };

  useEffect(() => {
    const fetchPathwayName = async () => {
      //   console.log("type", type, data.transferPathway);
      if (type === "Transfer Pathway") {
        if (data.transferPathway && api_key) {
          const pathwayResponse = await fetch(
            `https://us.api.bland.ai/v1/convo_pathway/${data.transferPathway}`,
            {
              method: "GET",
              headers: {
                authorization: api_key,
              },
            },
          );
          if (pathwayResponse.ok) {
            const pathwayData = await pathwayResponse.json();
            setTransferPathwayName(pathwayData.name);
            if (pathwayData.nodes && data.transferPathwayNode) {
              console.log("transfer pathway node", data.transferPathwayNode);
              const node = pathwayData.nodes.find(
                (node) => node.id === data.transferPathwayNode,
              );
              console.log("node found", node);
              setTransferPathwayNodeName(node?.data?.name);
            }
          } else {
            console.error("Failed to fetch pathway data");
          }
        }
      }
    };
    fetchPathwayName();
  }, [type, data.transferPathway]);

  return (
    <>
      <NodeToolbar position={"right"} align="start">
        <div className="flex flex-col gap-2 divide-y justify-center rounded-xl px-2 py-2 pt-4 border border-gray-200 z-[100] bg-white">
          <button className="text-gray-600">
            <MdDeleteOutline size={14} onClick={deleteElement} />
          </button>
          <div className="pt-2">
            <button className="text-gray-600">
              <GoDuplicate size={14} onClick={duplicateNode} />
            </button>
          </div>
          <div className="pt-2">
            <button className="text-gray-600">
              <LuClipboardCopy size={14} onClick={copyNode} />
            </button>
          </div>
        </div>
      </NodeToolbar>

      <div
        className={`rounded-lg w-[320px] border border-gray-200 ${
          data.isGlobal
            ? "border-4 border-yellow-300 bg-yellow-100"
            : "bg-white"
        } ${selected ? "border-2 !border-indigo-500" : ""} ${
          data.active ? "ring-2 ring-blue-500 shadow-lg shadow-blue-200" : ""
        }`}
        onDoubleClick={() => setIsOpen(true)}
      >
        {/* Container for the content, which allows the divider to extend fully */}
        <div
          className={`divide-y ${
            data.isGlobal ? "divide-yellow-300" : "divide-gray-200"
          }`}
        >
          <div className="p-6 h-[48px] flex items-center">
            {data.isGlobal && (
              <div className="text-lg absolute top-0 right-0 bg-yellow-500 text-white px-[4px] py-[1px] rounded-bl-lg text-sm">
                Global
              </div>
            )}
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center space-x-4">
                <span>
                  <NodeIcon
                    isStart={data.isStart}
                    isGlobal={data.isGlobal}
                    type={type}
                  />
                </span>
                <h2 className="text-[13px] font-medium leading-5 line-clamp-1">
                  {data.name}
                </h2>
              </div>
              <div className="text-gray-400 text-[11px] leading-4 font-medium">
                {type}
              </div>
              {/* <button onClick={() => setIsOpen(true)}>
              <EditIcon />
            </button> */}
            </div>
          </div>
          {/* This is the second part after the divider */}
          <div className="p-6">
            <div className="flex items-center gap-4">
              <p className="line-clamp-2 leading-[18px] font-normal text-[12px]">
                {data.text || data.prompt}
              </p>
            </div>

            {type === "SMS" && (
              <div className="flex-col items-center justify-center gap-4">
                <p className="text-[12px] font-medium line-clamp-2">
                  SMS Message -{" "}
                  <span className="font-normal">{data.message}</span>
                </p>
              </div>
            )}

            {type === "Transfer Pathway" && (
              <div className="flex flex-col justify-center space-y-4 mt-4">
                <p className="text-[12px] font-medium">
                  Pathway Name -{" "}
                  <span className="font-normal">{transferPathwayName}</span>
                </p>
                <p className="text-[12px] font-medium">
                  Start Node -{" "}
                  <span className="font-normal">{transferPathwayNodeName}</span>
                </p>
                <p className="text-[12px] font-medium">
                  Pathway Version -{" "}
                  <span className="font-normal">{data.version}</span>
                </p>
                <div className="mt-2">
                  <a
                    className="text-[12px] font-medium text-indigo-600 hover:underline"
                    href={`https://app.bland.ai/dashboard?page=convo-pathways&id=${data?.transferPathway}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Pathway
                  </a>
                </div>
              </div>
            )}

            {type === "Press Button" && (
              <div className="flex items-center gap-4 mt-4">
                <p className="text-[12px] font-normal line-clamp-2">
                  {data.digit ? (
                    <>
                      Button Digit -{" "}
                      <span className="font-normal">{data.digit}</span>
                    </>
                  ) : data.sequence ? (
                    <>
                      Sequence of Digits -{" "}
                      <span className="font-normal">{data.sequence}</span>
                    </>
                  ) : (
                    <>
                      Agent Decision -{" "}
                      <span className="font-normal">{data.digitPrompt}</span>
                    </>
                  )}
                </p>
              </div>
            )}

            {type === "Custom Tool" && (
              <div className="flex-col items-center justify-center gap-4 mt-4">
                <p className="text-[12px] font-medium mb-4">
                  Tool Name -{" "}
                  <span className="font-normal">{data?.tool?.tool?.name}</span>
                </p>
                <p className="text-[12px] font-medium">
                  Tool URL -{" "}
                  <span className="font-normal">
                    {data?.is_staging
                      ? data?.tool?.staging_tool?.url
                      : data?.tool?.tool?.url}
                  </span>
                </p>

                {data?.is_staging && (
                  <p className="text-[12px] font-medium mt-2">
                    Staging - <span className="font-normal">Enabled</span>
                  </p>
                )}
              </div>
            )}

            <div className="flex items-center gap-4 mt-4">
              {data.transferNumber && (
                <p className="border border-gray-200 bg-gray-100 flex items-center justify-center px-[8px] py-[6px] rounded-md text-[10px]">
                  {data?.transferNumber}
                </p>
              )}
              {data.url && (
                <div className="border border-gray-200 bg-gray-100 rounded-md p-2 mt-2">
                  <p className="text-[10px] line-clamp-2 break-all overflow-hidden">
                    {data?.url}
                  </p>
                </div>
              )}
            </div>

            {(!data.isGlobal || data.enableGlobalAutoReturn === false) && (
              <>
                {!data.isStart && !data.isGlobal && (
                  <Handle
                    key={`target-${id}`}
                    type="target"
                    position={Position.Top}
                    className="!w-3 !h-3 !bg-indigo-500"
                  />
                )}
                {(type !== "Transfer Call" ||
                  (data.warmTransferFields?.isEnabled &&
                    !data.warmTransferFields?.useCustomHoldMusic)) &&
                  type !== "End Call" &&
                  type !== "Transfer Pathway" && (
                    <Handle
                      type="source"
                      position={Position.Bottom}
                      className="!w-3 !h-3 !bg-indigo-500"
                    />
                  )}
              </>
            )}
          </div>
          {data?.tag && (
            <div
              className="px-6 py-4"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 10,
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  background: data?.tag?.color,
                  borderRadius: "50%",
                }}
              />
              <p style={{ fontSize: 12, fontWeight: "500" }}>
                {data?.tag?.name}
              </p>
            </div>
          )}
        </div>
        <NodeModal
          id={id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNodes={setNodes}
          setEdges={setEdges}
          data={data}
          type={type}
          context={context}
        />
      </div>
    </>
  );
};

export default memo(DefaultNode);
