import React, { useState, useEffect } from "react";
import { X, Repeat, Search } from "lucide-react";
import { getAuthToken } from "utils/funcs/browser/getAuthToken.js";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { Loading } from "components/core/Loading";
import { convertToMinutesAndSeconds } from "utils/formatting/secsToMins";
import { toDtmy } from "utils/formatting/toDtmy";
import { motion, AnimatePresence } from "framer-motion";
import CallDetailsSlider from "./CallDetailsSlider";
import { IconButton } from "@radix-ui/themes";
import { TextField } from "@radix-ui/themes";

const CallLogsModal = ({
  isOpen,
  onClose,
  pathwayId,
  handleReplayCall,
  isReplayLoading,
}) => {
  const [callLogs, setCallLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageSize] = useState(25);
  const [currPage, setCurrPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCallId, setSelectedCallId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchCallLogs();
    }
  }, [isOpen, pathwayId, currPage, searchTerm]);

  const fetchCallLogs = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `/api/user/logs?${new URLSearchParams({
          pageSize: pageSize,
          page: currPage + 1,
          pathway_id: pathwayId,
          search: searchTerm,
        })}`,
        {
          method: "GET",
          headers: {
            Authorization: getAuthToken(),
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch call logs");
      }

      const data = await response.json();
      setCallLogs(formatTableData(data.calls));
      setTotalRecords(data.totalCount);
    } catch (err) {
      setError("An error occurred while fetching call logs");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formatTableData = (data) => {
    return data.map((call, index) => ({
      id: index,
      created_at: toDtmy(new Date(call.created_at)),
      to: call.to,
      from: call.from,
      call_length: convertToMinutesAndSeconds(call.call_length),
      inbound: call.inbound ? "Inbound" : "Outbound",
      transferred_to: call.transferred_to || "-",
      cost: `$${call.price ? parseFloat(call.price).toFixed(2) : "0.00"}`,
      status: call.completed ? "Completed" : "In Progress",
      c_id: call.c_id,
      pathway_logs: call.pathway_logs
        ? JSON.stringify(call.pathway_logs)
        : "N/A",
      variables: call.variables,
      metadata: call.metadata ? JSON.stringify(call.metadata) : "N/A",
      batch_id: call.batch_id || "-",
      pathway_id: call.pathway_id || "-",
      pathway_tags: call.pathway_tags || "-",
      pathway_name: call.pathway_name || "-",
      answered_by: call.answered_by || "-",
      error_message: call.error_message || "-",
      call_ended_by: call.call_ended_by || "-",
    }));
  };

  const columns = [
    {
      field: "created_at",
      headerName: "Created On",
      width: 130,
    },
    {
      field: "replay",
      headerName: "Replay",
      width: 65,
      renderCell: (params) => (
        <IconButton
          variant="soft"
          size={1}
          disabled={isReplayLoading}
          onClick={(e) => {
            e.stopPropagation();
            handleReplayCall(params.row.c_id);
          }}
          style={{ cursor: "pointer" }}
        >
          {isReplayLoading ? (
            <Loading size={1} loading={isReplayLoading} />
          ) : (
            <Repeat size={20} />
          )}
        </IconButton>
      ),
    },
    {
      field: "call_length",
      headerName: "Call Length",
      width: 90,
    },
    { field: "to", headerName: "To", width: 110 },
    { field: "from", headerName: "From", width: 110 },
    { field: "c_id", headerName: "Call ID", width: 240 },
  ];

  const handleRowClick = (params) => {
    setSelectedCallId(params.id);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrPage(0); // Reset to first page when searching
  };

  return (
    <ModalWrapper
      initial={{ x: "100%" }}
      animate={{ x: isOpen ? 0 : "100%" }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <div
        style={{ height: 53 }}
        className="p-4 border-b border-gray-200 flex justify-between items-center"
      >
        <h2 className="text-xl font-semibold text-gray-800">Call Logs</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={20} />
        </button>
      </div>
      <SearchBarWrapper>
        <TextField.Root
          placeholder="Search call logs by ID or phone number"
          value={searchTerm}
          onChange={handleSearchChange}
          type="search"
        >
          <TextField.Slot>
            <Search size={16} />
          </TextField.Slot>
        </TextField.Root>
      </SearchBarWrapper>
      <Wrapper>
        <DataGrid
          columns={columns}
          rows={callLogs}
          pageSize={pageSize}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={(newPage) => setCurrPage(newPage)}
          page={currPage}
          autoHeight={false}
          disableColumnMenu
          disableSelectionOnClick
          loading={loading}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            Row: loading ? () => null : undefined,
          }}
          isRowSelectable={() => false}
          onRowClick={handleRowClick}
          sx={{
            height: "100%",
            width: "100%",
            "& .MuiDataGrid-main": { flexGrow: 1 },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 600,
              fontSize: "12px",
            },
            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
              {
                outline: "none !important",
              },
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none !important",
              background: "transparent !important",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "11px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              position: "sticky",
              top: 0,
              zIndex: 1,
            },
            "& .MuiDataGrid-row:hover": {
              background: "#FAFAFA !important",
              cursor: "pointer",
            },
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0!important",
            },
            borderRadius: "4px",
          }}
        />
        {error && <div className="text-red-500 text-center py-4">{error}</div>}
      </Wrapper>
      <AnimatePresence>
        {selectedCallId !== null && (
          <CallDetailsSlider
            callData={callLogs[selectedCallId]}
            onClose={() => setSelectedCallId(null)}
            handleReplayCall={handleReplayCall}
          />
        )}
      </AnimatePresence>
    </ModalWrapper>
  );
};

const SearchBarWrapper = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
`;

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CustomLoadingOverlay = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    }}
  >
    <Loading loading={true} />
  </div>
);

export default CallLogsModal;
