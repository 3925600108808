import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import WaveSurfer from "wavesurfer.js";
import { IconButton } from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";
import { RotateCw } from "lucide-react";
import { DownloadIcon } from "@radix-ui/react-icons";

const AudioPlayer = React.memo(
  ({ recordingUrl, onClose, autoplay = false }) => {
    const [isPlaying, setIsPlaying] = useState(autoplay);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [cursorPosition, setCursorPosition] = useState(0);
    const waveformRef = useRef(null);
    const waveformId = useRef(
      `waveform-${Math.random().toString(36).substr(2, 9)}`,
    );

    const initializeWaveform = useCallback(() => {
      if (waveformRef.current) {
        waveformRef.current.destroy();
      }

      const wavesurfer = WaveSurfer.create({
        container: `#${waveformId.current}`,
        waveColor: "#B3C9FB",
        progressColor: "#3A5BC8",
        height: 35,
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        cursorWidth: 2,
        cursorHeight: 6,
        cursorColor: "#000000", // Color of the vertical progress bar (grey)
        responsive: true,
        interact: true,
        fillParent: true,
        normalize: true,
        barHeight: 0.8,
      });

      waveformRef.current = wavesurfer;

      wavesurfer.load(recordingUrl);
      wavesurfer.on("ready", () => {
        setDuration(wavesurfer.getDuration());
        setIsLoading(false);
        if (autoplay) {
          wavesurfer.play();
        }
      });
      wavesurfer.on("audioprocess", () => {
        const currentTime = wavesurfer.getCurrentTime();
        setCurrentTime(currentTime);
        setCursorPosition(
          wavesurfer.getCurrentTime() / wavesurfer.getDuration(),
        );
      });
      wavesurfer.on("seek", (progress) => {
        const currentTime = progress * wavesurfer.getDuration();
        setCurrentTime(currentTime);
        setCursorPosition(progress);
      });
    }, [recordingUrl, duration, autoplay]);

    useEffect(() => {
      setIsLoading(true);
      initializeWaveform();
      return () => {
        if (waveformRef.current) {
          waveformRef.current.destroy();
        }
      };
    }, [initializeWaveform]);

    const togglePlayPause = useCallback(() => {
      if (waveformRef.current) {
        if (isPlaying) {
          waveformRef.current.pause();
        } else {
          waveformRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    }, [isPlaying]);

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    const handleKeyDown = useCallback(
      (event) => {
        // check if self or parents have ignore-audio-player class
        if (document.activeElement.closest(".ignore-audio-player")) {
          return;
        }

        if (event.code === "Space") {
          event.preventDefault();
          togglePlayPause();
        } else if (event.code === "ArrowRight") {
          event.preventDefault();
          if (waveformRef.current) {
            const newTime = Math.min(
              waveformRef.current.getCurrentTime() + 1,
              duration,
            );
            waveformRef.current.seekTo(newTime / duration);
          }
        } else if (event.code === "ArrowLeft") {
          event.preventDefault();
          if (waveformRef.current) {
            const newTime = Math.max(
              waveformRef.current.getCurrentTime() - 1,
              0,
            );
            waveformRef.current.seekTo(newTime / duration);
          }
        }
      },
      [togglePlayPause, duration],
    );

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);

    const handleDownload = useCallback(() => {
      window.open(recordingUrl, "_blank");
    }, [recordingUrl]);

    return (
      <AudioPlayerWrapper tabIndex="0">
        <IconButton
          onClick={togglePlayPause}
          disabled={isLoading}
          size="1"
          variant="surface"
          color="green"
          style={{ cursor: "pointer", marginTop: "6px" }}
        >
          {isLoading ? (
            <SpinningIcon />
          ) : isPlaying ? (
            <Icons.PauseIcon />
          ) : (
            <Icons.PlayIcon />
          )}
        </IconButton>
        <WaveformContainer>
          <div id={waveformId.current} />
        </WaveformContainer>
        <TimeDisplay style={{ marginTop: "10px" }}>
          {formatTime(currentTime)} / {formatTime(duration)}
        </TimeDisplay>
        <IconButton
          onClick={handleDownload}
          size="1"
          style={{ cursor: "pointer", marginTop: "6px" }}
          variant="surface"
          color="blue"
        >
          <DownloadIcon color="blue" />
        </IconButton>
        <IconButton
          onClick={onClose}
          size="1"
          style={{ cursor: "pointer", marginTop: "6px" }}
          variant="surface"
          color="gray"
        >
          <Icons.Cross1Icon color="grey" />
        </IconButton>
      </AudioPlayerWrapper>
    );
  },
);

const AudioPlayerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  &:focus {
    outline: none;
  }
`;

const WaveformContainer = styled.div`
  position: relative;
  flex-grow: 1;
  height: 43px;
`;

const TimeDisplay = styled.div`
  margin-top: 3px;
  font-size: 1.1rem;
  min-width: 50px;
`;

const SpinningIcon = styled(RotateCw)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
`;

export default AudioPlayer;
