import { Mixpanel } from "components/Mixpanel";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import { useEffect, useState } from "react";
import { getUserData } from "utils/funcs/browser/getUserData";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import axios from "axios";
import styled from "styled-components";
import Language from "../Parameters/Language";
import Voice from "../Parameters/Voice";
import InterruptionThresh from "../Parameters/InterruptionThresh";
import PromptSendCallPage from "../Parameters/PromptSendCallPage";
import Model from "../Parameters/Model";
import TransferNumber from "../Parameters/TransferNumber";
import AnsweredBy from "../Parameters/AnsweredBy";
import Record from "../Parameters/Record";
import MaxDuration from "../Parameters/MaxDuration";
import Button from "components/core/Button";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ActionBar from "components/core/ActionBar";
import CodePreview from "./CodePreview";
import WaitGreeting from "../Parameters/WaitGreeting";
import ActiveCall from "./ActiveCall";
import Temperature from "../Parameters/Temperature";
import ReactPhoneInput from "components/core/PhoneInput";
import RequestData from "../Parameters/RequestData";
import Switch from "components/core/Switch";
import JSONMode from "./JSONMode";
import TransferList from "../Parameters/TransferList";
import Pronunciation from "../Parameters/Pronunciation";
import "react-toastify/dist/ReactToastify.css";
import Metadata from "../Parameters/Metadata";
import CustomDeployment from "../Parameters/CustomDeployment";
import EncryptedKey from "../Parameters/EncryptedKey";
import Webhook from "../Parameters/Webhook";
import AMD from "../Parameters/AMD";
import Voicemail_message from "../Parameters/voicemailMessage";
import Modal from "components/core/Modal";
import TrashIcon from "../../../assets/icons/trash-grey-fig.svg";
import CloseIcon from "../../../assets/icons/close-icon-black.svg";
import BlockInterruptions from "../Parameters/BlockInterruptions";
import VoicemailActionSelect from "../Parameters/VoicemailAction";
import DynamicData from "../PhoneNumbers/Inbound/DynamicData/DynamicData";
import Pathway from "../Parameters/Pathway";
import AnalysisSchemaCo from "../Parameters/AnalysisSchema";
import AnalysisPreset from "../Parameters/AnalysisPreset";
import SMSForm from "../Parameters/SMS";
import VoicemailSMS from "../Parameters/voicemailSMS";
import usePhoneNumbers from "hooks/usePhoneNumbers";
import PhoneNumberSelector from "components/core/PhoneNumberSelector";
import SummaryPrompt from "../Parameters/SummaryPrompt";
import WebhookEvents from "../Parameters/WebhookEvents";

export default function SendCall() {
  const [callId, setCallId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [collapseCode, setCollapseCode] = useState(false);
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [promptName, setPromptName] = useState("");
  const [loadingPrompt, setLoadingPrompt] = useState(false);
  // Initializae the form and its default field values.
  const methods = useForm({
    defaultValues: {
      phone_number: "",
      from: null,
      task: "",
      voice: "nat",
      wait_for_greeting: false,
      record: false,
      local_dialing: false,
      amd: false,
      answered_by_enabled: false,
      interruption_threshold: 100, // 50 is the default. 500 is utterly insane and will make people think the AI is unbelievably slow.
      // voicemail_message: "",
      temperature: null, // we want people to use the default temperatures, since they're different for each model. This should not be set unless they choose to override it.
      transfer_phone_number: null,
      block_interruptions: false,
      transfer_list: {},
      first_sentence: null,
      max_duration: 12,
      model: "enhanced",
      language: "en",
      start_time: null,
      request_data: [],
      analysis_schema: [],
      pronunciation_guide: [],
      webhook: null,
      prompt_modal: false,
      selected_saved_prompt_id: null,
      selected_edit_prompt_id: null,
      save_prompt_modal: false,
      calendly_url: "",
      calendly_timezone: "America/Chicago",
      encrypted_key: null,
      metadata: null,
      pathway_id: null,
      dynamic_data: null,
      voicemail_sms: null,
      endpoint: "https://us.api.bland.ai",
      voicemail_action: "hangup",
      voicemail_message: null,
      summary_prompt: null,
      webhook_events: null,
      sms: null,
      save_prompt: () => {
        return methods.setValue("save_prompt_modal", true);
      },
    },
  });

  // For conditionally rendering the call transcript.
  const isCallActive = methods.watch("isCallActive");
  const jsonMode = methods.watch("json_mode_enabled");
  const promptModal = methods.watch("prompt_modal");
  const savePromptModal = methods.watch("save_prompt_modal");

  // Get user's API key for authorization in sending the call.
  const { api_key: apiKey, user } = getUserData();

  const {
    inboundNumbers,
    twilioNumbers,
    loading: phoneNumbersLoading,
    error: phoneNumbersError,
  } = usePhoneNumbers();

  // Main submit handler for sending a call.
  const onSubmit = async (formData) => {
    // Data Prepping
    const headers = {
      Authorization: apiKey,
      encrypted_key: formData.encrypted_key ?? undefined,
    };
    const from = formData.from?.length <= 0 ? null : formData.from;
    const requestData = KeyValuesToObj(formData.request_data);
    const analysisSchema = KeyValuesToObj(formData.analysis_schema);
    const transferList = KeyValuesToObj(formData.transfer_list);
    const metaData = KeyValuesToObj(formData.metadata);

    const transferPhone =
      formData.transfer_phone_number?.length <= 0
        ? null
        : formData.transfer_phone_number;
    // Main Payload
    const data = {
      phone_number: formData?.phone_number?.includes("+")
        ? (formData.phone_number ?? "")
        : (`+${formData.phone_number}` ?? ""),
      from: from,
      transfer_phone_number: transferPhone,
      transfer_list: transferList,
      language: formData.language,
      task: formData.task || "",
      reduce_latency: formData.reduce_latency,
      request_data: requestData,
      analysis_preset: formData.analysis_preset || null,
      analysis_schema: analysisSchema,
      pronunciation_guide: formData.pronunciation_guide,
      voicemail_action: formData.voicemail_action || "hangup",
      voicemail_message: formData.voicemail_message || null,
      voice: formData.voice,
      sms: formData.sms || null,
      pathway_id: formData.pathway_id || null,
      voicemail_sms: formData.voicemail_sms
        ? {
            message: formData.voicemail_sms,
          }
        : null,
      //voice_settings: formData.voice_settings[0],
      interruption_threshold: formData.interruption_threshold || 0.5,
      // voicemail_message: formData.voicemail_message || "",

      temperature: formData.temperature ?? undefined,
      answered_by_enabled: formData.answered_by_enabled,
      start_time: formData.start_time
        ? `${new Date(formData.start_time).toISOString().split(".")[0]}Z`
        : null,
      first_sentence: formData.first_sentence,
      record: formData.record,
      local_dialing: formData.local_dialing,
      max_duration: formData.max_duration,
      model: formData.model,
      summary_prompt: formData.summary_prompt,
      webhook_events: formData.webhook_events,
      metadata: metaData,
      webhook: formData.webhook || null,
      amd: formData.amd || false,
      dynamic_data: formData.dynamic_data.map((item) => {
        const newItem = {};
        for (const key in item) {
          if (key === "response_data") {
            newItem[key] = item[key];
          } else {
            newItem[key] = Array.isArray(item[key])
              ? KeyValuesToObj(item[key])
              : item[key];
          }
        }
        return newItem;
      }),
      // tools: tools || [],
      wait_for_greeting: formData.wait_for_greeting,
      block_interruptions: formData.block_interruptions,
    };

    if (formData?.calendly_url) {
      const crosscheck = [
        "America/New_York",
        "America/Chicago",
        "America/Los_Angeles",
      ];
      if (!crosscheck.includes(formData?.calendly_timezone)) {
        return toast.error(
          "Invalid Timezone for Calendly. Please use America/New_York, America/Chicago, or America/Los_Angeles. ",
        );
      }

      if (
        !formData?.calendly_url?.includes("https://") ||
        !formData?.calendly_url?.toLowerCase()?.includes("calendly")
      ) {
        return toast.error("Invalid Calendly Event Link.");
      }

      data.calendly = {
        url: formData?.calendly_url,
        timezone: formData?.calendly_timezone || "America/Chicago",
      };
    }

    try {
      // Remove the test error
      setLoading(true);
      const response = await axios.post(
        `${formData?.endpoint || "https://us.api.bland.ai"}/v1/calls`,
        data,
        {
          headers,
        },
      );
      if (response.data.call_id) {
        toast.success("Call sent!");
        setCallId(response.data.call_id);
        methods.setValue("isCallActive", true);
        setLoading(false);
        Mixpanel.track("Sent a test call");
        // navigate(`/dashboard?page=analytics&tab=realtime_logs&call_id=${response?.data?.call_id}`);
      }
    } catch (error) {
      console.error("Error submitting send call form:", error);

      let errorMessage = "An error occurred while submitting the call form.";

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data?.errors?.toString() ||
          error.response.data?.message?.toString() ||
          errorMessage;
        console.log("Server responded with error:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server.";
        console.log("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
        console.log("Error setting up request:", error.message);
      }

      toast(errorMessage, {
        type: "error",
      });

      setLoading(false);
    }
  };

  const handleDeletePrompt = async (promptId) => {
    const response = await fetch(
      `https://us.api.bland.ai/v1/prompts/${promptId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: apiKey,
        },
      },
    );

    if (response.ok && response.status === 200) {
      toast.success("Prompt deleted successfully.");
      handleGetSavedPrompts();
    } else {
      toast.error("Error deleting prompt. Please try again.");
    }
  };

  const handleGetSavedPrompts = async () => {
    const response = await fetch("https://us.api.bland.ai/v1/prompts", {
      method: "GET",
      headers: {
        Authorization: apiKey,
      },
    });

    if (response.ok && response.status === 200) {
      const data = await response.json();
      const prompts = data.prompts;
      if (prompts.length > 0) {
        setSavedPrompts(prompts);
      }
    }
  };

  const handleUpdatePrompt = async () => {
    setLoadingPrompt(true);
    const promptId = methods.getValues("selected_edit_prompt_id");
    const prompt = savedPrompts.find((prompt) => prompt.id === promptId);
    const response = await fetch(
      `https://us.api.bland.ai/v1/prompts/${prompt.id}`,
      {
        method: "POST",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: methods.getValues("task"),
        }),
      },
    );

    if (response.ok && response.status === 200) {
      toast.success("Prompt updated successfully.");
      methods.setValue("selected_edit_prompt_id", null);
      methods.setValue("selected_saved_prompt_id", null);
      methods.setValue("save_prompt_modal", false);
      setPromptName("");
      handleGetSavedPrompts();
      setLoadingPrompt(false);
    } else {
      setLoadingPrompt(false);
      toast.error("Error updating prompt. Please try again.");
    }
  };

  const handleCreatePrompt = async () => {
    setLoadingPrompt(true);
    const response = await fetch("https://us.api.bland.ai/v1/prompts", {
      method: "POST",
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: methods.getValues("task"),
        name: promptName,
      }),
    });

    if (response.ok && response.status === 200) {
      toast.success("Prompt saved successfully.");
      methods.setValue("selected_edit_prompt_id", null);
      methods.setValue("selected_saved_prompt_id", null);
      methods.setValue("prompt_modal", false);
      methods.setValue("save_prompt_modal", false);
      setPromptName("");
      handleGetSavedPrompts();
      setLoadingPrompt(false);
    } else {
      setLoadingPrompt(false);
      toast.error("Error saving prompt. Please try again.");
    }
  };

  // Analytics
  useEffect(() => {
    Mixpanel.track("Call page");
    handleGetSavedPrompts();
  }, []);

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          {isCallActive && <ActiveCall callId={callId} apiKey={apiKey} />}
          {!isCallActive && (
            <>
              <input type="hidden" {...methods.register("isCallActive")} />
              <>
                <ActionBar top spaceBetween>
                  <PageTitle>Send Call</PageTitle>
                  <div className="flex flex-row gap-5">
                    {!jsonMode && (
                      <Button
                        appearance="outline"
                        style={{ justifyContent: "start" }}
                        onClick={() => {
                          setCollapseCode(!collapseCode);
                        }}
                      >
                        {collapseCode ? "Show code" : "Hide code"}
                      </Button>
                    )}
                    <Switch label="JSON Mode" fieldName="json_mode_enabled" />
                  </div>
                </ActionBar>
                {!jsonMode ? (
                  <Grid showCode={!collapseCode}>
                    <FieldWrapper>
                      <Model fieldName="model" />
                      <ReactPhoneInput fieldName="phone_number" />
                      <PhoneNumberSelector
                        fieldName="from"
                        userNumbers={inboundNumbers}
                        byotNumbers={twilioNumbers}
                        loading={phoneNumbersLoading}
                        error={phoneNumbersError}
                      />
                      <EncryptedKey fieldName="encrypted_key" />
                      <PromptSendCallPage fieldName="task" />
                      {user && user?.sms_enabled && <SMSForm fieldName="sms" />}
                      <Pathway fieldName="pathway_id" />
                      <Language fieldName="language" />
                      <Voice fieldName="voice" apiKey={apiKey} />
                      {/*
                                 These are commented out intentionally 
                                 - it's available through the API, but it's too easy for less-tech-savvy-users to ruin their own calls by changing this one.
                                 - it also causes a ton of performance issues when changed because we can almost never cache audio with non-default values.

                                    <VoiceSettings fieldName="voice_settings" /> 
                                 */}
                      <Temperature fieldName="temperature" />
                      <InterruptionThresh fieldName="interruption_threshold" />
                      <TransferNumber fieldName="transfer_phone_number" />
                      <TransferList fieldName="transfer_list" />
                      <Metadata fieldName="metadata" />
                      <Pronunciation fieldName="pronunciation_guide" />
                      <VoicemailActionSelect fieldName="voicemail_action" />
                      <Voicemail_message fieldName="voicemail_message" />
                      {user && user?.sms_enabled && (
                        <VoicemailSMS fieldName="voicemail_sms" />
                      )}
                      {/*<StartTime fieldName="start_time" />*/}
                      <AnsweredBy fieldName="answered_by_enabled" />
                      <WaitGreeting fieldName="wait_for_greeting" />
                      <BlockInterruptions fieldName="block_interruptions" />
                      <Record fieldName="record" />
                      <AMD fieldName="amd" />
                      {/*{user.local_enabled && (
                        <LocalDialing fieldName={"local_dialing"} />
                      )}*/}
                      {/*<Calendly fieldName="calendly_url" />*/}
                      <MaxDuration fieldName="max_duration" />
                      <Webhook fieldName="webhook" />
                      <WebhookEvents fieldName="webhook_events" />
                      <CustomDeployment fieldName="endpoint" />
                      <RequestData fieldName="request_data" />
                      <AnalysisPreset fieldName="analysis_preset" />
                      <AnalysisSchemaCo
                        label={false}
                        fieldName="analysis_schema"
                      />
                      <SummaryPrompt fieldName="summary_prompt" />
                      <DynamicData fieldName="dynamic_data" />
                      {/* <Calendly urlFieldName={"calendly_url"} timezoneFieldName={"calendly_timezone"} /> */}
                    </FieldWrapper>
                    <CodePreview apiKey={apiKey} collapseCode={collapseCode} />
                  </Grid>
                ) : (
                  <JSONMode />
                )}
                <ActionBar bottom>
                  <div
                    style={{
                      width: "100%",
                      marginLeft: 15,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      submit
                      loading={loading}
                      style={{
                        borderRadius: "0px",
                      }}
                    >
                      SEND CALL
                      <span style={{ marginLeft: "6px" }}>
                        <ArrowOutwardIcon fontSize="large" />
                      </span>
                    </Button>

                    <a
                      href="https://docs.bland.ai/api-v1/post/calls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button appearance="outline">
                        Check out all of our API features here in the docs
                      </Button>
                    </a>
                  </div>
                </ActionBar>
              </>
            </>
          )}
        </Form>
      </FormProvider>

      <Modal
        open={promptModal}
        onClose={() => {
          methods.setValue("prompt_modal", false);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{ margin: 0, padding: 0, fontWeight: "500", fontSize: 18 }}
            >
              Saved Prompts ({savedPrompts.length})
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                methods.setValue("prompt_modal", false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "25px",
            }}
          >
            {savedPrompts.length > 0 ? (
              savedPrompts.map((prompt, index) => (
                <PromptItem
                  key={index}
                  onClick={() => {
                    methods.setValue("task", prompt.prompt);
                    methods.setValue("prompt_modal", false);
                    methods.setValue("selected_saved_prompt_id", prompt.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: "500" }}>
                      {prompt?.name || "My Prompt"}
                    </p>
                    <img
                      onClick={() => handleDeletePrompt(prompt.id)}
                      height={14}
                      width={14}
                      src={TrashIcon}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      marginTop: "2.5px",
                      color: "#555",
                    }}
                  >
                    {prompt?.prompt?.length > 400
                      ? prompt.prompt.slice(0, 400) + "..."
                      : prompt.prompt}
                  </p>
                </PromptItem>
              ))
            ) : (
              <p>No prompts saved yet.</p>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        open={savePromptModal}
        onClose={() => {
          methods.setValue("save_prompt_modal", false);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{ margin: 0, padding: 0, fontWeight: "550", fontSize: 16 }}
            >
              Save Prompt
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                methods.setValue("save_prompt_modal", false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "25px",
            }}
          >
            <input
              onFocus={() => {
                methods.setValue("selected_edit_prompt_id", null);
              }}
              type="text"
              placeholder="Enter a name for your prompt"
              onChange={(e) => setPromptName(e.target.value)}
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #f0f0f0",
              }}
            />

            {savedPrompts.length > 0 && (
              <div>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: "550",
                    color: "grey",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  - OR -
                </p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Update a saved prompt:
                </p>
                {savedPrompts.map((prompt, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (
                        methods.getValues("selected_edit_prompt_id") ===
                        prompt.id
                      ) {
                        methods.setValue("selected_edit_prompt_id", null);
                      } else {
                        methods.setValue("selected_edit_prompt_id", prompt.id);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      border:
                        methods.getValues("selected_edit_prompt_id") ===
                        prompt.id
                          ? "1px solid blue"
                          : "1px solid #f0f0f0",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: "500" }}>
                      {prompt?.name || "My Prompt"}{" "}
                      {methods.getValues("selected_edit_prompt_id") ===
                        prompt.id && "(Currently Editing)"}
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        marginTop: "2.5px",
                        color: "#555",
                      }}
                    >
                      {prompt?.prompt?.length > 400
                        ? prompt.prompt.slice(0, 400) + "..."
                        : prompt.prompt}
                    </p>
                  </div>
                ))}
              </div>
            )}

            <Button
              loading={loadingPrompt}
              onClick={() => {
                if (methods.getValues("selected_edit_prompt_id") !== null) {
                  // Update the prompt
                  console.log("Updating prompt...");
                  handleUpdatePrompt();
                } else {
                  handleCreatePrompt();
                }
              }}
              style={{ width: "100%", borderRadius: "5px" }}
            >
              Save Prompt
            </Button>
          </div>
        </div>
      </Modal>
    </PageWrapper>
  );
}

const PromptItem = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  border: 1px solid #f0f0f0;
  &:hover {
    background-color: #eeeeee;
  }
`;

const Form = styled.form`
  width: 100%;
  position: relative;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
  width: 100%;
  position: relative;

  /* Add a faint line between items */
  > *:not(:last-child) {
    position: relative;
    padding-bottom: 15px; /* Adjust as needed */
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Adjust the color and opacity as needed */
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ showCode }) => (showCode ? "1fr 1fr" : "1fr")};
  grid-gap: 40px;
`;
