import Switch from "components/core/Switch";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import LightTooltip from "./ToolTipDesc";

export default function IgnoreButtonPress({ fieldName }) {
  const longText = (
    <span>
      To ignore button presses on your phone call, set{" "}
      <strong>ignore_button_press</strong> to <strong>true</strong>. This will
      not apply on 'Amazon Connect' nodes.
    </span>
  );
  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch
          sx={{ m: 5 }}
          fieldName={fieldName}
          label="Ignore Button Press"
        />
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
