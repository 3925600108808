import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import BlandLogo from "../components/core/BlandLogo";
import { getUserData } from "utils/funcs/browser/getUserData";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

export default function OAuthApproval() {
  const [user, setUser] = useState(null);
  const jwt = getAuthToken();
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("redirect_url");
  const state = queryParams.get("state");
  const serviceName = redirectUrl?.replaceAll("https://", "").split(".")[0];

  useEffect(() => {
    const jwt = getUserData();
    setUser(jwt);
    console.log(redirectUrl);
  }, [redirectUrl]);

  const handleApprove = () => {
    if (!jwt) {
      // alert('You are not logged in.');
      window.location.href = "/signup";
      return;
    }
    // Directly redirecting with the JWT token appended
    window.location.href = `${redirectUrl}?bland_auth_token=${jwt}&bland_key=${user?.api_key}&state=${state}`;
  };

  if (!jwt) {
    return <div>You must be logged in to approve this application.</div>;
  }

  return (
    <div className="flex flex-col w-full h-screen bg-gray-200">
      <div className="mx-auto my-auto w-1/4 text-center px-12 py-12 rounded-lg shadow-md border border-gray-500 space-y-4 bg-white">
        <BlandLogo />
        <div className="font light text-xl">
          Allow <span className="font-bold">{serviceName}</span> to do the
          following?
        </div>
        <div className="flex flex-col text-gray-400 text-left text-md my-12">
          <div className="flex space-x-2">
            <span>Login as you</span>
            <FaCheckCircle className="my-auto text-green" />
          </div>
          <div className="flex space-x-2">
            <span>See your account data</span>
            <FaCheckCircle className="my-auto text-green" />
          </div>
          <div className="flex space-x-2">
            <span>See your call data</span>
            <FaCheckCircle className="my-auto text-green" />
          </div>
          <div className="flex space-x-2">
            <span>Make calls</span>
            <FaCheckCircle className="my-auto text-green" />
          </div>
          <div className="flex space-x-2">
            <span>Purchase Phone Numbers</span>
            <FaCheckCircle className="my-auto text-green" />
          </div>
        </div>
        <button
          disabled={!user?.api_key}
          onClick={handleApprove}
          className="bg-gray-300 shadow-sm w-full text-center py-2 hover:bg-gray-400"
        >
          Approve
        </button>
      </div>
    </div>
  );
}
