import { Dialog, Switch } from "@headlessui/react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import WebhookNodeModal from "./webhook_node_modal";
import ExtractInfo from "./extractInfo";
import ModelOptions from "./modelOptions";
import GlobalNodeOptions from "./globalSelect";
import ConditionOptions from "./conditionOptions";
import FineTuningExamples from "./FineTuningExamples";
import FlowContext from "../contextFlow";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";
import PathwayTransferModal from "./transferPathwayModal";
import CustomToolModal from "./customToolModal";
import SMSModal from "./smsModal";
import PressButtonModal from "./pressButtonModal";
import { AlertCircle, Copy, Trash2 } from "lucide-react";
import { TextAreaWithSuggestion } from "./promptImprovementTextArea";
import AmazonConnectModal from "./amazonConnectModal";
import UnitTest from "./unitTest";
import StaticTextArea from "./StaticTextArea";
import WarmTransferOptions from "./warmTransferOptions";

const NodeModal = ({
  id,
  setNodes,
  setEdges,
  isOpen,
  setIsOpen,
  data,
  type,
  context,
}) => {
  // im regretting using 1 modal for all the nodes..

  const user = useMemo(() => getUserData(), []);

  useEffect(() => {
    setName(data.name);
    setText(data.text);
    setKb(data.kb || "");
    setKbTool(data.kbTool || "");
    setPrompt(data.prompt || "");
    setTransferNumber(data.transferNumber || "");
    setIsForwardingEnabled(data.forwardingNode);
    setForwardingNode(data.forwardingNode || "");
    setModelOptions(
      data.modelOptions || {
        modelType: "smart",
        temperature: 0.2,
      },
    );
    setUseExistingKb(data.kbTool ? true : false);
    setUseSpeechDuringKB(data.speechDuringKBPrompt || false);
    setSpeechDuringKBPrompt(data.speechDuringKBPrompt || "");
    setCondition(data.condition || "");
    setUseStaticText(!data.prompt);
    setIsConditionChecked(data.condition);
    setIsGlobal(data.isGlobal || false);
    setGlobalPathwayLabel(data.globalLabel || "");
    setGlobalPathwayDescription(data.globalDescription || "");
    setEnableGlobalAutoReturn(
      data.enableGlobalAutoReturn === false ? false : true,
    );
    setPrevNodePathwayLabel(data.prevNodePathwayLabel || "");
    setPrevNodePathwayDescription(data.prevNodePathwayDescription || "");
    setNodeType(type || "default");
    setExtractVars(data.extractVars || [["", "", ""]]);
    setUseExtractVars(data.extractVars ? true : false);
    setDialogueExamples(data.dialogueExamples || []);
    setPathwayExamples(data.pathwayExamples || []);
    setConditionExamples(data.conditionExamples || []);
    setWarmTransferFields(
      data.warmTransferFields || {
        isEnabled: false,
        mergeCallPrompt: "",
        isMergeCallPromptStatic: false,
        agentPrompt: "",
        isAgentPromptStatic: false,
        userHandling: "on-hold",
        holdMusicUrl: "https://example.com/default-hold-music.mp3",
        waitingPrompt: "",
        useCustomFromNumber: false,
        fromNumber: "",
      },
    );
    setNodeTag(data.tag || null);
  }, [data, type]);

  const { exportFlow, triggerUpdate, elements } = useContext(FlowContext);
  const nodeOptions = [
    { id: 1, name: "Default" },
    { id: 2, name: "Knowledge Base" },
    { id: 3, name: "Transfer Call" },
    { id: 4, name: "End Call" },
    { id: 5, name: "Webhook" },
    { id: 6, name: "Wait for Response" },
    { id: 7, name: "Vector DB Knowledge Base" },
    { id: 8, name: "Transfer Pathway" },
    { id: 9, name: "Custom Tool" },
    { id: 10, name: "SMS" },
    { id: 11, name: "Press Button" },
    { id: 12, name: "Amazon Connect" },
  ];

  const [name, setName] = useState(data.name);
  const [text, setText] = useState(data.text);
  const [kb, setKb] = useState(data.kb || "");
  const [kbTool, setKbTool] = useState(data.kbTool || "");
  const [prompt, setPrompt] = useState(data.prompt || "");
  const [transferNumber, setTransferNumber] = useState(
    data.transferNumber || "",
  );
  const [isForwardingEnabled, setIsForwardingEnabled] = useState(
    data.forwardingNode,
  );
  const [forwardingNode, setForwardingNode] = useState(
    data.forwardingNode || "",
  );
  const [modelOptions, setModelOptions] = useState(
    data.modelOptions || {
      modelType: "smart",
      temperature: 0.2,
    },
  );

  const [kbOptions, setKbOptions] = useState([]); // State for dropdown options
  const [useExistingKb, setUseExistingKb] = useState(
    data.kbTool ? true : false,
  );

  const [useSpeechDuringKB, setUseSpeechDuringKB] = useState(
    data.speechDuringKBPrompt || false,
  );

  const [speechDuringKBPrompt, setSpeechDuringKBPrompt] = useState(
    data.speechDuringKBPrompt || "",
  );

  const [condition, setCondition] = useState(data.condition || "");
  const [useStaticText, setUseStaticText] = useState(!data.prompt);
  const [isConditionChecked, setIsConditionChecked] = useState(data.condition);

  const [isGlobal, setIsGlobal] = useState(data.isGlobal || false);
  const [globalPathwayLabel, setGlobalPathwayLabel] = useState(
    data.globalLabel || "",
  );

  const [nodeTag, setNodeTag] = useState(
    data.tag || {
      name: "default",
      color: "#000000",
    },
  );

  const [globalPathwayDescription, setGlobalPathwayDescription] = useState(
    data.globalDescription || "",
  );

  // set to true by default, but false if data.enableglobalautoreturn is false

  const [enableGlobalAutoReturn, setEnableGlobalAutoReturn] = useState(
    data.enableGlobalAutoReturn === false ? false : true,
  );

  const [prevNodePathwayLabel, setPrevNodePathwayLabel] = useState(
    data.prevNodePathwayLabel || "",
  );

  const [prevNodePathwayDescription, setPrevNodePathwayDescription] = useState(
    data.prevNodePathwayDescription || "",
  );

  const [enableReturnToPrevBeforeGlobal, setEnableReturnToPrevBeforeGlobal] =
    useState(data.enableReturnToPrevBeforeGlobal || false);

  const [nodeType, setNodeType] = useState(type || "default");

  const [extractVars, setExtractVars] = useState(
    data.extractVars || [["", "", ""]],
  );
  const [useExtractVars, setUseExtractVars] = useState(
    data.extractVars ? true : false,
  );

  const [variableValidationErrors, setVariableValidationErrors] = useState([]);
  const unitTestRef = useRef();

  const validateVariables = () => {
    // if useExtractVars is false, no need to validate
    if (!useExtractVars) {
      return true;
    }
    const errors = [];
    extractVars.forEach((variable, index) => {
      variable.forEach((field, fieldIndex) => {
        if (field.trim() === "") {
          errors.push({
            index,
            field: fieldIndex,
            message: "Field is required",
          });
        }
      });
    });
    setVariableValidationErrors(errors);
    return errors.length === 0;
  };

  const textareaRef = useRef(null);

  const isChildOfGlobalNode = useMemo(() => {
    // get the parent nodes of this node. then check if any of them are global nodes, if so, return true
    // if no edges, then return false
    if (!elements.edges || isGlobal) {
      return false;
    }
    const parentNodes = elements.edges
        .filter((edge) => edge.target === id)
        .map((edge) => edge.source),
      parentNodesData = elements.nodes.filter((node) =>
        parentNodes.includes(node.id),
      );

    return parentNodesData.some((node) => node.data.isGlobal);
  }, [elements, id]);

  // useEffect for when useExtractVars is toggled, if its false then clear the validation errors
  useEffect(() => {
    if (!useExtractVars) {
      setVariableValidationErrors([]);
    }
  }, [useExtractVars]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to get correct scrollHeight
      const newHeight = Math.min(textareaRef.current.scrollHeight, 200);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  });

  useEffect(() => {
    // console.log("this is being run");
    // console.log("user", user, user.api_key);
    const fetchKbOptions = async () => {
      const response = await fetch("https://kb.bland.ai/vectors", {
        method: "GET",
        headers: {
          authorization: user.api_key,
        },
      });
      if (response.ok) {
        const data = await response.json();

        console.log("kb options", data);
        setKbOptions(data.vectors);

        if (data.vectors.length === 0) {
          toast.warn("No Knowledge Bases found. Please create one first!");
        }
      } else {
        console.error("Failed to fetch KB options");
      }
    };

    if (nodeType === "Vector DB Knowledge Base") {
      fetchKbOptions();
    }
  }, [nodeType]);

  const [dialogueExamples, setDialogueExamples] = useState(
    data.dialogueExamples || [],
  );
  const [pathwayExamples, setPathwayExamples] = useState(
    data.pathwayExamples || [],
  );
  const [conditionExamples, setConditionExamples] = useState(
    data.conditionExamples || [],
  );

  useEffect(() => {
    setDialogueExamples(data.dialogueExamples || []);
  }, [data.dialogueExamples]);

  useEffect(() => {
    setPathwayExamples(data.pathwayExamples || []);
  }, [data.pathwayExamples]);

  useEffect(() => {
    setConditionExamples(data.conditionExamples || []);
  }, [data.conditionExamples]);

  const copyNodeId = () => {
    navigator.clipboard.writeText(id);
    toast.success("Node ID copied to clipboard!");
  };

  const [warmTransferFields, setWarmTransferFields] = useState(
    data.warmTransferFields || {
      isEnabled: false,
      mergeCallPrompt: "",
      isMergeCallPromptStatic: false,
      agentPrompt: "",
      isAgentPromptStatic: false,

      userHandling: "on-hold",
      holdMusicUrl:
        "http://com.twilio.sounds.music.s3.amazonaws.com/MARKOVICHAMP-Borghestral.mp3",
      waitingPrompt: "",
      useCustomFromNumber: false,
      fromNumber: "",
    },
  );

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      {/* for the background */}
      <div className="fixed inset-0 bg-black/30 z-[100]" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center overflow-y-auto z-[100]">
        <Dialog.Panel className="w-3/5 rounded bg-white px-12 py-8 space-y-4  overflow-y-scroll max-h-[90vh]">
          <div className="flex justify-between items-center">
            <Dialog.Title className="font-semibold">Edit Node</Dialog.Title>
            <button
              onClick={copyNodeId}
              className="flex items-center px-2 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded"
            >
              <Copy className="w-4 h-4 mr-1" />
              Copy ID
            </button>
          </div>
          <div>
            <label htmlFor="nodeType" className="block mb-2">
              Node Type
            </label>
            <select
              value={nodeType}
              onChange={(e) => setNodeType(e.target.value)}
              className="border border-gray-300 text-gray-900 rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5"
            >
              {nodeOptions.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          {nodeType === "Wait for Response" && (
            <div>
              <p className="text-gray-500 text-lg">
                This node will wait for a response from the user before
                proceeding.
              </p>
            </div>
          )}

          {nodeType === "Webhook" ? (
            <WebhookNodeModal
              id={id}
              setNodes={setNodes}
              setEdges={setEdges}
              data={data}
              setIsOpen={setIsOpen}
            />
          ) : nodeType === "Transfer Pathway" ? (
            <PathwayTransferModal id={id} data={data} setIsOpen={setIsOpen} />
          ) : nodeType === "Custom Tool" ? (
            <CustomToolModal id={id} data={data} setIsOpen={setIsOpen} />
          ) : nodeType === "SMS" ? (
            <SMSModal id={id} data={data} setIsOpen={setIsOpen} />
          ) : nodeType === "Press Button" ? (
            <PressButtonModal id={id} data={data} setIsOpen={setIsOpen} />
          ) : nodeType === "Amazon Connect" ? (
            <AmazonConnectModal id={id} data={data} setIsOpen={setIsOpen} />
          ) : (
            <Dialog.Description className="space-y-4">
              <div>Name:</div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full"
              />

              {nodeType === "Vector DB Knowledge Base" && (
                <div className="space-y-2">
                  <div>Knowledge Base:</div>
                  <select
                    value={kbTool}
                    onChange={(e) => setKbTool(e.target.value)}
                    className="border border-gray-300 text-gray-900 rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5"
                  >
                    <option value="">Select a Knowledge Base</option>
                    {kbOptions.map((option) => (
                      <option key={option.id} value={option.vector_id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="flex space-x-4">
                <Switch
                  checked={useStaticText}
                  onChange={setUseStaticText}
                  className={`${
                    useStaticText ? "bg-indigo-600" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Static Text</span>
                  <span
                    className={`${
                      useStaticText ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <label>
                  Static Text{" "}
                  <span className="italic">
                    (When you want the agent to say a specific dialogue. Uncheck
                    to use AI generated text)
                  </span>{" "}
                </label>
              </div>

              {useStaticText ? (
                <>
                  <div>Text:</div>
                  <StaticTextArea
                    placeholder="Exact text to be spoken by the agent"
                    value={text}
                    onChange={(e) => setText(e)}
                    className="border border-gray-300 rounded-md w-full min-h-[100px]"
                  />
                </>
              ) : (
                <>
                  <TextAreaWithSuggestion
                    placeholder="Provide a short goal/prompt for what the agent needs to do - e.g. Ask for the customer's name"
                    value={prompt}
                    onChange={(e) => setPrompt(e)}
                    className="border border-gray-300 rounded-md w-full min-h-[100px]"
                  />
                </>
              )}
              {nodeType === "Knowledge Base" && (
                <>
                  <div>Knowledge Base:</div>
                  <textarea
                    value={kb}
                    maxLength={30000}
                    onChange={(e) => setKb(e.target.value)}
                    className="border border-gray-300 p-2 rounded-md w-full min-h-[100px]"
                  />
                  <div className="text-right text-sm mt-1">
                    {kb.length}/30000 characters
                  </div>
                </>
              )}

              {nodeType === "Transfer Call" && (
                <>
                  <div>Transfer Number:</div>
                  <input
                    type="tel"
                    value={transferNumber}
                    onChange={(e) => setTransferNumber(e.target.value)}
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    className="border border-gray-300 p-2 rounded-md w-full"
                  />
                  <WarmTransferOptions
                    warmTransferFields={warmTransferFields}
                    setWarmTransferFields={setWarmTransferFields}
                  />
                </>
              )}

              {nodeType === "Vector DB Knowledge Base" && (
                <div className="border p-4 rounded-md shadow-sm">
                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-xl">
                        Send Speech During Vector DB Knowledge Base Query
                      </label>
                      <p className="text-lg text-gray-600 mt-1">
                        Enable speech output while the Vector DB Knowledge Base
                        Query is running in the background.
                      </p>
                    </div>
                    <Switch
                      checked={useSpeechDuringKB}
                      onChange={() => setUseSpeechDuringKB(!useSpeechDuringKB)}
                      className={`${
                        useSpeechDuringKB ? "bg-indigo-600" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">
                        Enable Speech During Query
                      </span>
                      <span
                        className={`${
                          useSpeechDuringKB ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </div>
                  {useSpeechDuringKB && (
                    <div className="space-x-4 mt-4 w-full">
                      <div className="mt-4">
                        Prompt:
                        <textarea
                          placeholder="Instructions for the agent to say while the Vector DB Knowledge Base Query is running in the background."
                          value={speechDuringKBPrompt}
                          onChange={(e) =>
                            setSpeechDuringKBPrompt(e.target.value)
                          }
                          className="border border-gray-300 p-2 rounded-md w-full text-lg mt-2 min-h-[100px]"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <ConditionOptions
                isConditionChecked={isConditionChecked}
                setIsConditionChecked={setIsConditionChecked}
                condition={condition}
                setCondition={setCondition}
              />

              <GlobalNodeOptions
                isGlobal={isGlobal}
                setIsGlobal={setIsGlobal}
                globalPathwayLabel={globalPathwayLabel}
                setGlobalPathwayLabel={setGlobalPathwayLabel}
                globalPathwayDescription={globalPathwayDescription}
                setGlobalPathwayDescription={setGlobalPathwayDescription}
                isForwardingEnabled={isForwardingEnabled}
                setIsForwardingEnabled={setIsForwardingEnabled}
                forwardingNode={forwardingNode}
                setForwardingNode={setForwardingNode}
                enableGlobalAutoReturn={enableGlobalAutoReturn}
                setEnableGlobalAutoReturn={setEnableGlobalAutoReturn}
                prevNodePathwayLabel={prevNodePathwayLabel}
                setPrevNodePathwayLabel={setPrevNodePathwayLabel}
                prevNodePathwayDescription={prevNodePathwayDescription}
                setPrevNodePathwayDescription={setPrevNodePathwayDescription}
                isChildOfGlobalNode={isChildOfGlobalNode}
                enableReturnToPrevBeforeGlobal={enableReturnToPrevBeforeGlobal}
                setEnableReturnToPrevBeforeGlobal={
                  setEnableReturnToPrevBeforeGlobal
                }
              />

              <ModelOptions
                modelOptions={modelOptions}
                setModelOptions={setModelOptions}
              />

              <ExtractInfo
                extractVars={extractVars}
                setExtractVars={setExtractVars}
                useExtractVars={useExtractVars}
                setUseExtractVars={setUseExtractVars}
                validationErrors={variableValidationErrors}
                setValidationErrors={setVariableValidationErrors}
              />

              <FineTuningExamples
                id={id}
                dialogueExamples={dialogueExamples}
                setDialogueExamples={setDialogueExamples}
                pathwayExamples={pathwayExamples}
                setPathwayExamples={setPathwayExamples}
                conditionExamples={conditionExamples}
                setConditionExamples={setConditionExamples}
              />

              <UnitTest
                ref={unitTestRef}
                initialTestData={data.unitTests || null}
              />

              <div className="border border-gray-20 p-4 rounded-lg shadow-sm overflow-hidden">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="text-2xl text-gray-900">Node Tag</h3>
                  {nodeTag && (
                    <div
                      style={{
                        padding: "3px 9px",
                        borderRadius: 6,
                        background: "#e6e6e6",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: 6,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNodeTag(null);
                        console.log("nodeTag", nodeTag);
                      }}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "grayText",
                        }}
                      >
                        Remove Tag
                      </p>
                      <Trash2 size={12} color="grayText" />
                    </div>
                  )}
                </div>

                <div style={{ marginTop: 10 }}>
                  <p>Tag Name</p>
                  <input
                    className="w-full mt-1 p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500 mb-3"
                    value={nodeTag?.name || ""}
                    onChange={(event) => {
                      if (nodeTag === null) {
                        setNodeTag({
                          name: event.target.value,
                          color: "#000000",
                        });
                      } else {
                        setNodeTag({
                          ...nodeTag,
                          name: event.target.value,
                        });
                      }
                    }}
                  />
                </div>

                <div style={{ marginTop: 5 }}>
                  <p>Tag Color</p>
                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <input
                      className="w-full mr-2 mt-0 p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500 mb-3"
                      value={nodeTag?.color || "#000000"}
                      placeholder="#000000"
                      onChange={(event) => {
                        setNodeTag({
                          ...nodeTag,
                          color: event.target.value,
                        });
                      }}
                    />

                    <input
                      style={{
                        border: "none",
                        padding: 0,
                        margin: 0,
                        height: 37,
                        width: 37,
                        background: "transparent",
                        borderRadius: 0,
                        outline: "none",
                        cursor: "pointer",
                        marginTop: -7,
                      }}
                      type="color"
                      value={nodeTag?.color}
                      onChange={(event) => {
                        setNodeTag({
                          ...nodeTag,
                          color: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 flex flex-row items-center">
                <button
                  onClick={() => {
                    if (validateVariables()) {
                      let edges = elements.edges;
                      const updatedNodes = elements.nodes.map(
                        (element_node) => {
                          if (element_node.id === id) {
                            let node = structuredClone(element_node);
                            node.data.name = name;
                            if (nodeTag || nodeTag === null) {
                              node.data.tag = nodeTag;
                            }
                            node.type = nodeType;
                            if (useStaticText) {
                              node.data.text = text;
                              setPrompt("");
                              delete node.data.prompt;
                            } else {
                              node.data.prompt = prompt;
                              setText("");
                              delete node.data.text;
                            }

                            if (isForwardingEnabled && forwardingNode) {
                              node.data.forwardingNode = forwardingNode;
                            } else {
                              delete node.data.forwardingNode;
                            }

                            if (isConditionChecked && condition) {
                              node.data.condition = condition;
                            } else {
                              delete node.data.condition;
                            }
                            if (nodeType === "Knowledge Base") {
                              node.data.kb = kb;
                            } else {
                              delete node.data.kb;
                            }

                            if (nodeType === "Vector DB Knowledge Base") {
                              if (kbTool === "") {
                                toast.warn(
                                  "Please select a Knowledge Base from the dropdown. If you don't have one, please create one first!",
                                );
                                return element_node;
                              }
                              node.data.kbTool = kbTool;
                              if (useSpeechDuringKB) {
                                node.data.speechDuringKBPrompt =
                                  speechDuringKBPrompt;
                              } else {
                                delete node.data.speechDuringKBPrompt;
                              }
                            } else {
                              delete node.data.kbTool;
                            }
                            if (nodeType === "Transfer Call") {
                              node.data.transferNumber = transferNumber;
                              node.data.warmTransferFields = warmTransferFields;
                            } else {
                              delete node.data.transferNumber;
                              delete node.data.warmTransferFields;
                            }

                            if (dialogueExamples.length > 0) {
                              node.data.dialogueExamples = dialogueExamples;
                            } else {
                              delete node.data.dialogueExamples;
                            }
                            if (pathwayExamples.length > 0) {
                              const savedPathwayEg = pathwayExamples.filter(
                                (example) => {
                                  return (
                                    example["Conversation History"] !== "" &&
                                    example["Chosen Pathway"] !== ""
                                  );
                                },
                              );
                              node.data.pathwayExamples = savedPathwayEg;
                            } else {
                              delete node.data.pathwayExamples;
                            }
                            if (conditionExamples.length > 0) {
                              node.data.conditionExamples = conditionExamples;
                            } else {
                              delete node.data.conditionExamples;
                            }

                            if (node.data?.routeExamples) {
                              delete node.data.routeExamples;
                            }

                            if (modelOptions) {
                              if (modelOptions.temperature) {
                                console.log(
                                  "modelOptions.temperature",
                                  modelOptions.temperature,
                                  typeof modelOptions.temperature,
                                );
                                modelOptions.temperature = parseFloat(
                                  modelOptions.temperature.toFixed(1),
                                );
                              } else {
                                delete modelOptions.temperature;
                              }
                              if (modelOptions.interruptionThreshold) {
                                modelOptions.interruptionThreshold = parseInt(
                                  modelOptions.interruptionThreshold,
                                );
                              } else {
                                delete modelOptions.interruptionThreshold;
                              }
                              if (
                                modelOptions.skipUserResponse &&
                                modelOptions.skipUserResponse === true
                              ) {
                                modelOptions.skipUserResponse = true;
                              } else {
                                modelOptions.skipUserResponse = false;
                              }

                              if (
                                modelOptions.disableSilenceRepeat &&
                                modelOptions.disableSilenceRepeat === true
                              ) {
                                modelOptions.disableSilenceRepeat = true;
                              } else {
                                modelOptions.disableSilenceRepeat = false;
                              }

                              if (
                                modelOptions.conditionOverridesGlobalPathway &&
                                modelOptions.conditionOverridesGlobalPathway ===
                                  true
                              ) {
                                modelOptions.conditionOverridesGlobalPathway = true;
                              } else {
                                delete modelOptions.conditionOverridesGlobalPathway;
                              }

                              node.data.modelOptions = modelOptions;
                            } else {
                              delete node.data.modelOptions;
                            }

                            if (isGlobal && !isChildOfGlobalNode) {
                              node.data.isGlobal = true;
                              node.data.globalLabel = globalPathwayLabel;
                              if (globalPathwayDescription) {
                                node.data.globalDescription =
                                  globalPathwayDescription;
                              } else {
                                delete node.data.globalDescription;
                              }
                              if (!enableGlobalAutoReturn) {
                                // if auto return is disabled/treating like normal node
                                node.data.enableGlobalAutoReturn = false;
                                if (prevNodePathwayLabel) {
                                  node.data.prevNodePathwayLabel =
                                    prevNodePathwayLabel;
                                }
                                if (prevNodePathwayDescription) {
                                  node.data.prevNodePathwayDescription =
                                    prevNodePathwayDescription;
                                }
                              } else {
                                edges = edges.filter(
                                  (edge) =>
                                    edge.target !== id && edge.source !== id,
                                );
                                delete node.data.enableGlobalAutoReturn;
                                delete node.data.prevNodePathwayLabel;
                                delete node.data.prevNodePathwayDescription;
                              }
                            } else {
                              delete node.data.isGlobal;
                              delete node.data.globalLabel;
                              delete node.data.globalDescription;
                              delete node.data.enableGlobalAutoReturn;
                              delete node.data.prevNodePathwayLabel;
                              delete node.data.prevNodePathwayDescription;
                            }

                            if (enableReturnToPrevBeforeGlobal) {
                              node.data.enableReturnToPrevBeforeGlobal = true;
                            } else {
                              delete node.data.enableReturnToPrevBeforeGlobal;
                            }

                            delete node.data.body;
                            delete node.data.method;
                            delete node.data.url;
                            delete node.data.responsePathways;
                            delete node.data.timeoutValue;

                            delete node.data.transferPathway;
                            delete node.data.transferPathwayNode;

                            const testData =
                              unitTestRef.current.getCurrentTestData();
                            // Add the test data to the node
                            if (testData.isEnabled) {
                              node.data.unitTests = testData;
                            } else {
                              delete node.data.unitTests;
                            }

                            if (useExtractVars) {
                              const resVars = extractVars.filter((varArr) => {
                                if (varArr[0] === "bland-prompt") {
                                  return true;
                                }
                                // For all variables excluding "bland-prompt", ensure all fields are non-empty
                                return (
                                  varArr[0] !== "" &&
                                  varArr[1] !== "" &&
                                  varArr[2] !== ""
                                );
                              });

                              const nonPromptVars = resVars.filter(
                                (varArr) => varArr[0] !== "bland-prompt",
                              );

                              if (nonPromptVars.length === 0) {
                                // If there are no valid variables, remove extractVars completely
                                delete node.data.extractVars;
                                setExtractVars([]);
                              } else {
                                // Save the variables including the "bland-prompt" if it exists and is non-empty
                                node.data.extractVars = resVars;
                                setExtractVars(resVars);
                              }
                            } else {
                              delete node.data.extractVars;
                            }
                            return node;
                          }
                          return element_node;
                        },
                      );
                      triggerUpdate(
                        {
                          nodes: updatedNodes,
                          edges: edges,
                        },
                        false,
                      );
                      setIsOpen(false);
                      //   exportFlow();
                    }
                  }}
                  hidden={context === "preview"}
                  className="bg-indigo-500 text-white py-2 px-4 rounded-md"
                >
                  Save
                </button>
                {variableValidationErrors.length > 0 && (
                  <div className="relative group ml-2">
                    <AlertCircle className="h-6 w-6 text-red-500 " />
                    <span className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded hidden group-hover:block whitespace-nowrap">
                      Some variables are missing fields
                    </span>
                  </div>
                )}
              </div>
            </Dialog.Description>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default NodeModal;
