import { PageTitle } from "components/core/PageTitle";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import ListeningGif from "../../../assets/gifs/listening-slow.gif";
import { Editor } from "@monaco-editor/react";
import Button from "components/core/Button";
import CopyIcon from "../../../assets/icons/copy-icon.svg";
import JsonViewer from "./components/JsonView";
import Select from "react-dropdown-select";
import { flattenJSON, parse_data } from "./utils/payload";
import PayloadDropdown from "./components/InputDropdown";
import BooleanDropdown from "./components/BooleanDropdown";
import SplitDropdown from "./components/SplitDropdown";
import { getUserData } from "utils/funcs/browser/getUserData";
import axios from "axios";
import Model from "../Parameters/Model";
import KeyValueDropdown from "./components/KeyValueDropdown";
import Temperature from "../Parameters/Temperature";
import InterruptionThresh from "../Parameters/InterruptionThresh";
import VoicesDropdown from "./components/VoiceDropdown";
import ExpandIcon from "../../../assets/icons/Expandicon.svg";
import CloseIcon from "../../../assets/icons/close-icon-black.svg";
import RefreshIcon from "../../../assets/icons/refresh-icon.svg";
import Modal from "components/core/Modal";
import SettingIcon from "../../../assets/icons/setting-icon.svg";
import HookInputDropdown from "./components/HookInputDropdown";
import AnalysisPreset from "../Parameters/AnalysisPreset";
import usePhoneNumbers from "hooks/usePhoneNumbers";
import PhoneNumberSelector from "components/core/PhoneNumberSelector";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

export default function Event() {
  const apiKey = getApiKey();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [updating, setUpdating] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manualTriggerOpen, setManualTriggerOpen] = useState(false);
  const [testmode, setTestmode] = useState(false);
  const [cdModal, setCdModal] = useState(false); // custom deployment modal;

  const [loadingCd, setLoadingCd] = useState(false);
  const [encryptedKeys, setEncryptedKeys] = useState(null);
  const [encryptedKey, setEncryptedKey] = useState(null);
  const [manualTriggerBody, setManualTriggerBody] = useState({
    phone: "+1...",
    name: "John Doe",
  });

  const {
    inboundNumbers,
    twilioNumbers,
    loading: phoneNumbersLoading,
    error: phoneNumbersError,
  } = usePhoneNumbers();

  const EVENT_BASE_URL = "https://us.api.bland.ai/api/events";

  const methods = useForm({
    defaultValues: {
      event_id: null,
      event_history: null,
      event: null,
      selected_payload: null,
      event_base_endpoint: "api.bland.ai",
      ///// SEND CALL PARAMS ////
      from: {
        using_key: false,
        value: "",
      },
      phone_number: {
        using_key: false,
        value: "",
      },
      model: {
        using_key: false,
        value: "enhanced",
      },
      task: {
        using_key: false,
        value: "",
      },
      webhook: {
        using_key: false,
        value: "",
      },
      first_sentence: {
        using_key: false,
        value: "",
      },
      voice: {
        using_key: false,
        value: "e1289219-0ea2-4f22-a994-c542c2a48a0f",
        options: [],
      },
      voicemail_message: {
        using_key: false,
        value: "",
      },
      transfer_phone_number: {
        using_key: false,
        value: "",
      },
      temperature: 0.7,
      interruption_threshold: 100,
      max_duration: {
        using_key: false,
        value: 30,
      },
      record: {
        using_key: false,
        value: false,
      },
      wait_for_greeting: {
        using_key: false,
        value: false,
      },
      block_interruptions: {
        using_key: false,
        value: false,
      },
      answered_by_enabled: {
        using_key: false,
        value: false,
      },
      amd: {
        using_key: false,
        value: false,
      },
      language: {
        using_key: false,
        value: "en-US",
        options: [
          {
            value: "en-US",
            label: "English (US)",
          },
          {
            value: "eng-GB",
            label: "English (UK)",
          },
          {
            value: "eng-AU",
            label: "English (Australia)",
          },
          {
            value: "eng-NZ",
            label: "English (New Zealand)",
          },
          {
            value: "eng-IN",
            label: "English (New India)",
          },
          {
            value: "es",
            label: "Spanish",
          },
          {
            value: "fr",
            label: "French",
          },
          {
            value: "de",
            label: "German",
          },
          {
            value: "hi",
            label: "Hindi",
          },
          {
            value: "ja",
            label: "Japanese",
          },
          {
            value: "it",
            label: "Italian",
          },
          {
            value: "pt",
            label: "Portuguese",
          },
          {
            value: "pt-BR",
            label: "Portuguese (Brazil)",
          },
          {
            value: "zh",
            label: "Chinese (Mandarin, Simplified)",
          },
        ],
      },
      pathway_id: {
        using_key: false,
        value: "",
        options: [
          {
            value: "somepathwayid",
            label: "Booking Pathway",
          },
          {
            value: "ipsumloremid",
            label: "Reservations Handler",
          },
        ],
      },
      tools: {
        using_key: false,
        value: "",
        options: [],
      },
      request_data: [],
      transfer_list: [],
      analysis_schema: [],
      metadata: [],
      summary_prompt: {
        using_key: false,
        value: "",
      },
      analysis_prompt: {
        using_key: false,
        value: "",
      },
      analysis_preset: {
        using_key: false,
        value: "",
      },
    },
  });

  const user = getUserData();
  const event = methods.watch("event");
  const event_history = methods.watch("event_history");
  const selected_payload = methods.watch("selected_payload");

  const handleTestCall = async () => {
    try {
      setLoading(true);

      if (!selected_payload) {
        toast.error(
          "To test this schema you must select a request from the dropdown below.",
        );
        return setLoading(false);
      }
      const payload = getPreviewData();
      if (payload?.webhook === "") {
        delete payload.webhook;
      }

      if (payload?.summary_prompt === "") {
        delete payload?.summary_prompt;
      }

      if (payload?.analysis_prompt === "") {
        delete payload?.analysis_prompt;
      }

      if (payload?.analysis_preset === "") {
        delete payload?.analysis_preset;
      }

      if (payload?.pathway_id === "") {
        delete payload?.pathway_id;
      }

      if (payload?.from === "") {
        delete payload?.from;
      }

      if (payload?.tools?.length || 0 === 0 || payload?.tools === "") {
        delete payload?.tools;
      }

      if (
        payload?.transfer_phone_number === "" ||
        !payload?.transfer_phone_number
      ) {
        delete payload?.transfer_phone_number;
      }

      const response = await fetch(
        `https://${methods.getValues("event_base_endpoint") || "api.bland.ai"}/v1/calls`,
        {
          method: "POST",
          headers: {
            authorization: apiKey,
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      if (response.ok && response.status === 200) {
        const data = await response.json();
        // console.log(data);
        if (data.status === "success") {
          setLoading(false);
          setPreviewModal(false);
          return toast.success("Test call dispatched!");
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      return toast.error("Issue dispatching test call.");
    }
  };

  const handleUpdateDeploymentUrl = async () => {
    try {
      setLoadingCd(true);
      const response = await fetch(
        `${EVENT_BASE_URL}/${event.id}/custom_deployment`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: apiKey,
          },
          body: JSON.stringify({
            endpoint: methods.getValues("event_base_endpoint"),
          }),
        },
      );

      setCdModal(false);
      setLoadingCd(false);
      if (response.ok && response.status === 200) {
        return toast.success("Updated Custom Deployment");
      } else {
        throw new Error();
      }
    } catch (error) {
      methods.setValue("event_base_endpoint", "api.bland.ai");
      return toast.error("Unexpected Error Saving");
    }
  };

  const updateUserEvent = async () => {
    try {
      setUpdating(true);
      const response = await fetch(
        `https://us.api.bland.ai/api/events/${event.id}/update`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: apiKey,
          },
          body: JSON.stringify({
            ...handleSaveConfiguration(),
          }),
        },
      );

      if (response.ok && response.status === 200) {
        const data = await response.json();
        await loadEventById(event.id);
      }

      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  };

  const getUserPathways = async () => {
    try {
      const response = await axios.get("/api/convo_pathway/get", {
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        validateStatus: false,
      });

      if (response.status === 200 && response.data.data) {
        const pathways = response?.data?.data;
        if (pathways && pathways?.length > 0) {
          const mappedPathways = pathways.map((doc) => ({
            value: doc.id,
            label: doc.name,
          }));
          methods.setValue("pathway_id.options", mappedPathways);
          return;
        }
      }
    } catch (error) {}
  };

  const getUserTools = async () => {
    const response = await fetch(`https://us.api.bland.ai/v1/tools`, {
      method: "GET",
      headers: {
        cache: "no-cache",
        authorization: apiKey,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data?.tools?.length > 0) {
        // setFocusedTool(data.tools[0]);
        data.tools.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );
        const mappedTools = data.tools?.map((doc) => ({
          value: doc?.tool_id,
          label: doc?.tool?.name,
        }));
        methods.setValue("tools.options", mappedTools);
      }
    }
  };

  const loadEventReqHistoryById = async (id) => {
    try {
      const response = await fetch(
        `https://us.api.bland.ai/api/events/${id}/history`,
        {
          method: "GET",
          headers: {
            "Content-Type": "Application/json",
            authorization: apiKey,
          },
        },
      );

      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (data.status === "success") {
          let _history = data.history;
          if (_history?.length > 0) {
          }
          _history.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
          );
          methods.setValue("event_history", _history || []);

          if (_history?.length > 0 && !selected_payload) {
            //console.log(_history[0]);
            methods.setValue("selected_payload", _history[0].raw_data);
          }
        }
      }
    } catch (error) {
      methods.setValue("event_history", []);
    }
  };

  const loadEventById = async (id) => {
    const response = await fetch(`${EVENT_BASE_URL}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        authorization: apiKey,
      },
    });

    if (response.ok && response.status === 200) {
      const data = await response.json();
      if (data?.status === "success") {
        const _event = data.event;
        setTestmode(_event?.dispatch_call);
        methods.setValue("event_base_endpoint", _event?.endpoint);
        methods.setValue("event", _event);
        if (data?.event?.encrypted_key) {
          setEncryptedKey(data?.event?.encrypted_key);
        }

        if (data?.event?.event_schema) {
          const event_schema = data?.event?.event_schema || {};
          handleEventSchema(event_schema);
        }
      }
    }
  };

  const handleOnUpdateTestMode = async (toggle) => {
    try {
      const updateState = !toggle;
      const response = await fetch(`${EVENT_BASE_URL}/${event?.id}/toggle`, {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
          authorization: apiKey,
        },
        body: JSON.stringify({
          toggle_dispatch: updateState,
        }),
      });

      setTestmode(updateState);
      return;
    } catch (error) {}
  };

  const qpSearchAndSet = () => {
    const eventIdVal = searchParams.get("eventId");
    if (!eventIdVal) return;
    methods.setValue("event_id", eventIdVal);
    return eventIdVal;
  };

  const handleEventSchema = (event_schema) => {
    const dd = methods.getValues();
    // const _data = dd;
    const data = {
      from: event_schema.from,
      amd: event_schema.amd,
      analysis_schema: event_schema.analysis_schema,
      analysis_prompt: event_schema.analysis_prompt,
      analysis_preset: event_schema.analysis_preset,
      answered_by_enabled: event_schema.answered_by_enabled,
      block_interruptions: event_schema.block_interruptions,
      first_sentence: event_schema.first_sentence,
      interruption_threshold: event_schema.interruption_threshold,
      language: {
        using_key: event_schema.language.using_key,
        value: event_schema.language.value,
        options: dd.language.options,
      },
      max_duration: event_schema.max_duration,
      metadata: event_schema.metadata,
      model: event_schema.model,
      pathway_id: {
        using_key: event_schema.pathway_id.using_key,
        value: event_schema.pathway_id.value,
        options: dd.pathway_id.options,
      },
      phone_number: event_schema.phone_number,
      record: event_schema.record,
      request_data: event_schema.request_data,
      summary_prompt: event_schema.summary_prompt,
      task: event_schema.task,
      temperature: event_schema.temperature,
      tools: {
        using_key: event_schema.tools.using_key,
        value: event_schema.tools.value,
        options: dd.tools.options,
      },
      transfer_list: event_schema.transfer_list,
      transfer_phone_number: event_schema.transfer_phone_number,
      voice: {
        using_key: event_schema.voice.using_key,
        value: event_schema.voice.value,
        options: dd.voice.options,
      },
      voicemail_message: event_schema.voicemail_message,
      wait_for_greeting: event_schema.wait_for_greeting,
      webhook: event_schema.webhook,
    };

    methods.reset({
      ...methods.getValues(),
      ...data,
    });
  };

  const handleSaveConfiguration = () => {
    const dd = methods.watch();
    const _data = dd;
    const data = {
      from: _data.from,
      amd: _data.amd,
      analysis_schema: _data.analysis_schema,
      analysis_prompt: _data.analysis_prompt,
      analysis_preset: _data.analysis_preset,
      answered_by_enabled: _data.answered_by_enabled,
      block_interruptions: _data.block_interruptions,
      first_sentence: _data.first_sentence,
      interruption_threshold: _data.interruption_threshold,
      language: {
        using_key: _data.language.using_key,
        value: _data.language.value,
      },
      max_duration: _data.max_duration,
      metadata: _data.metadata,
      model: _data.model,
      pathway_id: {
        using_key: _data.pathway_id.using_key,
        value: _data.pathway_id.value,
      },
      phone_number: _data.phone_number,
      record: _data.record,
      request_data: _data.request_data,
      summary_prompt: _data.summary_prompt,
      task: _data.task,
      temperature: _data.temperature,
      tools: {
        using_key: _data.tools.using_key,
        value: _data.tools.value,
      },
      transfer_list: _data.transfer_list,
      transfer_phone_number: _data.transfer_phone_number,
      voice: {
        using_key: _data.voice.using_key,
        value: _data.voice.value,
      },
      voicemail_message: _data.voicemail_message,
      wait_for_greeting: _data.wait_for_greeting,
      webhook: _data.webhook,
    };

    if (encryptedKey) {
      data.encrypted_key = encryptedKey;
    }

    return data;
  };

  const getPreviewData = () => {
    const schema = handleSaveConfiguration();
    const raw_payload = selected_payload || {};
    const json = parse_data(schema, raw_payload);
    return json || {};
  };

  const loadEncryptedKeys = async () => {
    try {
      const response = await fetch("/api/byot/get_keys", {
        method: "GET",
        headers: {
          authorization: getApiKey(),
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (Array.isArray(data) && data?.length > 0) {
          setEncryptedKeys(data);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const val = qpSearchAndSet();
    if (val) {
      loadEventReqHistoryById(val);
      loadEventById(val);
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (event?.id) {
        loadEventReqHistoryById(event?.id);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getUserPathways();
    getUserTools();
    loadEncryptedKeys();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#ced4da",
      width: "calc(100% + 10px)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#80bdff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#007bff"
        : state.isFocused
          ? "#e9ecef"
          : null,
      color: state.isSelected ? "white" : "black",
      padding: 10,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  useEffect(() => {
    if (user && user.voice_options) {
      // Separate options based on "Bland Curated" tag
      const mappedVoices = user.voice_options.map((doc) => ({
        value: doc.id,
        label: doc.name,
        type: doc?.tags?.includes("Bland Curated")
          ? "bland_curated"
          : "user_voices",
      }));

      methods.setValue("voice.options", mappedVoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!event || !event_history) return <div />;

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <Form>
          <Bar top>
            <div onClick={() => navigate("/dashboard?page=all-events")}>
              <PageTitle>Events {event && `/ ${event.event_name}`}</PageTitle>
            </div>

            <Button
              onClick={() => {
                navigator.clipboard.writeText(event?.id);
              }}
              appearance={"outline"}
              style={{
                marginLeft: "auto",
                marginRight: "10px",
                borderRadius: 6,
                height: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "7px",
                }}
              >
                <img src={CopyIcon} style={{ height: 10, width: 10 }} alt="" />
                <p>Copy Event Id</p>
              </div>
            </Button>

            <Button
              onClick={() => setPreviewModal(true)}
              appearance={"outline"}
              style={{ marginRight: "10px", borderRadius: 6, height: 30 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "7px",
                }}
              >
                <img
                  src={ExpandIcon}
                  style={{ height: 10, width: 10 }}
                  alt=""
                />
                <p>Preview Call Data</p>
              </div>
            </Button>

            <Button
              loading={updating}
              onClick={() => updateUserEvent()}
              style={{ borderRadius: 6, height: 30, marginRight: -58 }}
            >
              <p>Save Event</p>
            </Button>
          </Bar>
          <div
            style={{
              paddingLeft: 68,
              paddingRight: 10,
              position: "fixed",
              borderBottom: "1px solid #eeeeee",
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              height: 130,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "flex-start",
                columnGap: 10,
                marginTop: 0,
                width: "100%",
              }}
            >
              <div>
                <Label style={{ color: "#000" }}>Event Handler URL</Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 3,
                    marginTop: 5,
                  }}
                >
                  <Description
                    style={{
                      padding: "2px 4px",
                      background: "#eeeeee",
                      borderRadius: 4,
                      fontSize: 10,
                      fontWeight: 550,
                    }}
                  >
                    POST
                  </Description>
                  <Description>data from your server using</Description>
                  <Description
                    style={{
                      padding: "2px 4px",
                      background: "#eeeeee",
                      borderRadius: 4,
                      fontSize: 10,
                      fontWeight: 550,
                    }}
                  >
                    application/json
                  </Description>
                  <Description>
                    {" "}
                    to the Event Handler URL below to automatically dispatch
                    calls based on your schema.
                  </Description>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "flex-start",
                columnGap: 10,
                marginTop: 10,
                width: "100%",
              }}
            >
              <div style={{ width: "calc(100% - 32px)" }}>
                <StyledInput
                  disabled={true}
                  value={`${EVENT_BASE_URL}/${event.id}/handler`}
                  style={{ width: "100%" }}
                />
                <div
                  onClick={() => {
                    setCdModal(true);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 3,
                    marginTop: 5,
                  }}
                >
                  <Description style={{ fontWeight: "450" }}>
                    {" "}
                    Calls will use deployment:{" "}
                  </Description>
                  <Description
                    style={{
                      padding: "2px 4px",
                      background: "#eeeeee",
                      borderRadius: 4,
                      fontSize: 10,
                      fontWeight: 550,
                    }}
                  >
                    {methods.getValues("event_base_endpoint") || "api.bland.ai"}
                  </Description>
                  <img
                    src={SettingIcon}
                    style={{ height: 10, width: 10, cursor: "pointer" }}
                  />
                </div>
              </div>
              {/*
                                <Button onClick={() => setCdModal(true)} appearance={"outline"} style={{ height: 32, width: 38, padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={SettingIcon} style={{ height: 14, width: 14 }} alt="" />
                                </Button>
                            */}

              <Button
                onClick={() => setCdModal(true)}
                appearance={"outline"}
                style={{
                  height: 32,
                  width: 38,
                  padding: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SettingIcon}
                  style={{ height: 14, width: 14 }}
                  alt=""
                />
              </Button>

              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${EVENT_BASE_URL}/${event.id}/handler`,
                  );
                }}
                appearance={"outline"}
                style={{
                  height: 32,
                  width: 38,
                  padding: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={CopyIcon} style={{ height: 14, width: 14 }} alt="" />
              </Button>
            </div>
          </div>

          <Wrapper style={{}}>
            <Body style={{ padding: "0px 28px 15px 68px", marginTop: 15 }}>
              <Label style={{ color: "#000", fontSize: 14 }}>
                Configure Call
              </Label>
              <Description style={{ marginTop: 0 }}>
                Connect each field to the payload, enter a value manually, or
                combine payload fields and manual values using
                &#123;&#123;variable_name&#125;&#125; notation
              </Description>
              <Stack style={{ marginTop: 15 }}>
                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"phone_number"}
                  label={"Phone Number"}
                />

                <InputContainer>
                  <PhoneNumberSelector
                    fieldName={"from.value"}
                    userNumbers={inboundNumbers}
                    byotNumbers={twilioNumbers}
                    loading={phoneNumbersLoading}
                    error={phoneNumbersError}
                  />
                </InputContainer>

                {(methods.watch("from.value") || encryptedKey) && (
                  <HookInputDropdown
                    labelValueList={encryptedKeys?.map((item) => ({
                      label: "Encrypted key",
                      value: item.id,
                    }))}
                    label={"Encrypted Key"}
                    value={encryptedKey}
                    placeholder={"Encrypted Key"}
                    onChange={(value) => setEncryptedKey(value)}
                  />
                )}

                <p>{JSON.stringify(methods.watch("encrypted_key"))}</p>

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"task"}
                  label={"Prompt"}
                  prompt={true}
                />

                <InputContainer>
                  <Model fieldName={"model.value"} />
                </InputContainer>

                <VoicesDropdown
                  keypairs={flattenJSON(selected_payload)}
                  nested_options={methods.getValues("voice.options")}
                  fieldname={"voice"}
                  label={"Voice"}
                  apiKey={apiKey}
                />

                <SplitDropdown
                  keypairs={flattenJSON(selected_payload)}
                  nested_options={methods.getValues("language.options")}
                  fieldname={"language"}
                  label={"Language"}
                  tab_name={"Languages"}
                />

                <InputContainer>
                  <Temperature fieldName={"temperature"} />
                </InputContainer>

                <InputContainer>
                  <InterruptionThresh fieldName={"interruption_threshold"} />
                </InputContainer>

                <SplitDropdown
                  keypairs={flattenJSON(selected_payload)}
                  nested_options={methods.getValues("pathway_id.options")}
                  fieldname={"pathway_id"}
                  label={"Pathway"}
                  tab_name={"Pathways"}
                />

                <SplitDropdown
                  keypairs={flattenJSON(selected_payload)}
                  nested_options={methods.getValues("tools.options")}
                  fieldname={"tools"}
                  label={"Tools"}
                  tab_name={"Tools"}
                />

                <KeyValueDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"request_data"}
                  label={"Request Data"}
                />
                <InputContainer>
                  <AnalysisPreset fieldName={"analysis_preset"} />
                </InputContainer>

                <KeyValueDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"analysis_schema"}
                  label={"Analysis Schema"}
                />

                <BooleanDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"record"}
                  label={"Record"}
                />

                <BooleanDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"wait_for_greeting"}
                  label={"Wait For Greeting"}
                />

                <BooleanDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"answered_by_enabled"}
                  label={"Answered By"}
                />

                <BooleanDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"amd"}
                  label={"Answering Machine Detection"}
                />

                <BooleanDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"block_interruptions"}
                  label={"Block Interruptions"}
                />

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"first_sentence"}
                  label={"First Sentence"}
                />

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"voicemail_message"}
                  label={"Voicemail Message"}
                />

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"transfer_phone_number"}
                  label={"Transfer Phone Number"}
                />

                <KeyValueDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"transfer_list"}
                  label={"Transfer List"}
                />

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"summary_prompt"}
                  label={"Summary Prompt"}
                />

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"analysis_prompt"}
                  label={"Analysis Prompt"}
                />

                <PayloadDropdown
                  keypairs={flattenJSON(selected_payload)}
                  methods={methods}
                  fieldname={"webhook"}
                  label={"Webhook URL"}
                />

                <KeyValueDropdown
                  keypairs={flattenJSON(selected_payload)}
                  fieldname={"metadata"}
                  label={"Metadata"}
                />
              </Stack>
            </Body>

            <Popover>
              <div style={{ width: "100%", borderBottom: "1px solid #eeeeee" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    <Label style={{ color: "#000", fontSize: 14 }}>
                      Requests
                    </Label>
                    <Description>
                      This is where you can view and manage incoming requests.
                    </Description>
                  </div>
                  <img
                    src={ListeningGif}
                    height={20}
                    width={20}
                    style={{ marginLeft: "auto", marginRight: 10 }}
                  />
                  <Button
                    onClick={() => {
                      loadEventReqHistoryById(event.id);
                    }}
                    appearance={"outline"}
                    style={{
                      height: 32,
                      width: 38,
                      marginRight: -12,
                      padding: 0,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={RefreshIcon}
                      style={{ height: 14, width: 14 }}
                      alt=""
                    />
                  </Button>
                </div>

                <div
                  style={{
                    width: "calc(100% + 10px)",
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <Label style={{ color: "#000", fontWeight: "400" }}>
                      Test Mode
                    </Label>
                    <Description>
                      This will allow you to recieve data but not dispatch a
                      call on event.
                    </Description>
                  </div>
                  <CustomSwitch
                    isOn={testmode}
                    handleToggle={() => handleOnUpdateTestMode(testmode)}
                  />
                </div>

                <Label
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    color: "#000",
                    fontWeight: "400",
                  }}
                >
                  Incoming Request History
                </Label>
                <Select
                  styles={customStyles}
                  searchable={false}
                  direction="ltr"
                  onChange={(value) => {
                    let found = event_history?.find(
                      (doc) => doc.request_id === value[0].value,
                    )?.raw_data;
                    if (found.request_id === value[0].value) {
                      console.log("--matched--");
                      return methods.setValue("selected_payload", null);
                    }
                    methods.setValue("selected_payload", found);
                  }}
                  options={event_history?.map((val, ind) => ({
                    value: val.request_id,
                    label: `${formatTimestamp(val.created_at)}${ind === 0 ? "- (latest)" : ""}`,
                    createdAt: val.created_at,
                  }))}
                  placeholder="Select an option..."
                  noOptionsMessage={() => "No options available"}
                  noDataLabel={`Listening for events at: ${EVENT_BASE_URL}/${event.id}/handler`}
                  formatOptionLabel={(option, index) => (
                    <div>
                      <div style={{ fontSize: "small", color: "#6c757d" }}>
                        {index === 0 ? " (latest)" : ""}
                      </div>
                    </div>
                  )}
                />
              </div>

              <Button
                appearance={"outline"}
                style={{ height: 30, borderRadius: 6 }}
                onClick={() => {
                  setManualTriggerOpen(true);
                }}
              >
                <p style={{ fontSize: 12 }}>Trigger Request Manually</p>
              </Button>

              <Modal
                open={manualTriggerOpen}
                onClose={() => setManualTriggerOpen(false)}
              >
                <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>
                  Manual Trigger
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "darkgrey",
                    marginBottom: 10,
                  }}
                >
                  Send a test request to the event handler - then the Event
                  knows how your data is structured.
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "darkgrey",
                    marginBottom: 10,
                  }}
                >
                  Then, you'll be able to use that data in your call
                  configuration to change how each call is dispatched.
                </p>
                <Editor
                  height="30vh"
                  defaultLanguage="json"
                  defaultValue={JSON.stringify(manualTriggerBody, null, 2)}
                  onChange={(value) => setManualTriggerBody(value)}
                />
                <Button
                  onClick={() => {
                    try {
                      const json =
                        typeof manualTriggerBody === "string"
                          ? JSON.parse(manualTriggerBody)
                          : manualTriggerBody;
                      //console.log(json);
                      fetch(`${EVENT_BASE_URL}/${event.id}/handler`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: apiKey,
                        },
                        body: JSON.stringify(json),
                      })
                        .then((res) => res.json())
                        .then((data) => {
                          // console.log(data);
                          setManualTriggerOpen(false);
                          setTimeout(() => {
                            loadEventReqHistoryById(event.id);
                          }, 1000);
                        });
                    } catch (error) {
                      // console.log(error);
                      toast.error("Invalid JSON");
                    }
                  }}
                >
                  Send
                </Button>
              </Modal>

              <JsonViewer
                data={
                  selected_payload ||
                  event_history?.[event_history?.length - 1]?.raw_data ||
                  {}
                }
              />
            </Popover>
          </Wrapper>

          <Modal open={previewModal} onClose={() => setPreviewModal(false)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "500",
                  fontSize: 16,
                }}
              >
                Preview your call...
              </p>
              <img
                onClick={() => setPreviewModal(false)}
                style={{ cursor: "pointer" }}
                src={CloseIcon}
              />
            </div>

            <p
              style={{
                fontSize: 13,
                fontWeight: "300",
                color: "darkgrey",
                marginBottom: 10,
              }}
            >
              This is the payload that gets sent to dispatch a call based on
              your configured data. Tell your call based on configuration below.
            </p>
            <JsonViewer data={getPreviewData()} />

            <Button
              loading={loading}
              onClick={() => handleTestCall()}
              style={{ marginTop: 10, borderRadius: 6 }}
            >
              <p>Test Call</p>
            </Button>
          </Modal>

          <Modal open={cdModal} onClose={() => setCdModal(false)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "500",
                  fontSize: 16,
                }}
              >
                Custom Deployment
              </p>
              <img
                onClick={() => setCdModal(false)}
                style={{ cursor: "pointer" }}
                src={CloseIcon}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <StyledInput
                type="text"
                value={methods.watch("event_base_endpoint")}
                onChange={(e) =>
                  methods.setValue("event_base_endpoint", e.target.value)
                }
                placeholder="api.bland.ai"
                style={{ width: "100%" }}
              />

              <p
                onClick={() =>
                  methods.setValue("event_base_endpoint", "api.bland.ai")
                }
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 10,
                  textDecoration: "underline",
                  fontWeight: "400",
                }}
              >
                Use Default Endpoint
              </p>
            </div>

            <Button
              loading={loadingCd}
              onClick={() => handleUpdateDeploymentUrl()}
              style={{ height: 30, borderRadius: 6, marginTop: 20 }}
            >
              <p>Save Custom Deployment</p>
            </Button>
          </Modal>
        </Form>
      </FormProvider>
    </PageWrapper>
  );
}

{
  /*
                            <Stack>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                    <Label>Event Stream Feed</Label>
                                    <img src={ListeningGif} style={{ height: 20, width: 20, objectFit: 'fill' }} />
                                </div>

                                <StyledBox style={{ marginTop: 10 }}>
                                    {event_history?.length > 0 ? event_history?.map((doc, index) => (
                                        <div key={index} style={{ border: "1px solid #eeeeee", background: "#fff", padding: 10, borderRadius: 4, cursor: 'pointer' }}>
                                            <p style={{ fontSize: 12, fontWeight: 400, color: "#000" }}>Incoming Payload</p>
                                            <p style={{ fontSize: 10, fontWeight: 350, color: "darkgrey", marginTop: 2 }}>{JSON.stringify(doc?.raw_data || {})}</p>
                                        </div>
                                    )) : (
                                        <p style={{ textAlign: "center", fontWeight: '400', fontSize: 13, color: "lightgrey", padding: "20px 0px" }}>Event & Data will load here.</p>
                                    )}
                                </StyledBox>
                            </Stack>
*/
}

const CustomSwitch = ({ isOn, handleToggle }) => {
  return (
    <SwitchContainer onClick={handleToggle}>
      <SwitchLabel>
        <SwitchInput type="checkbox" checked={isOn} readOnly />
        <Slider />
      </SwitchLabel>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  cursor: pointer;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  cursor: pointer;

  &:checked + span {
    background-color: #4361ee;
  }

  &:checked + span:before {
    transform: translateX(20px);
  }
`;

const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const InputContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ClickOverlay = styled.div`
  position: relative;
  top: -40px;
  left: 0;
  height: 40px;
  cursor: pointer;
  background: transparent;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: grey;
  font-size: 13px;
  font-weight: 500;
`;

const Description = styled.p`
  margin: 0px;
  padding: 0px;
  color: darkgrey;
  font-size: 11px;
  font-weight: 400;
`;

const StyledBox = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #f0f3f6;
  gap: 10px;
`;

const StyledInput = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px;
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? "grey" : "black")};
  cursor: pointer;
  letter-spacing: 0.125px;
`;

const Bar = styled.div`
  position: fixed;
  bottom: ${({ bottom }) => (bottom ? "0px" : "auto")};
  top: ${({ top }) => (top ? "0px" : "auto")};
  background-color: white;
  z-index: 4000;
  height: 54px;
  width: ${({ sideMenu }) => (sideMenu ? "250px" : "calc(100%)")};
  display: flex;
  align-items: center;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "flex-start"};
  border-bottom: ${({ top }) => (top ? "1px solid #EEEEEE" : "none")};
  border-right: ${({ sideMenu }) => (sideMenu ? "1px solid #EEEEEE" : "none")};
  border-top: ${({ bottom }) => (bottom ? "1px solid #EEEEEE" : "none")};
  padding: 0px 69px;
  margin-left: ${({ sideMenu }) => (sideMenu ? "54px" : "0px")};
`;

const Body = styled.div`
  width: 65%;
  overflow-y: scroll;
  top: 184px;
  bottom: 0px;
  position: fixed;
  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px; /* Adjust the width for vertical scrollbar */
    height: 8px; /* Adjust the height for horizontal scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Optional: background of the track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #eeeeee; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
    border: 2px solid transparent; /* Space around thumb */
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc; /* Color of the scrollbar thumb on hover */
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #eeeeee #f1f1f1;

  /* Additional styles for the component */
  /* Adjust these as needed */
  padding: 16px;
  box-sizing: border-box;
`;

const Popover = styled.div`
  right: 0px;
  width: 35%;
  background: #fff;
  padding: 12.5px 22px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: fixed;
  top: 184px;
  bottom: 0px;
  overflow-y: scroll;
  border-left: 1px solid #eeeeee;
  -ms-overflow-style: none; /* Hide scrollbar */
  scrollbar-width: none; /* Hide scrollbar */
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just in case */
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  height: auto;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  padding: 54px 30px 0px 0px;
  background: #fff;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
